import EditDetailsPage from "./pages/protected_pages/EditDetailsPage";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUp";
import HowItWorks from "./pages/HowItWorks";
import ProtectedRoutes from "./components/utils/ProtectedRoutes";
import StudentAdsPage from "./pages/StudentsAdsPage";
import TutorsPage from "./pages/TutorsPage";
import PricingPage from "./pages/Pricing";
import FreeResources from "./pages/FreeResources";
import TermsOfService from "./pages/TermsOfSerivce";
import Privacy from "./pages/Privacy";
import RefundPolicy from "./pages/RefundPolicy";
import CookieBanner from "./components/utils/CookieBanner";
import Footer from "./components/reuseables/Footer";
import ContactButton from "./components/utils/ContactButton";
import PageNotFound from "./pages/pageNotFound";
import TutorProfile from "./pages/TutorProfile";
import BecomeATutorLandingPage from "./pages/BecomeATutorLandingPage";
import Unsuscribe from "./pages/Unsubscribe";
import Subscribe from "./pages/Subscribe";
import CAOCalculator from "./pages/CAOCalculator";

function App() {
  return (
    <>
      <div className="App paragraph-font">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="become-a-tutor" element={<BecomeATutorLandingPage />} />
          <Route path="login" element={<SignInPage />} />
          <Route path="sign-up" element={<SignUpPage />} />

          <Route path="how-it-works" element={<HowItWorks />} />
          <Route path="pricing" element={<PricingPage />} />
          <Route path="learning-resources" element={<FreeResources />} />

          {process.env.NODE_ENV === "development" && (
            <Route path="student-ads" element={<StudentAdsPage />} />
          )}
          <Route path="tutors" element={<TutorsPage />} />
          <Route path="tutors/:tutorSlug" element={<TutorProfile />} />

          <Route path="terms-of-service" element={<TermsOfService />} />
          <Route path="privacy-policy" element={<Privacy />} />
          <Route path="refund-policy" element={<RefundPolicy />} />
          <Route path="/unsubscribe" element={<Unsuscribe />} />
          <Route path="subscribe" element={<Subscribe />} />
          <Route path="cao-calculator" element={<CAOCalculator />} />
          <Route path="/*" element={<PageNotFound />} />

          {/* Must be signed in to access */}
          <Route element={<ProtectedRoutes />}>
            <Route path="dashboard" element={<EditDetailsPage />} />
          </Route>
        </Routes>
      </div>
      <CookieBanner />
      <ContactButton />
      <Footer />
    </>
  );
}

export default App;
