import { useState, useEffect } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import SubjectsFilterSelect from "./SubjectsFilterSelect";
import { useSearchParams } from "react-router-dom";
import LevelsFilterSelect from "./LevelsFilterSelect";
import { BsChevronDoubleRight } from "react-icons/bs";
import { HiChevronDoubleRight } from "react-icons/hi";

const FilterTutorsSection = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        variant="outline-dark"
        className="d-md-none d-flex mx-auto"
        onClick={handleShow}
      >
        <FaFilter className="my-auto mx-2" /> Filter
      </Button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        responsive="md"
        placement="start"
      >
        <Offcanvas.Header className="header-font" closeButton>
          <Offcanvas.Title>Filter Tutors</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="paragraph-font">
          <div className="w-100">
            <h5 className="header-font d-md-block d-none">Filter Tutors</h5>
            <hr className="mt-0 d-md-block d-none" />
            <Form>
              <Form.Group className="my-3">
                <Form.Label className="header-font mb-1 fs-5">Level</Form.Label>
                <LevelsFilterSelect />
              </Form.Group>
              <hr className="dashed" />
              <Form.Group className="my-3">
                <Form.Label className="header-font mb-1 fs-5">
                  Subjects
                </Form.Label>

                <SubjectsFilterSelect />
              </Form.Group>
              <hr className="dashed" />

              {searchParams.get("level") && (
                <Form.Group className="my-3">
                  <Form.Label className="header-font mb-1 fs-5">
                    Sort by Price
                  </Form.Label>
                  <Form.Check
                    type="switch"
                    defaultChecked={searchParams.get("sort") === "true"}
                    label="Sort Low to High?"
                    disabled={!searchParams.get("level")}
                    onChange={(e) => {
                      if (e.target.checked) {
                        // add sort to searchParams
                        const newSearchParams = new URLSearchParams(
                          searchParams
                        );
                        newSearchParams.set("sort", "true");
                        setSearchParams(newSearchParams);
                      } else {
                        // remove sort from searchParams
                        const newSearchParams = new URLSearchParams(
                          searchParams
                        );
                        newSearchParams.delete("sort");
                        setSearchParams(newSearchParams);
                      }
                    }}
                  />
                </Form.Group>
              )}
            </Form>
          </div>
          <div className="light-font d-md-none d-flex justify-content-end">
            <Button size="sm" onClick={() => setShow(false)}>
              View Tutors <HiChevronDoubleRight />
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default FilterTutorsSection;
