import { useEffect } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { useQuery } from "@tanstack/react-query";
import getMyContacts from "../../requests/getMyContacts";
import NoContactsAlert from "./contactsSection/NoContactsAlert";
import ContactBubbleEntire from "./contactsSection/ContactBubbleEntire";
import { useSearchParams } from "react-router-dom";

// can be modified for both components at once here
const LocalLoadingComp = () => (
  <div className="w-100 d-flex py-4">
    <Spinner className="mx-auto my-auto" style={{ width: 50, height: 50 }} />
  </div>
);
const LocalErrorComp = ({ children }: { children: any }) => (
  <Alert variant="danger" className="border-danger shadow mt-4">
    {children}
  </Alert>
);

const defaultErrorString =
  "An error occured while getting your contacts. Please try again later.";

const ContactsSection = () => {
  const { currentUser, accountType } = useAuth();
  const [searchParams] = useSearchParams();

  // this query gets invalidated if the user is a student & makes a new contact
  // so that contacts can be refecthed and displayed
  const { isLoading, isError, error, data } = useQuery(
    ["contacts", currentUser!.uid],
    () =>
      getMyContacts(
        currentUser?.uid || "",
        accountType,
        // so that if going directly to a contact, it appears first, and make it stand out below
        searchParams.get("contact")
      ),
    {
      staleTime: 1000 * 60 * 30, // 30 minutes
      refetchOnWindowFocus: true,
    }
  );

  useEffect(() => {
    if (window.innerWidth > 768) {
      document
        .getElementById("contacts-section-header")
        ?.scrollIntoView({ block: "center" });
    }
  }, []);

  if (accountType === "tutor" || accountType === "student") {
    return (
      <div>
        <div className="header-font fs-3" id="contacts-section-header">
          My Contacts
        </div>
        <hr />
        {isLoading && <LocalLoadingComp />}
        {isError && (
          <LocalErrorComp>
            {(error as any).message || defaultErrorString}
          </LocalErrorComp>
        )}
        {data && data.length > 0 ? (
          <div>
            {data.map((contact) => (
              <div
                key={contact.contactUid}
                className={`${
                  searchParams.get("contact") === contact.contactUid &&
                  "shadow-lg"
                }`}
              >
                <ContactBubbleEntire contact={contact} />
              </div>
            ))}
          </div>
        ) : (
          !isLoading && <NoContactsAlert accountType={accountType} />
        )}
      </div>
    );
  } else {
    return (
      <Alert variant="danger">
        An error occured loading your details. Refresh the page, and if this
        error persists please contact us to rectify it.
      </Alert>
    );
  }
};

export default ContactsSection;
