import React, { useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { UserCredential, User } from "firebase/auth";
import { auth, db } from "../firebase";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { subjectsAvailableInterface } from "../interfaces/websiteReadableInfo";
import privateUserDataInterface from "../interfaces/privateUserDataInterface";
import authenticatedReadableData from "../interfaces/authenticatedReadableData";

export interface AuthContextModel {
  // auth: Auth;
  currentUser: User | null;
  login: (email: string, password: string) => Promise<UserCredential>;
  logout: () => Promise<void>;
  signup: (email: string, password: string) => Promise<UserCredential>;
  accountType: string | null;
  userPrivateData: privateUserDataInterface | null;
  profileImageSrc: string;
  websiteReadableInfo: subjectsAvailableInterface | null;
  authenticatedReadableData: authenticatedReadableData | null;
}

const AuthContext = React.createContext<AuthContextModel>(
  {} as AuthContextModel
);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [accountType, setAccountType] = useState<string | null>(null);
  const [websiteReadableInfo, setWebsiteReadableInfo] =
    useState<subjectsAvailableInterface | null>(null);

  const [profileImageSrc, setProfileImageSrc] = useState<string>(
    "assets/no-profile-img1.png"
  );
  const [authenticatedReadableData, setAuthenticatedReadableData] =
    useState<authenticatedReadableData | null>(null);

  const [userPrivateData, setUserPrivateData] =
    useState<privateUserDataInterface | null>(null);

  function login(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function logout(): Promise<void> {
    return signOut(auth);
  }
  function signup(email: string, password: string): Promise<UserCredential> {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  useEffect(() => {
    let unsub = () => {};
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        // const profileImgDoc = doc(db, "profileImages", user.uid);
        // const profileImgSnap = await getDoc(profileImgDoc);
        unsub = onSnapshot(doc(db, "profileImages", user.uid), (doc) => {
          setProfileImageSrc(doc.data()?.imageUrl);
        });
        // setProfileImageSrc(profileImgSnap.data()?.imageUrl);
      } else {
        setProfileImageSrc("/assets/no-profile-img1.png");
      }

      // get website readable data here
      const websiteReadableInfoDoc = doc(
        db,
        "websiteReadableInfo",
        "subjectsAvailable"
      );
      const websiteReadableInfoSnap = await getDoc(websiteReadableInfoDoc);
      setWebsiteReadableInfo(
        websiteReadableInfoSnap.data() as subjectsAvailableInterface
      );

      setLoading(false);
    });

    return () => {
      unsubscribe();
      unsub();
    };
  }, []);

  // I've realised all this stuff below can probably go in the useeffect above lol
  useEffect(() => {
    const getData = async () => {
      if (currentUser) {
        // Get readable data for authenticated users
        const authReadableData = await getDoc(
          doc(db, "websiteReadableInfo", "authenticatedReadableData")
        );
        setAuthenticatedReadableData(
          authReadableData.data() as authenticatedReadableData
        );

        // This has to be a onSnapshot call because when user signs up
        // current user changes before the docs can be created, so must get the information
        // when the docs are created
        const getUserPrivateData = onSnapshot(
          doc(db, "allUsersPrivate", currentUser!.uid),
          (doc) => {
            setAccountType(doc.data()?.accountType);
            setUserPrivateData(doc.data() as privateUserDataInterface);
          }
        );
        return getUserPrivateData;
      } else {
        // console.log("fff");
        setAccountType(null);
        setUserPrivateData(null);
        setAuthenticatedReadableData(null);
      }
    };
    getData();
  }, [currentUser]);

  const value = {
    currentUser,
    login,
    logout,
    signup,
    accountType,
    userPrivateData,
    websiteReadableInfo,
    profileImageSrc,
    authenticatedReadableData,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
