import "../../styles/LoadingSpinnerBig.css";
const LoadingSpinnerBig = () => {
  return (
    <div className="loader-container-big">
      <div className="spinner-big"></div>
    </div>
  );
};

export default LoadingSpinnerBig;
