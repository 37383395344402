import { Alert } from "react-bootstrap";

const TutorReviewsSection = () => {
  return (
    <div className="p-2">
      <Alert
        style={{ backgroundColor: "#6cb2f035" }}
        className="border border-primary border-opacity-25 shadow light-font"
      >
        This section is coming soon!
      </Alert>
    </div>
  );
};

export default TutorReviewsSection;
