import { useRef, useState, useEffect, ReactNode } from "react";

interface Props {
  text: String;
  title: String;
  icon: ReactNode;
}

const SignUpBenefit = ({ text, title, icon }: Props) => {
  const domRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    let currentVal: any = null;
    if (!domRef.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    currentVal = domRef.current;
    return () => {
      if (currentVal) return observer.unobserve(currentVal);
    };
  }, []);

  return (
    <div
      className={`mt-3 mb-3 mt-md-1 mb-md-4 col-md-4 text-center fade-in-section ${
        isVisible ? "is-visible" : ""
      }`}
      ref={domRef}>
      <div
        style={{
          fontSize: "60px",
          objectFit: "cover",
          width: "100px",
          height: "100px",
          filter: "drop-shadow(0px 3px 5px #bbbbbb)",
        }}
        className="bg-dark rounded-circle shadow-lg mx-auto">
        {icon}
      </div>
      <div
        className="header-font mt-3"
        style={{ fontSize: "18px", letterSpacing: "0.05rem" }}>
        {title}
      </div>
      <p
        className="mt-2 mx-3"
        style={{ wordWrap: "break-word", textAlign: "justify" }}>
        {text}
      </p>
    </div>
  );
};

export default SignUpBenefit;
