import {
  DocumentData,
  QuerySnapshot,
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAt,
  where,
} from "firebase/firestore";
import { dbSubjectStrings } from "../constants/strings";
import { db } from "../firebase";
import { levelLabels } from "../interfaces/allTutorData";
import tutorUserData from "../interfaces/tutorUserData";

/**
 */

interface tempTutorData extends tutorUserData {
  id: string;
}

function getRandomChar() {
  const charSet =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const randomIndex = Math.floor(Math.random() * charSet.length);
  return charSet[randomIndex];
}

function shuffleArray(array: string[]) {
  for (let i = array.length - 1; i > 0; i--) {
    // Generate a random index between 0 and i (inclusive)
    const randomIndex = Math.floor(Math.random() * (i + 1));

    // Swap array[i] and array[randomIndex]
    [array[i], array[randomIndex]] = [array[randomIndex], array[i]];
  }
  return array;
}

function sortTutorIds(
  snap: QuerySnapshot<DocumentData>,
  level: string
): tempTutorData[] {
  const dataWithIds: tempTutorData[] = [];
  // Extract data and IDs from the snapshot
  snap.forEach((doc) => {
    dataWithIds.push({
      id: doc.id, // ID of the document
      ...(doc.data() as tutorUserData), // Rest of the document data
    });
  });

  // Sort based on a specific field, e.g., 'price'
  dataWithIds.sort((a, b) => {
    if (!a[`${level}Price`]) return 1;
    if (!b[`${level}Price`]) return -1;
    return a[`${level}Price`] - b[`${level}Price`];
  });
  return dataWithIds;
}

const getTutorIdsBySubject = async (
  subject: string,
  level: string,
  sortTutors: boolean,
  tutorSlugInParams: string
) => {
  console.log("getting tutors");
  // console.log(tutorSlugInParams)
  // if there is a tutorIdInParams, I can look for that tutor, and then get two more random ones
  // but if it doesn't exist, or if displayProfile is false, I can't do that so i need to get 3 random ones or ones with the subject
  // only problem with this is if the tutorIdInParams is not in the db, or if displayProfile is false
  // read the tutors doc, and check if displayProfile is true or if it exists
  let tutorWantedID = "";
  if (!!tutorSlugInParams) {
    let canReadTutor = true;
    const tutorRef = collection(db, "tutors");
    const tutorWantedQuery = query(
      tutorRef,
      where("slug", "==", tutorSlugInParams),
      where("displayProfile", "==", true)
    );

    try {
      const tutorWantedSnap = await getDocs(tutorWantedQuery);

      if (tutorWantedSnap.empty) {
        canReadTutor = false;
      } else {
        const tutorWantedDoc = tutorWantedSnap.docs[0];
        const tutorWantedData = tutorWantedDoc.data();
        // should always be true, but just in case
        canReadTutor = tutorWantedData.displayProfile;
        tutorWantedID = tutorWantedDoc.id;
      }
    } catch (err) {
      canReadTutor = false;
    }

    // if we can read tutor, return the tutorIdInParams, and two more random ones
    if (canReadTutor) {
      // get two more tutorIds, and return, with this one as the first one
      const ref = collection(db, "tutors");
      const randomChar = getRandomChar();
      const twoMoreTutorsQuery = query(
        ref,
        where("displayProfile", "==", true),
        orderBy("__name__"),
        startAt(randomChar),
        limit(2)
      );

      const snap = await getDocs(twoMoreTutorsQuery);

      if (snap.size < 2) {
        // if less than 2, get the rest from the beginning
        const q3 = query(
          ref,
          where("displayProfile", "==", true),
          orderBy("__name__"),
          limit(2 - snap.size)
        );
        const snap2 = await getDocs(q3);
        return [
          tutorWantedID,
          ...snap.docs.map((doc) => doc.id),
          ...snap2.docs.map((doc) => doc.id),
        ];
      }

      return [tutorWantedID, ...snap.docs.map((doc) => doc.id)];
    }
  }

  if (!!!subject) {
    // if no subject,
    // get 3 random tutors
    // but if there is a level selected, get tutors with that level
    const ref = collection(db, "tutors");
    if (!!level) {
      const q = query(
        ref,
        where("displayProfile", "==", true),
        where(`${level}Subjects`, "!=", []),
        orderBy(`${level}Subjects`)
      );

      const snap = await getDocs(q);
      if (sortTutors) {
        const sortedByPrice = sortTutorIds(snap, level);
        return sortedByPrice.map((doc) => doc.id);
      } else {
        return snap.docs.map((doc) => doc.id);
      }
    } else {
      // no level or subject
      // get 3 random tutors
      const randomChar = getRandomChar();
      const q2 = query(
        ref,
        where("displayProfile", "==", true),
        orderBy("__name__"),
        startAt(randomChar),
        limit(3)
      );

      const snap = await getDocs(q2);

      if (snap.size < 3) {
        // if less than 3, get the rest from the beginning
        const q3 = query(
          ref,
          where("displayProfile", "==", true),
          orderBy("__name__"),
          limit(3 - snap.size)
        );
        const snap2 = await getDocs(q3);
        return [
          ...snap.docs.map((doc) => doc.id),
          ...snap2.docs.map((doc) => doc.id),
        ];
      }
      return snap.docs.map((doc) => doc.id);
    }
  }

  if (!!subject && !!level) {
    // if there is a subject, and a level
    // find tutors with that subject and level
    const ref = collection(db, "tutors");
    const q = query(
      ref,
      where("displayProfile", "==", true),
      where(`${level}Subjects`, "array-contains", subject)
    );

    const snap = await getDocs(q);
    if (sortTutors) {
      const sortedByPrice = sortTutorIds(snap, level);
      return sortedByPrice.map((doc) => doc.id);
    } else {
      const tutorIds = snap.docs.map((doc) => doc.id);
      // shuffle them
      const shuffled = shuffleArray(tutorIds);
      return shuffled;
    }
  }

  // if there is a subject, get tutors with that subject
  const tutorIds = await Promise.all(
    dbSubjectStrings.map(async (dbSubjectString) => {
      const ref = collection(db, "tutors");
      const q = query(
        ref,
        where("displayProfile", "==", true),
        where(`${dbSubjectString}Subjects`, "array-contains", subject)
      );

      const snap = await getDocs(q);
      return snap.docs.map((doc) => doc.id);
    })
  );
  const flattened = tutorIds.flat();

  if (flattened.length === 0) return [];

  // typescript ignore
  // @ts-ignore
  const removedDupes = [...new Set(flattened)];

  // randomize the order
  const shuffled = shuffleArray(removedDupes);

  // console.log(removedDupes)
  return shuffled;
};

export default getTutorIdsBySubject;
