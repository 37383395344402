import { useState, useRef, useEffect } from "react";
// Import Swiper React components
import { EffectCards } from "swiper/modules";
import { Swiper as SwiperComp, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import TutorCard from "./TutorCard";
import SubjectsFilterSelect from "./SubjectsFilterSelect";
import { Button, Form, Pagination } from "react-bootstrap";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";

// typescript was complaining about the props, so I just made it 'any'
const props: any = {
  effect: "cards",
  grabCursor: true,
  centeredSlides: false,
  modules: [EffectCards],
};

type Props = {
  tutorIds: Array<string>;
  selectedSubject: string;
};

export default function PlanATutors({ tutorIds, selectedSubject }: Props) {
  const [listOfThreeTutorsToShow, setListOfThreeTutorsToShow] = useState(
    tutorIds?.slice(0, 3) || []
  );
  const swiperRef = useRef<any>(null);
  const [searchParams] = useSearchParams();

  // current page is 0-indexed!!!!!!!!
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(
    Math.ceil(tutorIds?.length / 3)
  );

  // no will be the last index of the tutor in the tutorIds array
  const [lastIndexOfTutor, setLastIndexOfTutor] = useState(
    tutorIds?.length < 3 ? tutorIds?.length - 1 : 2
  );
  const [currentSlide, setCurrentSlide] = useState(0);

  // leave this commented out
  // useEffect(() => {
  //   setListOfThreeTutorsToShow(
  //     tutorIds?.slice(lastIndexOfTutor - 2, lastIndexOfTutor + 1) || []
  //   );
  // }, [tutorIds, lastIndexOfTutor]);

  useEffect(() => {
    setListOfThreeTutorsToShow(tutorIds?.slice(0, 3) || []);
    setLastIndexOfTutor(2);
    setNumberOfPages(Math.ceil(tutorIds?.length / 3));
  }, [searchParams]);

  // const handleSeeMore = () => {
  //   if (tutorIds.length > lastIndexOfTutor + 3) {
  //     // console.log("1");
  //     setListOfThreeTutorsToShow(
  //       tutorIds?.slice(lastIndexOfTutor + 1, lastIndexOfTutor + 4) || []
  //     );
  //     setLastIndexOfTutor((prevVal) => prevVal + 3);
  //     swiperRef.current?.swiper?.slideTo(0);
  //   } else if (tutorIds.length - lastIndexOfTutor <= 3) {
  //     // console.log("2");
  //     console.log(lastIndexOfTutor);
  //     const tutorsRemaining = tutorIds.length - (lastIndexOfTutor + 1);
  //     setListOfThreeTutorsToShow(
  //       tutorIds?.slice(
  //         lastIndexOfTutor + 1,
  //         lastIndexOfTutor + tutorsRemaining + 1
  //       ) || []
  //     );
  //     setLastIndexOfTutor((prevVal) => prevVal + tutorsRemaining);
  //     swiperRef.current?.swiper?.slideTo(0);
  //   }
  // };
  const handleChangePage = (pageNumber: number) => {
    if (pageNumber === currentPage) return;
    setCurrentPage(pageNumber);
    setListOfThreeTutorsToShow(
      tutorIds?.slice(pageNumber * 3, pageNumber * 3 + 3) || []
    );
    // setLastIndexOfTutor(pageNumber * 3 + 2);
    swiperRef.current?.swiper?.slideTo(0);
  };
  useEffect(() => {
    setLastIndexOfTutor(
      tutorIds.indexOf(
        listOfThreeTutorsToShow[listOfThreeTutorsToShow.length - 1]
      )
    );
  }, [listOfThreeTutorsToShow]);

  const slideNext = () => {
    swiperRef.current?.swiper?.slideNext();
  };

  const slidePrev = () => {
    swiperRef.current?.swiper?.slidePrev();
  };
  console.log("Lasrt", lastIndexOfTutor);
  console.log("len", tutorIds.length - 1);
  return (
    <div style={{ overflow: "hidden" }}>
      <SwiperComp
        style={{ width: "100%" }}
        onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
        {...props}
        className="py-3 container fluid w-75"
        ref={swiperRef}
      >
        {listOfThreeTutorsToShow.map((tutorId) => (
          <SwiperSlide
            className="card bg-light"
            style={{ width: "minmax(200px, 100%)" }}
            key={tutorId}
          >
            <TutorCard tutorUid={tutorId} />
          </SwiperSlide>
        ))}
        <SwiperSlide
          className="card bg-light"
          style={{ width: "minmax(200px, 100%)" }}
        >
          {tutorIds.length > 2 ? (
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
              {lastIndexOfTutor !== tutorIds.length - 1 ? (
                <div className="light-font d-flex flex-column">
                  <span>Want to see more tutors?</span>
                  <Button
                    onClick={() => handleChangePage(currentPage + 1)}
                    className="d-flex justify-content-center"
                  >
                    See next page <FiChevronRight className="my-auto ms-2" />
                  </Button>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center h-100">
                  <Form.Label className="header-font mb-1 fs-5">
                    Select a Subject to view more tutors
                  </Form.Label>
                  <SubjectsFilterSelect />
                </div>
              )}
            </div>
          ) : (
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
              <Form.Label className="header-font mb-1 fs-5 text-center px-2">
                Select a Subject to view more tutors
              </Form.Label>
              <SubjectsFilterSelect />
            </div>
          )}
        </SwiperSlide>
      </SwiperComp>
      <div className="d-flex justify-content-around mt-2">
        <Button
          variant={currentSlide === 0 ? "outline-primary" : "primary"}
          className="d-flex"
          disabled={currentSlide === 0}
          onClick={slidePrev}
        >
          <FiChevronLeft className="mx-auto my-auto" />
        </Button>
        <Button
          variant={
            currentSlide === lastIndexOfTutor + 1 - currentPage * 3
              ? "outline-primary"
              : "primary"
          }
          className="d-flex"
          disabled={currentSlide === lastIndexOfTutor + 1 - currentPage * 3}
          onClick={slideNext}
        >
          <FiChevronRight className="mx-auto my-auto" />
        </Button>
      </div>
      {/* Pagination selection */}
      <div className="d-flex w-100 justify-content-center mt-2">
        <Pagination className="light-font">
          <Pagination.Prev
            className="mx-1"
            disabled={currentPage === 0}
            onClick={() => handleChangePage(currentPage - 1)}
          />
          {Array.from(Array(numberOfPages).keys()).map((pageNumber) => (
            <Pagination.Item
              className="mx-1"
              key={pageNumber}
              active={pageNumber === currentPage}
              onClick={() => handleChangePage(pageNumber)}
            >
              {pageNumber + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            className="mx-1"
            disabled={currentPage === numberOfPages - 1}
            onClick={() => handleChangePage(currentPage + 1)}
          />
        </Pagination>
      </div>
      {/* <SwiperBox /> */}
    </div>
  );
}

// export default PlanATutors;
