import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Form,
  OverlayTrigger,
  Popover,
  Spinner,
  Table,
} from "react-bootstrap";
import { AiFillQuestionCircle, AiOutlineWarning } from "react-icons/ai";
import { useAuth } from "../../contexts/AuthContext";

import studentUserData from "../../interfaces/studentUserData";
import tutorAboutInterface from "../../interfaces/tutorAboutInterface";
import tutorUserData, {
  availabilityDayObject,
} from "../../interfaces/tutorUserData";
import AvailabilityTable from "./AvailabilityTable";
import { Link } from "react-router-dom";
import { FiExternalLink } from "react-icons/fi";
import DisplayProfileAlert from "./DisplayProfileAlert";

const PersonalDetailsForm = ({
  userData,
  setUserDataCallback,
  tutorAbout,
}: {
  userData: studentUserData | tutorUserData;
  setUserDataCallback: (
    data: studentUserData | tutorUserData,
    tutorAbout: tutorAboutInterface
  ) => Promise<void>;
  tutorAbout: tutorAboutInterface | null;
}) => {
  const regName = /^[a-zA-Z ']+$/;
  const COUNTIES = [
    "carlow",
    "cavan",
    "clare",
    "cork",
    "donegal",
    "dublin",
    "galway",
    "kerry",
    "kildare",
    "kilkenny",
    "laois",
    "leitrim",
    "limerick",
    "longford",
    "louth",
    "mayo",
    "meath",
    "monaghan",
    "offaly",
    "roscommon",
    "sligo",
    "tipperary",
    "waterford",
    "westmeath",
    "wexford",
    "wicklow",
  ];

  const { accountType, profileImageSrc } = useAuth();

  const subheadingRef = useRef<HTMLTextAreaElement>(null);
  const fullyBookedRef = useRef<HTMLInputElement>(null);
  const freeConsultationRef = useRef<HTMLInputElement>(null);
  const locationOnlineRef = useRef<HTMLInputElement>(null);
  const locationStudentPlaceRef = useRef<HTMLInputElement>(null);
  const locationTutorsPlaceRef = useRef<HTMLInputElement>(null);

  const [atStudentLocationState, setAtStudentLocationState] = useState(
    userData.locationStudentsPlace || false
  );
  const [atTutorLocationState, setAtTutorLocationState] = useState(
    userData.locationTutorsPlace || false
  );

  const [availability, setAvailability] =
    useState<Array<availabilityDayObject> | null>(
      userData.availability || null
    );

  const displayProfileRef = useRef<HTMLInputElement>(null);
  const inPersonAreaRef = useRef<HTMLInputElement>(null);
  const inPersonCountyRef = useRef<HTMLSelectElement>(null);
  const travelRadiusRef = useRef<HTMLInputElement>(null);

  const [rangeState, setRangeState] = useState(userData.travelRadius || 0);

  const tutorAboutRef = useRef<HTMLTextAreaElement>(null);
  const [subheadingCount, setSubheadingCount] = useState(
    userData.subheading?.length || 0
  );
  const [aboutCount, setAboutCount] = useState(
    tutorAbout?.aboutSection.length || 0
  );

  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertVariant, setAlertVariant] = useState("danger");

  const styles = {
    pop: {
      backgroundColor: "#dedede",
    },
  };

  useEffect(() => {
    // some tutors (that signed up early) wont have availability in DB
    // so adding this for them so no errors when mapping through
    // and no errors when setting availibilty on any day
    if (accountType === "tutor" && !availability) {
      const placeholderAvailability = [];
      for (let i = 0; i < 7; i++) {
        placeholderAvailability.push({
          morning: false,
          afternoon: false,
          evening: false,
        });
      }
      setAvailability(placeholderAvailability);
    }

    if (window.innerWidth > 768) {
      document
        .getElementById("personal-details-header")
        ?.scrollIntoView({ block: "center", inline: "start" });
    }
  }, []);

  const saveStudentPersonalDetails = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    setError("Loading...");
    setAlertVariant("info");

    if (
      !regName.test(firstNameRef.current!.value) ||
      !regName.test(lastNameRef.current!.value)
    ) {
      setAlertVariant("danger");
      return setError(
        "Invalid name inputted. Verify there are no spaces or symbols in your name."
      );
    }
    if (
      !firstNameRef.current!.value.trim() ||
      !lastNameRef.current!.value.trim()
    ) {
      setAlertVariant("danger");
      return setError(
        "Invalid name inputted. Verify there are no spaces or symbols in your name."
      );
    }
    const fn =
      firstNameRef.current!.value.trim().charAt(0).toUpperCase() +
      firstNameRef.current!.value.trim().slice(1).toLowerCase();

    const ln =
      lastNameRef.current!.value.trim().charAt(0).toUpperCase() +
      lastNameRef.current!.value.trim().slice(1).toLowerCase();

    const data: studentUserData = {
      firstName: fn,
      lastName: ln,
      displayName: fn + " " + ln,
    };
    const tutorAbout: tutorAboutInterface = {
      aboutSection: "Student",
    };
    await setUserDataCallback(data, tutorAbout)
      .then(() => {
        setError("Details Updated Succesfully.");
        setAlertVariant("info");
      })
      .catch((e) => {
        setError(e.message);
        setAlertVariant("danger");
      });

    const target = e.target as HTMLFormElement;
    target.reset();
    setLoading(false);
  };

  const saveTutorPersonalDetails = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    setError("Loading...");
    setAlertVariant("info");
    // console.log("updating data");

    const name = { fn: "", ln: "" };

    if (!firstNameRef.current?.value) {
      name.fn = userData.firstName;
    } else {
      name.fn =
        firstNameRef.current!.value.trim().charAt(0).toUpperCase() +
        firstNameRef.current!.value.trim().slice(1).toLowerCase();
    }
    if (!lastNameRef.current?.value) {
      name.ln = userData.lastName;
    } else {
      name.ln =
        lastNameRef.current!.value.trim().charAt(0).toUpperCase() +
        lastNameRef.current!.value.trim().slice(1).toLowerCase();
    }
    // Test names for invalid inputs
    if (!regName.test(name.fn) || !regName.test(name.ln)) {
      setLoading(false);
      setAlertVariant("danger");
      return setError(
        "Invalid name inputted. Verify there are no spaces or symbols in your name."
      );
    }

    let countyToSet;
    let areaToSet;
    let travelR;

    if (
      locationStudentPlaceRef.current?.checked ||
      locationTutorsPlaceRef.current?.checked
    ) {
      countyToSet = inPersonCountyRef.current!.value;
      areaToSet = inPersonAreaRef.current!.value.toLowerCase();
    } else {
      countyToSet = userData.inPersonCounty || "";
      areaToSet = userData.inPersonArea || "";
    }

    if (locationStudentPlaceRef.current?.checked) {
      travelR = parseInt(travelRadiusRef.current!.value) / 5;
    } else {
      travelR = userData.travelRadius || 0;
    }

    const data = {
      firstName: name.fn,
      lastName: name.ln,
      displayName: name.fn + " " + name.ln,
      subheading: subheadingRef.current?.value || userData.subheading || "",
      currentlyBookedUp: fullyBookedRef.current!.checked,
      offersFreeConsultation: freeConsultationRef.current!.checked,
      locationOnline: locationOnlineRef.current!.checked,
      locationStudentsPlace: locationStudentPlaceRef.current!.checked,
      locationTutorsPlace: locationTutorsPlaceRef.current!.checked,
      displayProfile: userData.displayProfile,
      inPersonArea: areaToSet,
      inPersonCounty: countyToSet,
      travelRadius: travelR,
      availability: availability,
    };

    const tutorAboutSectionForCallback: tutorAboutInterface = {
      aboutSection:
        tutorAboutRef.current?.value || tutorAbout?.aboutSection || "",
    };

    await setUserDataCallback(data, tutorAboutSectionForCallback)
      .then(() => {
        setError("Details Updated Succesfully.");
        setAlertVariant("info");
      })
      .catch((e) => {
        setError(e.message);
        setAlertVariant("danger");
      });

    const target = e.target as HTMLFormElement;
    target.reset();
    setLoading(false);
  };

  const handleAvailabilityChange = (
    dayIndex: number,
    timeOfDay: keyof availabilityDayObject
  ) => {
    if (availability) {
      const newAvailability = [...availability];
      newAvailability[dayIndex][timeOfDay] =
        !newAvailability[dayIndex][timeOfDay];
      setAvailability(newAvailability);
    }
  };

  if (accountType === "tutor") {
    // tutor account personal details
    return (
      <Form onSubmit={saveTutorPersonalDetails}>
        <div className="header-font fs-3 d-flex" id="personal-details-header">
          My Details
          <div className="ms-auto paragraph-font">
            <Link
              className="btn btn-outline-success d-flex"
              target="_blank"
              to={`/tutors/${userData.slug}`}
            >
              View your Profile <FiExternalLink className="my-auto ms-2" />
            </Link>
          </div>
        </div>
        <hr />
        {/* This only displayed if accountType = tutor */}
        <DisplayProfileAlert tutorData={userData as tutorUserData} />
        {!userData.locationOnline &&
          !userData.locationStudentsPlace &&
          !userData.locationTutorsPlace && (
            <Alert variant="warning">
              <AiOutlineWarning className="text-danger fs-4 me-2 my-auto" />
              You have not selected any location for your tutoring classes.
              Select at least one to let students know where your classes will
              take place and make sure to save your changes using the button at
              the bottom of the page.
            </Alert>
          )}
        <div>
          <div className="d-flex my-2">
            <Form.Check
              className="mx-2"
              ref={fullyBookedRef}
              type="switch"
              id="fully-booked-switch"
              label="Currently Fully Booked?"
              defaultChecked={userData.currentlyBookedUp}
            />
            <OverlayTrigger
              overlay={
                <Popover className="fs-6">
                  <Popover.Header className="bg-dark text-light paragraph-font">
                    Fully Booked?
                  </Popover.Header>
                  <Popover.Body style={styles.pop} className="paragraph-font">
                    Toggle this switch to display to students that you are
                    currently fully booked.
                  </Popover.Body>
                </Popover>
              }
            >
              <div>
                <AiFillQuestionCircle className="mx-2 fs-4 mb-1" />
              </div>
            </OverlayTrigger>
          </div>
          <div className="d-flex my-2">
            <Form.Check
              className="mx-2"
              ref={freeConsultationRef}
              type="switch"
              id="free-consultation-switch"
              label="Offer Free Consultation?"
              defaultChecked={userData.offersFreeConsultation}
            />
            <OverlayTrigger
              overlay={
                <Popover className="fs-6">
                  <Popover.Header className="bg-dark text-light paragraph-font">
                    Offer Free Consultation?
                  </Popover.Header>
                  <Popover.Body style={styles.pop} className="paragraph-font">
                    Toggle this switch to display to students that you offer a
                    free 15 minute consultation so they can get to know you.
                    This is a great way to attract more students and we
                    recommend you do this if you are only starting off on your
                    tutoring journey!
                  </Popover.Body>
                </Popover>
              }
            >
              <div>
                <AiFillQuestionCircle className="mx-2 fs-4 mb-1" />
              </div>
            </OverlayTrigger>
          </div>

          {/* <div
            className={`d-flex my-2  ${userData.displayProfile && "d-none"}`}
          >
            <Form.Check
              // onChange={(e) => (e.target.checked = true)}
              className={"mx-2"}
              ref={displayProfileRef}
              type="switch"
              id="display-profile-switch"
              label="Display Profile?"
              defaultChecked={userData.displayProfile}
              disabled={userData.displayProfile}
              onChange={(e) => {
                if (
                  e.target.checked &&
                  profileImageSrc.includes("no_profile_letter_images")
                ) {
                  // check if profile image is set
                  console.log("no profile image set");
                  alert(
                    "You must set a profile image to display your profile."
                  );
                  e.target.checked = false;
                }
              }}
            />
            <OverlayTrigger
              overlay={
                <Popover className="fs-6">
                  <Popover.Header className="bg-dark text-light paragraph-font">
                    Display Profile?
                  </Popover.Header>
                  <Popover.Body style={styles.pop} className="paragraph-font">
                    Toggle this switch to display your profile to students on
                    the tutors page. Once checked, students will be able to see
                    your profile and contact you.
                  </Popover.Body>
                </Popover>
              }
            >
              <div>
                <AiFillQuestionCircle className="mx-2 fs-4 mb-1" />
              </div>
            </OverlayTrigger>
          </div> */}
        </div>

        <div className="d-flex flex-column flex-lg-row justify-content-around">
          <Form.Group id="edit-first-name-tutor" className="mt-3 me-lg-2 w-100">
            <Form.Label className="header-font">First Name</Form.Label>
            <Form.Control
              type="text"
              ref={firstNameRef}
              name="first-name"
              placeholder={userData.firstName}
            />
          </Form.Group>
          <Form.Group
            id="edit-second-name-tutor"
            className="mt-3 ms-lg-2 w-100"
          >
            <Form.Label className="header-font">Last Name</Form.Label>
            <Form.Control
              type="text"
              ref={lastNameRef}
              name="last-name"
              placeholder={userData.lastName}
            />
          </Form.Group>
        </div>
        <Form.Group
          className="mb-4 mt-4"
          // controlId="exampleForm.ControlTextarea1"
        >
          <Form.Label className="header-font d-flex">
            Short Intro About Yourself
            <OverlayTrigger
              overlay={
                <Popover className="fs-6">
                  <Popover.Header className="bg-dark text-light paragraph-font">
                    Short Introduction
                  </Popover.Header>
                  <Popover.Body style={styles.pop} className="paragraph-font">
                    Craft a brief introduction about yourself for your profile,
                    which students/parents can view on the "Find a tutor" page.
                    Make sure to stand out from other tutors by highlighting
                    your unique qualifications, experience, and teaching style.
                    This intro is your chance to make a great first impression,
                    so keep it engaging and memorable!
                  </Popover.Body>
                </Popover>
              }
            >
              <div>
                <AiFillQuestionCircle className="mx-2 fs-4 mb-1" />
              </div>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            ref={subheadingRef}
            as="textarea"
            rows={3}
            maxLength={100}
            onChange={(e) => setSubheadingCount(e.target.value.length)}
            placeholder={
              userData.subheading
                ? ""
                : 'You don\'t currently have a "Short Introduction" section set. Set one now to try and attract students!'
            }
            defaultValue={userData.subheading || ""}
          />
          <div className="char-count float-end" style={{ fontSize: "14px" }}>
            <span className="qm-current">{subheadingCount}</span>
            <span className="qm-limit">/100</span>
          </div>
        </Form.Group>
        <Form.Group
          className="mb-4 mt-4"
          // controlId="exampleForm.ControlTextarea1"
        >
          <Form.Label className="header-font d-flex">
            About You
            <OverlayTrigger
              overlay={
                <Popover className="fs-6">
                  <Popover.Header className="bg-dark text-light paragraph-font">
                    About You
                  </Popover.Header>
                  <Popover.Body style={styles.pop} className="paragraph-font">
                    Your tutor bio on our website can be 1 to 1500 characters
                    long and should aim to convince students/parents to contact
                    you for tutoring. Highlight your qualifications, experience,
                    and teaching style, and make sure to stand out. Be clear,
                    concise, and engaging in your writing. This bio is your
                    chance to make a great first impression, so craft it
                    carefully.
                  </Popover.Body>
                </Popover>
              }
            >
              <div>
                <AiFillQuestionCircle className="mx-2 fs-4 mb-1" />
              </div>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            ref={tutorAboutRef}
            as="textarea"
            rows={6}
            maxLength={1500}
            onChange={(e) => setAboutCount(e.target.value.length)}
            placeholder={
              tutorAbout?.aboutSection
                ? ""
                : 'You don\'t currently have an "About" section set. Set one now to start attracting students!'
            }
            defaultValue={tutorAbout?.aboutSection || ""}
          />
          <div className="char-count float-end" style={{ fontSize: "14px" }}>
            <span className="qm-current">{aboutCount}</span>
            <span className="qm-limit">/1500</span>
          </div>
        </Form.Group>
        <Form.Group>
          <Form.Label className="header-font">
            Available for classes:
          </Form.Label>
          <div className="d-flex flex-column flex-lg-row justify-content-between mx-lg-4">
            <Form.Check
              ref={locationOnlineRef}
              className="mx-2 my-2"
              type="switch"
              id="class-online-switch"
              label="Online"
              defaultChecked={userData.locationOnline || false}
            />
            <Form.Check
              ref={locationStudentPlaceRef}
              className="mx-2 my-2"
              type="switch"
              id="class-students-place-switch"
              label="At Students Location"
              defaultChecked={userData.locationStudentsPlace || false}
              onClick={(e) => {
                setAtStudentLocationState(e.currentTarget.checked);
              }}
            />
            <Form.Check
              ref={locationTutorsPlaceRef}
              className="mx-2 my-2"
              type="switch"
              id="class-tutors-place-switch"
              label="At Tutors Location"
              defaultChecked={userData.locationTutorsPlace || false}
              onClick={(e) => {
                setAtTutorLocationState(e.currentTarget.checked);
              }}
            />
          </div>
        </Form.Group>
        {(atStudentLocationState || atTutorLocationState) && (
          <Form.Group className="mt-3">
            <Form.Label className="header-font">
              In Person Location <span className="text-danger fw-bold">*</span>
            </Form.Label>
            <div className="d-flex flex-column flex-lg-row justify-content-around">
              <Form.Select
                required
                defaultValue={userData.inPersonCounty || ""}
                key={userData.inPersonCounty + "-select"}
                ref={inPersonCountyRef}
                className="mt-1 me-lg-2 w-100"
              >
                <option value="" className="text-muted">
                  Select County
                </option>
                {COUNTIES.map((county) => {
                  return (
                    <option value={county} key={county}>
                      {county.charAt(0).toUpperCase() + county.slice(1)}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Control
                maxLength={20}
                required
                ref={inPersonAreaRef}
                defaultValue={
                  userData.inPersonArea
                    ? userData.inPersonArea.charAt(0).toUpperCase() +
                      userData.inPersonArea.slice(1).toLowerCase()
                    : ""
                }
                className="mt-2 mt-lg-1 me-lg-2 w-100"
                type="text"
                placeholder="Area for In-Person Grinds/Tutoring"
              />
            </div>
          </Form.Group>
        )}
        {atStudentLocationState && (
          <Form.Group className="mt-4">
            <Form.Label className="header-font d-flex">
              Travel Radius
              <OverlayTrigger
                overlay={
                  <Popover className="fs-6">
                    <Popover.Header className="bg-dark text-light paragraph-font">
                      Travel Radius
                    </Popover.Header>
                    <Popover.Body style={styles.pop} className="paragraph-font">
                      Select how far you are willing to travel to a designated
                      location, or to the students place for tutoring.
                    </Popover.Body>
                  </Popover>
                }
              >
                <div>
                  <AiFillQuestionCircle className="mx-2 fs-4 mb-1" />
                </div>
              </OverlayTrigger>
            </Form.Label>
            <div>
              <Form.Range
                required
                ref={travelRadiusRef}
                defaultValue={userData.travelRadius * 5 || 0}
                onChange={(e) => setRangeState(parseInt(e.target.value) / 5)}
              />
              <div className="me-5 ms-2 my-auto text-center header-font">
                {rangeState}
                {rangeState === 20 ? "+" : ""} km
              </div>
            </div>
          </Form.Group>
        )}
        <div className="my-3">
          <Form.Label className="header-font">Availability:</Form.Label>
          <AvailabilityTable
            availability={availability}
            handleAvailabilityChange={handleAvailabilityChange}
          />
        </div>

        {error && (
          <Alert variant={alertVariant} className="mt-2">
            {error}
          </Alert>
        )}
        <div className="d-flex justify-content-end">
          <Button
            className="mt-3"
            type="submit"
            variant="primary"
            disabled={loading}
          >
            {loading ? <Spinner size="sm" /> : "Save Changes"}
          </Button>
        </div>
      </Form>
    );
  } else if (accountType === "student") {
    // student account personal details
    return (
      <Form id="outer-form" onSubmit={saveStudentPersonalDetails}>
        <div className="header-font fs-3" id="personal-details-header">
          My Details
        </div>
        <hr />
        {error && <Alert variant={alertVariant}>{error}</Alert>}
        <div className="d-flex flex-column flex-lg-row justify-content-around">
          <Form.Group
            id="edit-first-name-student"
            className="mt-3 me-lg-2 w-100"
          >
            <Form.Label className="header-font">
              First Name <span className="text-danger fw-bold">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              ref={firstNameRef}
              required
              name="first-name"
              placeholder={userData.firstName}
            />
          </Form.Group>
          <Form.Group
            id="edit-second-name-student"
            className="mt-3 ms-lg-2 w-100"
          >
            <Form.Label className="header-font">
              Last Name <span className="text-danger fw-bold">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              ref={lastNameRef}
              required
              name="last-name"
              placeholder={userData.lastName}
            />
          </Form.Group>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            className="mt-3"
            type="submit"
            variant="primary"
            disabled={loading}
          >
            {loading ? <Spinner size="sm" /> : "Save Changes"}
          </Button>
        </div>
      </Form>
    );
  } else {
    return (
      <Alert variant="danger">
        <AiOutlineWarning className="text-danger fs-4 me-2 my-auto" />
        An error occured loading your details. Refresh the page, and if this
        error persists please contact us to rectify it.
      </Alert>
    );
  }
};

export default PersonalDetailsForm;
