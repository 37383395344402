import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { AiFillUnlock } from "react-icons/ai";
import { Link } from "react-router-dom";

const UnlockResourceModal = ({
  img,
  header,
}: {
  img: string;
  header: string;
}) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Modal show={show} centered backdrop onHide={() => setShow(false)}>
        <Modal.Header className="header-font fs-4" closeButton>
          {header}
        </Modal.Header>
        <Modal.Body className="d-flex flex-column paragraph-font">
          <h5>Your learning is about to take a huge boost!</h5>
          <img
            src={img}
            className="mx-auto my-3 shadow-lg"
            style={{ maxWidth: "250px" }}
          />
          <h5>Login, or sign up to unlock this resource!</h5>
        </Modal.Body>
        <Modal.Footer className="light-font">
          <Link
            to="/login"
            className="btn btn-outline-primary"
            style={{ width: 120 }}>
            Login
          </Link>
          <Link
            to="/sign-up"
            className="btn btn-primary"
            style={{ width: 120 }}>
            Sign Up
          </Link>
        </Modal.Footer>
      </Modal>
      <Button className="font mt-3" onClick={() => setShow(true)}>
        Unlock this now <AiFillUnlock className="ms-2" />
      </Button>
    </>
  );
};

export default UnlockResourceModal;
