import { contactStatusTypes } from "../../../interfaces/allContactData";

const getColorBasedOnStatus = (status: contactStatusTypes) => {
  switch (status) {
    case "Pending":
      return "warning";
    case "Accepted":
      return "success";
    case "Rejected":
      return "danger";
    default:
      return "dark";
  }
}

export default getColorBasedOnStatus;