import { ReactNode, useState } from "react";
import { Button, Collapse } from "react-bootstrap";
import { FiMinus, FiPlus } from "react-icons/fi";
import FadeInComp from "../reuseables/FadeInComp";
import { useAuth } from "../../contexts/AuthContext";
import UnlockResourceModal from "./UnlockResourceModal";
import { AiOutlineDownload } from "react-icons/ai";

type Props = {
  header: string;
  body: ReactNode;
  image: string;
  reverse: boolean;
};

const FreeResourceBlock = ({ header, body, image, reverse }: Props) => {
  const { currentUser, authenticatedReadableData } = useAuth();
  const [expanded, setExapanded] = useState(false);
  return (
    <FadeInComp>
      <div
        className={`w-100 my-4 d-flex justify-content-around flex-column-reverse ${
          reverse ? "flex-md-row-reverse" : "flex-md-row"
        }`}>
        <div className="mx-auto px-3 my-3 my-md-0 w-100 d-flex flex-column justify-content-between">
          <h2 className="header-font text-center">{header}</h2>
          <div className="light-font fs-4">
            <Button
              size="sm"
              className="d-flex mx-auto mt-2"
              variant={expanded ? "dark" : "outline-dark"}
              onClick={() => setExapanded((prevState) => !prevState)}>
              {expanded ? (
                <>
                  <FiMinus className="me-2 my-auto" />
                  Read Less
                </>
              ) : (
                <>
                  <FiPlus className="me-2 my-auto" />
                  Read More
                </>
              )}
            </Button>
          </div>
          <Collapse in={expanded} className="light-font">
            <div
              className="my-3 text-secondary"
              style={{
                fontSize: "18px",
                wordWrap: "break-word",
                textAlign: "justify",
              }}>
              {body}
            </div>
          </Collapse>

          <div className="light-font text-center mt-auto">
            {currentUser ? (
              <a
                style={{ textDecoration: "none", maxWidth: 300 }}
                className="d-flex justify-content-center btn btn-sm btn-outline-primary mt-3 mx-auto"
                target="_blank"
                rel="nofollow noreferrer noopener"
                href={authenticatedReadableData?.notionFreeResources}>
                Download Free Resource{" "}
                <AiOutlineDownload className="ms-2 my-auto" />
              </a>
            ) : (
              <UnlockResourceModal header={header} img={image} />
            )}
          </div>
          {/* Button - if currentUser -> bring to notion, 
          else bring pop up saying to sign up/sign in */}
        </div>
        <div style={{ maxWidth: "350px" }} className="mx-auto">
          <img alt={header} src={image} className="w-100 shadow-lg" />
        </div>
      </div>
      <hr />
    </FadeInComp>
  );
};

export default FreeResourceBlock;
