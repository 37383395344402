import { Alert, Form, Table } from "react-bootstrap";
import { FiSunset, FiSunrise, FiSun } from "react-icons/fi";
import { availabilityDayObject } from "../../interfaces/tutorUserData";

type Props = {
  availability: Array<availabilityDayObject> | null;
  handleAvailabilityChange: (
    dayIndex: number,
    timeOfDay: keyof availabilityDayObject
  ) => void;
};

const AvailabilityTable = ({
  availability,
  handleAvailabilityChange,
}: Props) => {
  return availability ? (
    <Table
      style={{ minWidth: 800 }}
      responsive
      bordered
      hover
      className="light-font text-center">
      <thead>
        <tr className="bg-light">
          <th scope="col" style={{ width: "20%" }}></th>
          <th scope="col">Mon</th>
          <th scope="col">Tue</th>
          <th scope="col">Wed</th>
          <th scope="col">Thu</th>
          <th scope="col">Fri</th>
          <th scope="col">Sat</th>
          <th scope="col">Sun</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row" className="light-font text-start">
            <FiSunrise className="mx-2 fs-5" /> Before 12PM
          </th>
          {availability.map((dayAvailability, idx) => (
            <td key={"morning-" + idx}>
              <Form.Check
                className="customAvailabilityCheckbox"
                type="checkbox"
                checked={dayAvailability.morning}
                onChange={() => handleAvailabilityChange(idx, "morning")}
              />
            </td>
          ))}
        </tr>
        <tr>
          <th scope="row" className="light-font text-start">
            <FiSun className="mx-2 fs-5" /> 12PM - 6PM
          </th>
          {availability.map((dayAvailability, idx) => (
            <td key={"afternoon-" + idx}>
              <Form.Check
                className="customAvailabilityCheckbox"
                type="checkbox"
                checked={dayAvailability.afternoon}
                onChange={() => handleAvailabilityChange(idx, "afternoon")}
              />
            </td>
          ))}
        </tr>
        <tr>
          <th scope="row" className="light-font text-start">
            <FiSunset className="mx-2 fs-5" /> After 6PM
          </th>
          {availability.map((dayAvailability, idx) => (
            <td key={"evening-" + idx}>
              <Form.Check
                className="customAvailabilityCheckbox"
                type="checkbox"
                checked={dayAvailability.evening}
                onChange={() => handleAvailabilityChange(idx, "evening")}
              />
            </td>
          ))}
        </tr>
      </tbody>
    </Table>
  ) : (
    <Alert variant="warning">
      An error occured displaying your availability table. Refresh, and if error
      continues, please contact us!
    </Alert>
  );
};

export default AvailabilityTable;
