import { ReactNode } from "react";
import SignUpBenefit from "./SignUpBenefit";

interface Props {
  texts: Array<String>;
  titles: Array<String>;
  icons: Array<ReactNode>;
}

const SignUpBenefits = ({ texts, titles, icons }: Props) => {
  return (
    <div className="mt-3">
      <div className="d-flex justify-content-between row">
        <SignUpBenefit text={texts[0]} title={titles[0]} icon={icons[0]} />
        <SignUpBenefit text={texts[1]} title={titles[1]} icon={icons[1]} />
        <SignUpBenefit text={texts[2]} title={titles[2]} icon={icons[2]} />
      </div>
    </div>
  );
};

export default SignUpBenefits;
