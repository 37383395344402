import MyNavbar from "../components/reuseables/MyNavBar";
import SEO from "../components/utils/SEO";

const TermsOfService = () => {
  return (
    <>
      <SEO
        title="Terms of Service - MH1 Tutors"
        description="We believe in equal opportunities for all students and tutors. We aim to provide the most streamlined process when it comes to connecting tutors and students."
        keywords="Grinds Ireland,Maths Grinds,Find Grinds Tutors,Become a Grinds Tutor,Leaving Cert, Junior Cert, College Grinds, Primary School Grinds"
        robots=""
        ogTitle="Terms of Service - MH1 Tutors"
        ogUrl="https://www.mh1-tutors.com/terms-of-service"
        ogSiteName="MH1 Tutors"
        ogImage="https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2FLogo.png?alt=media&token=6e6b79d8-87db-45b1-840d-919623cbc159"
        ogImageType="image/png"
      />
      <MyNavbar />
      <div
        className="my-5 mx-4 mx-md-auto"
        style={{ textAlign: "justify", maxWidth: 768 }}
      >
        <h2 className="header-font text-center mb-5">
          MH1 Tutors - Terms of Service
        </h2>
        <div>
          <span className="header-font">Last Updated: </span>26th November 2023
        </div>
        <div>
          <p className="light-font">
            MH1 Tutors is an online platform that connects students with
            qualified tutors to provide academic support and resources. Our
            platform offers a range of features to help students achieve
            academic success, including access to a large pool of experienced
            tutors and comprehensive learning resources. These terms of service
            outline the guidelines for using our platform and the
            responsibilities of both students and tutors to ensure a safe and
            positive learning experience for all. Please read these terms
            carefully before using our services.
          </p>
          <p className="light-font">
            Throughout the site, the terms “we”, “us” and “our” refer to MH1
            Tutors. MH1 Tutors offers this website, including all information,
            tools and services available from this site to you, the user,
            conditioned upon your acceptance of all terms, conditions, policies
            and notices stated here. If you, the User have any questions, we can
            be contacted by the button on the bottom right of the screen.
          </p>
          <p className="light-font">
            By visiting our site and/ or purchasing something from us, you
            engage in our “Service” and agree to be bound by the following terms
            and conditions (“Terms of Service”, “Terms”), including those
            additional terms and conditions and policies referenced herein
            and/or available by hyperlink. These Terms of Service apply to all
            users of the site, including without limitation users who are
            browsers, vendors, customers, merchants, and/ or contributors of
            content. If you do not agree to these terms, please do not use our
            website.
          </p>
          <p className="light-font">
            Please read these Terms of Service carefully before accessing or
            using our website. By accessing or using any part of the site, you
            agree to be bound by these Terms of Service. If you do not agree to
            all the terms and conditions of this agreement, then you may not
            access the website or use any services. If these Terms of Service
            are considered an offer, acceptance is expressly limited to these
            Terms of Service.
          </p>
          <p className="light-font">
            You can review the most current version of the Terms of Service at
            any time on this page. We reserve the right to update, change or
            replace any part of these Terms of Service by posting updates and/or
            changes to our website. It is your responsibility to check this page
            periodically for changes. Your continued use of or access to the
            website following the posting of any changes constitutes acceptance
            of those changes.
          </p>
        </div>
        <div>
          <h5 className="header-font">MH1 Tutors Rights & Responsibilities</h5>

          <ul className="light-font">
            <li>
              MH1 Tutors is not responsible for the acts or omissions of
              students or tutors and is solely responsible for its own acts or
              omissions.
            </li>
            <li>
              To use reasonable endeavours to check the identity of all tutors.
              (But if a student is concerned about any tutor, do not meet a
              tutor alone.)
            </li>
          </ul>
        </div>
        <div>
          <h5 className="header-font">Student Responsibilities</h5>
          <ul className="light-font">
            <li>
              Students must be at least 18 years old to create an account and to
              contact a tutor for themselves. If you are a parent or legal
              guardian of a child under the age of 18, by allowing your child to
              use the Website, you are subject to these terms and conditions and
              responsible for your child’s activity on the Website. Individuals
              under the age of 18 must have their parent or legal guardian’s
              permission for them to receive tuition from a tutor. MH1 Tutors is
              not responsible for any dispute between a person under the age of
              18 and a parent or guardian.
            </li>
            <li>
              Nothing in these terms and conditions affects a Student’s
              statutory rights.
            </li>
            <li>
              By agreeing to these terms and conditions, a student is not
              required to make any contact with a tutor.
            </li>
            <li>A student may cease to use MH1 Tutors Services at any time.</li>
          </ul>
        </div>
        <div>
          <h5 className="header-font">Tutor Responsibilities</h5>
          <ul className="light-font">
            <li>
              A tutor must not contact a learner who is under the age of 18.
              Tutors must ensure that individuals under the age of 18 have their
              parent or legal guardian’s permission for them to receive tuition
              from a tutor via the Website.
            </li>
            <li>
              Tutors must use their own judgement about whether they wish to
              offer their tuition services to each individual student. Tutors
              must take every precaution to ensure that they work in a safe
              environment and are responsible for taking out and maintaining
              their own insurance policies to cover the work they undertake.
            </li>
          </ul>
        </div>
        <div>
          <h5 className="header-font">SECTION 1 - Scope of Service</h5>
          <p className="light-font">
            MH1 Tutors provides a platform for tutors to advertise their
            services to students seeking academic assistance, as well as
            offering digital learning resources and online services for tutors.
            MH1 Tutors is not a tutoring service and is not responsible for the
            quality of the tutoring provided by the tutors advertised on our
            website. We do not endorse or recommend any particular tutor and are
            not responsible for any interactions or transactions between tutors
            and students.
          </p>

          <p className="mb-0">
            Use of Email Addresses & other information provided to us
          </p>
          <p className="light-font">
            By agreeing to these Terms of Service, you grant MH1 Tutors the
            right to use your email address and any information provided for the
            purpose of connecting you with tutors on our platform. A small fee
            may be charged to the tutors for this service. You opt-in to this
            service when you contact a tutor. We are committed to protecting the
            privacy and security of your personal data and comply with all
            applicable data protection laws. You may withdraw your consent to
            this use of your email address and information at any time. For any
            queries or concerns regarding this policy, please contact us.
          </p>
        </div>
        <div>
          <h5 className="header-font">
            SECTION 2 - Relationship Between MH1 Tutors and the Tutors
          </h5>
          <p className="light-font">
            MH1 Tutors is an independent platform and is not affiliated with any
            of the tutors advertised on our website. Tutors are solely
            responsible for their own actions and any services they provide to
            students. MH1 Tutors is not a party to any agreement between a tutor
            and a student and is not responsible for any disputes or issues that
            may arise between them. Tutors are not employees of MH1 Tutors. MH1
            Tutors is not partnered in any way to the tutors, but is merely just
            advertising their service and nothing in these Terms and Conditions
            shall render the tutor an employee, worker, agent or partner of MH1
            Tutors and the tutor shall not hold themselves out as such. All
            tutors are solely responsible for their own actions both on and off
            the Website.
          </p>
          <p className="light-font">
            To ensure that our platform maintains high standards of integrity,
            MH1 Tutors requires all registered tutors to create unique ads with
            distinct titles. This is done to prevent potential issues with
            search engine optimization and to enhance the visibility of our
            tutors on our website.
          </p>
          <p className="light-font">
            Tutors are obligated to post only accurate and truthful information,
            as well as original photographs and videos that they have the rights
            to use. They are prohibited from including any false or misleading
            information that could deceive potential students.
          </p>
          <p className="light-font">
            It is strictly forbidden for tutors to include their contact details
            such as phone numbers, email addresses, and physical addresses in
            their advertisements. If they do so, their ads will be suspended,
            and they may face denial of access to our platform.
          </p>
          <p className="light-font">
            Transparency and accuracy are essential to us at MH1 Tutors, and we
            hold our tutors accountable for the information they provide in
            their ads. Although we cannot be held responsible for any
            inaccuracies, we encourage students to verify the information
            provided by tutors in their advertisements. If any information
            proves to be inaccurate, students are urged to notify MH1 Tutors
            immediately.
          </p>
        </div>
        <div>
          <h5 className="header-font">
            SECTION 3 - Subscription Fees and Termination
          </h5>
          <p className="light-font">
            Tutors may sign up for a subscription with MH1 Tutors to avail of
            bonus products and services. By signing up you understand that it is
            not guaranteed that you will be contacted by any students looking
            for classes. It is in your interest to make your profile as
            attractive as possible to students looking for a tutor. The
            subscription fees and any applicable taxes will be clearly stated at
            the time of sign up. Tutors may terminate their subscription at any
            time by contacting us at hello@mh1-tutors.com, or using the
            available link on their dashboard page. If a tutor wishes to
            terminate their subscription, they must do so through MH1 Tutors and
            not directly with their students. Refunds will only be given to
            tutors who have subscribed for less than one week.
          </p>
        </div>
        <div>
          <h5 className="header-font">
            SECTION 4 - Digital Learning Resources
          </h5>
          <p className="light-font">
            MH1 Tutors offers a range of free digital learning resources,
            including but not limited to PDFs on various topics. It is the
            responsibility of the user to ensure that they have the necessary
            equipment and software to access and use the digital learning
            resources. MH1 Tutors is not responsible for any issues that may
            arise in accessing or using the resources.
          </p>
        </div>
        <div>
          <h5 className="header-font">SECTION 5 - Prohibited Conduct</h5>
          <p className="light-font">
            Tutors may only sign up with MH1 Tutors with the intention of
            offering tutoring services. Tutors are not permitted to use our
            website for any illegal or unethical purposes. MH1 Tutors reserves
            the right to remove any tutor from our platform who is found to be
            engaging in prohibited conduct.
          </p>
        </div>
        <div>
          <h5 className="header-font">SECTION 6 - Disclaimer of Warranties</h5>
          <p className="light-font">
            MH1 Tutors provides our website and services on an "as is" and "as
            available" basis. We do not make any warranties, express or implied,
            about the completeness, accuracy, reliability, suitability or
            availability of our website, the services provided by the tutors
            advertised on our website, or the digital learning resources
            offered. Any reliance you place on such information is strictly at
            your own risk.
          </p>
        </div>
        <div>
          <h5 className="header-font">SECTION 7 - Limitation of Liability</h5>
          <p className="light-font">
            MH1 Tutors will not be liable for any damages, losses, or expenses
            arising from the use of our website, the services provided by the
            tutors advertised on our website, or the digital learning resources
            offered for purchase. This includes, but is not limited to,
            indirect, incidental, consequential, or punitive damages.
          </p>
        </div>
        <div>
          <h5 className="header-font">SECTION 8</h5>
          <p className="light-font">
            By agreeing to these Terms of Service, you represent that you are at
            least the age of majority in your state or province of residence, or
            that you are the age of majority in your state or province of
            residence and you have given us your consent to allow any of your
            minor dependents to use this site. You may not use our products for
            any illegal or unauthorized purpose nor may you, in the use of the
            Service, violate any laws in your jurisdiction (including but not
            limited to copyright laws). You must not transmit any worms or
            viruses or any code of a destructive nature. A breach or violation
            of any of the Terms will result in an immediate termination of your
            Services.
          </p>
        </div>
        <div>
          <h5 className="header-font">SECTION 9 - General Conditions</h5>
          <p className="light-font">
            We reserve the right to refuse service to anyone for any reason at
            any time. You understand that your content (not including credit
            card information), may be transferred unencrypted and involve (a)
            transmissions over various networks; and (b) changes to conform and
            adapt to technical requirements of connecting networks or devices.
            Credit card information is always encrypted during transfer over
            networks.
          </p>
          <p className="light-font">
            All payments on this website are processed by Stripe Payments
            Europe, Ltd, trading as Stripe and are subject to the Stripe
            Connected Account Agreement which includes the Stripe Services
            Agreement.
          </p>
          <p className="light-font">
            You agree not to reproduce, duplicate, copy, sell, resell or exploit
            any portion of the Service, use of the Service, or access to the
            Service or any contact on the website through which the service is
            provided, without express written permission by us. The headings
            used in this agreement are included for convenience only and will
            not limit or otherwise affect these Terms.
          </p>
        </div>
        <div>
          <h5 className="header-font">
            SECTION 10 - Accuracy, Completeness and Timeliness of Information
          </h5>
          <p className="light-font">
            We are not responsible if information made available on this site is
            not accurate, complete or current. The material on this site is
            provided for general information only and should not be relied upon
            or used as the sole basis for making decisions without consulting
            primary, more accurate, more complete or more timely sources of
            information. Any reliance on the material on this site is at your
            own risk. This site may contain certain historical information.
            Historical information, necessarily, is not current and is provided
            for your reference only. We reserve the right to modify the contents
            of this site at any time, but we have no obligation to update any
            information on our site. You agree that it is your responsibility to
            monitor changes to our site.
          </p>
        </div>
        <div>
          <h5 className="header-font">
            SECTION 11 - Modification of the Service and Prices
          </h5>
          <p className="light-font">
            Prices for our products are subject to change without notice. We
            reserve the right at any time to modify or discontinue the Service
            (or any part or content thereof) without notice at any time. We
            shall not be liable to you or to any third-party for any
            modification, price change, suspension or discontinuance of the
            Service.
          </p>
        </div>
        <div>
          <h5 className="header-font">
            SECTION 12 - Products or Services (if applicable)
          </h5>
          <p className="light-font">
            Certain products or services may be available exclusively online
            through the website. These products or services may have limited
            quantities and are subject to return or exchange only according to
            our Return Policy. We have made every effort to display as
            accurately as possible the colors and images of our products that
            appear at the store. We cannot guarantee that your computer
            monitor's display of any color will be accurate. We reserve the
            right, but are not obligated, to limit the sales of our products or
            Services to any person, geographic region or jurisdiction. We may
            exercise this right on a case-by-case basis. We reserve the right to
            limit the quantities of any products or services that we offer. All
            descriptions of products or product pricing are subject to change at
            anytime without notice, at the sole discretion of us. We reserve the
            right to discontinue any product at any time. Any offer for any
            product or service made on this site is void where prohibited. We do
            not warrant that the quality of any products, services, information,
            or other material purchased or obtained by you will meet your
            expectations, or that any errors in the Service will be corrected.
          </p>
        </div>
        <div>
          <h5 className="header-font">
            SECTION 13 - Accuracy of Billing & Account Information
          </h5>
          <p className="light-font">
            We reserve the right to refuse any order you place with us. We may,
            in our sole discretion, limit or cancel quantities purchased per
            person, per household or per order. These restrictions may include
            orders placed by or under the same customer account, the same credit
            card, and/or orders that use the same billing and/or shipping
            address. In the event that we make a change to or cancel an order,
            we may attempt to notify you by contacting the e‑mail and/or billing
            address/phone number provided at the time the order was made. We
            reserve the right to limit or prohibit orders that, in our sole
            judgment, appear to be placed by dealers, resellers or distributors.
          </p>
          <p className="light-font">
            You agree to provide current, complete and accurate purchase and
            account information for all purchases made at our store. You agree
            to promptly update your account and other information, including
            your email address and credit card numbers and expiration dates, so
            that we can complete your transactions and contact you as needed.
          </p>
          <p className="light-font">
            For more detail, please review our Returns Policy.
          </p>
        </div>
        <div>
          <h5 className="header-font">SECTION 14 - Optional Tools</h5>
          <p className="light-font">
            We may provide you with access to third-party tools over which we
            neither monitor nor have any control nor input. You acknowledge and
            agree that we provide access to such tools ”as is” and “as
            available” without any warranties, representations or conditions of
            any kind and without any endorsement. We shall have no liability
            whatsoever arising from or relating to your use of optional
            third-party tools. Any use by you of optional tools offered through
            the site is entirely at your own risk and discretion and you should
            ensure that you are familiar with and approve of the terms on which
            tools are provided by the relevant third-party provider(s). We may
            also, in the future, offer new services and/or features through the
            website (including, the release of new tools and resources). Such
            new features and/or services shall also be subject to these Terms of
            Service.
          </p>
        </div>
        <div>
          <h5 className="header-font">SECTION 15 - Third-Party inks</h5>
          <p className="light-font">
            Certain content, products and services available via our Service may
            include materials from third-parties. Third-party links on this site
            may direct you to third-party websites that are not affiliated with
            us. We are not responsible for examining or evaluating the content
            or accuracy and we do not warrant and will not have any liability or
            responsibility for any third-party materials or websites, or for any
            other materials, products, or services of third-parties. We are not
            liable for any harm or damages related to the purchase or use of
            goods, services, resources, content, or any other transactions made
            in connection with any third-party websites. Please review carefully
            the third-party's policies and practices and make sure you
            understand them before you engage in any transaction. Complaints,
            claims, concerns, or questions regarding third-party products should
            be directed to the third-party.
          </p>
        </div>
        <div>
          <h5 className="header-font">
            SECTION 16 - User Comments, Feedback and Other Submissions
          </h5>
          <p className="light-font">
            If, at our request, you send certain specific submissions (for
            example contest entries) or without a request from us you send
            creative ideas, suggestions, proposals, plans, or other materials,
            whether online, by email, by postal mail, or otherwise
            (collectively, 'comments'), you agree that we may, at any time,
            without restriction, edit, copy, publish, distribute, translate and
            otherwise use in any medium any comments that you forward to us. We
            are and shall be under no obligation (1) to maintain any comments in
            confidence; (2) to pay compensation for any comments; or (3) to
            respond to any comments. We may, but have no obligation to, monitor,
            edit or remove content that we determine in our sole discretion are
            unlawful, offensive, threatening, libelous, defamatory,
            pornographic, obscene or otherwise objectionable or violates any
            party’s intellectual property or these Terms of Service. You agree
            that your comments will not violate any right of any third-party,
            including copyright, trademark, privacy, personality or other
            personal or proprietary right. You further agree that your comments
            will not contain libelous or otherwise unlawful, abusive or obscene
            material, or contain any computer virus or other malware that could
            in any way affect the operation of the Service or any related
            website. You may not use a false e‑mail address, pretend to be
            someone other than yourself, or otherwise mislead us or
            third-parties as to the origin of any comments. You are solely
            responsible for any comments you make and their accuracy. We take no
            responsibility and assume no liability for any comments posted by
            you or any third-party.
          </p>
        </div>
        <div>
          <h5 className="header-font">SECTION 17 - Personal Information</h5>
          <p className="light-font">
            When you buy something on this website, we collect personal
            information from you to fulfill the order. We may collect
            information like your:
          </p>
          <ul className="light-font">
            <li>Billing and shipping address</li>
            <li>Details relating to your purchase.</li>
            <li>Email address</li>
            <li>Name</li>
            <li>Phone number</li>
          </ul>
          {/* <p className="light-font">
            As you go through checkout, this site may auto-complete your
            shipping and billing address by sharing what you type with the
            Google Places API and returning suggestions to you to improve your
            checkout experience.
          </p> */}
          <p className="light-font">
            For more information please see our Privacy Policy.
          </p>
          <p className="light-font">
            By signing up as a tutor on MH1 Tutors, you hereby grant us the
            right to display your information, including your name, contact
            information, and image, on our website for the purpose of
            advertising your tutoring services. You understand that your
            information and image may be visible to students and other users of
            our website and agree to the display of this information. You
            represent and warrant that you have the right to grant us this
            permission and that the display of your information and image will
            not violate any rights of any third party.
          </p>
          <p className="light-font">
            By signing up as a student on MH1 Tutors, you hereby grant us the
            right to display your information, including your name, contact
            information, and image, on our website. You understand that your
            information and image may be visible to tutors and other users of
            our website and agree to the display of this information. You
            represent and warrant that you have the right to grant us this
            permission and that the display of your information and image will
            not violate any rights of any third party.
          </p>
        </div>
        <div>
          <h5 className="header-font">
            SECTION 18 - Errors, Inaccuracies and Omissions
          </h5>
          <p className="light-font">
            Occasionally there may be information on our site or in the Service
            that contains typographical errors, inaccuracies or omissions that
            may relate to product descriptions, pricing, promotions, offers,
            product shipping charges, transit times and availability. We reserve
            the right to correct any errors, inaccuracies or omissions, and to
            change or update information or cancel orders if any information in
            the Service or on any related website is inaccurate at any time
            without prior notice (including after you have submitted your
            order). We undertake no obligation to update, amend or clarify
            information in the Service or on any related website, including
            without limitation, pricing information, except as required by law.
            No specified update or refresh date applied in the Service or on any
            related website, should be taken to indicate that all information in
            the Service or on any related website has been modified or updated.
          </p>
        </div>
        <div>
          <h5 className="header-font">SECTION 19 - Prohibited Use</h5>
          <p className="light-font">
            In addition to other prohibitions as set forth in the Terms of
            Service, you are prohibited from using the site or its content: (a)
            for any unlawful purpose; (b) to solicit others to perform or
            participate in any unlawful acts; (c) to violate any international,
            federal, provincial or state regulations, rules, laws, or local
            ordinances; (d) to infringe upon or violate our intellectual
            property rights or the intellectual property rights of others; (e)
            to harass, abuse, insult, harm, defame, slander, disparage,
            intimidate, or discriminate based on gender, sexual orientation,
            religion, ethnicity, race, age, national origin, or disability; (f)
            to submit false or misleading information; (g) to upload or transmit
            viruses or any other type of malicious code that will or may be used
            in any way that will affect the functionality or operation of the
            Service or of any related website, other websites, or the Internet;
            (h) to collect or track the personal information of others; (i) to
            spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any
            obscene or immoral purpose; or (k) to interfere with or circumvent
            the security features of the Service or any related website, other
            websites, or the Internet. We reserve the right to terminate your
            use of the Service or any related website for violating any of the
            prohibited uses.
          </p>
        </div>
        <div>
          <h5 className="header-font">
            SECTION 20 - Disclaimer of Warranties; Limitation of Liability
          </h5>
          <p className="light-font">
            We do not guarantee, represent or warrant that your use of our
            service will be uninterrupted, timely, secure or error-free. We do
            not warrant that the results that may be obtained from the use of
            the service will be accurate or reliable. You agree that from time
            to time we may remove the service for indefinite periods of time or
            cancel the service at any time, without notice to you. You expressly
            agree that your use of, or inability to use, the service is at your
            sole risk. The service and all products and services delivered to
            you through the service are (except as expressly stated by us)
            provided 'as is' and 'as available' for your use, without any
            representation, warranties or conditions of any kind, either express
            or implied, including all implied warranties or conditions of
            merchantability, merchantable quality, fitness for a particular
            purpose, durability, title, and non-infringement. In no case shall
            MH1 Tutors, our directors, officers, employees, affiliates, agents,
            contractors, interns, suppliers, service providers or licensors be
            liable for any injury, loss, claim, or any direct, indirect,
            incidental, punitive, special, or consequential damages of any kind,
            including, without limitation lost profits, lost revenue, lost
            savings, loss of data, replacement costs, or any similar damages,
            whether based in contract, tort (including negligence), strict
            liability or otherwise, arising from your use of any of the service
            or any products procured using the service, or for any other claim
            related in any way to your use of the service or any product,
            including, but not limited to, any errors or omissions in any
            content, or any loss or damage of any kind incurred as a result of
            the use of the service or any content (or product) posted,
            transmitted, or otherwise made available via the service, even if
            advised of their possibility. Because some states or jurisdictions
            do not allow the exclusion or the limitation of liability for
            consequential or incidental damages, in such states or
            jurisdictions, our liability shall be limited to the maximum extent
            permitted by law.
          </p>
        </div>
        <div>
          <h5 className="header-font">SECTION 21 - Indemnification</h5>
          <p className="light-font">
            You agree to indemnify, defend and hold harmless MH1 Tutors and our
            parent, subsidiaries, affiliates, partners, officers, directors,
            agents, contractors, licensors, service providers, subcontractors,
            suppliers, interns and employees, harmless from any claim or demand,
            including reasonable attorneys’ fees, made by any third-party due to
            or arising out of your breach of these Terms of Service or the
            documents they incorporate by reference, or your violation of any
            law or the rights of a third-party.
          </p>
        </div>
        <div>
          <h5 className="header-font">SECTION 22 - Termination</h5>
          <p className="light-font">
            The obligations and liabilities of the parties incurred prior to the
            termination date shall survive the termination of this agreement for
            all purposes. These Terms of Service are effective unless and until
            terminated by either you or us. You may terminate these Terms of
            Service at any time by notifying us that you no longer wish to use
            our Services, or when you cease using our site. If in our sole
            judgment you fail, or we suspect that you have failed, to comply
            with any term or provision of these Terms of Service, we also may
            terminate this agreement at any time without notice and you will
            remain liable for all amounts due up to and including the date of
            termination; and/or accordingly may deny you access to our Services
            (or any part thereof).
          </p>
        </div>
        <div>
          <h5 className="header-font">SECTION 23 - Entire Agreement</h5>
          <p className="light-font">
            The failure of us to exercise or enforce any right or provision of
            these Terms of Service shall not constitute a waiver of such right
            or provision. These Terms of Service and any policies or operating
            rules posted by us on this site or in respect to The Service
            constitutes the entire agreement and understanding between you and
            us and govern your use of the Service, superseding any prior or
            contemporaneous agreements, communications and proposals, whether
            oral or written, between you and us (including, but not limited to,
            any prior versions of the Terms of Service). Any ambiguities in the
            interpretation of these Terms of Service shall not be construed
            against the drafting party.
          </p>
        </div>
        <div>
          <h5 className="header-font">SECTION 24 - Governing Law</h5>
          <p className="light-font">
            These Terms of Service and any separate agreements whereby we
            provide you Services shall be governed by and construed in
            accordance with the laws of Republic of Ireland.
          </p>
        </div>
        <div>
          <h5 className="header-font">SECTION 25 - Child Protection</h5>
          <p className="light-font">
            Mh1 Tutors aims to provide the best service possible and advertise
            tutors who are looking to help people with their learning and exams.
            Any user who is the parent or legal guardian of a child under the
            age of 18 and allows their child to attend a lesson with a tutor
            should not leave the child in the sole care of that tutor and the
            parent or legal guardian should remain present for the duration of
            the Lesson. If a user has any concerns regarding child protection,
            they should contact Mh1 Tutors immediately.
          </p>
        </div>
        <div>
          <h5 className="header-font">
            SECTION 26 - Changes to the Terms of Service
          </h5>
          <p className="light-font">
            MH1 Tutors reserves the right to modify these terms of service at
            any time. Any changes will be posted on this page and your continued
            use of our website after the changes have been made will constitute
            your acceptance of the revised terms.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
