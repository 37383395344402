import { Placeholder } from "react-bootstrap";
import { FiCheckCircle } from "react-icons/fi";

type Props = {
  planName: string;
};

const DisabledPricingCard = ({ planName }: Props) => {
  return (
    <div
      className="p-3 bg-white shadow mx-auto my-3 rounded w-100 d-flex justify-content-between flex-column"
      style={{ maxWidth: 350 }}>
      <div>
        <h4 className="header-font text-start">{planName}</h4>
        <hr />
      </div>
      <ul
        style={{
          listStyleType: "none",
          margin: 0,
          padding: 0,
        }}
        className="mb-3 mt-1">
        {[0, 1, 2, 3, 4, 5].map((idx) => (
          <li
            aria-hidden="true"
            className="my-3"
            style={{ fontSize: "18px" }}
            key={idx}>
            <FiCheckCircle className="text-success me-2 my-auto" />
            <Placeholder key={idx} xs={10} />
          </li>
        ))}
      </ul>

      <div className="mb-2">
        <div
          className="w-100 bg-primary py-2 rounded text-center fs-5 text-dark"
          style={{
            textDecoration: "none",
            opacity: 0.5,
            backgroundImage: "linear-gradient(to right,#bab6b6,#787878)",
          }}>
          Coming Soon
        </div>
      </div>
    </div>
  );
};

export default DisabledPricingCard;
