import { useRef, useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";

type Props = {
  accountType: string | null;
};
const SignUpNumbers = ({ accountType }: Props) => {
  const domRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setVisible] = useState(false);

  const tutorImages = [
    "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fnumber_tutor_2.jpg?alt=media&token=a743cf37-593f-4523-95c3-7978b43f17a4",
    "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fnumber_tutor_3.jpg?alt=media&token=638d2ded-7cd9-48ca-82b3-f0e39cf6a033",
    "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fnumber_student_3.jpg?alt=media&token=b29f8767-a1eb-4fbf-83a0-75840d32176a",
  ];
  const studentImages = [
    "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fnumber_student_1.jpg?alt=media&token=68b73dd2-7df3-431d-b08c-94f0fc4d5514",
    "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fnumber_student_2.jpg?alt=media&token=f6cfd026-99e4-4826-9347-3826ef1c2312",
    "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fnumber_student_3.jpg?alt=media&token=b29f8767-a1eb-4fbf-83a0-75840d32176a",
  ];

  useEffect(() => {
    let currentVal: any = null;
    if (!domRef.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    currentVal = domRef.current;
    return () => {
      if (currentVal) return observer.unobserve(currentVal);
    };
  }, []);

  return (
    <div
      className={`mx-auto fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}>
      <div className="mx-auto pb-5" style={{ maxWidth: 600 }}>
        <Carousel variant="dark" interval={null}>
          {[0, 1, 2].map((idx) => (
            <Carousel.Item key={idx}>
              <img
                className="d-block w-100"
                src={
                  accountType === "tutor"
                    ? tutorImages[idx]
                    : studentImages[idx]
                }
                alt="MH1 Tutors -  Benefits of signing up."
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default SignUpNumbers;
