const CAOCalcBanner = () => {
  return (
    <div className="text-dark text-center">
      {/* <div style={{ backgroundColor: "#222222" }} className="py-3 px-2"> */}
      <h1 className="fs-4 header-font mt-2">Leaving Cert Points Calculator</h1>
      {/* </div> */}
    </div>
  );
};

export default CAOCalcBanner;
