import { useState } from "react";
import { Tabs, Tab, Alert } from "react-bootstrap";
import { BiMessageRounded } from "react-icons/bi";
import { BsFillPersonLinesFill } from "react-icons/bs";
import TutorReviewsSection from "./TutorReviewsSection";
import tutorQualsInterface from "../../../interfaces/tutorQualsInterface";
import TruncateTextComponent from "../../reuseables/TruncateTextComponent";
import tutorUserData from "../../../interfaces/tutorUserData";
import QualificationViewOnly from "./QualificationViewOnly";
import SubjectsSection from "./SubjectsSection";
import ContactTutorButton from "../ContactTutorButton";

const extraTabClasses = "w-100 sm-down-w-75 mx-auto";

type Props = {
  tutorUid: string;
  tutorUserData: tutorUserData;
  tutorAbout: string | null;
  tutorQualifications: tutorQualsInterface[] | null;
};

const PaddingComp = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <div className="bg-white shadow rounded-bottom border border-top-0">
        <hr className="mt-0 mb-3 border-secondary border-opacity-75" />
        <div className="p-2">{children}</div>
      </div>
    </>
  );
};

const CustomTutorHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <h3
      className="header-font rounded-3 p-2 shadow-sm"
      style={{ backgroundColor: "#6cb2f035" }}
    >
      {children}
    </h3>
  );
};

const TabSectionAboutAndReviews = ({
  tutorUid,
  tutorUserData,
  tutorAbout,
  tutorQualifications,
}: Props) => {
  // IMPORTANT
  // CHANGE THIS SO THAT THE TABS ARE TAKEN FROM URL PARAMETERS
  // MIGHT HELP WITH SEO HAVING MULTIPLE PAGES FOR EACH TAB
  const [tabKey, setTabKey] = useState("home");

  const tabClasses = (requiredTab: string) => {
    if (tabKey === requiredTab) {
      return `text-dark border-0 ${extraTabClasses} ${
        requiredTab === "home" ? "border-end" : "border-start"
      }`;
    } else {
      return `text-dark text-opacity-50 border-0 ${extraTabClasses} ${
        requiredTab === "home"
          ? "border-end border-light"
          : "border-start border-light"
      }`;
    }
  };

  return (
    <Tabs
      id="tutor-reviews-and-about"
      activeKey={tabKey}
      onSelect={(k) => k && setTabKey(k)}
      justify
      transition={false}
      className="mt-3 border border-bottom-0 rounded-top shadow"
    >
      <Tab
        tabClassName={tabClasses("home")}
        eventKey="home"
        style={{ transition: "height 0.5s ease-in-out" }}
        title={
          <span className="d-flex justify-content-center">
            <BsFillPersonLinesFill className="my-auto me-2" /> About
          </span>
        }
      >
        <PaddingComp>
          {tutorAbout && (
            <>
              <div className="px-2 pb-5">
                <CustomTutorHeader>
                  About {tutorUserData.firstName}
                </CustomTutorHeader>
                <div className="position-relative">
                  <TruncateTextComponent text={tutorAbout} />
                </div>
              </div>
              {/* <hr className="pb-3" /> */}
              {/* <div className="d-flex py-2">
                  <ContactTutorButton tutorUid={tutorUid} />
                </div> */}
            </>
          )}
          {tutorUserData && (
            <>
              <div className="px-2 pb-5">
                <CustomTutorHeader>Subjects & Prices</CustomTutorHeader>
                <SubjectsSection tutorData={tutorUserData} />
              </div>
              {/* <hr className="pb-3" /> */}
              {/* <div className="d-flex py-2">
                  <ContactTutorButton tutorUid={tutorUid} />
                </div> */}
            </>
          )}
          {tutorQualifications && tutorQualifications.length > 0 && (
            <>
              <div className="px-2">
                <CustomTutorHeader>Qualifications</CustomTutorHeader>
                <div>
                  {tutorQualifications.map((qual) => (
                    <QualificationViewOnly key={qual.id} qual={qual} />
                  ))}
                </div>
              </div>
            </>
          )}
          {/* {
            // if no about, subjects or qualifications
            !tutorAbout &&
              (!tutorQualifications || tutorQualifications.length === 0) && (
                <div className="p-2">
                  <Alert
                    className="light-font shadow border border-info border-opacity-25"
                    variant="info"
                  >
                    No information available for {tutorUserData.firstName}{" "}
                    {tutorUserData.lastName}! They are probably still setting up
                    their account. Please check back later.
                  </Alert>
                </div>
              )
          } */}
        </PaddingComp>
      </Tab>
      <Tab
        tabClassName={tabClasses("reviews")}
        eventKey="reviews"
        title={
          <span className="d-flex justify-content-center">
            <BiMessageRounded className="my-auto me-2 fs-5" /> Reviews
          </span>
        }
      >
        <PaddingComp>
          <TutorReviewsSection />
        </PaddingComp>
      </Tab>
    </Tabs>
  );
};

export default TabSectionAboutAndReviews;
