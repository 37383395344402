import MyNavbar from "../components/reuseables/MyNavBar";
import SEO from "../components/utils/SEO";

const RefundPolicy = () => {
  return (
    <>
      <SEO
        title="Refund Policy - MH1 Tutors"
        description="We beleive our refund policy towards purchased items on our website is fair and transparent."
        keywords="Grinds Ireland,Maths Grinds,Find Grinds Tutors,Become a Grinds Tutor,Leaving Cert, Junior Cert, College Grinds, Primary School Grinds"
        robots=""
        ogTitle="Refund Policy - MH1 Tutors"
        ogUrl="https://www.mh1-tutors.com/refund-policy"
        ogSiteName="MH1 Tutors"
        ogImage="https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2FLogo.png?alt=media&token=6e6b79d8-87db-45b1-840d-919623cbc159"
        ogImageType="image/png"
      />
      <MyNavbar />
      <div
        className="my-5 mx-4 mx-md-auto"
        style={{ textAlign: "justify", maxWidth: 768 }}>
        <div>
          <h2 className="header-font text-center mb-5">
            MH1 Tutors - Refund Policy
          </h2>
          <div className="light-font">
            <p>
              At MH1 Tutors, we strive to provide the highest quality services
              and products to our users. However, we understand that there may
              be situations where a refund is necessary. This refund policy
              outlines our policies and procedures for requesting and processing
              refunds.
            </p>
          </div>
        </div>
        <div>
          <div>
            <h5 className="header-font">Tutor Subscription</h5>
            <p className="light-font">
              Tutors may request a refund for their subscription within one week
              of purchase. Refunds will be processed within 5-10 business days
              of receipt of the request.
            </p>
          </div>
          <div>
            <h5 className="header-font">Tutor Free Plan</h5>
            <p className="light-font">
              Payments for access to student information are non-refundable. By
              signing up, you acknowledge that you understand and agree to this
              policy. Tutors are responsible for ensuring that they meet the
              qualifications and can fulfill the responsibilities before making
              the payment.
            </p>
          </div>
          <div>
            <h5 className="header-font">Student Extra Contacts</h5>
            <p className="light-font">
              Payments for the ability to make extra contacts as a student are
              non-refundable. By signing up, you acknowledge that you understand
              and agree to this policy. Users are responsible for ensuring that
              they meet the qualifications and can fulfill the responsibilities
              before making the payment.
            </p>
          </div>
          <div>
            <h5 className="header-font">Contact Us</h5>
            <p className="light-font">
              If you have any questions or concerns about our refund policy,
              please contact us by using the button on the bottom of the screen,
              or at mh1tutors.contact@gmail.com.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefundPolicy;
