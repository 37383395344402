import { useState } from "react";
import {
  Alert,
  Button,
  CloseButton,
  Form,
  InputGroup,
  Modal,
  Spinner,
  Toast,
} from "react-bootstrap";
import GradientButton from "../../reuseables/GradientButton";
import { useQuery } from "@tanstack/react-query";
import { BiPaperPlane, BiSolidUserDetail } from "react-icons/bi";
import RoundedImageFitted from "../../reuseables/RoundedImageFitted";
import LoadingDots from "../../reuseables/LoadingDots";
import firebaseError from "../../../interfaces/firebaseError";
import getUserEmailAndPhone from "../../../requests/getUserEmailAndPhone";
import { FiCopy, FiMail } from "react-icons/fi";

type Props = {
  tutorUid: string;
  tutorImageUrl: string;
  tutorName: string;
  contactUid: string;
};
const TutorDetailsButton = ({
  tutorUid,
  tutorImageUrl,
  tutorName,
  contactUid,
}: Props) => {
  const [showTutorDetailsModal, setShowTutorDetailsModal] = useState(false);
  const [emailCopiedToClipboard, setEmailCopiedToClipboard] = useState(false);
  const [showComingSoonToast, setShowComingSoonToast] = useState(false);

  const openTutorDetailsModal = () => setShowTutorDetailsModal(true);
  const closeTutorDetailsModal = () => {
    setShowTutorDetailsModal(false);
    setEmailCopiedToClipboard(false);
    setShowComingSoonToast(false);
  };

  const {
    data: tutorData,
    isLoading: tutorDataLoading,
    isError: isTutorDataError,
    error: tutorDataError,
  } = useQuery(
    ["tutorData", tutorUid],
    () => getUserEmailAndPhone(tutorUid, contactUid, "student"),
    {
      enabled: showTutorDetailsModal,
      retry: 2,
      staleTime: 1000 * 60 * 60 * 2, // 2 hours
    }
  );
  return (
    <>
      <GradientButton
        className="py-2 px-3"
        loading={false}
        onClickFunc={openTutorDetailsModal}
      >
        View Tutor Details <BiSolidUserDetail className="my-auto ms-2 fs-5" />
      </GradientButton>
      <Modal
        centered
        show={showTutorDetailsModal}
        onHide={closeTutorDetailsModal}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="header-font">
            {tutorName} Contact Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center">
            <RoundedImageFitted
              src={tutorImageUrl}
              alt={tutorName}
              width={100}
              height={100}
            />
            <h4 className="header-font my-auto">{tutorName}</h4>
          </div>
          <hr />
          <div>
            {tutorDataLoading && (
              <div className="d-flex flex-column align-items-center">
                <Spinner />
                <span>
                  Loading Tutor email
                  <LoadingDots />
                </span>
              </div>
            )}
            {isTutorDataError && (
              <Alert variant="danger" className="border-danger shadow mt-3">
                {(tutorDataError as firebaseError).message ||
                  "An error occured while getting student data. Please try again later."}
              </Alert>
            )}
            {tutorData && (
              <div className="d-flex flex-column align-items-center">
                <Toast
                  show={emailCopiedToClipboard}
                  bg="success"
                  autohide
                  delay={3000}
                  onClose={() => setEmailCopiedToClipboard(false)}
                  className="my-2 p-2 text-light light-font d-flex justify-content-between w-100"
                >
                  Email Copied to clipboard
                  <CloseButton
                    variant="white"
                    onClick={() => setEmailCopiedToClipboard(false)}
                  />
                </Toast>

                <div className="d-flex flex-row w-100">
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1">
                      <FiMail className="mx-2 fs-5 my-auto" />
                    </InputGroup.Text>
                    <Form.Control
                      className="text-center"
                      disabled
                      value={tutorData.email}
                      aria-label="Email"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                  <FiCopy
                    className="ms-3 me-2 fs-4 my-auto cursor-pointer"
                    onClick={() => {
                      try {
                        navigator.clipboard.writeText(tutorData.email);
                        setEmailCopiedToClipboard(true);
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                  />
                </div>

                {tutorData.phoneNumber && (
                  <>
                    <h5 className="header-font mt-3">Phone Number</h5>
                    <div className="paragraph-font">
                      {tutorData.phoneNumber}
                    </div>
                  </>
                )}
              </div>
            )}
            <Toast
              show={showComingSoonToast}
              bg="warning"
              autohide
              delay={10000}
              onClose={() => setShowComingSoonToast(false)}
              className="my-2 p-2 text-dark light-font d-flex justify-content-between w-100"
            >
              Messaging the tutor through our platform is coming soon. For now,
              you can email them directly by copying their email address.
              <CloseButton
                variant="white"
                onClick={() => setShowComingSoonToast(false)}
              />
            </Toast>
          </div>
        </Modal.Body>
        <Modal.Footer className="light-font">
          <Button
            disabled={tutorDataLoading}
            className="d-flex"
            onClick={() => setShowComingSoonToast(true)}
          >
            Email Tutor <BiPaperPlane className="my-auto ms-2" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TutorDetailsButton;
