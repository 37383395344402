import { useState, useEffect, useRef } from "react";
import StudentSignUpButton from "../reuseables/StudentSignUpButton";
import TutorSignUpButton from "../reuseables/TutorSignUpButton";
const HowItWorksBottom = () => {
  const domRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    let currentVal: any = null;
    if (!domRef.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    currentVal = domRef.current;
    return () => {
      if (currentVal) return observer.unobserve(currentVal);
    };
  }, []);

  return (
    <div
      ref={domRef}
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
    >
      <div className="mb-3">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fzigzag.jpg?alt=media&token=0aa351c1-1ff1-4c96-a3fc-92c543d3eb83"
          alt="cool blue zigzag"
          style={{ maxWidth: 80 }}
        />
      </div>
      <h4 className="header-font">
        Join the fastest growing community of tutors and students today
      </h4>
      <p className="light-font fs-5">
        Sign up using the buttons below, and you will receive all of our
        learning resources completely FREE!
      </p>
      <div
        style={{ maxWidth: 600 }}
        className="mx-auto my-5 d-flex flex-column flex-sm-row justify-content-around"
      >
        <StudentSignUpButton className="" />
        <TutorSignUpButton className="my-4 my-sm-0" />
      </div>
    </div>
  );
};

export default HowItWorksBottom;
