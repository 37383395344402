import { useState } from "react";
import { Alert, Button, Spinner } from "react-bootstrap";
import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import tutorUserData from "../../interfaces/tutorUserData";

type Props = {
  tutorData: tutorUserData;
};

const DisplayProfileAlert = ({ tutorData }: Props) => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(true);

  const handleRequestToPublishProfile = async () => {
    setLoading(true);
    const requestPublishTutorProfile = httpsCallable(
      functions,
      "requestPublishTutorProfile"
    );
    try {
      await requestPublishTutorProfile({
        tutorName: tutorData.displayName,
        tutorSlug: tutorData.slug,
      });
    } catch (err: any) {
      alert(
        err.message ||
          "Something went wrong. Please try again later, or contact us directly!"
      );
      setLoading(false);
      return;
    }

    // await new Promise((resolve) => setTimeout(resolve, 1000));
    alert(
      "Request sent! We will contact you by email once we have reviewed your profile!"
    );
    setLoading(false);
    setShowAlert(false);
  };

  if (!showAlert) {
    return null;
  }

  if (tutorData.displayProfileRequested && !tutorData.displayProfile) {
    return (
      <Alert variant="warning" className="border-opacity-50 border-warning">
        We are currently reviewing your profile. You will be contacted by email
        once we have reviewed it!
      </Alert>
    );
  }
  if (!tutorData.displayProfile) {
    return (
      <Alert
        variant="success"
        className="light-font border-opacity-50 border-success shadow d-flex flex-column flex-lg-row"
      >
        Ready to display your profile to students?
        <Button
          variant="success"
          className="mx-auto mx-lg-0 ms-lg-auto mt-3 mt-lg-0"
          onClick={handleRequestToPublishProfile}
          disabled={loading}
        >
          {loading ? <Spinner size="sm" /> : "Request to Publish Profile"}
        </Button>
      </Alert>
    );
  } else {
    return (
      <Alert
        variant="primary"
        className="light-font border-primary border-opacity-50 shadow"
      >
        Your profile is currently being displayed to students!
      </Alert>
    );
  }
};

export default DisplayProfileAlert;
