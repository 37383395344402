import { Alert, Spinner, Image, Button, Modal } from "react-bootstrap";
import getTutorDataForCard from "../../requests/getTutorDataForCard";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { BiErrorAlt, BiLinkExternal } from "react-icons/bi";
import { AiFillStar } from "react-icons/ai";
import ContactTutorButton from "./ContactTutorButton";
import { Link, useSearchParams } from "react-router-dom";
import getSingleTutor from "../../requests/getSingleTutor";
import { dbSubjectStrings, subjectLevels } from "../../constants/strings";
import { useEffect, useState } from "react";
import TutorMostRecentQualForCard from "./TutorMostRecentQualForCard";
import SubjectBubble from "../reuseables/SubjectBubble";
import { MdOutlineSubject } from "react-icons/md";
import { iconClasses } from "./tutorProfile/TutorInfoSideTab";
import { FiCheckSquare } from "react-icons/fi";
// import { ImBookmark } from "react-icons/im";

type TutorCardProps = {
  tutorUid: string;
};

const TutorCard = ({ tutorUid }: TutorCardProps) => {
  const {
    data: allTutorData,
    isLoading,
    isError,
  } = useQuery(
    ["tutorDataForCard", tutorUid],
    () => getTutorDataForCard(tutorUid),
    { staleTime: 1000 * 60 * 60, retry: 2 } // 1 hour
  );

  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const [subjectsUnique, setSubjectsUnique] = useState<string[]>([]);
  const [minPrice, setMinPrice] = useState<number | null>(null);
  const [showSubjects, setShowSubjects] = useState(false);

  useEffect(() => {
    let calcMinPrice: number | null = null;
    const levelInParams = searchParams.get("level");
    if (allTutorData && allTutorData.tutorUserData) {
      const allSubjects: string[] = [];
      dbSubjectStrings.forEach((level) => {
        // If the tutorSubjects structure contains arrays like "primarySubjects", "secondarySubjects", etc.

        const subjectsForLevel =
          allTutorData.tutorUserData &&
          allTutorData.tutorUserData[`${level}Subjects`];
        if (subjectsForLevel && Array.isArray(subjectsForLevel)) {
          allSubjects.push(...subjectsForLevel);
        }
        if (
          !allTutorData.tutorUserData ||
          !allTutorData.tutorUserData[`${level}Price`]
        )
          return;

        if (allTutorData.tutorUserData[`${level}Price`]) {
          if (calcMinPrice === null) {
            calcMinPrice = allTutorData.tutorUserData[`${level}Price`];
          } else {
            calcMinPrice = Math.min(
              calcMinPrice,
              allTutorData.tutorUserData[`${level}Price`]
            );
          }
        }
      });
      if (levelInParams) {
        calcMinPrice = allTutorData?.tutorUserData[`${levelInParams}Price`];
      }
      setSubjectsUnique([...new Set(allSubjects)]);
      setMinPrice(calcMinPrice);
    }
  }, [allTutorData, searchParams]);

  // console.log(subjectsUnique);

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center text-center p-3"
        // style={{ minHeight: "10vh" }}
      >
        <Spinner style={{ height: 35, width: 35 }} className="fs-5 my-auto" />
      </div>
    );
  }

  if (isError) {
    return (
      <Alert variant="danger" className="text-center shadow border-danger mb-0">
        <BiErrorAlt className="fs-1" /> Error Getting Tutor
      </Alert>
    );
  }

  return (
    <>
      <div className="p-2">
        <div className="d-flex justify-content-between align-items-center flex-column-reverse flex-md-row">
          <div className="d-flex flex-column">
            <h4 className="header-font my-1 text-center">
              {allTutorData?.tutorUserData?.displayName}
            </h4>
            {/* <ImBookmark
            className="text-danger fs-4 position-absolute top-0"
            // style={{ left: "5%" }}
          /> */}
            <div className="d-flex justify-content-center">
              {[0, 1, 2, 3, 4].map((idx) => (
                <AiFillStar key={idx} className="text-warning" />
              ))}
            </div>
            <span className="light-font" style={{ fontSize: 14 }}>
              {allTutorData?.tutorUserData?.subheading || ""}
            </span>
          </div>
          <Image
            src={allTutorData?.profileImageURL}
            alt={allTutorData?.tutorUserData?.displayName}
            className="rounded-4 sm-120px md-150px ms-2"
            style={{
              height: 120,
              width: 120,
              objectFit: "cover",
              filter: "drop-shadow(0px 3px 5px #bbbbbb)",
            }}
          />
        </div>
        {/* {subjectsUnique && subjectsUnique.length > 0 && (
        <div>
          <h6 className="header-font">
            Subjects{" "}
            <FiChevronDown
              className="fs-5 font-dark"
              style={{
                transform: showSubjects ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease",
              }}
              onClick={() => setShowSubjects((prevState) => !prevState)}
            />
          </h6>
          {showSubjects &&
            subjectsUnique.map((subject) => (
              <SubjectBubble subject={subject} key={subject} />
            ))}
        </div>
      )} */}
        <hr />
        {minPrice && minPrice > 0 ? (
          // This div only when subheading and price are in flex-column
          <div className="d-flex justify-content-between bg-secondary bg-opacity-10 px-2 py-1 rounded-3 mb-2">
            <span className="my-auto header-font">
              {searchParams.get("level") ? "Price:" : "From:"}
            </span>
            <span className="fs-5 header-font" style={{ color: "#0275d8" }}>
              €{minPrice}/h
            </span>
          </div>
        ) : (
          <></>
        )}
        {allTutorData?.tutorUserData.offersFreeConsultation && (
          <div
            className="light-font rounded-3 p-2 my-2"
            style={{ backgroundColor: "rgba(144, 238, 144, 0.2)" }}
          >
            <FiCheckSquare className={"text-success " + iconClasses} />
            Free 15 Minute Consultation
          </div>
        )}
        <div
          className={`light-font ${
            (!subjectsUnique || subjectsUnique.length <= 0) && "mt-4"
          }`}
        >
          {subjectsUnique && subjectsUnique.length > 0 && (
            <Button size="sm" onClick={() => setShowSubjects(true)}>
              See All Subjects <MdOutlineSubject />
            </Button>
          )}
        </div>
        <TutorMostRecentQualForCard tutorUid={tutorUid} />
        <hr />

        <div className="d-flex flex-row justify-content-between my-3">
          <ContactTutorButton
            tutorUid={tutorUid}
            tutorSlug={allTutorData?.tutorUserData.slug || ""}
            tutorImageUrl={allTutorData?.profileImageURL || ""}
            tutorName={allTutorData?.tutorUserData?.displayName || ""}
            tutorOffersFreeConsultation={
              allTutorData?.tutorUserData.offersFreeConsultation || false
            }
          />
          <div className="d-flex">
            <Link
              // onMouseEnter={() => {
              //   queryClient.prefetchQuery(
              //     ["tutor_profile_data", allTutorData?.tutorUserData.slug],
              //     () => getSingleTutor(allTutorData?.tutorUserData.slug || ""),
              //     { staleTime: 5 * 60 * 1000 } // 5 minutes
              //   );
              // }}
              className="border border-success btn btn-outline-success py-0 px-2 my-auto"
              target="_blank"
              to={`/tutors/${allTutorData?.tutorUserData.slug}`}
            >
              {/* <BiLinkExternal /> */} See More
            </Link>
          </div>
        </div>
      </div>
      <Modal
        centered
        show={showSubjects}
        onHide={() => setShowSubjects(false)}
        className="paragraph-font"
      >
        <Modal.Header className="header-font">
          <Modal.Title>
            {allTutorData?.tutorUserData.displayName || "Tutors"} Subjects
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {allTutorData && allTutorData.tutorUserData ? (
            <div>
              {subjectLevels.map(
                (level, idx) =>
                  allTutorData.tutorUserData &&
                  allTutorData?.tutorUserData[
                    dbSubjectStrings[idx] + "Subjects"
                  ] && (
                    <div key={idx} className="my-3">
                      <h6 className="header-font">{level}</h6>
                      <div
                        className="d-flex justify-content-start mt-2"
                        style={{ flexFlow: "row wrap" }}
                      >
                        {allTutorData.tutorUserData[
                          dbSubjectStrings[idx] + "Subjects"
                        ].map((subject: string) => (
                          <SubjectBubble
                            key={dbSubjectStrings[idx] + subject}
                            subject={subject}
                          />
                        ))}
                      </div>
                    </div>
                  )
              )}
            </div>
          ) : (
            <div>No subjects</div>
          )}
        </Modal.Body>
        <Modal.Footer className="light-font d-flex justify-content-center">
          <ContactTutorButton
            tutorUid={tutorUid}
            tutorSlug={allTutorData?.tutorUserData.slug || ""}
            tutorImageUrl={allTutorData?.profileImageURL || ""}
            tutorName={allTutorData?.tutorUserData?.displayName || ""}
            tutorOffersFreeConsultation={
              allTutorData?.tutorUserData.offersFreeConsultation || false
            }
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TutorCard;
