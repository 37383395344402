import { Helmet } from "react-helmet-async";
type Props = {
  title: string;
  description: string;
  keywords: string;
  robots: string;
  ogTitle: string;
  ogUrl: string;
  ogSiteName: string;
  ogImage: string;
  ogImageType: string;
  canonicalLink?: string;
};
const SEO = ({
  title,
  description,
  keywords,
  robots,
  ogTitle,
  ogUrl,
  ogSiteName,
  ogImage,
  ogImageType,
  canonicalLink,
}: Props) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {robots ? <meta name="robots" content={robots} /> : <></>}
      {canonicalLink && <link rel="canonical" href={canonicalLink} />}
      {/* OG meta tags */}
      <meta property="og:locale" content="en_IE" />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:site_name" content={ogSiteName} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:image:type" content={ogImageType} />
      <meta property="og:title" content={ogTitle} />

      {/* Twitter meta tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="MH1 Tutors" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImage} />
    </Helmet>
  );
};

export default SEO;
