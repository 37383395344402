
export const dbSubjectStrings = ["primary", "jc", "lc", "college", "casual"];
export const subjectLevels = [
  "Primary School",
  "Junior Cert",
  "Leaving Cert",
  "College",
  "Casual",
];

export const COUNTIES = [
  "carlow",
  "cavan",
  "clare",
  "cork",
  "donegal",
  "dublin",
  "galway",
  "kerry",
  "kildare",
  "kilkenny",
  "laois",
  "leitrim",
  "limerick",
  "longford",
  "louth",
  "mayo",
  "meath",
  "monaghan",
  "offaly",
  "roscommon",
  "sligo",
  "tipperary",
  "waterford",
  "westmeath",
  "wexford",
  "wicklow",
];

export const MONTHS = [
  "January",
  "Febuary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September ",
  "October",
  "November",
  "December",
];

export const default_image_path = "/assets/no-profile-img1.png"

export const CONTACT_DECLINE_REASONS = [
  "I am fully booked.",
  "I am not able to take on any more students.",
  "I am not available at the requested locations.",
  "I am not available at the requested times."
]