import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const BecomeATutorLandingPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    return navigate("/sign-up?account=tutor");
  }, [navigate]);
  // not implmented yet,but need a url for it, so for now will just redireect to sign up page
  return <></>;
};

export default BecomeATutorLandingPage;
