import { useEffect, useState } from "react";
import tutorQualsInterface from "../../../interfaces/tutorQualsInterface";
import { MONTHS } from "../../../constants/strings";
import { FiCalendar, FiHome, FiMinus, FiPlus } from "react-icons/fi";
import { Collapse } from "react-bootstrap";

type Props = {
  qual: tutorQualsInterface;
};
const QualificationViewOnly = ({ qual }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const [datePeriod, setDatePeriod] = useState<string>(
    qual.startDate?.replace("-", "/") + "-" + qual.endDate?.replace("-", "/")
  );

  useEffect(() => {
    const [startMonth, startYear] = qual.startDate.split("-");
    if (qual.endDate !== "present") {
      const [endMonth, endYear] = qual.endDate.split("-");
      setDatePeriod(
        MONTHS[Number(startMonth) - 1] +
          " " +
          startYear +
          " - " +
          MONTHS[Number(endMonth) - 1] +
          " " +
          endYear
      );
    } else {
      setDatePeriod(
        MONTHS[Number(startMonth) - 1] + " " + startYear + " - Present"
      );
    }
  }, [qual]);

  return (
    <>
      <div className="d-flex justify-content-between py-3">
        <h5 className="header-font mb-0">{qual.title}</h5>
        <div
          className="mx-2"
          aria-controls={"qual-bio-" + qual.id}
          aria-expanded={expanded}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? (
            <FiMinus className="cursor-pointer" />
          ) : (
            <FiPlus className="cursor-pointer" />
          )}
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row text-muted my-2">
        <div className="me-0 me-md-3 d-flex flex-row mb-2 mb-sm-0">
          <FiHome className="me-1 my-auto" />
          {qual.school}
        </div>
        <div className="ms-0 ms-md-3 d-flex flex-row mb-2 mb-sm-0">
          <FiCalendar className="me-1 my-auto" /> {datePeriod}
        </div>
      </div>
      <Collapse in={expanded} className="light-font">
        <div
          id={"qual-bio-" + qual.id}
          className="my-3 text-secondary"
          style={{ fontSize: "18px", wordWrap: "break-word" }}
        >
          {qual.bio}
        </div>
      </Collapse>

      <hr className="dashed" />
    </>
  );
};

export default QualificationViewOnly;
