import { useState } from "react";
import { contactBubbleProps } from "../../../interfaces/allContactData";
import { useQuery } from "@tanstack/react-query";
import getUserImage from "../../../requests/getUserImage";
import { default_image_path } from "../../../constants/strings";
import TutorDetailsButton from "./TutorDetailsButton";
import { Button, CloseButton, Form, Spinner, Toast } from "react-bootstrap";
import RoundedImageFitted from "../../reuseables/RoundedImageFitted";
import { formatFirebaseTimestamp } from "../../utils/ToolingFunctions";
import { BiPaperPlane } from "react-icons/bi";

const ContactBubbleStudent = ({
  contact,
  status,
  color,
}: contactBubbleProps) => {
  const {
    isLoading: isLoadingUserImage,
    // isError: isErrorWithUserImage,
    // error: errorWithUserImage,
    data: tutorImageSrc,
  } = useQuery(
    ["userImage", contact.tutorUid],
    () => getUserImage(contact.tutorUid),
    {
      staleTime: 1000 * 60 * 30, // 30 minutes
    }
  );

  const [showComingSoonToast, setShowComingSoonToast] = useState(false);

  return (
    // view tutor details button - get email
    <>
      {/* Contact Info */}
      <div className="d-flex flex-row justify-content-between w-100">
        <div className="d-flex flex-column align-items-start">
          <h5 className="header-font mb-0">{contact.tutorName}</h5>
          <div
            style={{ fontSize: "0.8rem" }}
            className={`bg-${color} rounded-pill px-3 text-center text-${
              status === "Pending" ? "dark" : "light"
            }`}
          >
            {status}
          </div>
        </div>
        <span>
          {isLoadingUserImage ? (
            <Spinner size="sm" />
          ) : (
            <RoundedImageFitted
              src={tutorImageSrc || default_image_path}
              alt={contact.tutorName}
              height={75}
              width={75}
              className="mx-3"
            />
          )}
        </span>
      </div>
      <div className="mt-2">
        {contact.timestamp && (
          <div>
            <span className="header-font">Contact Date:</span>{" "}
            {formatFirebaseTimestamp(contact.timestamp)}
          </div>
        )}
      </div>
      {status === "Accepted" && (
        <div className="d-flex justify-content-end mt-3">
          <TutorDetailsButton
            tutorUid={contact.tutorUid}
            tutorImageUrl={tutorImageSrc || default_image_path}
            tutorName={contact.tutorName}
            contactUid={contact.contactUid}
          />
        </div>
      )}
      {status === "Rejected" && (
        <Form.Group>
          <Form.Label className="mt-2 mb-0">
            <span className="header-font">Response Message:</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            disabled
            value={contact.responseMessage || "Tutor is not available"}
          />
        </Form.Group>
      )}
      {status === "Pending" && (
        <>
          <Toast
            show={showComingSoonToast}
            bg="warning"
            autohide
            delay={5000}
            onClose={() => setShowComingSoonToast(false)}
            className="my-2 p-2 text-dark light-font d-flex justify-content-between w-100"
          >
            This Feature is Coming Soon! Contact us and we will remind them
            manually for now!
            <CloseButton
              variant="white"
              onClick={() => setShowComingSoonToast(false)}
            />
          </Toast>
          <div className="d-flex justify-content-end mt-3 light-font">
            <Button
              variant="outline-dark"
              onClick={() => setShowComingSoonToast(true)}
            >
              <BiPaperPlane className="my-auto me-2" /> Send Reminder
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default ContactBubbleStudent;
