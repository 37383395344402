import { useEffect, useState } from "react";
import tutorUserData from "../../../interfaces/tutorUserData";
import { ImBookmark } from "react-icons/im";
import { dbSubjectStrings } from "../../../constants/strings";
import { AiFillStar } from "react-icons/ai";
import { FiMapPin } from "react-icons/fi";
import { BiLogoZoom } from "react-icons/bi";
import { formatCountyString } from "../../utils/ToolingFunctions";

type Props = {
  tutorUserData: tutorUserData;
};
const TutorDetailsTopSection = ({ tutorUserData }: Props) => {
  const [minPrice, setMinPrice] = useState<number | null>(null);

  useEffect(() => {
    if (tutorUserData) {
      let minPrice: number | null = null;
      dbSubjectStrings.forEach((level) => {
        if (tutorUserData[`${level}Price`]) {
          if (minPrice === null) {
            minPrice = tutorUserData[`${level}Price`];
          } else {
            minPrice = Math.min(minPrice, tutorUserData[`${level}Price`]);
          }
        }
      });
      setMinPrice(minPrice);
    }
  }, [tutorUserData]);

  return (
    <div className="bg-white shadow rounded-3 mt-1 px-3 pb-3 position-relative">
      <div className="d-flex justify-content-between pe-3">
        <h1 className="fs-3 header-font pt-2">{tutorUserData.displayName}</h1>

        <ImBookmark
          className="text-danger fs-4 position-absolute top-0"
          style={{ right: "5%" }}
        />
      </div>
      <div className="d-flex justify-content-between flex-column flex-sm-row flex-md-column flex-lg-row">
        <span className="light-font pe-0 pe-sm-4 pe-md-0 pe-lg-4">
          {tutorUserData.subheading}
        </span>
        {minPrice && (
          // This div only when subheading and price are in flex-row
          <div className="d-none d-sm-flex d-md-none d-lg-flex flex-column">
            <span className="light-font" style={{ fontSize: 14 }}>
              From:
            </span>
            <span className="fs-5 header-font" style={{ color: "#0275d8" }}>
              €{minPrice}/h
            </span>
          </div>
        )}
      </div>
      <div className="my-2 row">
        {
          <div className="d-flex my-1 my-sm-auto col col-12 col-sm-4 justify-content-start">
            <AiFillStar className="text-warning my-auto fs-5 mx-2" />
            {tutorUserData.averageRating ? (
              <>
                {tutorUserData.averageRating}
                <span className="text-secondary">/5</span>
                <span className="ms-2 light-font text-secondary">
                  ({tutorUserData.numberOfRatings})
                </span>
              </>
            ) : (
              "No ratings yet"
            )}
          </div>
        }
        {(tutorUserData.locationStudentsPlace ||
          tutorUserData.locationTutorsPlace) &&
          tutorUserData.inPersonCounty && (
            <div className="d-flex my-1 my-sm-auto justify-content-start justify-content-sm-center col col-12 col-sm-5">
              <FiMapPin className="text-secondary my-auto fs-5 mx-2" />
              <span className="text-secondary">
                {formatCountyString(tutorUserData.inPersonCounty)},{" "}
                {formatCountyString(tutorUserData.inPersonArea)}
              </span>
            </div>
          )}
        {tutorUserData.locationOnline && (
          <div className="d-flex my-1 my-sm-auto col col-12 col-sm-3 justify-content-start justify-content-sm-end">
            <BiLogoZoom className="text-primary my-auto fs-4 mx-2" />
            <span>Online</span>
          </div>
        )}
      </div>
      {minPrice && (
        // This div only when subheading and price are in flex-column
        <div className="d-flex d-sm-none d-md-flex d-lg-none justify-content-between bg-secondary bg-opacity-10 px-2 py-1 rounded-3">
          <span className="my-auto header-font">From:</span>
          <span className="fs-5 header-font" style={{ color: "#0275d8" }}>
            €{minPrice}/h
          </span>
        </div>
      )}
    </div>
  );
};

export default TutorDetailsTopSection;
