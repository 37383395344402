import { useState, useRef, useEffect } from "react";
import { BiPaperPlane } from "react-icons/bi";
import { Button, Modal, Form, Alert, Spinner } from "react-bootstrap";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";

// fix grecaptcha not defined error
declare const grecaptcha: any;

const ContactButton = () => {
  const [displayForm, setDisplayForm] = useState(false);
  const [error, setError] = useState("");
  const [displayMessage, setDisplayMessage] = useState("");
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);
  const recaptchaRenderedRef = useRef<boolean | null>(null);

  const openForm = () => setDisplayForm(true);
  const closeModal = () => {
    setDisplayForm(false);
    recaptchaRenderedRef.current = false;
  };

  useEffect(() => {
    if (displayForm && !recaptchaRenderedRef.current) {
      grecaptcha.render("recaptcha-container", {
        sitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
      });
      recaptchaRenderedRef.current = true;
    }
  }, [displayForm]);

  const submitQuickMessage = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setDisplayMessage("Sending message, please wait....");

    // get recaptcha token
    const formTarget = e.target as HTMLFormElement;
    const recaptchaComp = formTarget.querySelector(
      ".g-recaptcha-response"
    ) as HTMLTextAreaElement;

    const recaptchaToken = recaptchaComp.value;

    if (!recaptchaToken) {
      setError("Please complete the recaptcha.");
      setDisplayMessage("");
      return setLoading(false);
    }

    // send email here through firebase function
    const sendEmail = httpsCallable(functions, "sendQuickMessageEmail");

    try {
      await sendEmail({
        name:
          process.env.NODE_ENV === "development"
            ? "Dev Name"
            : nameRef.current!.value,
        email: emailRef.current!.value,
        message: messageRef.current!.value,
        recaptchaToken,
      });
      setDisplayMessage(
        "Your message has been sent. Thank you for getting in touch. We will get back to you shortly."
      );
    } catch (err: any) {
      setError(
        "An error occured while sending your message.\nError message: " +
          err.message
      );
      setDisplayMessage("");
      return setLoading(false);
    }
    formTarget.reset();

    setLoading(false);
  };
  return (
    <>
      <Modal show={displayForm} onHide={closeModal} backdrop={true} centered>
        <Modal.Header className="header-font fs-4" closeButton>
          Leave a quick message
        </Modal.Header>
        <Modal.Body>
          {displayMessage && (
            <Alert variant="info" className="paragraph-font">
              {displayMessage}
            </Alert>
          )}
          {error && (
            <Alert variant="danger" className="paragraph-font">
              {error}
            </Alert>
          )}
          <Form onSubmit={submitQuickMessage} className="paragraph-font">
            <Form.Group id="qm-name" className="my-3 ms-auto w-100-lg">
              <Form.Label className="paragraph-font">
                Name <span className="text-danger fw-bold">*</span>
              </Form.Label>
              <Form.Control
                className="paragraph-font"
                ref={nameRef}
                type="text"
                placeholder="eg. Joe Bloggs"
                name="qm-name"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="paragraph-font">
                Email address <span className="text-danger fw-bold">*</span>
              </Form.Label>
              <Form.Control
                className="paragraph-font"
                ref={emailRef}
                type="email"
                placeholder="name@example.com"
                required
              />
            </Form.Group>
            <Form.Group
              className="mb-4"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label className="paragraph-font">
                Message <span className="text-danger fw-bold">*</span>
              </Form.Label>
              <Form.Control
                className="paragraph-font"
                ref={messageRef}
                as="textarea"
                rows={5}
                required
                maxLength={150}
                onChange={(e) => setCount(e.target.value.length)}
                placeholder="Leave a quick message for us. Whether it be a problem you found on our website, a subject you would like us to find a tutor for, or any question you have. We will reply as soon as possible!"
              />
              <div
                className="char-count float-end"
                style={{ fontSize: "14px" }}
              >
                <span className="qm-current">{count}</span>
                <span className="qm-limit">/150</span>
              </div>
            </Form.Group>
            <div
              id="recaptcha-container"
              className="g-recaptcha mb-2"
              data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              // data-badge="inline"
            ></div>
            <Button
              type="submit"
              variant="outline-dark"
              className="d-flex"
              disabled={loading}
            >
              {loading ? (
                <Spinner size="sm" className="mx-auto my-auto" />
              ) : (
                <>
                  Send Message <BiPaperPlane className="ms-2 my-auto" />
                </>
              )}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Button
        onClick={() => {
          displayForm ? closeModal() : openForm();
          setError("");
          setDisplayMessage("");
          setCount(0);
        }}
        variant="dark"
        style={{
          zIndex: 99,
          position: "fixed",
          bottom: "20px",
          right: "20px",
        }}
        className="fs-6 d-inline-flex justify-content-center align-items-center p-1 shadow"
      >
        <span className="paragraph-font p-2">
          <BiPaperPlane className="me-2" />
          Contact Us
        </span>
      </Button>
    </>
  );
};

export default ContactButton;
