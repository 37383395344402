import { Container, Row, Col } from "react-bootstrap";
import FadeInComp from "../../reuseables/FadeInComp";
import { FaExclamationCircle, FaHome, FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";

const TutorNotFoundComp = () => {
  return (
    <FadeInComp>
      <Container className="my-5">
        <Row>
          <Col xs={12} className="text-center">
            <FaExclamationCircle size={48} className="text-warning" />
            <h1 className="mt-3 header-font">Tutor Not Found</h1>
            <p>
              The tutor URL you entered is either incorrect, or the tutor
              profile is private.
            </p>
            <div
              className="w-50 mx-auto"
              style={{ minWidth: 200, maxWidth: 300 }}
            >
              <Link
                to="/tutors"
                className="btn btn-outline-primary d-flex justify-content-center mb-3"
              >
                <FaUser className="my-auto me-2" /> Back to Tutors Page
              </Link>
              <Link
                to="/"
                className="btn btn-outline-dark d-flex justify-content-center mt-3"
              >
                <FaHome className="my-auto me-2" />
                Back to Home Page
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </FadeInComp>
  );
};

export default TutorNotFoundComp;
