import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const parts = document.cookie.split("; necessaryCookies=");
    if (parts.length > 0 && parts.length === 2) {
      const persistentConsentVal = parts.pop()?.split("; ").shift();
      if (persistentConsentVal) {
        setShowBanner(!(persistentConsentVal === "true"));
      }
    } else {
      setShowBanner(true);
    }
  }, []);

  const handleCookieClick = () => {
    const cookieExpiryTime = new Date().getTime() + 30 * 24 * 60 * 60 * 1000;
    const cookieExpiryDate = new Date(cookieExpiryTime).toUTCString();
    document.cookie =
      "necessaryCookies=true; expires=" + cookieExpiryDate + ";";
    setShowBanner(false);
  };
  return showBanner ? (
    <div
      className="cookie-banner border shadow rounded-2 p-3"
      style={{
        zIndex: 99,
        position: "fixed",
        maxWidth: "200px",
        bottom: "20px",
        left: "20px",
        backgroundColor: "#ddd",
        // display: showBanner ? "block" : "none",
      }}>
      <img
        className="d-flex mx-auto"
        src="/assets/cookie.png"
        alt="cookie"
        style={{ maxWidth: "60px" }}
      />
      <div className="paragraph-font">
        <header className="header-font mt-3">Cookies Consent</header>
        <p className="paragraph-font">
          By using this website you agree to the use of our necessary cookies.
        </p>
        <Button className="my-1 w-100" onClick={handleCookieClick}>
          Accept
        </Button>
        <Link to="/privacy-policy" className="my-1">
          Learn More
        </Link>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default CookieBanner;
