import { useState, useEffect, RefObject, useRef } from "react";
import { Card, Container } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import TabComponentBenefits from "./TabComponentBenefits";

interface Props {
  firstNameRef: RefObject<HTMLInputElement>;
}

const TabComponent = ({ firstNameRef }: Props) => {
  const [selectedTabIsStudent, setSelectedTabIsStudent] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const cardsDomRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setVisible] = useState(false);
  useEffect(() => {
    let currentVal: any = null;
    if (!cardsDomRef.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(cardsDomRef.current);
    currentVal = cardsDomRef.current;
    return () => {
      if (currentVal) return observer.unobserve(currentVal);
    };
  }, []);

  useEffect(() => {
    if (searchParams.get("account") === "tutor") {
      setSelectedTabIsStudent(false);
    } else {
      setSelectedTabIsStudent(true);
    }
  }, [searchParams]);

  return (
    <>
      <div className="mx-3">
        <Container
          ref={cardsDomRef}
          className={`my-4 fade-in-section d-flex justify-content-center ${
            isVisible ? "is-visible" : ""
          }`}
          fluid
        >
          <Card
            style={{ maxWidth: "250px", letterSpacing: "0.06rem" }}
            onClick={() => {
              const newParams = new URLSearchParams(searchParams);
              newParams.set("account", "student");
              setSearchParams(newParams);
            }}
            className={
              "smooth mx-2 mx-md-4 mx-sm-2 text-center cursor-pointer header-font border" +
              (selectedTabIsStudent
                ? " text-light bg-primary border-primary shadow-lg"
                : " shadow")
            }
          >
            <Card.Img
              variant="top"
              src="https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fsignup_student2.jpg?alt=media&token=dc8cb10f-eb5d-48a5-ae1d-2934ef721a3e"
            />
            <Card.Body>Student or Parent</Card.Body>
          </Card>
          <Card
            style={{ maxWidth: "250px", letterSpacing: "0.1rem" }}
            onClick={() => {
              const newParams = new URLSearchParams(searchParams);
              newParams.set("account", "tutor");
              setSearchParams(newParams);
            }}
            className={
              "smooth mx-2 mx-md-4 mx-sm-2 text-center cursor-pointer header-font border" +
              (!selectedTabIsStudent
                ? " text-light bg-primary border-primary shadow-lg"
                : " shadow")
            }
          >
            <Card.Img
              variant="top"
              src="https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fsignup_tutor.jpg?alt=media&token=7396df5a-0414-412e-bf85-7b645088654f"
            />
            <Card.Body>Tutor</Card.Body>
          </Card>
        </Container>
        <Container fluid>
          <TabComponentBenefits
            accountType={searchParams.get("account") || ""}
          />
        </Container>
      </div>
    </>
  );
};

export default TabComponent;
