import { useState, useEffect, useRef } from "react";
import {
  Alert,
  Button,
  Card,
  Container,
  Form,
  InputGroup,
  Modal,
  Toast,
  CloseButton,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import {
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
  getAdditionalUserInfo,
  sendEmailVerification,
} from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { auth } from "../firebase";
import GoogleSignInDivider from "../components/signInSignUp/GoogleSignInDivider";
import LoadingSpinnerBig from "../components/reuseables/LoadingSpinnerBig";
import MyNavbar from "../components/reuseables/MyNavBar";
import SEO from "../components/utils/SEO";
import { browserName } from "react-device-detect";
import ChromeOrSafariAlert from "../components/utils/ChromeOrSafariAlert";

// This will still send password reset to email even if registered with a google account
const SignInPage = () => {
  const { currentUser, login, accountType } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [resetPasswordAlert, setResetPasswordAlert] = useState("");

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const resetPasswordEmailRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();
  const [isBadBrowser, setIsBadBrowser] = useState<boolean>(false);

  const [show, setShow] = useState(false);
  const [showSignedInToast, setShowSignedInToast] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setResetPasswordAlert("");
    return setShow(true);
  };

  useEffect(() => {
    if (currentUser && !currentUser.emailVerified) {
      // Turn this to a toast & set it as true here
      // -> with button to resend email on the toast if possibly
      setShowSignedInToast(true);
      // reload user every 10 seconds to check if they verified they're email
      const interval = setInterval(() => {
        if (currentUser.emailVerified) {
          navigate("/dashboard");
        }
        return currentUser.reload();
      }, 1000);
      return () => clearInterval(interval);
    } else if (currentUser && currentUser.emailVerified && accountType) {
      switch (accountType) {
        case "student":
          return navigate("/tutors");
        case "tutor":
          return navigate("/dashboard");
        default:
          return navigate("/tutors");
      }
    } else {
      setShowSignedInToast(false);
      setError("");
    }
  }, [currentUser, navigate, accountType]);

  const resendConfirmationEmail = (e: React.SyntheticEvent) => {
    if (currentUser) {
      sendEmailVerification(currentUser).then(() =>
        alert("Confirmation email has been sent.")
      );
    }
    const btn = e.target as HTMLButtonElement;
    btn.disabled = true;
  };

  // Sign in form submit -> sign in with email and password
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setError("Loading...");
    setLoading(true);
    try {
      await login(emailRef.current!.value, passwordRef.current!.value).then(
        () => {
          // return navigate("/dashboard");
        }
      );
    } catch (err: any) {
      setLoading(false);
      return setError(err.message.replace("Firebase: ", ""));
    }
  };

  // Google sign in
  const googleSignIn = async () => {
    if (
      browserName === "Instagram" ||
      browserName === "Facebook" ||
      browserName === "TikTok"
    ) {
      return setIsBadBrowser(true);
    }

    // GOOGLE SIGN
    const provider = new GoogleAuthProvider();
    setError("Loading....");
    setLoading(true);
    try {
      await signInWithPopup(auth, provider).then((result) => {
        if (getAdditionalUserInfo(result)?.isNewUser) {
          result.user.delete().then(() => {
            setLoading(false);
            alert("User does not exist, please sign up to continue!");
            return navigate("/sign-up");
          });
        } else {
          // return navigate("/dashboard");
        }
      });
    } catch (err: any) {
      setLoading(false);
      return setError(err.message.replace("Firebase:", ""));
    }
  };

  // Send email if forgot password
  const sendForgotPasswordEmail = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const signInMethods = await fetchSignInMethodsForEmail(
      auth,
      resetPasswordEmailRef.current!.value
    );
    // const target = e.target as HTMLFormElement;
    if (signInMethods.includes("google.com")) {
      return setResetPasswordAlert(
        "The email specified was used to register with Google."
      );
    } else {
      await sendPasswordResetEmail(auth, resetPasswordEmailRef.current!.value, {
        url: process.env.REACT_APP_FIREBASE_EMAIL_FORWARD_URL as string,
      })
        .then(() => {
          setResetPasswordAlert(
            "Reset password link has been sent. Please check your emails including spam to reset your password. Once your password is reset, you can login!"
          );
        })
        .catch((err: any) => {
          return setResetPasswordAlert(err.message.replace("Firebase:", ""));
        });
    }
  };

  // Check email (and spam) -> if not there have button to resend verification email
  return (
    <>
      <SEO
        title="Find Junior and Leaving Cert Grinds Tutors in Ireland - MH1 Tutors"
        description="Sign back in to your MH1 Tutors account to connect with tutors & students on our platform."
        keywords="Grinds Ireland,Maths Grinds,Find Grinds Tutors,Become a Grinds Tutor,Leaving Cert, Junior Cert, College Grinds, Primary School Grinds"
        robots=""
        ogTitle="Find Junior and Leaving Cert Grinds Tutors in Ireland - MH1 Tutors"
        ogUrl="https://www.mh1-tutors.com/login"
        ogSiteName="MH1 Tutors"
        ogImage="https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2FLogo.png?alt=media&token=6e6b79d8-87db-45b1-840d-919623cbc159"
        ogImageType="image/png"
      />
      {loading && <LoadingSpinnerBig />}
      <MyNavbar />
      {/* {!loading && <ComingSoonAlert />} */}
      <div className="row w-100 mx-auto">
        <div className="col-md-7 col-12 py-lg-5 py-3">
          {/* <h1 className="text-center header-font fs-2">Welcome Back</h1> */}
          <Container
            className="login-page d-flex align-items-center justify-content-center my-2"
            style={{ minHeight: "100%" }}
          >
            <div className="w-100 my-2" style={{ maxWidth: "500px" }}>
              <Card className="shadow-lg rounded-lg border" bg="light">
                <Card.Body>
                  <h2 className="text-center mb-4 header-font">Login</h2>

                  {error && <Alert variant="danger">{error}</Alert>}
                  <Form id="signin-form" onSubmit={handleSubmit}>
                    <Form.Group id="signin-email" className="mt-3">
                      <Form.Label>
                        Email <span className="text-danger fw-bold">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        ref={emailRef}
                        name="email"
                        required
                      />
                    </Form.Group>
                    <Form.Group id="signin-password" className="mt-3">
                      <Form.Label>
                        Password <span className="text-danger fw-bold">*</span>
                      </Form.Label>
                      <Form.Control
                        type="password"
                        ref={passwordRef}
                        name="password"
                        required
                      />
                    </Form.Group>
                    <div className="mt-2">
                      Forgot your password?{" "}
                      <span
                        className="cursor-pointer"
                        onClick={handleShow}
                        style={{
                          color: "gray",
                          textDecoration: "underline",
                        }}
                      >
                        Click here
                      </span>{" "}
                      to reset it!
                    </div>
                    <Button
                      disabled={loading || (currentUser ? true : false)}
                      type="submit"
                      variant="primary"
                      className="w-100 mt-3"
                    >
                      Login
                    </Button>
                  </Form>
                  <GoogleSignInDivider />
                  <Button
                    disabled={loading || (currentUser ? true : false)}
                    variant="dark"
                    className="w-100"
                    onClick={googleSignIn}
                  >
                    <FcGoogle className="me-2 fs-5" />
                    Sign in with Google
                  </Button>
                </Card.Body>
              </Card>
              <div className="w-100 text-center mt-2">
                Don't have an account?{" "}
                <Link to="/sign-up" className="text-secondary">
                  Sign up
                </Link>{" "}
                here!
              </div>

              <Modal // Password reset modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Forgot Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {resetPasswordAlert && <Alert>{resetPasswordAlert}</Alert>}
                  <Form onSubmit={sendForgotPasswordEmail}>
                    <InputGroup>
                      <InputGroup.Text>Email</InputGroup.Text>
                      <Form.Control
                        ref={resetPasswordEmailRef}
                        placeholder="email@domain.com"
                        required
                      />
                    </InputGroup>
                    <Button type="submit" variant="primary" className="mt-3">
                      Send Reset Password Email
                    </Button>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </Container>
        </div>
        <div className="col-md-5 col-12">
          <div className="h-100 d-flex my-3">
            <img
              alt="MH1 Tutors - Find a Grinds Tutor in Ireland"
              src="https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Flogin.png?alt=media&token=8b5aec61-4801-4108-bfa6-68936ae7679f"
              className="w-75-md my-auto mx-auto"
              style={{ maxWidth: "100%" }}
            />
          </div>
        </div>
      </div>
      <ChromeOrSafariAlert
        showProp={isBadBrowser}
        setShowProp={setIsBadBrowser}
        chromeUrl={`googlechrome://${window.location.hostname}/login`}
      />
      <Toast
        show={showSignedInToast}
        className="mx-auto fs-6"
        style={{ position: "fixed", bottom: 75, left: 0, right: 0 }}
      >
        <Toast.Header
          closeButton={false}
          className="d-flex justify-content-between bg-danger text-light"
        >
          Your email address is not verified
          {/* <CloseButton
            onClick={() => setShowSignedInToast(false)}
            variant="white"
          /> */}
        </Toast.Header>
        <Toast.Body className="bg-white rounded d-flex flex-column">
          If you haven't received the verification email, click the button below
          to resend it. Also make sure to check your Spam inbox!
          {currentUser && !currentUser.emailVerified && (
            <Button
              variant="outline-dark"
              className="mx-auto my-2"
              onClick={resendConfirmationEmail}
            >
              Resend Verification Email
            </Button>
          )}
          Once you've verified your email, refresh this page to sign in.
        </Toast.Body>
      </Toast>
      <Toast
        show={currentUser && currentUser.emailVerified ? true : false}
        className="mx-auto"
        style={{ position: "fixed", bottom: 75, left: 0, right: 0 }}
      >
        <Toast.Header
          closeButton={false}
          className="d-flex justify-content-between bg-danger text-light"
        >
          You are already Signed In
        </Toast.Header>
        <Toast.Body className="bg-white rounded">
          Click here to go to your <Link to="/dashboard">dashboard</Link>
        </Toast.Body>
      </Toast>
    </>
  );
};

export default SignInPage;
