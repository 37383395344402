import { useState, useEffect } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Form,
  Modal,
  Spinner,
  ToggleButton,
} from "react-bootstrap";
import { AiOutlineSend } from "react-icons/ai";
import { useAuth } from "../../contexts/AuthContext";
import { sendEmailVerification } from "firebase/auth";
import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";

type Props = {
  handleModalToDisplay: () => void;
  modalToShow: string;
  tutorName: string;
  tutorUid: string;
  tutorSlug: string;
  closeModal: () => void;
};

// if user logged in, but email not verified, show modal to verify email
// if user not logged in, show modal to log in or sign up

const LoginOrSignUpToContactTutorModal = ({
  handleModalToDisplay,
  modalToShow,
  tutorName,
  tutorUid,
  tutorSlug,
  closeModal,
}: Props) => {
  const { currentUser } = useAuth();
  const [showSignUpStuff, setShowSignUpStuff] = useState(true);
  const [loading, setLoading] = useState(false);
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const [loadingSendVerificationEmail, setLoadingSendVerificationEmail] =
    useState(false);

  const resendConfirmationEmail = async () => {
    setVerificationEmailSent(false);
    setLoadingSendVerificationEmail(true);
    if (!currentUser)
      return alert("An error occured. Please ensure you are signed in!");

    const currentDomain = window.location.hostname;
    const actionCodeSettings = {
      url: `https://${currentDomain}/tutors?tu=${tutorSlug}`,
    };

    try {
      await sendEmailVerification(currentUser, actionCodeSettings);
      setVerificationEmailSent(true);
    } catch (err: any) {
      if (err.message.includes("too-many-requests")) {
        alert("Too many requests. Please try again later.");
      } else {
        alert("An error occured. Please try again later.");
      }
    }

    setLoadingSendVerificationEmail(false);
  };

  return (
    <Modal
      show={modalToShow === "login"}
      onHide={closeModal}
      centered
      backdrop="static"
      keyboard={false}
      className="paragraph-font"
    >
      {/* If email not verified, show "Verify Email" */}
      {!currentUser && (
        <Modal.Header closeButton={!loading} className="pb-1">
          <Modal.Title className="header-font">
            {showSignUpStuff ? "Sign Up" : "Login"} to contact {tutorName}
          </Modal.Title>
        </Modal.Header>
      )}
      {currentUser && !currentUser.emailVerified && (
        <Modal.Header closeButton={!loading} className="pb-1">
          <Modal.Title className="header-font">
            Verify Email to contact {tutorName}
          </Modal.Title>
        </Modal.Header>
      )}
      {!currentUser && (
        <>
          <Alert
            variant="success"
            className="light-font mb-0 mx-2 mt-2 shadow border-success border-opacity-50 p-2"
          >
            One final step to contact {tutorName}!
          </Alert>
          <ButtonGroup className="d-flex justify-content-center light-font px-2 pt-3">
            <ToggleButton
              value="sign-up-tgl"
              variant={showSignUpStuff ? "dark" : "outline-dark"}
              onClick={() => setShowSignUpStuff(true)}
            >
              Sign Up
            </ToggleButton>
            <ToggleButton
              value="log-in-tgl"
              variant={!showSignUpStuff ? "dark" : "outline-dark"}
              onClick={() => setShowSignUpStuff(false)}
            >
              Login
            </ToggleButton>
          </ButtonGroup>
        </>
      )}
      {/* always display, because when user with email not verified signs up
        there is a useEffect in there that needs to run!!!!!!
        */}
      {showSignUpStuff ? (
        <SignUpForm
          setLoading={setLoading}
          loading={loading}
          tutorSlug={tutorSlug}
          handleModalToDisplay={handleModalToDisplay}
        />
      ) : (
        <SignInForm
          setLoading={setLoading}
          loading={loading}
          tutorSlug={tutorSlug}
          handleModalToDisplay={handleModalToDisplay}
        />
      )}

      {/* this should only show if user is logged in
         but email is not verified */}
      {currentUser && !currentUser.emailVerified && !loading && (
        <Modal.Body>
          {verificationEmailSent ? (
            <Alert variant="success">
              Verification email sent! Please check your inbox to confirm your
              email. you don't see it, check your spam folder.
            </Alert>
          ) : (
            <>
              <Alert
                variant="warning"
                className="shadow border-warning border-opacity-50 light-font"
              >
                One last step!{" "}
                <span className="paragraph-font">Please verify your email</span>{" "}
                to contact this tutor!
              </Alert>
              <div>
                If you haven't received the verification email, click the button
                below to resend it. Also make sure to check your Spam inbox!
              </div>
              <div className="d-flex my-3">
                {currentUser && !currentUser.emailVerified && (
                  <Button
                    disabled={loadingSendVerificationEmail}
                    variant="outline-dark"
                    className="mx-auto"
                    onClick={resendConfirmationEmail}
                  >
                    {loadingSendVerificationEmail ? (
                      <Spinner className="my-auto mx-auto" size="sm" />
                    ) : (
                      <>
                        Resend Verification Email{" "}
                        <AiOutlineSend className="ms-2" />
                      </>
                    )}
                  </Button>
                )}
              </div>
            </>
          )}
          <div>
            Once you've verified your email, you will be able to contact this
            tutor!
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default LoginOrSignUpToContactTutorModal;
