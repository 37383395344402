import { Container } from "react-bootstrap";
import CAOCalcButtonSection from "./CAOCalcButtonSection";
import CAOCalculatorComponent from "./CAOCalculatorComponent";

const CAOCalcBody = () => {
  return (
    <Container
      style={{
        backgroundSize: "cover",
      }}
      className="py-3 d-flex flex-column justify-content-center"
    >
      <CAOCalcButtonSection />
      <CAOCalculatorComponent />
    </Container>
  );
};

export default CAOCalcBody;
