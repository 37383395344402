import { useEffect } from "react";
import { Alert, Container } from "react-bootstrap";
import tutorQualsInterface from "../../interfaces/tutorQualsInterface";
import NewQualificationButton from "./NewQualificationButton";
import Qualification from "./Qualification";
import { AiOutlineWarning } from "react-icons/ai";

const QualificationsForm = ({
  tutorQuals,
}: {
  tutorQuals: Array<tutorQualsInterface> | null;
}) => {
  useEffect(() => {
    if (window.innerWidth > 768) {
      document
        .getElementById("edit-quals-header")
        ?.scrollIntoView({ block: "center" });
    }
  }, []);

  return (
    <div>
      <div className="header-font fs-3 d-flex" id="edit-quals-header">
        Qualifications{" "}
        <NewQualificationButton
          disabled={(tutorQuals && tutorQuals?.length > 5) || false}
        />
      </div>
      <hr />
      {tutorQuals ? (
        <div className="d-flex flex-column-reverse">
          {tutorQuals.map((qual) => {
            return (
              <Container key={qual.title} className="py-2">
                <Qualification qual={qual} />
              </Container>
            );
          })}
        </div>
      ) : (
        <Alert variant="warning">
          <AiOutlineWarning className="text-danger fs-4 me-2 my-auto" />
          You don't have any qualifications set. Displaying your qualifications
          is a great way to show students or parents that you are a competent
          and qualified tutor. Press the "Add New" button to add a new
          qualification.
        </Alert>
      )}
    </div>
  );
};

export default QualificationsForm;
