import { Container } from "react-bootstrap";

const GoogleSignInDivider = () => {
  return (
    <Container className="row my-2 mx-auto" fluid style={{ color: "#676767" }}>
      <hr className="col-5 my-auto" />
      <div className="col-2 text-center">OR</div>
      <hr className="col-5 my-auto" />
    </Container>
  );
};

export default GoogleSignInDivider;
