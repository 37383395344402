import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

type Props = {
  accountType: string;
};

const alertClassName = "border-info shadow mt-4";

const NoContactsAlert = ({ accountType }: Props) => {
  if (accountType === "tutor") {
    return (
      <Alert variant="info" className={alertClassName}>
        You have not been contacted by any student yet. Make sure to set up your
        details to give you the best chance of being contacted by students.
      </Alert>
    );
  } else if (accountType === "student") {
    return (
      <Alert variant="info" className={alertClassName}>
        You have not contacted any tutors yet.{" "}
        <Link to="/tutors">Find a tutor</Link> to get started.
      </Alert>
    );
  } else {
    return null;
  }
};

export default NoContactsAlert;
