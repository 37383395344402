import { BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom";

type Props = {
  className: string;
  findTutorBool?: boolean;
};

const StudentSignUpButton = ({ className, findTutorBool }: Props) => {
  return (
    <Link
      style={{
        backgroundImage: "linear-gradient(to right,#8ac1f1,#0275d8)",
        minWidth: 250,
      }}
      className={
        className +
        " text-light light-font text-decoration-none d-flex justify-content-between mx-2 p-2 rounded shadow fs-4 hover-btn-stdnt"
      }
      to={findTutorBool ? "/tutors" : "/sign-up?account=student"}
    >
      <span className="mx-auto">
        {findTutorBool ? "Find a Grinds Tutor" : "Join as a Student"}
      </span>
      <BiChevronRight className="my-auto" />
    </Link>
  );
};

export default StudentSignUpButton;
