import { BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom";

type Props = {
  className: string;
};

const TutorSignUpButton = ({ className }: Props) => {
  return (
    <Link
      style={{ minWidth: 250 }}
      className={
        className +
        " text-decoration-none text-dark d-flex justify-content-between bg-white mx-2 p-2 rounded shadow light-font fs-4 hover-btn-tutor"
      }
      to="/sign-up?account=tutor">
      <span className="mx-auto">Join as a Tutor</span>
      <BiChevronRight className="my-auto" />
    </Link>
  );
};

export default TutorSignUpButton;
