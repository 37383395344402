import { useEffect, useState, useRef } from "react";
import Review from "./Review";

type Props = {
  reviewers: Array<string>;
  reviewees: Array<string>;
  imageUrls: Array<string>;
  texts: Array<string>;
  revieweeTypes: Array<string>;
  accountTypeSelected: string | null;
};

const MultipleReviews = ({
  reviewers,
  reviewees,
  imageUrls,
  texts,
  revieweeTypes,
  accountTypeSelected,
}: Props) => {
  const domRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    let currentVal: any = null;
    if (!domRef.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    currentVal = domRef.current;
    return () => {
      if (currentVal) return observer.unobserve(currentVal);
    };
  }, []);
  return (
    <div className="pt-5">
      <div
        ref={domRef}
        className={`header-font fade-in-section ${
          isVisible ? "is-visible" : ""
        }`}>
        <h3>
          {accountTypeSelected === "tutor"
            ? "What tutors who used our previous platform said:"
            : "What students have said about their tutors:"}
        </h3>
      </div>
      <div className="row justify-content-between">
        {[0, 1, 2].map((idx) => (
          <Review
            key={idx}
            reviewer={reviewers[idx]}
            reviewee={reviewees[idx]}
            imageUrl={imageUrls[idx]}
            text={texts[idx]}
            revieweeType={revieweeTypes[idx]}
          />
        ))}
      </div>
    </div>
  );
};

export default MultipleReviews;
