import allTutorData from "../../../interfaces/allTutorData";
import TabSectionAboutAndReviews from "./TabSectionAboutAndReviews";
import MobileContactBubble from "./MobileContactBubble";
import TutorInfoSideTab from "./TutorInfoSideTab";
import TutorDetailsTopSection from "./TutorDetailsTopSection";

type Props = {
  tutorData: allTutorData;
};

// possibly change this so that the tutorData is passed from a context
// and just wrap the TutorDataComp in the context provider
// would mean having to change a lot of the code in the components
const TutorDataComp = ({ tutorData }: Props) => {
  return (
    <>
      <div className="mx-3">
        <div className="my-3 d-flex flex-column flex-md-row-reverse justify-content-between">
          <div className="col col-12 col-md-4 col-xl-3 mx-auto">
            <div className="h-100-md sticky-on-med-up" style={{ top: 150 }}>
              <TutorInfoSideTab
                tutorUserData={tutorData.tutorUserData}
                tutorImage={tutorData.profileImageURL}
                tutorUid={tutorData.uid}
              />
            </div>
          </div>
          <div className="col col-12 col-md-8 col-xl-7 mx-auto">
            <div className="me-md-3">
              <TutorDetailsTopSection tutorUserData={tutorData.tutorUserData} />

              <TabSectionAboutAndReviews
                tutorUid={tutorData.uid}
                tutorUserData={tutorData.tutorUserData}
                tutorAbout={tutorData.aboutSection}
                tutorQualifications={tutorData.tutorQualifications}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <MobileContactBubble
          tutorUid={tutorData.uid}
          tutorData={tutorData.tutorUserData}
          tutorImage={tutorData.profileImageURL}
        />
      </div>
    </>
  );
};

export default TutorDataComp;
