import { useState, useRef } from "react";
import GradientButton from "../../reuseables/GradientButton";
import { FiCheck } from "react-icons/fi";
import { useAuth } from "../../../contexts/AuthContext";
import allContactData, {
  contactStatusTypes,
} from "../../../interfaces/allContactData";
import {
  Button,
  CloseButton,
  Overlay,
  Spinner,
  Toast,
  Tooltip,
} from "react-bootstrap";
import { useQueryClient } from "@tanstack/react-query";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase";

type Props = {
  contact: allContactData;
  status: contactStatusTypes;
};
const AcceptContactButton = ({ contact, status }: Props) => {
  const { userPrivateData, currentUser } = useAuth();
  const queryClient = useQueryClient();

  const [loadingAcceptContact, setLoadingAcceptContact] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);
  const [errorAcceptingContact, setErrorAcceptingContact] = useState("");

  const btnTarget = useRef<HTMLButtonElement>(null);

  const handleInitialButtonClick = () => setShowToolTip((prevVal) => !prevVal);

  const handleAcceptButtonClick = async () => {
    setLoadingAcceptContact(true);
    if (!userPrivateData)
      return alert(
        "There is an error with your account. Please contact us to rectify it!"
      );
    if (userPrivateData?.freeContactsAsTutorRemaining > 0) {
      console.log("accepting contact for free");
      const acceptContactUsingFreeContacts = httpsCallable(
        functions,
        "acceptContactUsingFreeContacts"
      );

      try {
        await acceptContactUsingFreeContacts({
          contactUid: contact.contactUid,
          studentUid: contact.studentUid,
          tutorUid: contact.tutorUid,
          tutorName: contact.tutorName,
          studentName: contact.studentName,
        });
      } catch (err: any) {
        console.log(err);
        setErrorAcceptingContact(err.message);
      }
    } else {
      setLoadingAcceptContact(false);
      setShowToolTip(false);
      return alert("An error occured. Please contact us.");
      // console.log("need to pay to accept contact");
      const currentDomain = window.location.origin;

      const createAcceptContactCheckoutSession = httpsCallable(
        functions,
        "createAcceptContactCheckoutSession"
      );
      try {
        const res: any = await createAcceptContactCheckoutSession({
          contactUid: contact.contactUid,
          studentUid: contact.studentUid,
          studentName: contact.studentName,
          tutorName: contact.tutorName,
          successUrl: `${currentDomain}/dashboard?location=contacts&contact=${contact.contactUid}`,
          cancelUrl: `${currentDomain}/dashboard?location=contacts&cancelled=true&contact=${contact.contactUid}`,
        });

        const session = res.data.session;
        const paymentLink = session.url;
        console.log(session);
        //window.location.replace(paymentLink);
        // open new tab instead
        // window.open(paymentLink, "_blank");
        window.location.replace(paymentLink);
      } catch (err: any) {
        console.log(err.message);
        setErrorAcceptingContact(err.message);
      }
    }
    // after contact accept
    queryClient.invalidateQueries(["contacts", currentUser!.uid]);
    setLoadingAcceptContact(false);
    setShowToolTip(false);
  };

  return (
    <div>
      <GradientButton
        onClickFunc={handleInitialButtonClick}
        disabled={status !== "Pending" || loadingAcceptContact}
        loading={false}
        ref={btnTarget}
        style={{ backgroundImage: "none" }}
        // if status is pending, make button bigger
        className={`${
          status === "Pending" ? "py-2 px-3" : "px-2 py-1"
        } me-2 bg-success`}
      >
        Accept Contact
        <FiCheck className="my-auto ms-2" />
      </GradientButton>
      <Overlay
        show={showToolTip}
        target={btnTarget.current}
        placement="top"
        containerPadding={0}
      >
        <Tooltip id="button-tooltip" style={{ opacity: 1 }}>
          <div className="p-2" style={{ fontSize: 12 }}>
            <div className="mb-2 text-start">
              {userPrivateData &&
              // if has free contacts - say one will be deducated, otherwise say you will be charged
              userPrivateData?.freeContactsAsTutorRemaining > 0
                ? "One free contact will be deducted from your balance in order to accept this contact!"
                : "As you are on the free plan, there is a small fee to accept this contact."}
            </div>
            <Button
              className="w-100"
              size="sm"
              onClick={handleAcceptButtonClick}
              disabled={loadingAcceptContact}
            >
              {loadingAcceptContact ? (
                <Spinner className="my-auto" size="sm" />
              ) : (
                "Unlock Student Details"
              )}
            </Button>
            <div
              className="light-font text-secondary text-start"
              style={{ fontSize: 12 }}
            >
              By accepting this contact, you agree to let us share your details
              with this student.
            </div>
          </div>
        </Tooltip>
      </Overlay>
      <Toast
        autohide
        delay={10000}
        show={errorAcceptingContact !== ""}
        bg="danger"
        onClose={() => setErrorAcceptingContact("")}
        className="mt-2 mx-auto p-2 text-light light-font d-flex justify-content-between"
        style={{ position: "fixed", bottom: 75, right: 20, zIndex: 99 }}
      >
        {errorAcceptingContact ||
          "An error occured while accepting contact. Please try again later."}
        <CloseButton
          variant="white"
          onClick={() => setErrorAcceptingContact("")}
        />
      </Toast>
    </div>
  );
};

export default AcceptContactButton;
