import { useState, useRef } from "react";
import { Alert, Button, Form, Modal, Spinner } from "react-bootstrap";
import { FiX } from "react-icons/fi";
import allContactData, {
  contactStatusTypes,
} from "../../../interfaces/allContactData";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase";
import { useQueryClient } from "@tanstack/react-query";
import { useAuth } from "../../../contexts/AuthContext";
import { CONTACT_DECLINE_REASONS } from "../../../constants/strings";

type Props = {
  contact: allContactData;
  status: contactStatusTypes;
};

const DeclineContactButton = ({ contact, status }: Props) => {
  const { currentUser } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [declineError, setDeclineError] = useState("");

  const reasonInputRef = useRef<HTMLSelectElement>(null);
  const queryClient = useQueryClient();

  const openModal = () => setShowModal(true);
  const closeModal = () => {
    setShowModal(false);
    setDeclineError("");
  };

  const handleDeclineModal = async (e: any) => {
    e.preventDefault();
    if (status !== "Pending")
      return setDeclineError("You can only decline pending contacts!");
    setLoading(true);
    setDeclineError("");

    const reason = reasonInputRef.current?.value;
    const declineContactAsTutor = httpsCallable(
      functions,
      "declineContactAsTutor"
    );
    try {
      await declineContactAsTutor({
        contactUid: contact.contactUid,
        declineMessage: reason,
        studentUid: contact.studentUid,
        studentName: contact.studentName,
        tutorUid: contact.tutorUid,
        tutorName: contact.tutorName,
      });
    } catch (e: any) {
      setDeclineError(e.message);
    }
    queryClient.invalidateQueries(["contacts", currentUser!.uid]);
    setLoading(false);
    closeModal();
  };

  return (
    <>
      <Button
        disabled={status !== "Pending"}
        variant="danger"
        size="sm"
        className="d-flex my-auto"
        onClick={openModal}
      >
        Decline <FiX className="ms-2 my-auto" />
      </Button>
      <Modal
        show={showModal}
        onHide={closeModal}
        centered
        backdrop={loading ? "static" : true}
      >
        <Modal.Header closeButton={!loading}>
          <Modal.Title className="header-font">Decline Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body className="paragraph-font">
          {status !== "Pending" && (
            <Alert variant="danger" className="border-danger shadow">
              You can only decline pending contacts!
            </Alert>
          )}
          <Form onSubmit={handleDeclineModal} id="decline-contact-form">
            <Form.Group>
              <Form.Label className="header-font">
                Select a reason for declining
              </Form.Label>
              <Form.Select
                disabled={loading}
                ref={reasonInputRef}
                className="light-font"
                required
                defaultValue=""
              >
                <option value="" className="text-secondary">
                  Select a reason
                </option>
                {CONTACT_DECLINE_REASONS.map((reason) => (
                  <option key={reason} value={reason}>
                    {reason}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <p className="light-font mt-2 mb-0" style={{ fontSize: 14 }}>
              *By declining this contact, you will no longer be able to unlock
              the students details. If you accidently decline, contact us and we
              will try to fix the issue.
            </p>
          </Form>
          {declineError && (
            <Alert variant="danger" className="border-danger shadow">
              {declineError}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer className="light-font">
          <Button
            disabled={loading || status !== "Pending"}
            variant="outline-danger"
            type="submit"
            form="decline-contact-form"
          >
            {loading ? <Spinner size="sm" /> : "Confirm Decline"}
          </Button>
          <Button disabled={loading} variant="dark" onClick={closeModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeclineContactButton;
