// import { useRef } from "react";
import { Button, Container } from "react-bootstrap";
import { AiOutlineFacebook, AiOutlineInstagram } from "react-icons/ai";
import { IoLogoTiktok } from "react-icons/io5";
import { Link } from "react-router-dom";

const Footer = () => {
  // const emailRef = useRef<HTMLInputElement>(null);

  const styles = {
    link: {
      color: "rgba(255,255,255,0.7)",
      marginRight: "10px",
      marginLeft: "10px",
    },
  };

  return (
    <div
      style={{
        color: "rgba(255,255,255,0.85)",
        backgroundColor: "#323232",
      }}
      className="fluid-container pb-2 paragraph-font"
    >
      <div
        style={{ fontSize: "30px" }}
        className="text-center header-font pt-2"
      >
        MH1 Tutors
      </div>
      <div className="social-links container text-center fs-2 mb-4 mt-1">
        <a
          style={styles.link}
          href="https://www.facebook.com/mh1.tutors"
          target="_blank"
          rel="nofollow noreferrer noopener"
        >
          <AiOutlineFacebook />
        </a>
        <a
          style={styles.link}
          href="https://www.instagram.com/mh1_tutors"
          target="_blank"
          rel="nofollow noreferrer noopener"
        >
          <AiOutlineInstagram />
        </a>
        <a
          style={styles.link}
          href="https://www.tiktok.com/@mh1_tutors"
          target="_blank"
          rel="nofollow noreferrer noopener"
        >
          <IoLogoTiktok />
        </a>
      </div>
      <Container className="row mx-auto my-2 text-center">
        <div className="col-sm-12 col-md-6 mb-3" style={{ fontSize: "20px" }}>
          {/* <div className="mb-2 header-font">Latest Updates</div>
          <div style={{ fontSize: "16px", color: "rgba(255,255,255,0.7)" }}>
            <p className="mb-0">
              <Button
                style={styles.link}
                variant="link"
                onClick={() => alert("Coming soon!")}
              >
                Subscribe to our newsletter
              </Button>
            </p>
          </div> */}
        </div>

        <div className="col-sm-12 col-md-6 mb-3" style={{ fontSize: "20px" }}>
          <div className="mb-2 header-font">Useful Links</div>
          <div style={{ fontSize: "16px", color: "rgba(255,255,255,0.7)" }}>
            <p className="mb-0">
              <Link style={styles.link} to="/terms-of-service">
                Terms of Service
              </Link>
            </p>
            <p className="mb-0">
              <Link style={styles.link} to="/refund-policy">
                Refund Policy
              </Link>
            </p>
            <p className="mb-0">
              <Link style={styles.link} to="/privacy-policy">
                Privacy Policy
              </Link>
            </p>
          </div>
        </div>
      </Container>
      <p style={{ textAlign: "center", fontSize: "14px" }} className="mx-3">
        © 2023, MH1 Tutors (previously Maths Help Ireland)
      </p>
    </div>
  );
};

export default Footer;
