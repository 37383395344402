import { doc, getDoc } from "firebase/firestore"
import { db } from "../firebase"

const getUserImage = async (userUid: string) => {

  const userImageRef = doc(db, "profileImages", userUid)
  const userImageDoc = await getDoc(userImageRef)

  if (userImageDoc.exists()) {
    return userImageDoc.data().imageUrl
  } else {
    return null
  }
}

export default getUserImage