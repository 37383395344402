import { useEffect, useState } from "react";
import { CloseButton } from "react-bootstrap";
import RoundedImageFitted from "../../reuseables/RoundedImageFitted";
import ContactTutorButton from "../ContactTutorButton";
import tutorUserData from "../../../interfaces/tutorUserData";
import { FiStar } from "react-icons/fi";
import { AiFillStar } from "react-icons/ai";

type Props = {
  tutorUid: string;
  tutorData: tutorUserData;
  tutorImage: string;
};

const MobileContactBubble = ({ tutorUid, tutorData, tutorImage }: Props) => {
  const [show, setShow] = useState(false);

  const toggleVisibility = () => {
    const scrolled = document.documentElement.scrollTop;
    // this can be adjusted to change when the bubble appears
    // depending on length of page
    const appearValue = 500;

    // console.log(scrolled);

    if (scrolled > appearValue) {
      setShow(true);
    } else if (scrolled <= appearValue) {
      setShow(false);
    }
  };

  useEffect(() => {
    // set this timeout so the page has time to scroll back to top
    const timeout = setTimeout(() => {
      window.addEventListener("scroll", toggleVisibility);
    }, 500);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div
      className={`light-font d-md-none bg-white m-1 p-2 rounded-3 shadow border border-dark border-opacity-50 fade-in-section fade-out-section ${
        show ? "is-visible" : ""
      }`}
      style={{
        minWidth: 350,
        position: "fixed",
        bottom: 10,
        zIndex: 100,
        fontSize: "0.82rem",
      }}
    >
      {/* <div>
        <CloseButton onClick={() => setShow(false)} />
      </div> */}
      <div className="d-flex flex-row justify-content-between w-100">
        <div className="d-flex flex-column align-items-center">
          <RoundedImageFitted
            src={tutorImage}
            alt={tutorData.displayName}
            width={80}
            height={80}
          />
          {/* <div>
            {[0, 1, 2, 3, 4, 5].map((val) => (
              <AiFillStar className="text-warning" />
            ))}
          </div> */}
        </div>
        <div className="d-flex flex-column justify-content-around align-items-center">
          <h5 className="header-font mb-0">{tutorData.displayName}</h5>
          <ContactTutorButton
            tutorUid={tutorUid}
            tutorSlug={tutorData.slug}
            tutorName={tutorData.displayName}
            tutorImageUrl={tutorImage}
            tutorOffersFreeConsultation={tutorData.offersFreeConsultation}
          />
        </div>
      </div>
    </div>
  );
};

export default MobileContactBubble;
