import React from "react";
import { Spinner } from "react-bootstrap";

type Props = {
  loading: boolean;
  onClickFunc: () => void;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
};

const GradientButton = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const { loading, onClickFunc, style, children, className, disabled } =
      props;
    return (
      <button
        ref={ref}
        disabled={disabled}
        style={{
          border: "none",
          backgroundImage: disabled
            ? ""
            : "linear-gradient(to right,#6cb2f0,#0275d8)",
          ...style,
        }}
        className={`light-font text-decoration-none d-flex justify-content-around rounded shadow ${className} ${
          disabled
            ? "bg-secondary bg-opacity-50 text-opacity-50"
            : "text-light hover-btn-stdnt"
        }`}
        onClick={onClickFunc}
      >
        {loading ? <Spinner size="sm" className="my-1" /> : children}
      </button>
    );
  }
);

export default GradientButton;
