import { contactBubbleProps } from "../../../interfaces/allContactData";
import { useQuery } from "@tanstack/react-query";
import getUserImage from "../../../requests/getUserImage";
import { default_image_path } from "../../../constants/strings";
import DeclineContactButton from "./DeclineContactButton";
import AcceptContactButton from "./AcceptContactButton";
import { Button, Form, Spinner } from "react-bootstrap";
import StudentDetailsButton from "./StudentDetailsButton";
import RoundedImageFitted from "../../reuseables/RoundedImageFitted";
import { formatFirebaseTimestamp } from "../../utils/ToolingFunctions";

const ContactBubbleTutor = ({ contact, status, color }: contactBubbleProps) => {
  const { isLoading, data: studentImageSrc } = useQuery(
    ["userImage", contact.studentUid],
    () => getUserImage(contact.studentUid),
    {
      staleTime: 1000 * 60 * 30, // 30 minutes
    }
  );

  // TODO:
  // function to accept contacts if user has free contacts
  // function to decline contacts
  // function to get stripe session, and after succesful payment, accept the contact
  // after function runs, update the invalidate cache so that the contact is updated
  // if status is accepts, don't display buttons below, instead display button to show contact info of student

  const buttonsToShow = () => {
    switch (status) {
      case "Pending":
        return (
          <>
            <AcceptContactButton contact={contact} status={status} />
            <DeclineContactButton contact={contact} status={status} />
          </>
        );
      case "Accepted":
        return (
          <StudentDetailsButton
            studentUid={contact.studentUid}
            studentImageUrl={studentImageSrc}
            studentName={contact.studentName}
            contactUid={contact.contactUid}
          />
        );
      case "Rejected":
        return (
          <Button variant="outline-danger" disabled size="sm">
            Declined
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    ///// MAKE IMAGE ROUND< MAKE IMAGE ROUND< MAKE IMAGE ROUND
    <>
      {/* Contact Info */}
      <div className="d-flex flex-row justify-content-between w-100">
        <div className="d-flex flex-column align-items-start">
          <h5 className="header-font mb-0">{contact.studentName}</h5>
          <div
            style={{ fontSize: "0.8rem" }}
            className={`bg-${color} rounded-pill px-3 text-center text-${
              status === "Pending" ? "dark" : "light"
            }`}
          >
            {status}
          </div>
        </div>
        <span>
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            <RoundedImageFitted
              src={studentImageSrc || default_image_path}
              alt={contact.studentName}
              height={75}
              width={75}
              className="mx-3"
            />
          )}
        </span>
      </div>
      <div className="">
        {contact.timestamp && (
          <div>
            <span className="header-font">Contact Date:</span>{" "}
            {formatFirebaseTimestamp(contact.timestamp)}
          </div>
        )}
        {contact.message && (
          <Form.Control
            className="light-font shadow-sm"
            as="textarea"
            rows={5}
            value={contact.message}
            disabled
          />
        )}
      </div>

      {status === "Rejected" && contact.respondedAt && (
        <div>
          <span className="header-font">Decline Date:</span>{" "}
          {formatFirebaseTimestamp(contact.respondedAt)}
        </div>
      )}
      <div className="d-flex justify-content-end paragraph-font light-font mt-3">
        {buttonsToShow()}
      </div>
    </>
  );
};

export default ContactBubbleTutor;
