import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import allTutorData from "../interfaces/allTutorData";
import tutorUserData from "../interfaces/tutorUserData";
import tutorQualsInterface from "../interfaces/tutorQualsInterface";
import profileImageInterface from "../interfaces/profleImageInterface";

/**
 * @param slug the slug from url ("/tutors/{slug}") of the tutor to view
 * 
 * @description gets all data for a single tutor, this includes:
 * 
 * - tutorUserData (from the tutors collection)
 * - tutorSubjects (from the tutorSubjects collection)
 * - profileImageURL (from the profileImages collection)
 * - tutorQualifications (from the tutors/{tutorUid}/qualifications collection)
 * - aboutSection (from the tutorAbouts collection)
 * 
 * @returns allTutorData object for the tutor with the given slug or null if no tutor found
 */
const getSingleTutor = async (slug: string) => {
  if(!slug) return console.log("no slug provided")
  console.log("Getting:", slug)
  // get tutor with required slug
  const ref = collection(db, "tutors");
  const q = query(
    ref,
    where("slug", "==", slug),
    where("displayProfile", "==", true)
  );

  // get from first doc (from tutors collection)
  let tutorUid: string | null = null;
  let tutorUserDataDoc: tutorUserData | null = null;

  // get form rest of docs
  let tutorImage: string | null = null;
  let tutorQuals: tutorQualsInterface[] | null = null;
  let tutorAbout: string | null = null;

  // have some sort of error handling here??
  const snap = await getDocs(q);
  if (snap.empty) {
    return null;
  } else {
    // the query should only ever return one tutor at most
    // if there's more than one tutor with same slug,
    // then there's a problem

    tutorUserDataDoc = snap.docs[0].data() as tutorUserData
    tutorUid = snap.docs[0].id;
  }

  // this will never be null, but just in case
  if (!tutorUid) return null;

  // we have uid, so can now get rest of info

  const tutorAboutPromise = getDoc(doc(db, "tutorAbouts", tutorUid));
  const tutorImagePromise = getDoc(doc(db, "profileImages", tutorUid));
  const tutorQualsPromise = getDocs(
    collection(db, "tutors", tutorUid, "qualifications")
  );

  const [
    tutorAboutSnap,
    tutorImageSnap,
    tutorQualsSnap,
  ] = await Promise.all([
    tutorAboutPromise,
    tutorImagePromise,
    tutorQualsPromise,
  ]);

  // tutor about section - AS STRING
  if (tutorAboutSnap.exists()) {
    tutorAbout = tutorAboutSnap.data().aboutSection;
  } else {
    tutorAbout = null;
  }

  // tutor image
  if (tutorImageSnap.exists()) {
    tutorImage = (tutorImageSnap.data() as profileImageInterface).imageUrl;
  } else {
    tutorImage = "/assets/no-profile-img1.png";
  }

  // tutor qualifications
  if (tutorQualsSnap.empty) {
    tutorQuals = null;
  }else {
    tutorQuals = [];
    tutorQualsSnap.forEach((doc) => {
      tutorQuals?.push(doc.data() as tutorQualsInterface);
    });
  }

  // return all data
  return {
    uid: tutorUid,
    tutorUserData: tutorUserDataDoc,
    profileImageURL: tutorImage,
    tutorQualifications: tutorQuals,
    aboutSection: tutorAbout,
  } as allTutorData;

};

export default getSingleTutor;