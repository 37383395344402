import { httpsCallable } from "firebase/functions";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Alert, Button, Form, Modal, Spinner } from "react-bootstrap";
import { FaGraduationCap } from "react-icons/fa";
import { HiPlus } from "react-icons/hi";
import { functions } from "../../firebase";

const NewQualificationButton = ({ disabled }: { disabled: boolean }) => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("danger");
  const [loading, setLoading] = useState(false);

  const MONTHS = useMemo(
    () => [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    []
  );

  const [years, setYears] = useState([0]);

  const [bioLength, setBioLength] = useState(0);
  const [currentlyAttending, setCurrentlyAttending] = useState(false);

  const titleRef = useRef<HTMLInputElement>(null);
  const schoolRef = useRef<HTMLInputElement>(null);
  const startMonthRef = useRef<HTMLSelectElement>(null);
  const startYearRef = useRef<HTMLSelectElement>(null);
  const endMonthRef = useRef<HTMLSelectElement>(null);
  const endYearRef = useRef<HTMLSelectElement>(null);
  const bioRef = useRef<HTMLTextAreaElement>(null);

  const handleClose = () => {
    setShow(false);
    setMessage("");
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    // set years array
    const thisYear = new Date().getFullYear();
    setYears(Array.from(new Array(40), (val, index) => thisYear - index));
  }, []);

  const handleAddNewQualification = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    setAlertVariant("warning");
    setMessage("Saving Qualification. Please wait...");

    // check if start date is greater than today
    const today = new Date();
    const thisYear = today.getFullYear();
    const thisMonth = today.getMonth() + 1;
    if (
      parseInt(startYearRef.current!.value) === thisYear &&
      parseInt(startMonthRef.current!.value) > thisMonth
    ) {
      setAlertVariant("danger");
      setLoading(false);
      return setMessage("Start Date can't be after today's date!");
    }

    // Check if end date is before start date
    if (
      !currentlyAttending &&
      parseInt(startYearRef.current!.value) >
        parseInt(endYearRef.current!.value)
    ) {
      setAlertVariant("danger");
      setLoading(false);
      return setMessage("End Date can't be before Start Date!");
    }
    if (
      !currentlyAttending &&
      parseInt(startYearRef.current!.value) ===
        parseInt(endYearRef.current!.value) &&
      parseInt(startMonthRef.current!.value) >
        parseInt(endMonthRef.current!.value)
    ) {
      setAlertVariant("danger");
      setLoading(false);
      return setMessage("End Date can't be before Start Date!");
    }

    const addTutorQualification = httpsCallable(
      functions,
      "addTutorQualification"
    );

    const qualData = {
      title: titleRef.current!.value,
      school: schoolRef.current!.value,
      startDate:
        startMonthRef.current!.value + "-" + startYearRef.current!.value,
      endDate: currentlyAttending
        ? "present"
        : endMonthRef.current!.value + "-" + endYearRef.current!.value,
      bio: bioRef.current!.value,
    };
    await addTutorQualification(qualData)
      .then(() => {
        setAlertVariant("info");
        setMessage("Qualification added succesfully!");
      })
      .catch((err: any) => {
        setAlertVariant("danger");
        setLoading(false);
        if (err.message.toLowerCase() === "internal")
          return setMessage("An error occured. Plase try again later.");
        return setMessage(err.message.replace("Firebase:", ""));
      });

    setLoading(false);
    const target = e.target as HTMLFormElement;
    target.reset();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="paragraph-font">
        <Modal.Header closeButton={!loading}>
          <Modal.Title className="header-font">
            <FaGraduationCap className="me-2 fs-2" />
            Add New Qualification
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleAddNewQualification}>
          <Modal.Body className="paragraph-font">
            {message && <Alert variant={alertVariant}>{message}</Alert>}
            {disabled && !message && (
              <Alert variant="warning">
                You currently have six qualifications set. At the moment, this
                is the maximum you can set. We are very sorry for any
                inconvenience.
              </Alert>
            )}
            <Form.Group>
              <Form.Label className="header-font">
                Qualification Title{" "}
                <span className="text-danger fw-bold">*</span>
              </Form.Label>
              <Form.Control
                ref={titleRef}
                placeholder="eg. Leaving Cert"
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="header-font mt-3">
                School or Educational Institution{" "}
                <span className="text-danger fw-bold">*</span>
              </Form.Label>
              <Form.Control
                ref={schoolRef}
                type="text"
                placeholder="Institution Name"
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="header-font mt-3">
                Start Date <span className="text-danger fw-bold">*</span>
              </Form.Label>
              <div className="d-flex flex-row">
                <Form.Select
                  ref={startMonthRef}
                  defaultValue=""
                  required
                  className="me-2">
                  <option value="" className="text-secondary">
                    Start Month
                  </option>
                  {MONTHS.map((month, idx) => (
                    <option value={idx + 1} key={"start-" + month}>
                      {month}
                    </option>
                  ))}
                </Form.Select>
                <Form.Select
                  ref={startYearRef}
                  defaultValue=""
                  required
                  className="ms-2">
                  <option value="" className="text-secondary">
                    Start Year
                  </option>
                  {years[0] &&
                    years.map((year, idx) => (
                      <option value={year} key={"start-" + year}>
                        {year}
                      </option>
                    ))}
                </Form.Select>
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label className="header-font mt-3">
                End Date <span className="text-danger fw-bold">*</span>
              </Form.Label>
              {!currentlyAttending && (
                <div className="d-flex flex-row">
                  <Form.Select
                    ref={endMonthRef}
                    defaultValue=""
                    required
                    className="me-2">
                    <option value="" className="text-secondary">
                      End Month
                    </option>
                    {MONTHS.map((month, idx) => (
                      <option value={idx + 1} key={"start-" + month}>
                        {month}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Select
                    ref={endYearRef}
                    defaultValue=""
                    required
                    className="ms-2">
                    <option value="" className="text-secondary">
                      End Year
                    </option>
                    {years[0] &&
                      years.map((year, idx) => (
                        <option value={year} key={"start-" + year}>
                          {year}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              )}
              <Form.Check
                defaultChecked={currentlyAttending}
                label="Currently attending this course."
                type="switch"
                className="mb-3 mt-2"
                onChange={(e) => setCurrentlyAttending(e.target.checked)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="header-font">
                Short Bio <span className="text-danger fw-bold">*</span>
              </Form.Label>
              <Form.Control
                ref={bioRef}
                as="textarea"
                rows={3}
                placeholder="Write a short bio about the qualification!"
                required
                maxLength={300}
                onChange={(e) => setBioLength(e.target.value.length)}
              />
              <div
                className="char-count float-end"
                style={{ fontSize: "14px" }}>
                <span className="qm-current">{bioLength}</span>
                <span className="qm-limit">/300</span>
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              type="submit"
              disabled={loading || disabled}>
              {loading ? <Spinner size="sm" /> : "Add Qualification"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Button
        variant="outline-success"
        className="ms-auto border border-success border-2 d-flex"
        onClick={handleShow}>
        <HiPlus className="me-2 fs-5 my-auto" />
        Add New
      </Button>
    </>
  );
};

export default NewQualificationButton;
