import { useState } from "react";
import { Image } from "react-bootstrap";
import ContactTutorButton from "../ContactTutorButton";
import tutorUserData from "../../../interfaces/tutorUserData";
import { FiCheckSquare, FiHome, FiMapPin, FiVideo } from "react-icons/fi";
import { MdOutlineSchedule } from "react-icons/md";

type Props = {
  tutorUid: string;
  tutorImage: string;
  tutorUserData: tutorUserData;
};

const bubbleClasses =
  "bg-secondary bg-opacity-10 p-2 my-1 rounded-3 d-flex light-font";
export const iconClasses = "my-auto mx-2 fs-5";

const TutorInfoSideTab = ({ tutorImage, tutorUid, tutorUserData }: Props) => {
  const [image, setImage] = useState<string>(tutorImage);
  return (
    <div
      className="bg-white shadow rounded-3 p-3 mb-3 mb-md-0 d-flex flex-column"
      // 94 is height of the NavBar
      style={{ marginTop: 94 }}
    >
      <div
        className="position-relative"
        style={{ paddingTop: "65px" }} // creates space for the image to 'pop out'
      >
        <Image
          roundedCircle
          className="position-absolute start-50 translate-middle"
          alt={""}
          style={{
            width: 150,
            height: 150,
            objectFit: "cover",
            filter: "drop-shadow(0px 10px 10px #bbbbbb)",
            top: -30,
            zIndex: 1,
          }}
          onError={() => setImage("/assets/no-profile-img1.png")}
          src={image}
        />
      </div>
      <h4 className="header-font text-center">{tutorUserData.displayName}</h4>
      <div className="d-flex flex-column mb-3">
        {tutorUserData.currentlyBookedUp && (
          <div className="p-2 my-1 rounded-3 d-flex bg-danger bg-opacity-10 light-font">
            <MdOutlineSchedule className={"text-danger " + iconClasses} />
            Currently Fully Booked
          </div>
        )}

        {tutorUserData.offersFreeConsultation && (
          <div
            className="light-font rounded-3 p-2 my-1"
            style={{ backgroundColor: "rgba(144, 238, 144, 0.2)" }}
          >
            <FiCheckSquare className={"text-success " + iconClasses} />
            Free 15 Minute Consultation
          </div>
        )}
        <hr className="dashed" />
        {tutorUserData.locationTutorsPlace && (
          <div className={bubbleClasses}>
            <FiHome className={"text-primary " + iconClasses} /> At Tutors Place
          </div>
        )}
        {tutorUserData.locationStudentsPlace && (
          <div className={bubbleClasses}>
            <FiMapPin className={"text-success " + iconClasses} /> At Students
            Place
          </div>
        )}
        {tutorUserData.locationOnline && (
          <div className={bubbleClasses}>
            <FiVideo style={{ color: "orangered" }} className={iconClasses} />{" "}
            Online
          </div>
        )}
      </div>
      <ContactTutorButton
        tutorUid={tutorUid}
        tutorSlug={tutorUserData.slug}
        tutorOffersFreeConsultation={tutorUserData.offersFreeConsultation}
        tutorName={tutorUserData.displayName}
        tutorImageUrl={tutorImage}
      />
    </div>
  );
};

export default TutorInfoSideTab;
