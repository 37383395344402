import MyNavbar from "../components/reuseables/MyNavBar";
import SEO from "../components/utils/SEO";

const Privacy = () => {
  return (
    <>
      <SEO
        title="Privacy Policy - MH1 Tutors"
        description="We respect your privacy as a grinds tutor or student/parent."
        keywords="Grinds Ireland,Maths Grinds,Find Grinds Tutors,Become a Grinds Tutor,Leaving Cert, Junior Cert, College Grinds, Primary School Grinds"
        robots=""
        ogTitle="Pricing - MH1 Tutors"
        ogUrl="https://www.mh1-tutors.com/privacy-policy"
        ogSiteName="MH1 Tutors"
        ogImage="https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2FLogo.png?alt=media&token=6e6b79d8-87db-45b1-840d-919623cbc159"
        ogImageType="image/png"
      />
      <MyNavbar />
      <div
        className="my-5 mx-4 mx-md-auto"
        style={{ textAlign: "justify", maxWidth: 768 }}
      >
        <h2 className="header-font text-center mb-5">
          MH1 Tutors - Privacy Policy
        </h2>
        <div>
          <span className="header-font">Last Updated: </span>31st August 2023
        </div>
        <div className="light-font">
          <p>
            At MH1 Tutors, we are committed to protecting the privacy of our
            users. This privacy policy outlines the types of information we
            collect, how we use that information, and the steps we take to
            ensure that your personal data is kept secure.
          </p>
        </div>
        <div>
          <h5 className="header-font">Use of Cookies:</h5>
          <p className="light-font">
            We use necessary cookies to provide our login and other essential
            site functions.
          </p>
        </div>
        <div>
          <h5 className="header-font">Form Block Submissions:</h5>
          <p className="light-font">
            When you submit information to this website via webform, we collect
            the data requested in the webform in order to track and respond to
            your submissions. Your information is encrypted before being written
            to a disk. We store your information in Cloud Firestore provided by
            Firebase.
          </p>
        </div>
        <div>
          <h5 className="header-font">User Account:</h5>
          <p className="light-font">
            If you create a user account on this website, we collect personal
            information to improve our platform experience and customer service.
            This information may include your:
          </p>
          <ul className="light-font">
            <li>Email Address</li>
            <li>Name</li>
            <li>Account Type</li>
            <li>Qualification(s) details</li>
            <li>Your profile picture</li>
          </ul>
        </div>
        <div>
          <h5 className="header-font">Marketing Emails:</h5>
          <p className="light-font">
            We may send you marketing emails, which you can unsubscribe from by
            clicking the link at the bottom of the email.
          </p>
        </div>
        <div>
          <h5 className="header-font">Order & Account Emails:</h5>
          <p className="light-font">
            We may email you with messages about your order or account activity.
            For example, we may email you to tell you that:
          </p>
          <ul className="light-font">
            <li>You've created a user account</li>
            <li>Your password has been reset or updated</li>
            <li>Your email has been reset or updated</li>
            <li>You've succesfully contacted a tutor</li>
            <li>A tutor has responded to your contact</li>
            <li>A student/parent has contacted you</li>
          </ul>
          <p className="light-font">
            It’s not possible to unsubscribe from these messages.
          </p>
        </div>
        <div>
          <p className="light-font">
            By using our services, you consent to the collection and use of your
            personal information as described in this privacy policy. If you
            have any questions or concerns about our privacy practices, please
            contact us.
          </p>
        </div>
      </div>
    </>
  );
};

export default Privacy;
