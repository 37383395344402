import { useEffect, useState, useRef } from "react";

interface Props {
  text: String;
}

const FadeInHeader = ({ text }: Props) => {
  const textDomRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setVisible] = useState(false);
  useEffect(() => {
    let currentVal: any = null;
    if (!textDomRef.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(textDomRef.current);
    currentVal = textDomRef.current;
    return () => {
      if (currentVal) return observer.unobserve(currentVal);
    };
  }, []);
  return (
    <div
      ref={textDomRef}
      className={`text-center my-5 fade-in-section ${
        isVisible ? "is-visible" : ""
      }`}>
      <h2 className="header-font" style={{ letterSpacing: "0.05rem" }}>
        {text}
      </h2>
    </div>
  );
};

export default FadeInHeader;
