import { Alert } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import MyNavbar from "../components/reuseables/MyNavBar";
import SEO from "../components/utils/SEO";

const PageNotFound = () => {
  const location = useLocation();
  return (
    <>
      <SEO
        title="Error 404 - MH1 Tutors"
        description="MH1 Tutors - Page not found"
        keywords="Grinds Ireland,Maths Grinds,Find Grinds Tutors,Become a Grinds Tutor,Leaving Cert, Junior Cert, College Grinds, Primary School Grinds"
        robots="noindex"
        ogTitle="MH1 Tutors"
        ogUrl="https://www.mh1-tutors.com/404"
        ogSiteName="MH1 Tutors"
        ogImage="https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2FLogo.png?alt=media&token=6e6b79d8-87db-45b1-840d-919623cbc159"
        ogImageType="image/png"
      />
      <MyNavbar />
      <div className="m-5 text-center fs-3 header-font py-5">
        <div className="pt-5">
          The page '{location.pathname}' is currently under construction. We
          will be launching our full platform soon.
        </div>
        <Alert variant="success" className="fs-5 paragraph-font my-5">
          <Link className="alert-link header-font" to="/sign-up">
            Sign up now
          </Link>{" "}
          to find out when our platform is fully launched and you will also
          receive all of our learning resources completely FREE!
        </Alert>
        <div className="fs-5 paragraph-font pb-5">
          <Link to="/">Back to Home Page</Link>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
