import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { tutorDataOnTutorsPage } from "../interfaces/allTutorData";
import tutorUserData from "../interfaces/tutorUserData";
import profileImageInterface from "../interfaces/profleImageInterface";

/**
 *
 * - tutorUserData (from the tutors collection)
 * - tutorSubjects (from the tutorSubjects collection)
 * - profileImageURL (from the profileImages collection)
 *
 * @returns
 */
const getTutorDataForCard = async (tutorUid: string) => {
  if (!tutorUid) return console.log("no tutorUid provided");
  console.log("Getting tutor card");
  // get tutor with required slug
  const ref = doc(db, "tutors", tutorUid);
  const tutorDoc = await getDoc(ref);

  // if tutor doesn't exist, return null
  if (!tutorDoc.exists()) {
    return null;
  }
  // get tutor data
  const tutorUserDataDoc = tutorDoc.data() as tutorUserData;

  // get form rest of docs
  let tutorImage: string | null = null;

  // we have uid, so can now get rest of info

  const tutorImageSnap = await getDoc(doc(db, "profileImages", tutorUid));

  // tutor image
  if (tutorImageSnap.exists()) {
    tutorImage = (tutorImageSnap.data() as profileImageInterface).imageUrl;
  } else {
    tutorImage = "/assets/no-profile-img1.png";
  }

  // return all data
  return {
    uid: tutorUid,
    tutorUserData: tutorUserDataDoc,
    profileImageURL: tutorImage,
  } as tutorDataOnTutorsPage;
};

export default getTutorDataForCard;
