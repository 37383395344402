import { doc, setDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useState, useRef } from "react";
import { Button, Modal, Form, Alert, Spinner } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { db, storage } from "../../firebase";

const ChangeProfileImageModal = () => {
  const { currentUser } = useAuth();
  const [displayForm, setDisplayForm] = useState(false);
  const [error, setError] = useState("");
  const [displayMessage, setDisplayMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const imageInputRef = useRef<HTMLInputElement>(null);

  const submitProfilePicture = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setDisplayMessage("Uploading File...");
    const extension: string | undefined = imageInputRef
      .current!.value.split(".")
      .pop();

    if (imageInputRef.current!.files && imageInputRef.current) {
      const file = imageInputRef.current.files[0];
      const imageSize = file.size;

      // I dont like all these checks being on client side but sure look
      // image size also checked on firebase

      // check if image not greater than 2MB (also checks in firestore)
      if (imageSize > 2048000) {
        setDisplayMessage("");
        setLoading(false);
        return setError("Image should not exceed 2 MB");
      }
      // check if image is square
      //   const isImageSquare = await checkIfImageIsSquare(
      //     window.URL.createObjectURL(file)
      //   );
      //   if (!isImageSquare) {
      //     setDisplayMessage("");
      //     setLoading(false);
      //     return setError("Image must be square!");
      //   }
    }
    // console.log("hello, i am after the if statement");

    // checks image format
    if (
      extension?.toLowerCase() !== "png" &&
      extension?.toLowerCase() !== "jpg" &&
      extension?.toLowerCase() !== "jpeg"
    ) {
      setDisplayMessage("");
      setLoading(false);
      return setError("Ensure the file is PNG, JPEG or JPG.");
    }
    // upload to firebase storage
    if (currentUser && imageInputRef.current?.files) {
      const uploadFileRef = ref(
        storage,
        "users/" + currentUser.uid + "/profilePicture"
      );
      const firestoreImageRef = doc(db, "profileImages", currentUser.uid);
      await uploadBytes(uploadFileRef, imageInputRef.current.files[0])
        .then(async (snapshot) => {
          await getDownloadURL(snapshot.ref).then(async (url) => {
            // set this url to firestore
            await setDoc(
              firestoreImageRef,
              { imageUrl: url },
              { merge: true }
            ).then(() => {
              setLoading(false);
              return setDisplayMessage("Image uploaded succesfully!");
            });
          });
        })
        .catch((e) => setError(e.message));
    } else {
      return setError("Please sign in to continue this operation.");
    }
  };

  // function checkIfImageIsSquare(src: string) {
  //   return new Promise((resolve, reject) => {
  //     let image = new Image();
  //     image.onload = function () {
  //       const height = image.naturalWidth;
  //       const width = image.naturalHeight;
  //       // console.log(height + " " + width);
  //       if (Math.abs(height - width) < 20) {
  //         resolve(true);
  //       } else {
  //         resolve(false);
  //       }
  //     };
  //     image.src = src;

  //     image.onerror = function () {
  //       reject(new Error("Failed to load image"));
  //     };
  //   });
  // }

  return (
    <>
      <Modal
        centered
        show={displayForm}
        onHide={() => setDisplayForm(false)}
        backdrop="static">
        <Modal.Header className="header-font fs-4" closeButton>
          Change Profile Picture
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          {displayMessage && <Alert variant="info">{displayMessage}</Alert>}

          <Form onSubmit={submitProfilePicture}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Upload Profile Picture</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                required
                ref={imageInputRef}
              />
            </Form.Group>
            <Button type="submit" disabled={loading}>
              {loading ? <Spinner size="sm" /> : "Save Profile Picture"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Button
        className="mb-3"
        onClick={() => {
          setDisplayForm(!displayForm);
          setError("");
          setDisplayMessage("");
          setLoading(false);
        }}>
        Change Profile Photo
      </Button>
    </>
  );
};

export default ChangeProfileImageModal;
