import { useEffect } from "react";
import { Container } from "react-bootstrap";
import TutorSubjectsLevel from "./TutorSubjectsLevel";
import { levelLabels } from "../../interfaces/allTutorData";
import tutorUserData from "../../interfaces/tutorUserData";

const TutorSubjectsForm = ({
  tutorData,
}: {
  tutorData: tutorUserData | null;
}) => {
  const LEVELS = [
    "Primary School",
    "Junior Cert",
    "Leaving Cert",
    "College",
    "Casual",
  ];

  const dbLabels: levelLabels[] = ["primary", "jc", "lc", "college", "casual"];

  useEffect(() => {
    if (window.innerWidth > 768) {
      document
        .getElementById("tutor-subjects-header")
        ?.scrollIntoView({ block: "center" });
    }
  }, []);

  return (
    <div>
      <div className="header-font fs-3" id="tutor-subjects-header">
        Subjects I can teach
      </div>
      <hr />
      <Container className="d-flex flex-column" fluid>
        {LEVELS.map((level, idx) => {
          return (
            <TutorSubjectsLevel
              key={level + "-subjects-display-edit"}
              level={level}
              levelDbLabel={dbLabels[idx]}
              tutorUserData={tutorData}
            />
          );
        })}
      </Container>
    </div>
  );
};

export default TutorSubjectsForm;
