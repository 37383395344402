import { useEffect, useState } from "react";
import MyNavbar from "../components/reuseables/MyNavBar";
import SEO from "../components/utils/SEO";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { Alert, Container, Spinner } from "react-bootstrap";
import FilterTutorsSection from "../components/tutors/FilterTutorsSection";
import { AiOutlineWarning } from "react-icons/ai";
import getTutorIdsBySubject from "../requests/getTutorIdsBySubject";
import PlanATutors from "../components/tutors/PlanATutors";
import { dbSubjectStrings, subjectLevels } from "../constants/strings";

const startsWithVowel = (str: string) => {
  return ["a", "e", "i", "o", "u"].indexOf(str[0].toLowerCase()) !== -1;
};

/**
 * TODO: Will we be able to add the "subject" as a parameter so we can have different pages for different subjects?
 * This way we can add them to the sitemap and might help with SEO
 *
 * Or maybe just have a separate component for these pages, and we can just have something like:
 * "/{subject(all-lower-case)}-tutors" and based off that it'll display the tutors for that subject
 * and can have a description about the subject and a title - again, for SEO
 *
 */

// temporary plan to use
const planToUse: "A" | "B" = "A";

const TutorsPage = () => {
  const [searchParams] = useSearchParams();
  const [subject, setSubject] = useState(searchParams.get("subject") || "");
  const queryClient = useQueryClient();

  useEffect(() => {
    setSubject(searchParams.get("subject")?.replaceAll("-", " ") || "");
  }, [searchParams]);

  const {
    data: tutorIds,
    isLoading: loadingTutorIds,
    error,
  } = useQuery(
    [
      "tutorIds",
      searchParams.get("subject") || "no-subject",
      searchParams.get("level") || "no-level",
      searchParams.get("sort") || "no-sort",
    ],
    () =>
      getTutorIdsBySubject(
        searchParams.get("subject") || "",
        searchParams.get("level") || "",
        searchParams.get("sort") === "true",
        searchParams.get("tu") || ""
      ),
    { staleTime: 1000 * 60 * 60 }
  );

  // useEffect(() => {
  //   const subject = searchParams.get("subject") || "no-subject";
  //   const level = searchParams.get("level") || "no-level";
  //   const sort = searchParams.get("sort") || "no-sort";
  //   queryClient.invalidateQueries(["tutorIds", subject, level, sort]);
  // }, [searchParams]);

  return (
    <>
      <SEO
        canonicalLink="https://www.mh1-tutors.com/tutors"
        title={`${
          subject + " " || ""
        }Junior and Leaving Cert Grinds Tutors in Ireland - MH1 Tutors`}
        description="Discover expert tutors in various subjects for Primary School, Junior Cert, Leaving Cert, College and higher! Alternatively, sign up as a tutor to advertise your service for free. "
        keywords="Maths,Chemistry,English,Irish,French,Biology,Programming,Science,Business,Grinds Ireland,Maths Grinds,Find Grinds Tutors,Become a Grinds Tutor,Leaving Cert, Junior Cert, College Grinds, Primary School Grinds"
        robots=""
        ogTitle={`${
          subject + " " || ""
        }Junior and Leaving Cert Grinds Tutors in Ireland - MH1 Tutors`}
        ogUrl="https://www.mh1-tutors.com/tutors"
        ogSiteName="MH1 Tutors"
        ogImage="https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2FLogo.png?alt=media&token=6e6b79d8-87db-45b1-840d-919623cbc159"
        ogImageType="image/png"
      />
      <MyNavbar />
      <div
        className="shadow-sm px-5 py-4 py-md-5 text-center text-md-start"
        style={{ backgroundColor: "#dddddd59" }}
      >
        <h1 className="header-font" style={{ fontSize: "50px" }}>
          Find a{subject && startsWithVowel(subject) && "n"}
          {subject ? ` ${subject}` : ""} Tutor in Ireland
        </h1>
        <p className="text-center text-md-start light-font text-secondary">
          Say Goodbye to Exam Stress: Connect with a tutor today!
        </p>
      </div>

      <Container className="d-flex my-3 w-100" fluid>
        {/* Displayed on larger screens */}
        <div className="light-font d-none d-md-block" style={{ minWidth: 200 }}>
          <FilterTutorsSection />
        </div>
        <div className="d-flex flex-column ms-md-4 w-100 justify-content-center align-items-center">
          <div className="light-font d-flex flex-column d-md-none me-auto">
            <FilterTutorsSection />
            {searchParams.get("subject") && (
              <div className="d-flex mt-2" style={{ flexFlow: "row wrap" }}>
                <div
                  className="blue-bubble-hover d-flex ms-2 px-2 rounded my-1"
                  style={{
                    backgroundColor: "#a9e0fb",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  {searchParams.get("subject")?.replaceAll("-", " ") || ""}
                </div>
              </div>
            )}
            {searchParams.get("level") && (
              <div className="d-flex mt-2" style={{ flexFlow: "row wrap" }}>
                <div
                  className="blue-bubble-hover d-flex ms-2 px-2 rounded my-1"
                  style={{
                    backgroundColor: "#a9e0fb",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  {
                    subjectLevels[
                      dbSubjectStrings.indexOf(searchParams.get("level") || "")
                    ]
                  }
                </div>
              </div>
            )}
          </div>

          {loadingTutorIds && (
            <div className="d-flex w-100">
              <Spinner animation="border" className="mx-auto my-3" />
            </div>
          )}
          {tutorIds && tutorIds.length > 0 ? (
            <div className="my-3 w-100 w-md-500px w-lg-800px">
              {planToUse === "A" ? (
                <PlanATutors tutorIds={tutorIds} selectedSubject={subject} />
              ) : (
                <div>Plan B</div>
              )}
            </div>
          ) : (
            !loadingTutorIds && (
              <Alert
                variant="warning"
                className="my-3 shadow border-warning w-100"
              >
                <AiOutlineWarning className="me-2" />
                No tutors found for this subject
              </Alert>
            )
          )}
        </div>
      </Container>
    </>
  );
};

export default TutorsPage;
