import { Toast, CloseButton } from "react-bootstrap";
import { useLocation } from "react-router-dom";

type Props = {
  showProp: boolean;
  setShowProp: React.Dispatch<React.SetStateAction<boolean>>;
  chromeUrl: string;
};

const ChromeOrSafariAlert = ({ showProp, setShowProp, chromeUrl }: Props) => {
  const location = useLocation();

  return (
    <Toast
      show={showProp}
      className="mt-2 mx-auto"
      style={{ position: "fixed", bottom: 75, left: 0, right: 0 }}
    >
      <Toast.Header
        closeButton={false}
        className="bg-danger text-light d-flex justify-content-between"
      >
        Google Sign-in is not supported on the current browser
        <CloseButton onClick={() => setShowProp(false)} variant="white" />
      </Toast.Header>
      <Toast.Body className="bg-white rounded">
        Google Sign-In does not work on your current browser. If you would like
        to sign in with your Google account, you can do one of the following
        actions:
        <ul className="my-2">
          <li>
            Open this page in{" "}
            <a
              href={chromeUrl}
              rel="noreferrer nofollow noopener"
              target="_blank"
            >
              Chrome
            </a>
          </li>
          <li>Open this page in the default browser</li>
          <li
            onClick={() => setShowProp(false)}
            className="link-primary"
            style={{ textDecoration: "underline" }}
          >
            Sign in with email instead
          </li>
        </ul>
      </Toast.Body>
    </Toast>
  );
};

export default ChromeOrSafariAlert;
