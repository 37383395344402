import { Modal } from "react-bootstrap";

type ModalProps = {
  show: boolean;
  onHideFunc: () => void;
  title: string;
  body: JSX.Element;
  footer?: JSX.Element;
};

/**
 *
 * This is a default modal template you can use for any modal you want to create.
 *
 * Before editing it, please ensure you don't mess up the layout of any other places it is used.
 *
 * @param show This should be a boolean that is set to true when you want the modal to show.
 * @param onHideFunc This function should set the state of the modal to false.
 * @param title This should be a string that is the title of the modal.
 * @param body This should be a JSX element that is the body of the modal.
 * @param footer This should be a JSX element that is the footer of the modal.
 *  - I have not included a defualt footer layout as this may depend on wether there is a function to call when submitting a form or something.
 * @returns
 */
const CustomModalTemplate = ({
  show,
  onHideFunc,
  title,
  body,
  footer,
}: ModalProps) => {
  return (
    <Modal
      show={show}
      onHide={onHideFunc}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header className="header-font" closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
};

export default CustomModalTemplate;
