import { useState } from "react";
import MyNavbar from "../components/reuseables/MyNavBar";
import HowItWorksCard from "../components/HowItWorks/HowItWorksCard";
import { useSearchParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import HowItWorksMiddle from "../components/HowItWorks/HowItWorksMiddle";
import HowItWorksBottom from "../components/HowItWorks/HowItWorksBottom";
import SEO from "../components/utils/SEO";

const HowItWorks = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showInfo, setShowInfo] = useState(true);

  const images = [
    "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fhow_it_works_1.jpg?alt=media&token=f435611a-2669-47b9-af67-6511e59b2cdb",
    "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fhow_it_works_2.jpg?alt=media&token=7bf178e1-e8ef-413c-a708-2e0f70897e70",
    "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fhow_it_works_3.jpg?alt=media&token=35ce17e4-c729-48bd-9eef-fbde3c4d1cdb",
  ];

  const titles = [
    "Find the perfect tutor",
    "Make a student account",
    "Contact your ideal tutor & start learning",
  ];
  const paragraphs = [
    "Browse tutors in any subject. Filter them by price, location, availability and much more.",
    "Once you've found the perfect tutor, create a student account in under 1 minute.",
    "You can now contact the tutor, and once they accept, you can organise your classes with them.",
  ];
  const tutorTitles = [
    "Sign up as a tutor",
    "Edit your profile",
    "Accept contacts from students",
  ];
  const tutorParagraphs = [
    "Create your account completely free.",
    "Customise your profile, displaying all your experience and qualifications so far.",
    "When a student contacts you, you can accept it to get their details and organise a time to start teaching.",
  ];

  const badgeColors = ["#0275d8", "#f0ad4e", "#292b2c"];

  const handleChangeAccountType = async (accountType: string) => {
    if (searchParams.get("account") === accountType) return;
    setShowInfo(false);
    setSearchParams({ account: accountType });
    await delay(100);
    return setShowInfo(true);
  };

  const delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  return (
    <>
      <SEO
        title="Leaving Cert and Junior Cert Grinds Ireland - MH1 Tutors"
        description="Learn how our platform works. Join the fasest growing community of tutors and students in Ireland in just three simple steps."
        keywords="Grinds Ireland,Maths Grinds,Find Grinds Tutors,Become a Grinds Tutor,Leaving Cert, Junior Cert, College Grinds, Primary School Grinds"
        robots=""
        ogTitle="Leaving Cert and Junior Cert Grinds Ireland - MH1 Tutors"
        ogUrl="https://www.mh1-tutors.com/how-it-works"
        ogSiteName="MH1 Tutors"
        ogImage="https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2FLogo.png?alt=media&token=6e6b79d8-87db-45b1-840d-919623cbc159"
        ogImageType="image/png"
      />
      <MyNavbar />
      <div className="text-center m-3" style={{ minHeight: "80vh" }}>
        <div>
          <div>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fzigzag.jpg?alt=media&token=0aa351c1-1ff1-4c96-a3fc-92c543d3eb83"
              alt="cool blue zigzag"
              style={{ maxWidth: 50 }}
            />
          </div>
          <h5 className="paragraph-font">Full Platform Launching Soon</h5>
          <h3 className="header-font">Connecting Students and Grinds Tutors</h3>
          <p
            className="light-font mx-auto"
            style={{ maxWidth: 750, textAlign: "justify" }}
          >
            {searchParams.get("account") === "tutor"
              ? "Get access to FREE advertising and a broader pool of potential students. With a personalised tutor profile, you can showcase your skills and attract students who best fit your expertise. Join our community of educators and take your tutoring career to the next level in 3 easy steps."
              : "Our platform offers students FREE access to a wide range of tutors, allowing them to find the one that best meets their needs. Find and connect with the perfect tutor in just 3 simple steps."}
          </p>
          <div>
            <Button
              className="mx-2 px-3"
              variant={
                searchParams.get("account") === "tutor"
                  ? "primary"
                  : "outline-primary"
              }
              style={{ minWidth: 100 }}
              onClick={() => handleChangeAccountType("tutor")}
            >
              Tutor
            </Button>
            <Button
              className="mx-2 px-3"
              variant={
                searchParams.get("account") === "tutor"
                  ? "outline-primary"
                  : "primary"
              }
              style={{ minWidth: 100 }}
              onClick={() => handleChangeAccountType("student")}
            >
              Student/Parent
            </Button>
          </div>
          <div className={showInfo ? "d-block" : "d-none"}>
            <div className="row pt-3">
              {[0, 1, 2].map((idx) => (
                <HowItWorksCard
                  accountType={searchParams.get("account")}
                  key={idx}
                  image={
                    images[
                      searchParams.get("account") === "tutor" ? 2 - idx : idx
                    ]
                  }
                  title={
                    searchParams.get("account") === "tutor"
                      ? tutorTitles[idx]
                      : titles[idx]
                  }
                  paragraph={
                    searchParams.get("account") === "tutor"
                      ? tutorParagraphs[idx]
                      : paragraphs[idx]
                  }
                  badgeColor={badgeColors[idx]}
                  step={idx}
                />
              ))}
            </div>
            <div>
              <HowItWorksMiddle />
            </div>
            <div>
              <HowItWorksBottom />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
