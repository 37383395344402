import { useMemo } from "react";
import MyNavbar from "../components/reuseables/MyNavBar";
import SEO from "../components/utils/SEO";
import FreeResourceBlock from "../components/freeResourcePage/FreeResourceBlock";

const FreeResources = () => {
  const headers = useMemo(
    () => [
      "Weekly Study Timetable",
      "Pomodoro Study Notion Template",
      "Junior Cert & Leaving Cert Construction Booklet",
      "Daily Study Planner",
      "5 Step Plan to Better Grades",
    ],
    []
  );
  const images = useMemo(
    () => [
      "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Ffree_weekly_study_timetable.png?alt=media&token=a54d1f07-d99e-47be-b89a-6bd740f67d0b",
      "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Ffree_pomodoro_notion.png?alt=media&token=bd1780c2-8fce-463b-8cc7-3e137333bb76",
      "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Ffree_constructions.png?alt=media&token=fc48e8f3-b456-43b4-8fc0-f9810de9ffff",
      "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Ffree_daily_planner.png?alt=media&token=868f57ed-0b9a-4236-bda0-49eded10cacb",
      "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Ffree_max_grade.png?alt=media&token=1654360b-bb31-4137-9381-3f7c8474ece0",
    ],
    []
  );
  const bodies = useMemo(
    () => [
      <>
        <p>
          A free weekly study plan template is a valuable tool that helps
          students effectively organise their study time. It provides a
          structured approach to learning, allowing individuals to prioritise
          their studies and allocate sufficient time for each subject. With this
          template, students can plan their study schedule every week, breaking
          down their tasks into manageable chunks that are easy to accomplish.
        </p>
        <p>
          Whether preparing for an upcoming exam or trying to catch up on missed
          coursework, this free weekly study plan template will help you stay
          organised and focused. It's easy to download and use, so start using
          it today and get the best exam results.
        </p>
      </>,
      <>
        <p>
          Maximize your study sessions with this user-friendly Notion template,
          featuring the proven Pomodoro method! This template makes it easy for
          you to stay focused, motivated, and productive while you learn, so you
          can get the most out of your study time.
        </p>

        <p>
          The Pomodoro Technique is a simple but powerful time management method
          that can help you stay focused and motivated while you learn. It works
          by breaking your study sessions into 25-minute blocks, separated by
          short breaks. This template includes a timer to help you stick to the
          Pomodoro method and make the most of your study time.
        </p>

        <p>
          But that's not all - this template also includes a to-do list to keep
          you organized and a section to listen to music while you work. And the
          best part? You can choose between a dark theme or light theme to match
          your personal preference.
        </p>

        <p>
          Take control of your learning and get more done with this stylish and
          helpful Notion template. Try it out today!
        </p>
      </>,
      <>
        <p>
          The Leaving Cert and Junior Cert maths constructions proofs booklet is
          a must-have resource for students studying higher-level maths in
          Ireland. This comprehensive guide provides a step-by-step approach to
          completing the 22 mathematical constructions required for the Leaving
          Cert Maths exam.
        </p>
        <p>
          The booklet includes clear instructions, helpful diagrams, and
          easy-to-understand proofs that break down the mathematical concepts
          behind each construction. Using this resource, students can build a
          strong foundation in geometric concepts and improve their
          problem-solving skills, making it an essential tool for success in the
          Leaving Cert and Junior Cert Maths exams. So, whether you want to
          improve your grades or gain a deeper understanding of mathematical
          constructions, download the booklet today and take the first step
          towards maths mastery.
        </p>
      </>,
      <>
        <p>
          A daily study planner is essential for students looking to stay on top
          of their coursework and achieve their academic goals. This free
          planner provides a simple yet effective way to organise your daily
          schedule and maximise productivity.
        </p>
        <p>
          With a daily study planner, you can set clear goals and priorities for
          each day, helping you to stay focused and motivated. The planner
          includes sections for scheduling your classes, planning your study
          sessions, and tracking your progress throughout the day. You can also
          use the planner to jot down notes and ideas, ensuring you stay
          organised and prepared for each task.
        </p>
        <p>
          Whether you are a college, secondary school, or professional looking
          to improve your time management skills, this daily study planner is a
          valuable resource. It's easy to use and can help you to stay on track
          with your studies and achieve your academic or career goals. So,
          download the planner today and start organising your day for success!
        </p>
      </>,
      <>
        <p>
          The 5-Step Plan to Better Grades eBook is the ultimate guide for
          students looking to improve their studying habits and achieve their
          academic goals. This 25-page eBook offers a comprehensive roadmap for
          maximising exam performance, including planning tips, practising exam
          questions, and staying motivated.
        </p>
        <p>
          The eBook goes beyond just providing study tips and includes
          customisable templates for creating monthly, weekly, and daily study
          plans and other helpful resources. With these tools, you can create a
          personalised study plan that works for you and your unique learning
          style.
        </p>
        <p>
          Whether you are struggling to keep up with your coursework or seeking
          ways to improve your grades, this eBook is an essential resource for
          any student looking to achieve exam success. So, start your journey
          towards better rates today and download this valuable resource!
        </p>
      </>,
    ],
    []
  );

  return (
    <>
      <SEO
        title="Free Leaving Cert and Junior Cert Learning Resources - MH1 Tutors"
        description="Free learning resources to help you succeed. Download all our digital learning tools and find expert grinds tutors in any subject."
        keywords="Digital Learning Resources,Learning Aids,Learning Tools,Grinds Ireland,Maths Grinds,Find Grinds Tutors,Become a Grinds Tutor,Leaving Cert, Junior Cert, College Grinds, Primary School Grinds"
        robots=""
        ogTitle="Free Leaving Cert and Junior Cert Learning Resources - MH1 Tutors"
        ogUrl="https://www.mh1-tutors.com/learning-resources"
        ogSiteName="MH1 Tutors"
        ogImage="https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2FLogo.png?alt=media&token=6e6b79d8-87db-45b1-840d-919623cbc159"
        ogImageType="image/png"
      />
      <MyNavbar />
      <div className="m-4 row">
        {headers.map((header, idx) => (
          <FreeResourceBlock
            key={header}
            header={headers[idx]}
            body={bodies[idx]}
            image={images[idx]}
            reverse={idx % 2 === 0}
          />
        ))}
      </div>
    </>
  );
};

export default FreeResources;
