import { useState, useRef, useEffect } from "react";

type Props = {
  button: React.ReactNode;
};

const CTAButtonFadeIn = ({ button }: Props) => {
  const domRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    let currentVal: any = null;
    if (!domRef.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    currentVal = domRef.current;
    return () => {
      if (currentVal) return observer.unobserve(currentVal);
    };
  }, []);

  return (
    <div
      ref={domRef}
      className={`mx-auto  fade-in-section ${isVisible ? "is-visible" : ""}`}
      style={{ maxWidth: 450 }}>
      {button}
    </div>
  );
};

export default CTAButtonFadeIn;
