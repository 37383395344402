import { ReactNode } from "react";
import HomePageBenefit from "./HomePageBenefit";

interface Props {
  texts: Array<String>;
  titles: Array<String>;
  icons: Array<ReactNode>;
}

const HomePageBenefits = ({ texts, titles, icons }: Props) => {
  return (
    <div className="my-3">
      <HomePageBenefit text={texts[0]} title={titles[0]} icon={icons[0]} />
      <HomePageBenefit
        text={texts[1]}
        title={titles[1]}
        icon={icons[1]}
        reverse={true}
      />
      <HomePageBenefit text={texts[2]} title={titles[2]} icon={icons[2]} />
    </div>
  );
};

export default HomePageBenefits;
