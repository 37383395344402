import React, { ReactNode, useEffect, useRef, useState } from "react";

const FadeInComp = ({ children }: { children: ReactNode }) => {
  const domRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    let currentVal: any = null;
    if (!domRef.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    currentVal = domRef.current;
    return () => {
      if (currentVal) return observer.unobserve(currentVal);
    };
  }, []);
  return (
    <div
      ref={domRef}
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}>
      {children}
    </div>
  );
};

export default FadeInComp;
