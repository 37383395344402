import { useEffect, useState, useRef, useMemo } from "react";
import tutorQualsInterface from "../../interfaces/tutorQualsInterface";
import {
  FiHome,
  FiCalendar,
  FiTrash2,
  FiEdit3,
  FiMinus,
  FiPlus,
} from "react-icons/fi";
import { Alert, Button, Collapse, Form, Modal, Spinner } from "react-bootstrap";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";

const Qualification = ({ qual }: { qual: tutorQualsInterface }) => {
  const MONTHS = useMemo(
    () => [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    []
  );

  const [expanded, setExpanded] = useState<boolean>(false);
  const [datePeriod, setDatePeriod] = useState<string>(
    qual.startDate?.replace("-", "/") + "-" + qual.endDate?.replace("-", "/")
  );

  const [loading, setLoading] = useState(false);
  const [showEditQualModal, setShowEditQualModal] = useState(false);
  const [editMessage, setEditMessage] = useState("");
  const [editAlertVariant, setEditAlertVariant] = useState("info");

  const [showDeleteQualModal, setShowDeleteQualModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [deleteAlertVariant, setDeleteAlertVariant] = useState("info");

  // edit qual form stuff
  const titleRef = useRef<HTMLInputElement>(null);
  const schoolRef = useRef<HTMLInputElement>(null);
  const startMonthRef = useRef<HTMLSelectElement>(null);
  const startYearRef = useRef<HTMLSelectElement>(null);
  const endMonthRef = useRef<HTMLSelectElement>(null);
  const endYearRef = useRef<HTMLSelectElement>(null);
  const bioRef = useRef<HTMLTextAreaElement>(null);

  const [years, setYears] = useState([0]);
  const [bioLength, setBioLength] = useState(qual.bio?.length);
  const [currentlyAttending, setCurrentlyAttending] = useState(false);

  useEffect(() => {
    const thisYear = new Date().getFullYear();
    setYears(Array.from(new Array(40), (val, index) => thisYear - index));
  }, []);

  useEffect(() => {
    const [startMonth, startYear] = qual.startDate.split("-");
    if (qual.endDate !== "present") {
      const [endMonth, endYear] = qual.endDate.split("-");
      setDatePeriod(
        MONTHS[Number(startMonth) - 1] +
          " " +
          startYear +
          " - " +
          MONTHS[Number(endMonth) - 1] +
          " " +
          endYear
      );
    } else {
      setCurrentlyAttending(true);
      setDatePeriod(
        MONTHS[Number(startMonth) - 1] + " " + startYear + " - Present"
      );
    }
  }, [qual, MONTHS]);

  const handleEditModalClose = () => {
    if (qual.endDate === "present") {
      setCurrentlyAttending(true);
    } else {
      setCurrentlyAttending(false);
    }
    setEditMessage("");
    setShowEditQualModal(false);
  };

  const handleEdit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    setEditMessage("Saving your changes. Please wait...");
    setEditAlertVariant("info");

    // check if start date is greater than today
    const today = new Date();
    const thisYear = today.getFullYear();
    const thisMonth = today.getMonth() + 1;
    if (
      parseInt(startYearRef.current!.value) === thisYear &&
      parseInt(startMonthRef.current!.value) > thisMonth
    ) {
      setEditAlertVariant("danger");
      setLoading(false);
      return setEditMessage("Start Date can't be after today's date!");
    }

    // Check if end date is before start date
    if (
      !currentlyAttending &&
      parseInt(startYearRef.current!.value) >
        parseInt(endYearRef.current!.value)
    ) {
      setEditAlertVariant("danger");
      setLoading(false);
      return setEditMessage("End Date can't be before Start Date!");
    }
    if (
      !currentlyAttending &&
      parseInt(startYearRef.current!.value) ===
        parseInt(endYearRef.current!.value) &&
      parseInt(startMonthRef.current!.value) >
        parseInt(endMonthRef.current!.value)
    ) {
      setEditAlertVariant("danger");
      setLoading(false);
      return setEditMessage("End Date can't be before Start Date!");
    }

    const qualData = {
      title: titleRef.current!.value,
      school: schoolRef.current!.value,
      startDate:
        startMonthRef.current!.value + "-" + startYearRef.current!.value,
      endDate: currentlyAttending
        ? "present"
        : endMonthRef.current!.value + "-" + endYearRef.current!.value,
      bio: bioRef.current!.value,
      id: qual.id,
    };

    const editTutorQualification = httpsCallable(
      functions,
      "editTutorQualification"
    );

    await editTutorQualification(qualData)
      .then(() => {
        setEditAlertVariant("info");
        setEditMessage("Qualification Edited succesfully!");
      })
      .catch((err: any) => {
        setEditAlertVariant("danger");
        setLoading(false);
        if (err.message.toLowerCase() === "internal")
          return setEditMessage("An error occured. Plase try again later.");
        return setEditMessage(err.message.replace("Firebase:", ""));
      });

    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    setDeleteMessage("Deleting Qualification, please wait....");
    setDeleteAlertVariant("info");

    const deleteQualification = httpsCallable(functions, "deleteQualification");

    await deleteQualification({ id: qual.id })
      .then(() => {
        setDeleteAlertVariant("info");
        return setDeleteMessage("Qualification has been succesfully deleted.");
      })
      .catch((err) => {
        setDeleteAlertVariant("danger");
        return setDeleteMessage(err.message.replace("Firebase: ", ""));
      });

    setLoading(false);
  };

  return (
    <>
      <div className="d-flex justify-content-between my-2">
        <h5 className="header-font">{qual.title}</h5>
        <div className="d-flex flex-row">
          <div className="mx-2 text-danger">
            <FiTrash2
              className="cursor-pointer"
              onClick={() => setShowDeleteQualModal(true)}
            />
          </div>
          <div className="mx-2 text-success">
            <FiEdit3
              className="cursor-pointer"
              onClick={() => setShowEditQualModal(true)}
            />
          </div>
          <div
            className="mx-2"
            aria-controls={"qual-bio-" + qual.id}
            aria-expanded={expanded}
            onClick={() => setExpanded(!expanded)}>
            {expanded ? (
              <FiMinus className="cursor-pointer" />
            ) : (
              <FiPlus className="cursor-pointer" />
            )}
          </div>
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row text-muted my-2">
        <div className="me-0 me-md-3 d-flex flex-row mb-2 mb-sm-0">
          <FiHome className="me-1 my-auto" />
          {qual.school}
        </div>
        <div className="ms-0 ms-md-3 d-flex flex-row mb-2 mb-sm-0">
          <FiCalendar className="me-1 my-auto" /> {datePeriod}
        </div>
      </div>
      <Collapse in={expanded} className="light-font">
        <div
          id={"qual-bio-" + qual.id}
          className="my-3 text-secondary"
          style={{ fontSize: "18px", wordWrap: "break-word" }}>
          {qual.bio}
        </div>
      </Collapse>

      <hr className="dashed" />
      <Modal
        id={"delete-qual-" + qual.id}
        show={showDeleteQualModal}
        onHide={() => setShowDeleteQualModal(false)}
        backdrop="static"
        keyboard={false}
        centered>
        <Modal.Header closeButton={!loading}>
          <Modal.Title className="header-font">
            Delete Qualification?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleteMessage && (
            <Alert className="paragraph-font" variant={deleteAlertVariant}>
              {deleteMessage}
            </Alert>
          )}
          <Alert variant="secondary" className="paragraph-font">
            Are you sure you want to permanently delete the qualification titled
            "{qual.title}"?
          </Alert>
        </Modal.Body>
        <Modal.Footer className="paragraph-font">
          <Button
            variant="outline-danger"
            onClick={handleDelete}
            disabled={loading}>
            {loading ? <Spinner size="sm" /> : "Confirm Delete"}
          </Button>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteQualModal(false)}
            disabled={loading}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        id={"edit-qual-" + qual.id}
        show={showEditQualModal}
        onHide={handleEditModalClose}
        backdrop="static"
        keyboard={false}
        centered>
        <Modal.Header closeButton={!loading}>
          <Modal.Title className="header-font">Edit Qualification</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleEdit}>
          <Modal.Body className="paragraph-font">
            {editMessage && (
              <Alert variant={editAlertVariant}>{editMessage}</Alert>
            )}
            <Form.Group>
              <Form.Label className="header-font">
                Qualification Title{" "}
                <span className="text-danger fw-bold">*</span>
              </Form.Label>
              <Form.Control ref={titleRef} defaultValue={qual.title} required />
            </Form.Group>
            <Form.Group>
              <Form.Label className="header-font mt-3">
                School or Educational Institution{" "}
                <span className="text-danger fw-bold">*</span>
              </Form.Label>
              <Form.Control
                ref={schoolRef}
                type="text"
                defaultValue={qual.school}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="header-font mt-3">
                Start Date <span className="text-danger fw-bold">*</span>
              </Form.Label>
              <div className="d-flex flex-row">
                <Form.Select
                  ref={startMonthRef}
                  defaultValue={qual.startDate.split("-")[0]}
                  required
                  className="me-2">
                  <option value="" className="text-secondary">
                    Start Month
                  </option>
                  {MONTHS.map((month, idx) => (
                    <option value={idx + 1} key={"start-" + month}>
                      {month}
                    </option>
                  ))}
                </Form.Select>
                <Form.Select
                  ref={startYearRef}
                  defaultValue={qual.startDate.split("-")[1]}
                  required
                  className="ms-2">
                  <option value="" className="text-secondary">
                    Start Year
                  </option>
                  {years[0] &&
                    years.map((year, idx) => (
                      <option value={year} key={"start-" + year}>
                        {year}
                      </option>
                    ))}
                </Form.Select>
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label className="header-font mt-3">
                End Date <span className="text-danger fw-bold">*</span>
              </Form.Label>
              {!currentlyAttending && (
                <div className="d-flex flex-row">
                  <Form.Select
                    ref={endMonthRef}
                    defaultValue={
                      qual.endDate === "present"
                        ? ""
                        : qual.endDate.split("-")[0]
                    }
                    required
                    className="me-2">
                    <option value="" className="text-secondary">
                      End Month
                    </option>
                    {MONTHS.map((month, idx) => (
                      <option value={idx + 1} key={"start-" + month}>
                        {month}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Select
                    ref={endYearRef}
                    defaultValue={
                      qual.endDate === "present"
                        ? ""
                        : qual.endDate.split("-")[1]
                    }
                    required
                    className="ms-2">
                    <option value="" className="text-secondary">
                      End Year
                    </option>
                    {years[0] &&
                      years.map((year, idx) => (
                        <option value={year} key={"start-" + year}>
                          {year}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              )}
              <Form.Check
                defaultChecked={currentlyAttending}
                label="Currently attending this course."
                type="switch"
                className="mb-3 mt-2"
                onChange={(e) => setCurrentlyAttending(e.target.checked)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="header-font">
                Short Bio <span className="text-danger fw-bold">*</span>
              </Form.Label>
              <Form.Control
                ref={bioRef}
                as="textarea"
                rows={3}
                defaultValue={qual.bio}
                required
                maxLength={300}
                onChange={(e) => setBioLength(e.target.value.length)}
              />
              <div
                className="char-count float-end"
                style={{ fontSize: "14px" }}>
                <span className="qm-current">{bioLength}</span>
                <span className="qm-limit">/300</span>
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="paragraph-font">
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? <Spinner size="sm" /> : "Save Changes"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default Qualification;
