import { useParams } from "react-router-dom";
import MyNavbar from "../components/reuseables/MyNavBar";
import { Spinner } from "react-bootstrap";
import SEO from "../components/utils/SEO";
import TutorNotFoundComp from "../components/tutors/tutorProfile/TutorNotFoundComp";
import { useQuery } from "@tanstack/react-query";
import getSingleTutor from "../requests/getSingleTutor";
import TutorDataComp from "../components/tutors/tutorProfile/TutorDataComp";

const TutorProfile = () => {
  const { tutorSlug } = useParams();

  const {
    isLoading,
    error,
    data: tutorData,
  } = useQuery(
    ["tutor_profile_data", tutorSlug],
    () => getSingleTutor(tutorSlug || ""),
    { staleTime: 5 * 60 * 1000 } // 5 minutes
  );

  return (
    <>
      <SEO
        title={
          tutorData?.tutorUserData?.displayName
            ? `${tutorData.tutorUserData.displayName} Grinds Tutor in Ireland`
            : "Leaving Cert and Junior Cert Grinds Tutors in Ireland - MH1 Tutors"
        }
        description={
          tutorData?.tutorUserData?.subheading ||
          "Find grinds teachers in Ireland for Junior Cert, Leaving Cert, Primary School, College and more. Discover grinds tutors in subjects like Maths, Irish, Chemistry, French, and many more."
        }
        keywords="Grinds Ireland,Maths Grinds,Find Grinds Tutors,Become a Grinds Tutor,Leaving Cert, Junior Cert, College Grinds, Primary School Grinds"
        robots=""
        ogTitle="Leaving Cert and Junior Cert Grinds Tutors in Ireland - MH1 Tutors"
        ogUrl={"https://www.mh1-tutors.com/tutors/" + tutorSlug}
        ogSiteName="MH1 Tutors"
        ogImage="https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2FLogo.png?alt=media&token=6e6b79d8-87db-45b1-840d-919623cbc159"
        ogImageType="image/png"
        canonicalLink={`https://www.mh1-tutors.com/tutors/${tutorSlug}`}
      />
      <MyNavbar />
      {/* Display a spinner while loading tutor data - move to displaying placeholder components instead */}
      {isLoading ? (
        <div
          className="d-flex justify-content-center text-center"
          style={{ minHeight: "50vh" }}
        >
          <Spinner style={{ width: 80, height: 80 }} className="fs-4 my-auto" />
        </div>
      ) : tutorData ? (
        // If tutor is found, display their profile
        <div>
          <TutorDataComp tutorData={tutorData} />
          {/* <div>
            {otherTutorsData ? (
              <div>
                These are taken from cache:
                {otherTutorsData.map((tutor) => (
                  <div key={tutor.uid}>{tutor.tutorUserData.displayName}</div>
                ))}
              </div>
            ) : null}
          </div> */}
        </div>
      ) : (
        // If tutor is not found, display some message for users
        <TutorNotFoundComp />
      )}
    </>
  );
};

export default TutorProfile;
