import MyNavbar from "../components/reuseables/MyNavBar";

const Subscribe = () => {
  return (
    <>
      <MyNavbar />
      <div className="px-3 py-5 my-5">Susbcribe - todo</div>
    </>
  );
};

export default Subscribe;
