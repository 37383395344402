import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import CustomModalTemplate from "../utils/CustomModalTemplate";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
import { FirebaseError } from "firebase/app";
import ContactTutorData from "../../interfaces/contactTutorData";
import { Alert, Button, Form, Image, Spinner, Toast } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import LoginOrSignUpToContactTutorModal from "./LoginOrSignUpToContactTutorModal";

type modalValues = "" | "contact" | "login" | "tutor";

type Props = {
  tutorUid: string;
  tutorSlug: string;
  tutorOffersFreeConsultation: boolean;
  tutorName: string;
  tutorImageUrl: string;
};

/**
 * This is just a button at the moment. May be changed into a form that
 * can be displayed for the student to contact the tutor
 * @returns
 */
const ContactTutorButton = ({
  tutorUid,
  tutorSlug,
  tutorOffersFreeConsultation,
  tutorName,
  tutorImageUrl,
}: Props) => {
  const queryClient = useQueryClient();

  // const defaultMessage = `Hi ${
  //   tutorName.split(" ")[0]
  // }, I am looking for some help with [Subject, eg "Maths"]. I am in [Year, eg 6th Year] doing [Level, eg Higher Level]. I am available for grinds online, or in person at [location]. What is your availability like?`;

  const [searchParams] = useSearchParams();
  const { currentUser, accountType, userPrivateData, logout } = useAuth();
  const [modalToDisplay, setModalToDisplay] = useState<modalValues>("");
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  useEffect(() => {
    const tutorInSearchParams = searchParams.get("tu");
    if (
      tutorInSearchParams &&
      tutorInSearchParams === tutorSlug &&
      modalToDisplay === ""
    ) {
      handleModalToDisplay();
    }
  }, [searchParams, tutorSlug]);

  const handleModalToDisplay = (e?: any, modalWanted?: modalValues) => {
    setError("");
    setShowSuccessToast(false);
    if (modalWanted) return setModalToDisplay(modalWanted);
    // if email is not verified, show login modal where there'll be a case to verify email
    if (accountType === "student" && currentUser?.emailVerified) {
      setModalToDisplay("contact");
    } else if (accountType === "tutor") {
      setModalToDisplay("tutor");
    } else {
      setModalToDisplay("login");
    }
  };

  const closeModal = () => setModalToDisplay("");

  const contactTutor = async () => {
    setLoading(true);
    setError("");
    if (messageRef.current?.value === "") {
      setError("Please enter a message to send to the tutor.");
      return setLoading(false);
    }
    // should never really occur, but mainly here to prevent typescript errors
    if (!currentUser) return console.log("no user");
    const contactTutor = httpsCallable(functions, "contactTutor");
    try {
      const currentMessageFromRef = messageRef.current?.value;

      // subject, message and phone number are null for now improving the UI
      const data: ContactTutorData = {
        tutorUid,
        studentUid: currentUser.uid,
        studentName: currentUser.displayName || "",
        tutorName,
        subject: null,
        message: currentMessageFromRef || null,
        //  || currentMessageFromRef === defaultMessage
        //   ? null
        //   : currentMessageFromRef,
        tutorOffersFreeConsultation,
        // if no phone number, get phone number from an input which is only displayed when the student does not have a phone number
        // else don't set a phone number
        phoneNumber:
          userPrivateData?.phoneNumber || phoneNumberRef.current?.value || null,
      };
      const result = await contactTutor(data);
      setShowSuccessToast(true);
      console.log(result);
    } catch (error: any | FirebaseError) {
      console.log(error.message);
      setError(error.message);
    }

    // unvalidate the cache for mycontacts in case it exists
    // could probably just add them to the cache instead of unvalidating
    queryClient.invalidateQueries(["contacts", currentUser.uid]);

    setLoading(false);
  };

  return (
    <>
      <div className="light-font mx-auto">
        <button
          style={{
            border: "none",
            backgroundImage: "linear-gradient(to right,#6cb2f0,#0275d8)",
            minWidth: 150,
          }}
          className="text-light light-font text-decoration-none d-flex justify-content-around mx-2 p-2 rounded shadow fs-6 hover-btn-stdnt"
          onClick={handleModalToDisplay}
        >
          Contact Tutor <BiSolidMessageSquareAdd className="my-auto fs-5" />
        </button>
      </div>
      <CustomModalTemplate
        show={modalToDisplay === "tutor"}
        onHideFunc={closeModal}
        title={`Contact ${tutorName}`}
        body={
          <div className="light-font">
            <Alert
              variant="warning"
              className="border border-warning border-opacity-50 rounded-3 shadow"
            >
              You are a tutor on our website! You cannot contact other tutors.
              If you are looking for a tutor, please log out and create a
              student account.
            </Alert>
          </div>
        }
        footer={
          <div className="paragraph-font">
            <Button
              className="btn btn-primary me-2"
              onClick={async () => {
                logout().then(() => {
                  setModalToDisplay("login");
                });
              }}
            >
              Logout
            </Button>
          </div>
        }
      />
      <LoginOrSignUpToContactTutorModal
        handleModalToDisplay={handleModalToDisplay}
        closeModal={closeModal}
        modalToShow={modalToDisplay}
        tutorUid={tutorUid}
        tutorSlug={tutorSlug}
        tutorName={tutorName}
      />

      {/* ------------------------------------------------------------------ */}
      {/* IF USER CONTACTING IS A STUDENT */}
      <CustomModalTemplate
        show={modalToDisplay === "contact"}
        onHideFunc={closeModal}
        title={`${tutorName}`}
        body={
          <>
            <div className="d-flex flex-column w-100 paragraph-font">
              <Image
                roundedCircle
                className="mx-auto"
                alt={tutorName + "-profile-image"}
                style={{
                  width: 150,
                  height: 150,
                  objectFit: "cover",
                  filter: "drop-shadow(0px 10px 10px #bbbbbb)",
                  zIndex: 1,
                }}
                src={tutorImageUrl}
              />
            </div>
            <div className="my-3 d-flex flex-column text-center paragraph-font">
              <span className="bg-success bg-opacity-25 border border-success border-opacity-10 p-2 rounded-3 shadow">
                You have found a great tutor to help you take your learning to
                the next level!{" "}
              </span>
              <br />
              <span>
                Contact <span className="header-font">{tutorName}</span> now
                using the button below!
              </span>
            </div>

            <Form.Group className="mb-3">
              <Form.Label className="paragraph-font">
                Message <span className="light-font text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                required
                // defaultValue={defaultMessage}
                placeholder="Please enter a message to send to the tutor, outline the subject and level you are interested in etc."
                className="paragraph-font"
                ref={messageRef}
              />
              <span
                className="font-light text-secondary"
                style={{ fontSize: 12 }}
              ></span>
            </Form.Group>
            <Toast
              show={showSuccessToast}
              onClose={() => setShowSuccessToast(false)}
              className="shadow mx-auto mb-3 w-100 paragraph-font"
              autohide
              delay={3000}
            >
              <Toast.Header className="bg-success bg-opacity-25 text-dark header-font d-flex justify-content-between">
                Tutor Contacted Successfully!
              </Toast.Header>
              <Toast.Body className="bg-success bg-opacity-10">
                You have successfully contacted {tutorName}! They will be in
                touch with you shortly.
              </Toast.Body>
            </Toast>

            {error && (
              <Alert
                variant="danger"
                className="border-danger shadow light-font"
              >
                {error}
              </Alert>
            )}
            <div className="d-flex justify-content-center mb-3">
              <button
                disabled={loading}
                style={{
                  border: "none",
                  backgroundImage: "linear-gradient(to right,#6cb2f0,#0275d8)",
                  minWidth: 150,
                }}
                className="text-light light-font text-decoration-none d-flex justify-content-around mx-2 py-2 px-3 rounded shadow hover-btn-stdnt"
                onClick={contactTutor}
              >
                {loading ? (
                  <Spinner size="sm" className="my-1" />
                ) : (
                  <>
                    Contact {tutorName.split(" ")[0]}{" "}
                    <BiSolidMessageSquareAdd className="my-auto ms-2 fs-5" />
                  </>
                )}
              </button>
            </div>
          </>
        }
        footer={
          <div
            style={{ fontSize: 10 }}
            className="text-secondary paragraph-font"
          >
            *By contacting a tutor on our website, you agree to let us share
            your information with the chosen tutor and you agree to our{" "}
            <Link to="/terms-of-service">Terms of Service</Link> and{" "}
            <Link to="/privacy-policy">Privacy Policy</Link>.
          </div>
        }
      />
    </>
  );
};

export default ContactTutorButton;
