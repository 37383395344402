import { useEffect, useRef, useState } from "react";
import {
  Card,
  Container,
  Form,
  Alert,
  Button,
  Toast,
  CloseButton,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  sendEmailVerification,
  signInWithPopup,
  UserCredential,
  GoogleAuthProvider,
  getAdditionalUserInfo,
  AuthError,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { auth, functions } from "../firebase";
import { FcGoogle } from "react-icons/fc";
import { AiOutlineMail } from "react-icons/ai";
import { getLetterImageFromStorage } from "../requests/getStorageProfileImage";
import LoadingSpinnerBig from "../components/reuseables/LoadingSpinnerBig";
import MyNavbar from "../components/reuseables/MyNavBar";
import TabComponent from "../components/signInSignUp/TabComponent";
import SignUpBottomSection from "../components/signInSignUp/SignUpBottomSection";
import SEO from "../components/utils/SEO";
import { browserName } from "react-device-detect";
import ChromeOrSafariAlert from "../components/utils/ChromeOrSafariAlert";
import RefBannerSignUp from "../components/signInSignUp/RefBannerSignUp";

const SignUpPage = () => {
  const { currentUser, accountType } = useAuth();
  const mhiLink = "https://www.mh1-tutors.com/";
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const passwordConfirmRef = useRef<HTMLInputElement>(null);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);

  const accountTypeRef = useRef<HTMLSelectElement>(null);
  const acceptsMarketingRef = useRef<HTMLInputElement>(null);

  const [isBadBrowser, setIsBadBrowser] = useState<boolean>(false);

  const { signup } = useAuth();
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [signUpWithGoogleState, setSignUpWithGoogleState] =
    useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [showSignedInToast, setShowSignedInToast] = useState(false);

  useEffect(() => {
    if (currentUser && currentUser.emailVerified && accountType) {
      switch (accountType) {
        case "student":
          return navigate("/tutors");
        case "tutor":
          return navigate("/dashboard");
        default:
          return navigate("/dashboard");
      }
    }
    // make toast appear if a user is signed in
    currentUser && currentUser.emailVerified
      ? setShowSignedInToast(true)
      : setShowSignedInToast(false);
  }, [currentUser, accountType]);

  useEffect(() => {
    if (error)
      document
        .getElementById("sign-up-error")
        ?.scrollIntoView({ block: "center" });
  }, [error]);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    // in case student or tutor was not selected
    if (
      accountTypeRef.current!.value !== "student" &&
      accountTypeRef.current!.value !== "tutor"
    ) {
      return setError(
        "An error occured. Please refresh the page and try again"
      );
    }
    if (
      !firstNameRef.current?.value.replaceAll(" ", "") ||
      !lastNameRef.current?.value.replaceAll(" ", "")
    ) {
      return setError("Please enter your first and last name");
    }

    // GOOGLE SIGN UP
    if (signUpWithGoogleState) {
      const provider = new GoogleAuthProvider();
      setError("");
      setLoading(true);
      const googleResult = await signInWithPopup(auth, provider).catch(
        (err) => {
          return setError(
            "An error occured setting up your account - " +
              err.message.replace("Firebase: ", "")
          );
        }
      );

      if (!googleResult) {
        return setLoading(false);
        // return setError("An error occured setting up your account.");
      }

      if (getAdditionalUserInfo(googleResult)?.isNewUser) {
        // set up everything here
        let firstName: string;
        let lastName: string;
        const user = googleResult.user;
        const accountType = accountTypeRef.current!.value;
        const imageUrl = user.photoURL;

        // get user display name
        if (user.displayName) {
          firstName = user.displayName.split(" ")[0];
          lastName = user.displayName.split(" ")[1];
        } else {
          firstName = "firstName";
          lastName = "lastName";
        }

        // Call firebase function to set user document
        const createUserDocs = httpsCallable(functions, "createUserDocs");
        try {
          await createUserDocs({
            firstName: firstName.charAt(0).toUpperCase() + firstName.slice(1),
            lastName: lastName.charAt(0).toUpperCase() + lastName.slice(1),
            accountType: accountType,
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            imageUrl: imageUrl,
            ref: searchParams.get("ref"),
            acceptsMarketing: acceptsMarketingRef.current?.checked,
          });
        } catch (err: any) {
          setLoading(false);
          await user.delete();
          return setError(
            err.message.replace("Firebase:", "") +
              " - Something went wrong. Please try again, and if an error persists, please contact us."
          );
        }
        // console.log("db data set");
        setLoading(false);
        setError("");
        return navigate("/dashboard");
        // returned from function -> firestore docs should be created
      } else {
        // tell the user account already exists if not new user
        setLoading(false);
        alert("Account already exists. Re-directing to Dashboard page.");
        return navigate("/dashboard");
      }

      //// END OF GOOGLE SIGN UP
      //////////////////////////////////////////////////////////////////////////////
    } else {
      ///////////////////////////////////////////////////////////////////////////////
      // SIGN UP WITH EMAIL & PASSWORD
      setError("");
      setLoading(true);
      if (passwordRef.current!.value !== passwordConfirmRef.current!.value) {
        setLoading(false);
        return setError("Passwords don't match");
      }

      let userCredential: UserCredential;
      try {
        userCredential = await signup(
          emailRef.current!.value,
          passwordRef.current!.value
        );

        // ... continue your logic here
      } catch (error: any) {
        setLoading(false);

        if ((error as AuthError).code === "auth/email-already-in-use") {
          return setError(
            "The email address is already in use by another account."
          );
        } else {
          return setError("Error creating your account. Please try again.");
        }
      }
      ///////////////////////////////////////////////////////////////////
      // setting up database documents here
      try {
        const firstName: string = firstNameRef.current!.value;
        const lastName: string = lastNameRef.current!.value;
        const user = userCredential.user;
        const accountType = accountTypeRef.current!.value;

        // get image from storage to set as profile image
        const imageUrl = await getLetterImageFromStorage(firstName);

        // Call firebase function to set user document
        const createUserDocs = httpsCallable(functions, "createUserDocs");
        await createUserDocs({
          firstName: firstName.charAt(0).toUpperCase() + firstName.slice(1),
          lastName: lastName.charAt(0).toUpperCase() + lastName.slice(1),
          accountType: accountType,
          uid: user.uid,
          email: user.email,
          displayName:
            firstName.charAt(0).toUpperCase() +
            firstName.slice(1) +
            " " +
            lastName.charAt(0).toUpperCase() +
            lastName.slice(1),
          imageUrl: imageUrl,
          ref: searchParams.get("ref"),
          acceptsMarketing: acceptsMarketingRef.current?.checked,
        });
        // returned from server -> firestore docs should be created
        // console.log("db data set");
      } catch (err: any) {
        // an error occurred somehwere - deleting user for now
        await userCredential.user.delete();
        setLoading(false);
        return setError(
          err.message.replace("Firebase:", "") +
            " - an error occured setting up your account. Please contact us if this error persists"
        );
      }

      ///////////////////////////////////////////////////////////////////
      // user has been signed up, and docs have been created successfully
      // send them an email to verify their email address
      try {
        await sendEmailVerification(userCredential.user, {
          url: process.env.REACT_APP_FIREBASE_EMAIL_FORWARD_URL as string,
        });
        alert(
          "You have been sent a verification email - please confirm your email address to complete sign-up."
        );
        // After succesful sign-up
        setLoading(false);
        setError("");
      } catch (err) {
        alert(
          "Your account has been created, but an error occured sending you a verification email. Please use the 'Resend Verification Email' button from the login page."
        );
      }
      ///////////////////////////////////////////////////////////////////

      navigate("/dashboard");
    }
    const target = e.target as HTMLFormElement;
    target.reset();
  };

  return (
    <>
      <SEO
        title="Find Junior and Leaving Cert Grinds in Ireland - MH1 Tutors"
        description="Sign up to our platform to connect with grinds tutors completely free, or sign up as a tutor and advertise your service on our platform for free."
        keywords="Grinds Ireland,Maths Grinds,Find Grinds Tutors,Become a Grinds Tutor,Leaving Cert, Junior Cert, College Grinds, Primary School Grinds"
        robots=""
        ogTitle="Find Junior and Leaving Cert Grinds in Ireland - MH1 Tutors"
        ogUrl="https://www.mh1-tutors.com/sign-up"
        ogSiteName="MH1 Tutors"
        ogImage="https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2FLogo.png?alt=media&token=6e6b79d8-87db-45b1-840d-919623cbc159"
        ogImageType="image/png"
      />
      {loading && <LoadingSpinnerBig />}
      <MyNavbar />

      <RefBannerSignUp bannerRef={searchParams.get("ref") || null} />

      <div className="row w-100 mx-auto">
        <div className="col-lg-6 col-12 pt-lg-3">
          <Container className="login-page d-flex justify-content-center my-2">
            <div className="w-100" style={{ maxWidth: "500px" }}>
              <Card className="shadow-lg border" bg="light">
                <Card.Body>
                  <h2 className="text-center mb-4 header-font">Sign Up</h2>

                  {error && (
                    <Alert
                      className="text-center"
                      id="sign-up-error"
                      variant="danger"
                    >
                      {error}
                    </Alert>
                  )}
                  <div className="text-center">
                    {!signUpWithGoogleState ? (
                      <Button
                        disabled={loading}
                        variant="outline-dark"
                        onClick={() =>
                          browserName === "Instagram" ||
                          browserName === "Facebook" ||
                          browserName === "TikTok"
                            ? setIsBadBrowser(true)
                            : setSignUpWithGoogleState(true)
                        }
                      >
                        <FcGoogle className="me-2 fs-4" />
                        Sign up with Google Instead
                      </Button>
                    ) : (
                      <Button
                        disabled={loading}
                        variant="outline-dark"
                        onClick={() => setSignUpWithGoogleState(false)}
                      >
                        <AiOutlineMail className="me-2 fs-5" />
                        Sign up with Email Instead
                      </Button>
                    )}
                  </div>

                  <Form id="signup-form" onSubmit={handleSubmit}>
                    {signUpWithGoogleState ? null : (
                      <>
                        <div className="d-flex flex-column flex-lg-row">
                          <Form.Group
                            id="first-name"
                            className="mt-3 me-lg-2 w-100-lg"
                          >
                            <Form.Label>
                              First Name{" "}
                              <span className="text-danger fw-bold">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              ref={firstNameRef}
                              name="first-name"
                              required
                            />
                          </Form.Group>
                          <Form.Group
                            id="second-name"
                            className="mt-3 ms-lg-2 w-100-lg"
                          >
                            <Form.Label>
                              Last Name{" "}
                              <span className="text-danger fw-bold">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              ref={lastNameRef}
                              name="last-name"
                              required
                            />
                          </Form.Group>
                        </div>
                        <Form.Group id="signup-email" className="mt-3">
                          <Form.Label>
                            Email <span className="text-danger fw-bold">*</span>
                          </Form.Label>
                          <Form.Control
                            type="email"
                            ref={emailRef}
                            name="email"
                            required
                          />
                        </Form.Group>
                        <Form.Group id="signup-password" className="mt-3">
                          <Form.Label>
                            Password{" "}
                            <span className="text-danger fw-bold">*</span>
                          </Form.Label>
                          <Form.Control
                            type="password"
                            ref={passwordRef}
                            name="password"
                            required
                          />
                        </Form.Group>
                        <Form.Group
                          id="signup-password-confirm"
                          className="mt-3"
                        >
                          <Form.Label>
                            Confirm Password{" "}
                            <span className="text-danger fw-bold">*</span>
                          </Form.Label>
                          <Form.Control
                            type="password"
                            ref={passwordConfirmRef}
                            name="password-confirm"
                            required
                          />
                        </Form.Group>
                      </>
                    )}
                    <Form.Group className="mt-3" id="account-type-select">
                      <Form.Label>
                        Account Type{" "}
                        <span className="text-danger fw-bold">*</span>
                      </Form.Label>
                      <Form.Control
                        as={Form.Select}
                        required
                        value={searchParams.get("account") || ""}
                        onChange={(e) => {
                          // append account type to url
                          const newParams = new URLSearchParams(searchParams);
                          newParams.set("account", e.target.value);
                          setSearchParams(newParams);
                        }}
                        placeholder="Select Account Type"
                        ref={accountTypeRef}
                      >
                        <option value="" className="text-secondary">
                          Select Account Type
                        </option>
                        <option value="tutor">Tutor</option>
                        <option value="student">Student/Parent</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Check
                      required
                      className="mt-3"
                      type="checkbox"
                      defaultChecked={false}
                      label={
                        <p>
                          <span className="text-danger fw-bold">*</span> By
                          Signing up, you agree to our{" "}
                          <a href={mhiLink + "terms-of-service"}>
                            Terms of Service
                          </a>
                          , and our{" "}
                          <a href={mhiLink + "privacy-policy"}>
                            Privacy Policy
                          </a>
                        </p>
                      }
                    />
                    <Form.Check
                      className="mt-1"
                      type="checkbox"
                      defaultChecked={false}
                      ref={acceptsMarketingRef}
                      label={
                        <p>
                          Check this box to receive updates about our latest
                          features, updates, and promotions.
                        </p>
                      }
                    />
                    <Button
                      disabled={loading || (currentUser ? true : false)}
                      type="submit"
                      variant="primary"
                      className="w-100 mt-4"
                    >
                      {signUpWithGoogleState ? (
                        <>
                          <FcGoogle className="me-2 fs-4" />
                          Sign up with Google
                        </>
                      ) : (
                        "Sign Up"
                      )}
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
              <div className="w-100 text-center mt-2">
                Already have an account?{" "}
                <Link to="/login" style={{ color: "gray" }}>
                  Log In
                </Link>{" "}
                here!
              </div>
            </div>
          </Container>
        </div>
        <div className="col-lg-6 col-12">
          <div className="mt-3 py-2">
            <TabComponent firstNameRef={firstNameRef} />
          </div>
        </div>
        <div>
          <SignUpBottomSection
            firstNameRef={firstNameRef}
            accountTypeSelected={searchParams.get("account")}
          />
        </div>
      </div>
      <ChromeOrSafariAlert
        showProp={isBadBrowser}
        setShowProp={setIsBadBrowser}
        chromeUrl={`googlechrome://${
          window.location.hostname
        }/sign-up?account=${searchParams.get("account")}`}
      />
      <Toast
        show={showSignedInToast}
        className="mx-auto"
        style={{ position: "fixed", bottom: 75, left: 0, right: 0 }}
      >
        <Toast.Header
          closeButton={false}
          className="d-flex justify-content-between bg-danger text-light"
        >
          Already Signed In{" "}
          <CloseButton
            onClick={() => setShowSignedInToast(false)}
            variant="white"
          />
        </Toast.Header>
        <Toast.Body className="bg-white rounded">
          Click here to go to your <Link to="/dashboard">dashboard</Link>
        </Toast.Body>
      </Toast>
    </>
  );
};

export default SignUpPage;
