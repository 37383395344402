import DisabledPricingCard from "./DisabledPricingCard";
import PricingCard from "./PricingCard";

type Props = {
  accountType: string;
};

const PricingCards = ({ accountType }: Props) => {
  const STUDENT_FEATURES = [
    "3 Free contats per month",
    "Unlimited Access to all Tutor Profiles",
    "Access to Digital Learning Resources",
    "Place an Ad to find Tutors (coming soon!)",
    "Instant Email Notifications",
    "Personalised Tutor Matching",
  ];

  const STUDENT_FEATURES_DESCS = [
    "Contact up to three tutors for free each month. If you need more contacts, these can be purchased separately.",
    "Get unlimited access to all the tutor profiles on our platform, allowing you to find the perfect match for your learning needs.",
    "Access and download all our Digital Learning Resources for free.",
    "Let all tutors know that you are looking for grinds in a specific subject. You can have one active ad for free. Any extra ads will be charged accordingly.",
    "Receive instant email notifications when tutors reply to your contacts, or apply to your student ad.",
    "Can't decide on a tutor? We can help you find the right tutor for your needs!",
  ];

  const TUTOR_FREE_FEATURES = [
    "Free Advertising",
    "2 Free Contacts per Month",
    "No Commission Fees",
    "Instant Email Notifications",
    "Flexible Scheduling",
    "Teach Any Subject",
    // "Personalised Tutor Profile",
  ];

  const TUTOR_FREE_FEATURES_DESCS = [
    "Advertise your tutoring service completely FREE on our platform. Get seen by thousands of students who visit our website!",
    "You can unlock the details of 2 contacts per month for free.",
    "Keep 100% of each class you teach. Once you accept a contact from a student, you are free to organise classes and payments however you please.",
    "Receive instant emails whenever a student contacts you.",
    "Schedule your classes whenever you please. Being a tutor gives you the freedom to book your schedule however you please!",
    "Teach any subjects you like. If there is a subject you can't find that you would like to teach, contact us and we will add it to our list.",
    // "Customise your profile however you like!",
  ];

  return accountType === "tutor" ? (
    <div className="my-4 row">
      <div className="col-12 col-md-6 d-flex">
        <PricingCard
          features={TUTOR_FREE_FEATURES}
          featuresDesc={TUTOR_FREE_FEATURES_DESCS}
          planName="Tutor Free"
          planPrice=""
          accountType="tutor"
          buttonText="Join for Free"
        />
      </div>
      {/* Disabled Cards for plans coming soon */}
      {/* <div className="col-12 col-md-6 col-lg-4 d-flex">
        <DisabledPricingCard planName="Tutor Basic" />
      </div> */}
      <div className="col-12 col-md-6 d-flex">
        <DisabledPricingCard planName="Tutor Pro" />
      </div>
    </div>
  ) : (
    // Student Pricing Cards
    <div className="my-4 d-flex">
      <PricingCard
        features={STUDENT_FEATURES}
        featuresDesc={STUDENT_FEATURES_DESCS}
        planName="Student or Parent"
        planPrice=""
        accountType="student"
        buttonText="Join for Free"
      />
    </div>
  );
};

export default PricingCards;
