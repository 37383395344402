import { dbSubjectStrings, subjectLevels } from "../../../constants/strings";
import React from "react";
import tutorUserData from "../../../interfaces/tutorUserData";

type Props = {
  tutorData: tutorUserData;
};

const checkForAnyMoreSubjectsOrLevelsLeftToDisplay = (
  tutorData: tutorUserData,
  idx: number
) => {
  let startIdx = idx + 1;
  let endIdx = dbSubjectStrings.length;

  for (let i = startIdx; i < endIdx; i++) {
    if (
      tutorData[`${dbSubjectStrings[i]}Price`] ||
      (tutorData[`${dbSubjectStrings[i]}Subjects`] &&
        tutorData[`${dbSubjectStrings[i]}Subjects`].length > 0)
    ) {
      return true;
    }
  }
  return false;
};

const SubjectsSection = ({ tutorData }: Props) => {
  return (
    <div>
      {dbSubjectStrings.map((level, idx) => {
        if (
          (!tutorData[`${level}Subjects`] && !tutorData[`${level}Price`]) ||
          (tutorData[`${level}Subjects`] &&
            tutorData[`${level}Subjects`].length <= 0)
        )
          return null;
        return (
          <React.Fragment key={level}>
            <div
              // key={level}
              className="d-flex justify-content-between"
            >
              <div>
                <h5 className="header-font">{subjectLevels[idx]}</h5>
                <div className="d-flex" style={{ flexFlow: "row wrap" }}>
                  {tutorData[`${level}Subjects`] &&
                  tutorData[`${level}Subjects`].length > 0
                    ? tutorData[`${level}Subjects`].map((subject: string) => {
                        const formattedSubject = subject.replaceAll("-", " ");
                        return (
                          <div
                            className="subject-bubble d-flex me-3 px-2 rounded my-2"
                            key={level + "-" + subject}
                            style={{
                              backgroundColor: "#f1f1f1",
                              fontSize: 16,
                            }}
                          >
                            {formattedSubject}
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
              <div className="my-auto">
                {tutorData[`${level}Price`] ? (
                  <>
                    <div className="my-auto text-center">
                      <span className="light-font">Price: </span>
                      <span className="header-font">
                        €{tutorData[`${level}Price`]}
                      </span>
                    </div>
                    <div className="light-font" style={{ fontSize: 14 }}>
                      per class
                    </div>
                  </>
                ) : (
                  <span className="light-font">Price not set</span>
                )}
              </div>
            </div>
            {/* Don't show dashed line for last level */}
            {checkForAnyMoreSubjectsOrLevelsLeftToDisplay(tutorData, idx) && (
              <hr className="dashed" />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default SubjectsSection;
