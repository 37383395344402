import { useState } from "react";
import {
  Alert,
  Button,
  CloseButton,
  Form,
  InputGroup,
  Modal,
  Spinner,
  Toast,
} from "react-bootstrap";
import GradientButton from "../../reuseables/GradientButton";
import { useQuery } from "@tanstack/react-query";
import getUserEmailAndPhone from "../../../requests/getUserEmailAndPhone";
import { BiPaperPlane, BiSolidUserDetail } from "react-icons/bi";
import RoundedImageFitted from "../../reuseables/RoundedImageFitted";
import LoadingDots from "../../reuseables/LoadingDots";
import firebaseError from "../../../interfaces/firebaseError";
import { FiCopy, FiMail, FiPhone } from "react-icons/fi";

type Props = {
  studentUid: string;
  studentImageUrl: string;
  studentName: string;
  contactUid: string;
};
const StudentDetailsButton = ({
  studentUid,
  studentImageUrl,
  studentName,
  contactUid,
}: Props) => {
  const [showStudentDetailsModal, setShowStudentDetailsModal] = useState(false);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [showComingSoonToast, setShowComingSoonToast] = useState(false);

  const openStudentDetailsModal = () => setShowStudentDetailsModal(true);
  const closeStudentDetailsModal = () => {
    setShowStudentDetailsModal(false);
    setCopiedToClipboard(false);
    setShowComingSoonToast(false);
  };

  const {
    data: studentData,
    isLoading: studentDataLoading,
    isError: isStudentDataError,
    error: studentDataError,
  } = useQuery(
    ["studentData", studentUid],
    // get the student data
    () => getUserEmailAndPhone(studentUid, contactUid, "tutor"),
    {
      enabled: showStudentDetailsModal,
      retry: 2,
      staleTime: 1000 * 60 * 60 * 2, // 2 hours
    }
  );
  return (
    <>
      <GradientButton
        className="py-2 px-3"
        loading={false}
        onClickFunc={openStudentDetailsModal}
      >
        View Student Details <BiSolidUserDetail className="my-auto ms-2 fs-5" />
      </GradientButton>
      <Modal
        centered
        show={showStudentDetailsModal}
        onHide={closeStudentDetailsModal}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="header-font">
            {studentName} Contact Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center">
            <RoundedImageFitted
              src={studentImageUrl}
              alt={studentName}
              width={100}
              height={100}
            />
            <h4 className="header-font my-auto">{studentName}</h4>
          </div>
          <hr />
          <div>
            {studentDataLoading && (
              <div className="d-flex flex-column align-items-center">
                <Spinner />
                <span>
                  Loading Student email
                  <LoadingDots />
                </span>
              </div>
            )}
            {isStudentDataError && (
              <Alert variant="danger" className="border-danger shadow mt-3">
                {(studentDataError as firebaseError).message ||
                  "An error occured while getting student data. Please try again later."}
              </Alert>
            )}
            {studentData && (
              <div className="d-flex flex-column align-items-center">
                <Toast
                  show={copiedToClipboard}
                  bg="success"
                  autohide
                  delay={3000}
                  onClose={() => setCopiedToClipboard(false)}
                  className="my-2 p-2 text-light light-font d-flex justify-content-between w-100"
                >
                  Copied to clipboard
                  <CloseButton
                    variant="white"
                    onClick={() => setCopiedToClipboard(false)}
                  />
                </Toast>

                <div className="d-flex flex-row w-100">
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1">
                      <FiMail className="mx-2 fs-5 my-auto" />
                    </InputGroup.Text>
                    <Form.Control
                      className="text-center"
                      disabled
                      value={studentData.email}
                      aria-label="Email"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                  <FiCopy
                    className="ms-3 me-2 fs-4 my-auto cursor-pointer"
                    onClick={() => {
                      try {
                        navigator.clipboard.writeText(studentData.email);
                        setCopiedToClipboard(true);
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                  />
                </div>

                {studentData.phoneNumber && (
                  <>
                    <div className="d-flex flex-row w-100 mt-2">
                      <InputGroup>
                        <InputGroup.Text id="basic-addon1">
                          <FiPhone className="mx-2 fs-5 my-auto" />
                        </InputGroup.Text>
                        <Form.Control
                          className="text-center"
                          disabled
                          value={studentData.phoneNumber}
                          aria-label="Phone"
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                      <FiCopy
                        className="ms-3 me-2 fs-4 my-auto cursor-pointer"
                        onClick={() => {
                          try {
                            navigator.clipboard.writeText(
                              studentData.phoneNumber!
                            );
                            setCopiedToClipboard(true);
                          } catch (err) {
                            console.log(err);
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
            <Toast
              show={showComingSoonToast}
              bg="warning"
              autohide
              delay={10000}
              onClose={() => setShowComingSoonToast(false)}
              className="my-2 p-2 text-dark bg-opacity-75 light-font d-flex justify-content-between w-100"
            >
              Messaging the student through our platform is coming soon. For
              now, you can email them directly by copying their email address.
              <CloseButton onClick={() => setShowComingSoonToast(false)} />
            </Toast>
          </div>
        </Modal.Body>
        <Modal.Footer className="light-font">
          <Button
            disabled={studentDataLoading}
            className="d-flex"
            onClick={() => setShowComingSoonToast(true)}
          >
            Email Student <BiPaperPlane className="my-auto ms-2" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StudentDetailsButton;
