import {  collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import allContactData from '../interfaces/allContactData';

// This is front end code, so we can access current user
const getMyContacts = async (userUid: string, accountType :string | null, contactUidInSearchParams: string | null) => {
  if (!accountType || (accountType !== 'tutor' && accountType !== 'student')) {
    throw new Error('There is an error with your account. Please contact us to rectify it.');
  }

  let queryString = '';
  if (accountType === 'tutor') {
    queryString = 'tutorUid';
  } else if (accountType === 'student') {
    queryString = 'studentUid';
  } else {
    throw new Error('There is an error with your account. Please contact us to rectify it.');
  }

  console.log('getMyContacts() called');
  const myContacts: allContactData[] = [];
  const myContactsQueryRef = query(collection(db, 'contacts'), where(queryString, '==', userUid), orderBy('timestamp', 'desc'));
  try{
    const myContactsSnapshot = await getDocs(myContactsQueryRef);
    myContactsSnapshot.forEach((doc) => {
      const contact = doc.data() as allContactData;
      myContacts.push(contact);
    });
  }catch (err){
    console.log('Error getting my contacts: ', err);
    throw new Error('An error occured while getting your contacts. Please try again later.');
  }

  //if search param is in url, display that first
  if (contactUidInSearchParams) {
    const contactIndex = myContacts.findIndex((contact) => contact.contactUid === contactUidInSearchParams);
    if (contactIndex !== -1) {
      const contact = myContacts.splice(contactIndex, 1)[0];
      myContacts.unshift(contact);
    }
  }

  return myContacts

}

export default getMyContacts;