import { useRef, useState, useEffect } from "react";
import { AiFillStar } from "react-icons/ai";

type Props = {
  reviewer: string;
  reviewee: string;
  imageUrl: string;
  text: string;
  revieweeType: string;
};

const Review = ({
  reviewer,
  reviewee,
  imageUrl,
  text,
  revieweeType,
}: Props) => {
  const domRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    let currentVal: any = null;
    if (!domRef.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    currentVal = domRef.current;
    return () => {
      if (currentVal) return observer.unobserve(currentVal);
    };
  }, []);
  return (
    <div
      className={`my-3 col-12 col-md-4 text-center fade-in-section ${
        isVisible ? "is-visible" : ""
      }`}
      ref={domRef}
      style={{ minWidth: 240 }}>
      <div
        className="bg-white shadow rounded p-3 mx-auto d-flex flex-column justify-content-between"
        style={{ maxWidth: 350, height: "100%" }}>
        {reviewee === "MH1" ? (
          <>
            <div className="d-flex flex-row justify-content-between">
              <div className="text-start">
                <div className="header-font">{reviewer}</div>
                <div>{revieweeType}</div>
                <div>
                  {[0, 1, 2, 3, 4].map((idx) => (
                    <AiFillStar key={idx} className="text-warning" />
                  ))}
                </div>
              </div>
              <div>
                <img
                  className="rounded-circle ms-1"
                  style={{ width: 80, height: 80, objectFit: "cover" }}
                  src={imageUrl}
                  alt="MH1 Tutors - Reviewer"
                />
              </div>
            </div>

            <div className="text-start fs-6 mt-2 mb-auto light-font">
              {text}
            </div>
          </>
        ) : (
          <>
            <div className="d-flex flex-row justify-content-between">
              <div className="text-start">
                <div className="header-font">{reviewee}</div>
                <div>{revieweeType}</div>
                <div>
                  {[0, 1, 2, 3, 4].map((idx) => (
                    <AiFillStar key={idx} className="text-warning" />
                  ))}
                </div>
              </div>
              <div>
                <img
                  className="rounded-circle ms-1"
                  style={{ width: 80, height: 80, objectFit: "cover" }}
                  src={imageUrl}
                  alt="MH1 Tutors"
                />
              </div>
            </div>

            <div className="text-start fs-6 mt-2 light-font mb-auto">
              {text}
            </div>
            <div className="text-end ">{reviewer}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default Review;
