import { RefObject } from "react";
import SignUpButton from "./SignUpButton";
import SignUpNumbers from "./SignUpNumbers";
import MultipleReviews from "../reuseables/MultipleReviews";

type Props = {
  firstNameRef: RefObject<HTMLInputElement>;
  accountTypeSelected: string | null;
};

const SignUpBottomSection = ({ firstNameRef, accountTypeSelected }: Props) => {
  return (
    <div>
      {accountTypeSelected === "tutor" ? (
        <div>
          {/* 
      Shown when tutor card selected
      -> the tutors give reviews about their experience using MH1
      -> reviewee is MH1
    */}
          <SignUpButton text="Become a Tutor" firstNameRef={firstNameRef} />

          <MultipleReviews
            accountTypeSelected={accountTypeSelected}
            texts={[
              "I can't thank MH1 Tutors enough for getting me all these grinds, can't believe how quickly you got to work for me too. Hugely appreciated!",
              "MH1 Tutors has allowed me to reach a wider audience after I decided to start offering grinds. Becoming a tutor has been an incredibly rewarding experience.",
              "The level of services offered from MH1 Tutors are in the highest echelon of professionalism.",
            ]}
            reviewers={["Michael K.", "Patricia Iusan", "Sina Tavakoli"]}
            imageUrls={[
              "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fmk_img.jpeg?alt=media&token=243ccd1a-1a70-4a28-bd7c-a411bbb969ae",
              "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fpi_img.jpg?alt=media&token=37161724-deff-47cb-b32f-6d5b5f47c897",
              "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fst_img.jpg?alt=media&token=a25893a3-64d0-49a3-aabb-9dcfa59f85a6",
            ]}
            // must be MH1
            reviewees={["MH1", "MH1", "MH1"]}
            // In this case, reviewee type is actually the reviewer type, just reducing number of props
            revieweeTypes={[
              "Maths Tutor",
              "Science and Maths Tutor",
              "Maths, Physics & Programming Tutor",
            ]}
          />
          <SignUpButton text="Become a Tutor" firstNameRef={firstNameRef} />
          <SignUpNumbers accountType={accountTypeSelected} />
          {/* <SignUpButton text="Become a Tutor" firstNameRef={firstNameRef} /> */}
        </div>
      ) : (
        <div>
          <SignUpButton
            text="Sign Up & Find a Tutor"
            firstNameRef={firstNameRef}
          />
          {/* 
      This is shown when "Student/Parent is selected"
      -> reviewer is student
      -> reviewee is tutor
        */}
          <MultipleReviews
            accountTypeSelected={accountTypeSelected}
            texts={[
              "Really helpful notes that are available to you after the class. The ability to choose what topics to focus on was great and gave me much more confidence when it came to the LC papers in June.",
              "I find Conor's lessons really clear and easy to follow. Maths is making much more sense to me now. I get the chance to ask questions if I'm confused, and Conor explains it more clearly than my teachers.",
              "Each grind session was genuinely fun and encouraging. I came out of it with confidence and a new understanding of the different concepts found in maths.",
            ]}
            reviewers={[
              "Molly H, LC Student",
              "Aaron, LC Student",
              "Kai, LC Student",
            ]}
            imageUrls={[
              "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fad_img.png?alt=media&token=32001b3f-e27b-4557-a4fd-88115ed7a3c4",
              "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fcp_img.jpg?alt=media&token=9037a8b5-1f18-4d93-b5ca-e25e07ce74bb",
              "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fdmc_img.jpeg?alt=media&token=3aa8fc9f-496e-43e5-af95-c7f7ebbebf6c",
            ]}
            reviewees={["Adelin Diac", "Conor Phelan", "Dale McLoughlin"]}
            revieweeTypes={[
              "Maths & Programming Tutor",
              "Maths Tutor",
              "Maths Tutor",
            ]}
          />
          <SignUpButton
            text="Sign Up & Find a Tutor"
            firstNameRef={firstNameRef}
          />
          <SignUpNumbers accountType={accountTypeSelected} />
          {/* <SignUpButton
            text="Sign Up & Find a Tutor"
            firstNameRef={firstNameRef}
          /> */}
        </div>
      )}
    </div>
  );
};

export default SignUpBottomSection;
