import { useState, useRef, useEffect } from "react";
import { Form, Modal, Spinner, Button, Alert } from "react-bootstrap";
import { AiOutlineDoubleRight } from "react-icons/ai";
import GoogleSignInDivider from "../signInSignUp/GoogleSignInDivider";
import { FcGoogle } from "react-icons/fc";
import { useAuth } from "../../contexts/AuthContext";
import { browserName } from "react-device-detect";
import ChromeOrSafariAlert from "../utils/ChromeOrSafariAlert";
import {
  GoogleAuthProvider,
  getAdditionalUserInfo,
  signInWithPopup,
} from "firebase/auth";
import { auth, functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";

type Props = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  tutorSlug: string;
  handleModalToDisplay: () => void;
};

const SignInForm = ({
  setLoading,
  loading,
  tutorSlug,
  handleModalToDisplay,
}: Props) => {
  const { currentUser, accountType, login } = useAuth();

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [isBadBrowser, setIsBadBrowser] = useState(false);
  const [error, setError] = useState("");

  // when loggin in, but also want the account type
  useEffect(() => {
    if (currentUser && accountType) {
      handleModalToDisplay();
    }
  }, [currentUser, accountType, handleModalToDisplay]);

  useEffect(() => {
    if (currentUser && !currentUser.emailVerified) {
      // listen for cahnges in emailVerified
      const interval = setInterval(() => {
        console.log("listen for changes in emailVerified");
        if (currentUser.emailVerified) {
          clearInterval(interval);
          return handleModalToDisplay();
        }
        return currentUser.reload();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [currentUser, accountType, handleModalToDisplay]);

  const handleLogin = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      await login(emailRef.current!.value, passwordRef.current!.value);
    } catch (e: any) {
      setError(e.message.replace("Firebase: Error", ""));
      return setLoading(false);
    }

    setLoading(false);
  };

  const googleSignIn = async () => {
    if (
      browserName === "Instagram" ||
      browserName === "Facebook" ||
      browserName === "TikTok"
    ) {
      return setIsBadBrowser(true);
    }
    // GOOGLE SIGN
    const provider = new GoogleAuthProvider();
    setLoading(true);

    let googleResult = null;

    // try to sign in
    try {
      googleResult = await signInWithPopup(auth, provider);
    } catch (e: any) {
      setError(
        "An error occured setting up your account: " +
          e.message.replace("Firebase: Error", "")
      );
      return setLoading(false);
    }

    // if new user, set up account
    // if existing user, the log in is complete

    const additionalUserInfo = getAdditionalUserInfo(googleResult);
    if (additionalUserInfo?.isNewUser) {
      // set up account
      console.log("set up account");
      // set up everything here
      let firstName: string;
      let lastName: string;
      const user = googleResult.user;

      // get user display name
      if (user.displayName) {
        firstName = user.displayName.split(" ")[0];
        lastName = user.displayName.split(" ")[1];
      } else {
        firstName = "firstName";
        lastName = "lastName";
      }

      // Call firebase function to set user document
      const createUserDocs = httpsCallable(functions, "createUserDocs");
      try {
        await createUserDocs({
          firstName: firstName.charAt(0).toUpperCase() + firstName.slice(1),
          lastName: lastName.charAt(0).toUpperCase() + lastName.slice(1),
          accountType: "student",
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          imageUrl: user.photoURL,
        });
      } catch (err: any) {
        await user.delete();
        setLoading(false);
        return setError(
          err.message.replace("Firebase:", "") +
            " - Something went wrong. Please try again, and if an error persists, please contact us."
        );
      }
    }
    setLoading(false);
  };

  const handleShowResetPasswordModal = () => {
    console.log("show reset password modal");
  };

  if (currentUser && loading)
    return (
      <div className="d-flex flex-column w-100">
        <Alert className="shadow border-primary border-opacity-50 m-2">
          Setting up your account, please wait...
        </Alert>
        <Spinner className="mx-auto my-4" />
      </div>
    );
  else if (currentUser) return null;

  return (
    <>
      {error && (
        <Alert
          variant="danger"
          className="mt-2 mx-2 mb-0 light-font border-danger border-opacity-50 shadow"
        >
          {error}
        </Alert>
      )}
      <Form onSubmit={handleLogin}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label className="paragraph-font mb-0">
              Email <span className="text-danger fw-bold">*</span>
            </Form.Label>
            <Form.Control required type="email" ref={emailRef} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="paragraph-font mb-0">
              Password <span className="text-danger fw-bold">*</span>
            </Form.Label>
            <Form.Control required type="password" ref={passwordRef} />
          </Form.Group>
          <div className="mt-2">
            Forgot your password?{" "}
            <span
              className="cursor-pointer"
              onClick={handleShowResetPasswordModal}
              style={{
                color: "gray",
                textDecoration: "underline",
              }}
            >
              Click here
            </span>{" "}
            to reset it!
          </div>
        </Modal.Body>
        <Modal.Footer className="light-font">
          <Button
            variant="primary"
            type="submit"
            className="w-100"
            disabled={loading}
          >
            {loading ? (
              <Spinner size="sm" />
            ) : (
              <>
                Login <AiOutlineDoubleRight className="my-auto ms-2" />
              </>
            )}
          </Button>
          <GoogleSignInDivider />
          {isBadBrowser && (
            <ChromeOrSafariAlert
              showProp={isBadBrowser}
              setShowProp={setIsBadBrowser}
              chromeUrl={`googlechrome://${window.location.hostname}/tutors?tu=${tutorSlug}`}
            />
          )}
          <Button
            disabled={loading || (currentUser ? true : false)}
            variant="dark"
            className="w-100"
            onClick={googleSignIn}
          >
            {loading ? (
              <Spinner size="sm" className="my-auto" />
            ) : (
              <>
                <FcGoogle className="me-2 fs-5" />
                Sign in with Google
              </>
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </>
  );
};

export default SignInForm;
