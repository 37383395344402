import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Form,
  // Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import validator from "validator";
import { useAuth } from "../../contexts/AuthContext";
import LoadingSpinner from "../reuseables/LoadingSpinner";

import { FcGoogle } from "react-icons/fc";
// import { httpsCallable } from "firebase/functions";
// import { functions } from "../firebase";
// import { useNavigate } from "react-router-dom";
import DangerZoneDivier from "./DangerZoneDivider";
import privateUserDataInterface from "../../interfaces/privateUserDataInterface";

const AccountDetailsForm = ({
  privateData,
}: {
  privateData: privateUserDataInterface;
}) => {
  // const { logout } = useAuth();
  const { currentUser, login, accountType } = useAuth();
  const [loading, setLoading] = useState(true);
  // const [emailLoading, setEmailLoading] = useState(false);

  const [isGoogleUser, setIsGoogleUser] = useState(false);

  const [passError, setPassError] = useState("");
  // const [emailError, setEmailError] = useState("");
  const [passMessage, setPassMessage] = useState("");
  // const [emailMessage, setEmailMessage] = useState("");
  // const [showEmailChangeModal, setShowEmailChangeModal] = useState(false);

  // const newEmailRef = useRef<HTMLInputElement>(null);
  // const currentPassEmailRef = useRef<HTMLInputElement>(null);

  const currentPassRef = useRef<HTMLInputElement>(null);
  const newPassRef = useRef<HTMLInputElement>(null);
  const newPassConfirmRef = useRef<HTMLInputElement>(null);

  // const navigate = useNavigate();

  useEffect(() => {
    // check if user is signed in with google vs email&password
    const getSignInMethods = async () => {
      if (currentUser && currentUser.email) {
        // This only works on current session, so if user signs in with
        // Google, it will say its a google account, if they sign in with email
        // it will allow them to change password & email
        // if user changes email, it will change both providers to look for that
        // email next time they sign in
        const signInMethods = await currentUser.getIdTokenResult();
        if (signInMethods.signInProvider === "google.com") {
          setIsGoogleUser(true);
        }
        setLoading(false);
      }
    };
    getSignInMethods();

    // scroll to loading scroller if loading
    if (window.innerWidth > 768) {
      document
        .getElementById("spinner-account-details")
        ?.scrollIntoView({ block: "center" });
    }
  }, [currentUser]);

  // const handleChangeEmail = async (e: React.SyntheticEvent) => {
  //   e.preventDefault();
  //   setEmailError("");
  //   setEmailMessage("");
  //   setEmailLoading(true);
  //   const newEmail = newEmailRef.current!.value;
  //   if (currentUser && currentUser.email) {
  //     try {
  //       await login(currentUser.email, currentPassEmailRef.current!.value);
  //     } catch (e: any) {
  //       setEmailLoading(false);
  //       setEmailMessage("");
  //       return setEmailError(e.message.replace("Firebase: ", ""));
  //     }
  //   } else {
  //     setEmailLoading(false);
  //     setEmailMessage("");
  //     return setEmailError(
  //       "An error occured. Please refresh the page and try again later!"
  //     );
  //   }

  //   const changeEmail = httpsCallable(functions, "changeEmail");
  //   changeEmail({ newEmail: newEmail })
  //     .then(() => {
  //       setEmailLoading(false);
  //       logout().then(() => {
  //         alert(
  //           'Email has been changed succesfully. Please sign in with your new email and then verify it via the "Resend Confirmation Email" button.'
  //         );
  //         navigate("/login");
  //       });
  //     })
  //     .catch((err) => {
  //       setEmailLoading(false);
  //       setEmailMessage("");
  //       return setEmailError(err.message.replace("Firebase: ", ""));
  //     });
  // };

  const handleChangePassword = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setPassError("");
    setLoading(true);
    const form = e.target as HTMLFormElement;
    if (newPassRef.current!.value !== newPassConfirmRef.current!.value) {
      setLoading(false);
      form.reset();
      return setPassError(
        "New password confirmation does not match the new password!"
      );
    }
    if (!validator.isStrongPassword(newPassRef.current!.value)) {
      setLoading(false);
      form.reset();
      return setPassError(
        "Password is not strong. It must contain uppercase & lowercase letters, numbers & symbols."
      );
    }
    if (currentUser && currentUser.email) {
      const newPass = newPassRef.current!.value;
      const currentPass = currentPassRef.current!.value;
      try {
        const userCred = await login(
          currentUser.email,
          currentPassRef.current!.value
        );
        if (userCred) {
          if (newPass === currentPass) {
            form.reset();
            setLoading(false);
            return setPassError(
              "New Password cannot be the same as previous password!"
            );
          }
          try {
            const credential = EmailAuthProvider.credential(
              currentUser.email,
              currentPass
            );
            await reauthenticateWithCredential(currentUser, credential);
            await updatePassword(currentUser, newPass);
            window.location.reload();
          } catch (e: any) {
            return setPassError(e.message.replace("Firebase: ", ""));
          }
          setPassMessage("Password changed succesfully.");
          setPassError("");
        }
      } catch (e: any) {
        form.reset();
        setLoading(false);
        return setPassError(e.message.replace("Firebase: ", ""));
      }
    } else {
      return setPassError("An error occured. Please ensure you are signed in!");
    }
    setLoading(false);
  };

  const handleDeleteAccount = () => {
    alert(
      "This function is not currently available. If you really want to delete your account please contact us :("
    );

    // delete account here...
  };

  const upgradeButton = () => {
    if (accountType === "student") {
      return (
        <OverlayTrigger
          placement="top"
          delay={{ show: 0, hide: 200 }}
          overlay={
            <Tooltip>
              Run out of free contacts? The option to get more will be coming
              soon. For now contact us and we will try to help!
            </Tooltip>
          }
        >
          <div className="ms-auto">
            {/* remove this div once the upgrade plan button becomes clickable */}
            <Button
              variant="outline-success"
              className="ms-auto border border-success border-2"
              disabled
            >
              Get more contacts
            </Button>
          </div>
        </OverlayTrigger>
      );
    } else if (accountType === "tutor") {
      return (
        <OverlayTrigger
          placement="top"
          delay={{ show: 0, hide: 200 }}
          overlay={
            <Tooltip>
              The option to upgrade your plan will be coming soon!
            </Tooltip>
          }
        >
          <div className="ms-auto">
            {/* remove this div once the upgrade plan button becomes clickable */}
            <Button
              variant="outline-success"
              className="ms-auto border border-success border-2"
              disabled
            >
              Upgrade Plan
            </Button>
          </div>
        </OverlayTrigger>
      );
    } else {
      return null;
    }
  };

  const DeleteAccountButton = () => {
    return (
      <div className="d-flex">
        <Button
          variant="danger"
          className="mx-auto"
          onClick={handleDeleteAccount}
        >
          Permanently Delete Account
        </Button>
      </div>
    );
  };

  // This component returns an upgrade button based off the user accountType
  // And the returns a details based off if its logged in with google or not

  // if account is not google account - haev this, else
  if (isGoogleUser) {
    return (
      <div>
        <div className="header-font fs-3 d-flex">
          <div>Account Details</div>
          {upgradeButton()}
        </div>
        <hr />
        <Alert variant="info">
          <FcGoogle className="me-2 fs-4" />
          Your account was signed in using a Google account.
        </Alert>

        <Form.Group>
          <Form.Label className="header-font">Registered Email</Form.Label>
          <Form.Control placeholder={currentUser?.email || ""} disabled />
        </Form.Group>

        <DangerZoneDivier />
        {DeleteAccountButton()}
      </div>
    );
  } else {
    return loading ? (
      <div id="spinner-account-details">
        <LoadingSpinner />
      </div>
    ) : (
      <>
        <div>
          <div className="header-font fs-3 d-flex">
            <div>Account Details</div>
            {upgradeButton()}
          </div>
          <hr />
          <h5 className="header-font text-secondary">{/* Change */}Email</h5>

          <div className="d-flex flex-column flex-md-row justify-content-between">
            <Form.Group id="change-email" className="mt-3 w-65-md">
              <Form.Label className="header-font">Current Email</Form.Label>
              <Form.Control
                disabled
                type="email"
                name="change-email"
                placeholder={privateData.email}
                required
              />
            </Form.Group>
            {/* <Button
              className="mt-3 align-self-end"
              variant="primary"
              disabled={loading}
              onClick={() => {
                setEmailError("");
                setEmailMessage("");
                setShowEmailChangeModal(true);
              }}>
              Change Email
            </Button> */}
          </div>
          <hr
            className="mx-3"
            style={{ marginTop: "50px", marginBottom: "50px" }}
          />
          <h5 className="header-font text-secondary">Change Password</h5>
          {passError && (
            <Alert variant="danger" id="pass-change-alert">
              {passError}
            </Alert>
          )}
          {passMessage && (
            <Alert variant="info" id="pass-change-message">
              {passMessage}
            </Alert>
          )}
          <Form onSubmit={handleChangePassword}>
            <Form.Group id="confirm-current-password" className="mt-3 w-lg-50">
              <Form.Label className="header-font">
                Confirm Current Password{" "}
                <span className="text-danger fw-bold">*</span>
              </Form.Label>
              <Form.Control
                type="password"
                ref={currentPassRef}
                name="confirm-current-password"
                placeholder={""}
                required
              />
            </Form.Group>
            <div className="d-flex flex-column flex-lg-row justify-content-around">
              <Form.Group id="change-password" className="mt-3 me-lg-2 w-100">
                <Form.Label className="header-font">
                  New Password <span className="text-danger fw-bold">*</span>
                </Form.Label>
                <Form.Control
                  type="password"
                  ref={newPassRef}
                  name="change-password"
                  placeholder={""}
                  required
                />
              </Form.Group>
              <Form.Group
                id="change-password-confirm"
                className="mt-3 ms-lg-2 w-100"
              >
                <Form.Label className="header-font">
                  Confirm New Password{" "}
                  <span className="text-danger fw-bold">*</span>
                </Form.Label>
                <Form.Control
                  type="password"
                  ref={newPassConfirmRef}
                  name="change-password-confirm"
                  placeholder={""}
                  required
                />
              </Form.Group>
            </div>
            <div className="d-flex justify-content-end">
              <Button
                className="mt-3 align-self-end"
                type="submit"
                variant="primary"
                disabled={loading}
              >
                Change Password
              </Button>
            </div>
          </Form>
          <DangerZoneDivier />
          {DeleteAccountButton()}
        </div>
        {/* MODAL FOR CHANGING EMAIL ADDRESS */}
        {/* <Modal
          show={showEmailChangeModal}
          onHide={() => setShowEmailChangeModal(false)}
          centered>
          <Modal.Header closeButton className="header-font fs-4">
            Change Email
          </Modal.Header>
          <Modal.Body className="paragraph-font">
            {emailError && (
              <Alert variant="danger" id="email-change-error">
                {emailError}
              </Alert>
            )}
            {emailMessage && (
              <Alert variant="info" id="email-change-message">
                {emailMessage}
              </Alert>
            )}
            {emailLoading ? (
              <LoadingSpinner />
            ) : (
              <Form onSubmit={handleChangeEmail}>
                <Form.Group id="change-email" className="mt-3 w-65-md">
                  <Form.Label>
                    New Email <span className="text-danger fw-bold">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    ref={newEmailRef}
                    name="change-email"
                    placeholder={privateData.email}
                    required
                  />
                </Form.Group>
                <Form.Group
                  id="confirm-current-password-email"
                  className="mt-3 w-65-md">
                  <Form.Label>
                    Confirm Current Password{" "}
                    <span className="text-danger fw-bold">*</span>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    ref={currentPassEmailRef}
                    name="confirm-current-password-email"
                    placeholder={""}
                    required
                  />
                </Form.Group>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={emailLoading}
                  className="mt-3">
                  Change Email
                </Button>
              </Form>
            )}
          </Modal.Body>
        </Modal> */}
      </>
    );
  }
};

export default AccountDetailsForm;
