import { Button } from "react-bootstrap";
import MyNavbar from "../components/reuseables/MyNavBar";
import SEO from "../components/utils/SEO";
import { useSearchParams } from "react-router-dom";
import PricingCards from "../components/pricingComps/PricingCards";

const PricingPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <SEO
        title="Find Leaving Cert and Junior Cert Grinds Tutors in Ireland"
        description="No sign up fee for students, parents or tutors. Find grinds tutors completely free. Advertise your service as a tutor for free on our website."
        keywords="Grinds Ireland,Maths Grinds,Find Grinds Tutors,Become a Grinds Tutor,Leaving Cert, Junior Cert, College Grinds, Primary School Grinds"
        robots=""
        ogTitle="Find Leaving Cert and Junior Cert Grinds Tutors in Ireland"
        ogUrl="https://www.mh1-tutors.com/pricing"
        ogSiteName="MH1 Tutors"
        ogImage="https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2FLogo.png?alt=media&token=6e6b79d8-87db-45b1-840d-919623cbc159"
        ogImageType="image/png"
      />
      <MyNavbar />
      <div className="d-flex flex-column my-2">
        <div className="mx-auto">
          <img
            className="my-3"
            src="https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fzigzag.jpg?alt=media&token=0aa351c1-1ff1-4c96-a3fc-92c543d3eb83"
            alt="cool blue zigzag"
            style={{ maxWidth: 50 }}
          />
        </div>
        <div className="mx-auto">
          <Button
            className="mx-2 px-3"
            variant={
              searchParams.get("account") === "tutor"
                ? "primary"
                : "outline-primary"
            }
            style={{ minWidth: 100 }}
            onClick={() => setSearchParams({ account: "tutor" })}
          >
            Tutor
          </Button>
          <Button
            className="mx-2 px-3"
            variant={
              searchParams.get("account") === "tutor"
                ? "outline-primary"
                : "primary"
            }
            style={{ minWidth: 100 }}
            onClick={() => setSearchParams({ account: "student" })}
          >
            Student/Parent
          </Button>
        </div>
      </div>
      <div className="m-3">
        {/* Pricing cards - student 1 card, tutor 3 cards (2 "disabled") have a selected card?*/}
        <PricingCards accountType={searchParams.get("account") || "student"} />
      </div>
    </>
  );
};

export default PricingPage;
