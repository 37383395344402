import { useState, useEffect } from "react";
import allContactData, {
  colorFromStatusType,
  contactStatusTypes,
} from "../../../interfaces/allContactData";
import getColorBasedOnStatus from "./getColorBasedOnStatus";
import { useAuth } from "../../../contexts/AuthContext";
import ContactBubbleTutor from "./ContactBubbleTutor";
import ContactBubbleStudent from "./ContactBubbleStudent";

type Props = {
  contact: allContactData;
};
const ContactBubbleEntire = ({ contact }: Props) => {
  const [status, setStatus] = useState<contactStatusTypes>("Pending");
  const [color, setColor] = useState<colorFromStatusType>(
    getColorBasedOnStatus(status)
  );

  const { accountType } = useAuth();

  useEffect(() => {
    if (contact.accepted) {
      setStatus("Accepted");
    }
    // if the tutor responded, but did not accept
    else if (contact.responded && !contact.accepted) {
      setStatus("Rejected");
    } else {
      setStatus("Pending");
    }
  }, [contact]);

  useEffect(() => setColor(getColorBasedOnStatus(status)), [status]);

  const contactBubbleBasedOnAccountType = (accountType: string | null) => {
    if (accountType === "student") {
      return (
        <ContactBubbleStudent contact={contact} status={status} color={color} />
      );
    } else if (accountType === "tutor") {
      return (
        <ContactBubbleTutor contact={contact} status={status} color={color} />
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="d-flex flex-row my-5">
        <div className={`border border-4 border-${color}`}></div>
        {/* Contact Info */}
        <div
          // border-color can be removed from this
          className={`border border-${color} border-opacity-25 rounded-3-right shadow-sm d-flex flex-row w-100`}
        >
          <div className="py-2 px-3 w-100">
            {contactBubbleBasedOnAccountType(accountType)}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactBubbleEntire;
