import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Button, Image } from "react-bootstrap";

import { useAuth } from "../../contexts/AuthContext";

import { FaChalkboardTeacher } from "react-icons/fa";
import { BsFillMegaphoneFill } from "react-icons/bs";
import { RiLoginCircleFill } from "react-icons/ri";
import {
  AiOutlinePlusCircle,
  AiOutlineInfoCircle,
  AiFillQuestionCircle,
  AiOutlineLogin,
  AiOutlineEdit,
  AiOutlineSearch,
  AiOutlineMessage,
  AiFillHome,
  AiOutlineAppstoreAdd,
} from "react-icons/ai";
import { SlNotebook } from "react-icons/sl";
import { ImBubble, ImBubble2, ImBubbles3 } from "react-icons/im";
import NewTermsBanner from "../utils/NewTermsBanner";

const MyNavbar = () => {
  const { currentUser, logout, accountType, profileImageSrc } = useAuth();
  const [navExpanded, setNavExpanded] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();

  const [hideNav, setHideNav] = useState(false);

  // on component mount
  useEffect(() => {
    let prevScrollPos = window.scrollY;

    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isScrollingDown = prevScrollPos < currentScrollPos;
      setHideNav(isScrollingDown);
      prevScrollPos = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // when location changes, close navbar
  useEffect(() => {
    setNavExpanded(false);
  }, [location]);

  // Wait 200ms before setting size of window
  const handleResize = useDebouncedCallback(() => {
    setWindowWidth(window.innerWidth);
  }, 200);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  // Confirm Logout
  const confirmLogout = async () => {
    if (window.confirm("Confirm logout?")) {
      await logout();
    }
  };
  // Set options on navbar
  const userMenuList = (): JSX.Element => {
    //get user type, or else just set it to a string "null"
    switch (accountType) {
      case "tutor":
        return (
          <>
            <NavDropdown.Header>{currentUser?.displayName}</NavDropdown.Header>
            <NavDropdown.Item as={Link} to="/" className="font-18">
              <AiFillHome className="me-2 fs-5" />
              Home Page
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              as={Link}
              to="/dashboard?location=contacts"
              className="font-18"
            >
              <AiOutlineMessage className="me-2 fs-5" />
              My Contacts
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/dashboard" className="font-18">
              <AiOutlineEdit className="me-2 fs-5" />
              My Details
            </NavDropdown.Item>
            {process.env.NODE_ENV === "development" && (
              <NavDropdown.Item
                // disabled
                as={Link}
                to="/student-ads"
                className="font-18"
              >
                <FaChalkboardTeacher className="me-2 fs-5" />
                View Student Ads
              </NavDropdown.Item>
            )}
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={confirmLogout} className="font-18">
              <AiOutlineLogin className="me-2 fs-5" />
              Logout
            </NavDropdown.Item>
          </>
        );
      case "student":
        return (
          <>
            <NavDropdown.Header>{currentUser?.displayName}</NavDropdown.Header>
            <NavDropdown.Item as={Link} to="/" className="font-18">
              <AiFillHome className="me-2" />
              Home Page
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              as={Link}
              to="/dashboard?location=contacts"
              className="font-18"
            >
              <AiOutlineMessage className="me-2" />
              Tutors Contacted
            </NavDropdown.Item>
            {/* <NavDropdown.Item
              as={Link}
              to="/dashboard?location=my-student-ads"
              className="font-18"
            >
              <BsFillMegaphoneFill className="me-2" />
              My Student Ads
            </NavDropdown.Item> */}
            <NavDropdown.Item as={Link} to="/dashboard" className="font-18">
              <AiOutlineEdit className="me-2" />
              My Details
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              as={Button}
              onClick={confirmLogout}
              className="font-18"
            >
              <AiOutlineLogin className="me-2" />
              Logout
            </NavDropdown.Item>
          </>
        );
      default:
        return (
          <>
            <NavDropdown.Item as={Link} to="/" className="font-18">
              <AiFillHome className="me-2 fs-5" />
              Home Page
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to="/login" className="font-18">
              <AiOutlineLogin className="me-2 fs-5" />
              Login
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/sign-up" className="font-18">
              <RiLoginCircleFill className="me-2 fs-5" />
              Sign Up
            </NavDropdown.Item>
          </>
        );
    }
  };

  return (
    <>
      <Navbar
        // onSelect={}
        bg="light"
        expand="lg"
        // collapseOnSelect
        sticky="top"
        expanded={navExpanded}
        className={`paragraph-font pb-0 ${hideNav ? "hide-my-nav" : "my-nav"}`}
        style={{
          boxShadow: "0 2px 10px 0px rgba(0, 0, 0, 0.2)",
          zIndex: 249,
        }}
      >
        <Container fluid>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => {
              setNavExpanded(!navExpanded);
            }}
          />
          <Navbar.Brand
            as={Link}
            to="/"
            className="header-font mx-auto"
            style={{
              fontSize: "30px",
              letterSpacing: windowWidth < 768 ? "0rem" : "0.12rem",
            }}
          >
            <img
              alt="MH1 - Maths Help Ireland"
              className="mb-2 me-2"
              src="/assets/mhi-icon.png"
              style={{
                width: "60px",
                maxWidth: "60px",
                filter: "drop-shadow(0px 3px 5px #bbbbbb",
              }}
            />
            MH1 Tutors
          </Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <Nav.Link as={Link} to="/tutors" className="mx-0">
                <Button variant="primary" className="p-2 px-3 mx-xl-3 font-18">
                  <AiOutlineSearch className="me-2 fs-5" />
                  Find a tutor
                </Button>
              </Nav.Link>
              {
                !currentUser ? (
                  <Nav.Link
                    as={Link}
                    to="/sign-up?account=tutor"
                    className="mx-0 mx-xl-3"
                  >
                    <Button variant="dark" className="p-2 px-3 font-18">
                      <AiOutlinePlusCircle className="me-2 fs-5" />
                      Join as a Tutor
                      {/* <span style={{ fontSize: "10px", color: "lightgray" }}>
                  {" "}
                  It's Free
                </span> */}
                    </Button>
                  </Nav.Link>
                ) : (
                  // replace this with/add a button to see "Student Ads" when that releases
                  <Nav.Link
                    as={Link}
                    to="/dashboard?location=contacts"
                    className="mx-0 mx-xl-3"
                  >
                    <Button variant="dark" className="p-2 px-3 font-18">
                      <ImBubbles3 className="me-2 fs-5" />
                      My Contacts
                      {/* <span style={{ fontSize: "10px", color: "lightgray" }}>
                  {" "}
                  It's Free
                </span> */}
                    </Button>
                  </Nav.Link>
                )
                // <Nav.Link
                //   as={Link}
                //   to="/dashboard?location=my-student-ads"
                //   className="mx-0 mx-xl-3"
                // >
                //   <Button variant="dark" className="p-2 px-3 font-18">
                //     <AiOutlinePlusCircle className="me-2 fs-5" />
                //     Place student ad
                //     {/* <span style={{ fontSize: "10px", color: "lightgray" }}>
                //     {" "}
                //     It's Free
                //   </span> */}
                //   </Button>
                // </Nav.Link>
              }
              {/* Student Ads Dropdown */}
              <NavDropdown
                title="Student Resources"
                className="my-auto mx-1 mx-xl-3 font-18"
                align="end"
              >
                {/* <NavDropdown.Item as={Link} to="/student-ads" className="font-18">
                <FaChalkboardTeacher className="me-2 fs-5" />
                View Student Ads
          </NavDropdown.Item> */}
                {process.env.NODE_ENV === "development" &&
                  accountType &&
                  accountType === "student" && (
                    <NavDropdown.Item
                      className="font-18"
                      as={Link}
                      to="/dashboard?location=my-student-ads"
                    >
                      <AiOutlinePlusCircle className="me-2 fs-5" />
                      Place Student Ad
                    </NavDropdown.Item>
                  )}
                <NavDropdown.Item
                  as={Link}
                  to="/learning-resources"
                  className="font-18"
                >
                  <SlNotebook className="me-2 fs-5" />
                  Learning Resources
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="font-18"
                  as={Link}
                  to="/cao-calculator"
                  // target="_blank"
                >
                  <AiOutlineAppstoreAdd className="me-2 fs-5" />
                  CAO Points Calculator
                </NavDropdown.Item>
              </NavDropdown>
              {/* About Dropdown */}
              <NavDropdown
                title="About"
                className="my-auto mx-2 mx-xl-3 font-18"
                align="end"
              >
                <NavDropdown.Item
                  as={Link}
                  to="/how-it-works"
                  className="font-18"
                >
                  <AiFillQuestionCircle className="me-2 fs-5" />
                  How it Works
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pricing" className="font-18">
                  <AiOutlineInfoCircle className="me-2 fs-5" />
                  Pricing
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
          <NavDropdown
            className={navExpanded ? "my-3" : ""}
            title={
              <Image
                roundedCircle
                className="cursor-pointer"
                alt="MH1 Profile"
                style={{
                  width: "40px",
                  maxWidth: "40px",
                  height: "40px",
                  objectFit: "cover",
                  filter: "drop-shadow(0px 3px 5px #bbbbbb)",
                }}
                src={profileImageSrc}
              />
            }
            id="basic-nav-dropdown"
            align={navExpanded ? "start" : "end"}
          >
            {userMenuList()}
          </NavDropdown>
        </Container>
      </Navbar>
      {currentUser && <NewTermsBanner />}
    </>
  );
};

export default MyNavbar;
