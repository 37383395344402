import { Container } from "react-bootstrap";

const DangerZoneDivier = () => {
  return (
    <Container className="row my-4 mx-auto text-danger" fluid>
      <hr className="col-sm-4 col-3 my-auto" />
      <div className="col-sm-4 col-6 text-center">Danger Zone</div>
      <hr className="col-sm-4 col-3 my-auto" />
    </Container>
  );
};

export default DangerZoneDivier;
