import { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useSearchParams } from "react-router-dom";
import { Button, Collapse, Form, InputGroup } from "react-bootstrap";
import { FiCheckCircle, FiChevronUp, FiX } from "react-icons/fi";

const label = "Subject";
const SubjectsFilterSelect = () => {
  const { websiteReadableInfo } = useAuth();

  const [searchTerm, setSearchTerm] = useState("");
  const [itemsInSearch, setItemsInSearch] = useState(
    websiteReadableInfo?.subjectsAvailable || []
  );

  const [expanded, setExapanded] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const subjectsFormatted = useMemo(() => {
    if (!websiteReadableInfo?.subjectsAvailable) return;
    return websiteReadableInfo.subjectsAvailable.map((str) =>
      str.replaceAll("-", " ")
    );
  }, [websiteReadableInfo]);

  const getNonFormattedItem = (formattedItem: string) => {
    const index = subjectsFormatted?.indexOf(formattedItem);
    return index !== undefined &&
      websiteReadableInfo?.subjectsAvailable &&
      index > -1
      ? websiteReadableInfo?.subjectsAvailable[index]
      : formattedItem;
  };

  const getFormattedItem = (nonFormattedItem: string) => {
    const index =
      websiteReadableInfo?.subjectsAvailable?.indexOf(nonFormattedItem);
    return index !== undefined && subjectsFormatted && index > -1
      ? subjectsFormatted[index]
      : nonFormattedItem;
  };

  useEffect(() => {
    if (searchTerm) {
      // search for item in formatted array, but set the itemsInSearch
      // array using non formatted values (full array)
      const temp =
        subjectsFormatted &&
        subjectsFormatted.filter((str) => {
          return str.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
        });
      const nonFormatted = temp?.map((item) => getNonFormattedItem(item));
      setItemsInSearch(nonFormatted || []);
    } else {
      setItemsInSearch(websiteReadableInfo?.subjectsAvailable || []);
    }
  }, [searchTerm]);

  const handleItemClick = (item: string) => {
    console.log(item);
    if (searchParams.get("subject") === item) {
      setSearchParams((prevParams) => {
        prevParams.delete("subject");
        return prevParams;
      });
    } else {
      const newParams = new URLSearchParams(searchParams);
      newParams.set("subject", item);
      setSearchParams(newParams);
    }
    setExapanded(false);
  };

  return (
    <>
      <div>
        {/* REMOVE HOVER EFFECT */}
        <InputGroup className="shadow-sm rounded">
          <Form.Control
            onChange={(e) => {
              if (e.target.value) setExapanded(true);
              return setSearchTerm(e.target.value);
            }}
            placeholder={"Search " + label}
            aria-label={"Search " + label}
            aria-describedby={label + "Subject-2"}
          />
          <Button
            className="d-flex border"
            variant="light"
            id={label + "-Button-2"}
            onClick={() => {
              setExapanded((prevState) => !prevState);
            }}
          >
            <FiChevronUp
              className="mx-auto my-auto"
              style={{
                transform: !expanded ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease",
              }}
            />
          </Button>
        </InputGroup>

        <Collapse in={expanded}>
          <div>
            <div
              className="d-flex flex-column font-light border rounded mt-2 shadow-sm"
              style={{ maxHeight: 250, overflowY: "scroll" }}
            >
              {itemsInSearch.map((item) => (
                <div
                  onClick={() => handleItemClick(item)}
                  key={item}
                  className={
                    "py-1 ps-3 rounded hover-opacity d-flex flex-row justify-content-between cursor-pointer " +
                    (searchParams.get("subject") === item
                      ? "paragraph-font"
                      : "light-font")
                  }
                >
                  {getFormattedItem(item)}

                  {searchParams.get("subject") === item ? (
                    <FiCheckCircle className="text-success my-auto me-3" />
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </Collapse>

        {searchParams.get("subject") && (
          <div className="d-flex mt-2" style={{ flexFlow: "row wrap" }}>
            <div className="header-font w-100">Selected {label}:</div>
            <div
              className="blue-bubble-hover d-flex me-2 ps-1 rounded my-1"
              style={{
                backgroundColor: "#a9e0fb",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.15)",
              }}
            >
              {getFormattedItem(searchParams.get("subject") || "")}
              <FiX
                className="subject-bubble-icon my-auto mx-1 cursor-pointer"
                // since it must be in the arrayToFilter array to appear here, pressing
                // X means it will remove it
                onClick={() =>
                  handleItemClick(searchParams.get("subject") || "")
                }
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SubjectsFilterSelect;
