import { useEffect, useState } from "react";
import { FiChevronsRight } from "react-icons/fi";
import { Link } from "react-router-dom";

const CAOCalcButtonSection = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return (
    <div
      id="mhi-buttons"
      className={
        "d-inline-flex mb-4 mx-auto " +
        (windowWidth > 500 ? "flex-row" : "flex-column")
      }
    >
      <Link className="btn btn-primary m-2" to="/tutors">
        <span className="light-font fs-6 d-flex justify-content-between">
          Find a Grinds Tutor <FiChevronsRight className="fs-6 ms-2 my-auto" />
        </span>
      </Link>
      <Link className="btn btn-outline-primary m-2" to="/sign-up">
        <span className="light-font fs-6 d-flex justify-content-between">
          Become a Grinds Tutor{" "}
          <FiChevronsRight className="fs-6 ms-2 my-auto" />{" "}
        </span>
      </Link>
    </div>
  );
};

export default CAOCalcButtonSection;
