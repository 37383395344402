import { useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";

import HomePageTopSection from "../components/HomePageComps/HomePageTopSection";
import MyNavbar from "../components/reuseables/MyNavBar";
import SEO from "../components/utils/SEO";
import { FcBullish, FcCheckmark, FcIdea } from "react-icons/fc";
import CTAButtonFadeIn from "../components/HomePageComps/CTAButtonFadeIn";
import TutorSignUpButton from "../components/reuseables/TutorSignUpButton";
import StudentSignUpButton from "../components/reuseables/StudentSignUpButton";
import HomePageBenefits from "../components/HomePageComps/HomePageBenefits";
import BecomeTutor from "../components/HomePageComps/BecomeTutor";
import MultipleReviews from "../components/reuseables/MultipleReviews";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const { accountType, currentUser } = useAuth();
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Wait 200ms before setting size of window
  const handleResize = useDebouncedCallback(() => {
    setWindowWidth(window.innerWidth);
  }, 100);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  // useEffect(() => {
  //   if (currentUser && accountType) {
  //     if (accountType === "tutor") {
  //       navigate("/dashboard");
  //     } else if (accountType === "student") {
  //       navigate("/tutors");
  //     }
  //   }
  // }, [currentUser, accountType, navigate]); // Only re-run if these values change

  return (
    <>
      {/* Have to finish this seo */}
      <SEO
        title="Leaving and Junior Cert Grinds Tutors in Ireland - MH1 Tutors"
        description="Find grinds teachers for Junior Cert, Leaving Cert, Primary School, College and more. Discover grinds tutors in subjects like Maths, Irish, Chemistry, French, and many more."
        keywords="Grinds Ireland,Maths Grinds,Find Grinds Tutors,Become a Grinds Tutor,Leaving Cert, Junior Cert, College Grinds, Primary School Grinds"
        robots=""
        ogTitle="Leaving and Junior Cert Grinds Tutors in Ireland - MH1 Tutors"
        ogUrl="https://www.mh1-tutors.com"
        ogSiteName="MH1 Tutors"
        ogImage="https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2FLogo.png?alt=media&token=6e6b79d8-87db-45b1-840d-919623cbc159"
        ogImageType="image/png"
        canonicalLink="https://www.mh1-tutors.com"
      />
      <MyNavbar />
      {/* <ComingSoonAlert /> */}
      <div id="landing-body" className="pt-2 pb-5 px-4">
        <HomePageTopSection windowWidth={windowWidth} />

        {/* This info can be changed */}
        <HomePageBenefits
          texts={[
            "Working with a tutor who understands your unique needs and learning style will help you feel more confident and better prepared for exams.",
            "A dedicated tutor will work with you to improve your grades and build your academic confidence.",
            "With personalised support from an experienced tutor, you can master challenging subjects and achieve your desired grades.",
          ]}
          titles={[
            "Boost your Confidence",
            "Improve your Grades",
            "Excel in your Studies",
          ]}
          icons={[
            <FcBullish className="my-auto mx-auto" />,
            <FcIdea className="my-auto mx-auto" />,
            <FcCheckmark className="my-auto mx-auto" />,
          ]}
        />
        <CTAButtonFadeIn
          button={<StudentSignUpButton findTutorBool={true} className="my-3" />}
        />
        {/* Reviews will be from Trustpilot soon */}

        {/* REVIEWS FROM STUDENTS */}

        <MultipleReviews
          accountTypeSelected="student"
          texts={[
            "Really helpful notes that are available to you after the class. The ability to choose what topics to focus on was great and gave me much more confidence when it came to the LC papers in June.",
            "I find Conor's lessons really clear and easy to follow. Maths is making much more sense to me now. I get the chance to ask questions if I'm confused, and Conor explains it more clearly than my teachers.",
            "Each grind session was genuinely fun and encouraging. I came out of it with confidence and a new understanding of the different concepts found in maths.",
          ]}
          reviewers={[
            "Molly H, LC Student",
            "Aaron, LC Student",
            "Kai, LC Student",
          ]}
          imageUrls={[
            "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fad_img.png?alt=media&token=32001b3f-e27b-4557-a4fd-88115ed7a3c4",
            "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fcp_img.jpg?alt=media&token=9037a8b5-1f18-4d93-b5ca-e25e07ce74bb",
            "https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Fdmc_img.jpeg?alt=media&token=3aa8fc9f-496e-43e5-af95-c7f7ebbebf6c",
          ]}
          reviewees={["Adelin Diac", "Conor Phelan", "Dale McLoughlin"]}
          revieweeTypes={[
            "Maths & Programming Tutor",
            "Maths Tutor",
            "Maths Tutor",
          ]}
        />

        <CTAButtonFadeIn
          button={<StudentSignUpButton findTutorBool={true} className="my-5" />}
        />
        {/* FEATURED TUTORS -> Cards of 3/4 tutors with link to find out more about them -> button to find more tutors (brings to sign uo atm) */}
        {/* (Will be on full version) Find certain subjects */}
        {/* BECOME A TUTOR -> benefits, CTA button, review from michael */}
        <BecomeTutor />
        <CTAButtonFadeIn button={<TutorSignUpButton className="" />} />
      </div>
    </>
  );
};

export default HomePage;
