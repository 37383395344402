import { useEffect, useState } from "react";
import getTutorLatestQual from "../../requests/getTutorLatestQual";
import { useQuery } from "@tanstack/react-query";
import { MONTHS } from "../../constants/strings";
import { FiHome } from "react-icons/fi";
type Props = {
  tutorUid: string;
};

const TutorMostRecentQualForCard = ({ tutorUid }: Props) => {
  const {
    data: tutorQual,
    isLoading,
    isError,
  } = useQuery(
    ["tutor_latest_qual", tutorUid],
    () => getTutorLatestQual(tutorUid),
    { staleTime: 1000 * 60 * 60 * 24 * 7, retry: 2 } // 1 week
  );

  const [datePeriod, setDatePeriod] = useState("");

  useEffect(() => {
    if (!tutorQual) return;
    const [startMonth, startYear] = tutorQual.startDate.split("-");
    if (tutorQual.endDate !== "present") {
      const [endMonth, endYear] = tutorQual.endDate.split("-");
      setDatePeriod(
        MONTHS[Number(startMonth) - 1] +
          " " +
          startYear +
          " - " +
          MONTHS[Number(endMonth) - 1] +
          " " +
          endYear
      );
    } else {
      setDatePeriod(
        MONTHS[Number(startMonth) - 1] + " " + startYear + " - Present"
      );
    }
  }, [tutorQual, MONTHS]);

  return (
    <div className="my-2">
      <h6 className="header-font mb-0">Latest Qualification</h6>
      {tutorQual ? (
        <>
          <div>{tutorQual.title}</div>
          <div className="text-secondary">
            <FiHome className="my-auto me-1" /> {datePeriod}
          </div>
        </>
      ) : (
        <>
          <div>Independant Tutor</div>
        </>
      )}
    </div>
  );
};

export default TutorMostRecentQualForCard;
