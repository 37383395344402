import { BiChevronRight } from "react-icons/bi";
import { Container } from "react-bootstrap";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

const HomePageTopSection = ({ windowWidth }: { windowWidth: number }) => {
  return (
    <div
      id="top-section"
      className="d-flex row ms-lg-5 pb-2 pb-md-5 align-items-center"
      style={{ minHeight: "80vh" }}
    >
      <div className="col-12 col-lg-5 my-md-auto mt-3">
        <div className="mt-3 mb-4 text-center text-lg-start ms-lg-4 header-font ">
          <h1 style={{ fontSize: windowWidth >= 992 ? "50px" : "35px" }}>
            Find the Perfect Grinds Tutor in any subject
          </h1>
          <h4
            className="paragraph-font mt-3"
            style={{ lineHeight: "2rem", letterSpacing: "0.06rem" }}
          >
            Connect with Ireland's best tutors, and forget about your exam
            worries.
          </h4>
        </div>
        {/* This will be a box where you can enter a word & more suggestions come */}
        <Link
          to="/tutors"
          style={{
            maxWidth: "300px",
            textDecoration: "none",
            backgroundImage:
              "linear-gradient(130deg,#8ac1f1 0%,#0275d8 43.23%)",
          }}
          className="rounded text-light text-center paragraph-font fs-4 py-2 pe-3 ps-4 my-4 btn-hover d-flex justify-content-between mx-auto ms-lg-4 shadow-lg"
        >
          Find a Grinds Tutor
          <BiChevronRight className="fs-3 my-auto" />
        </Link>
        <Container className="mb-lg-0 fs-5 paragraph-font text-center text-lg-start ms-lg-3">
          <p>
            <AiOutlineCheckCircle className="text-success me-2 fs-4" />
            Personalised Classes
          </p>
          <p>
            <AiOutlineCheckCircle className="text-success me-2 fs-4" />
            Increased Confidence
          </p>
          <p>
            <AiOutlineCheckCircle className="text-success me-2 fs-4" />
            Improved Exam Results
          </p>
        </Container>
      </div>
      <div className="d-flex col-lg-7 col-12">
        <div className="w-75 w-md-100 mx-auto d-flex">
          <img
            alt="MH1 Tutors - Find Grinds Tutors in Ireland"
            className="my-auto mx-auto w-100"
            // style={{ maxWidth: "90%" }}
            src="https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Flanding1.jpg?alt=media&token=a5559cee-b5b3-4436-8496-5f05fa6aa923"
          />
        </div>
      </div>
    </div>
  );
};

export default HomePageTopSection;
