import { useEffect, useState, useRef } from "react";
import { FiCalendar, FiCheckCircle, FiStar } from "react-icons/fi";

const BecomeTutor = () => {
  const domRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    let currentVal: any = null;
    if (!domRef.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    currentVal = domRef.current;
    return () => {
      if (currentVal) return observer.unobserve(currentVal);
    };
  }, []);
  // this is here to update branches
  return (
    <div
      ref={domRef}
      className={`row shadow mb-4 mt-5 fade-in-section ${
        isVisible ? "is-visible" : ""
      }`}>
      <div className="bg-white col-12 col-md-6 text-start py-4 ps-3">
        <h3 className="header-font">
          Do you have a passion you want to share?
        </h3>
        <h5 className="light-font">Why not become a Grinds Tutor?</h5>
        <div className="d-flex flex-row mt-4">
          <div
            className="text-success p-4 rounded-circle me-3 my-auto d-flex"
            style={{
              backgroundColor: "rgba(92, 184, 92, 0.3)",
              // color: "rgb(249, 96, 235)",
              width: 76,
              height: 76,
            }}>
            <FiCheckCircle className="fs-3 mx-auto my-auto" />
          </div>
          <div className="my-auto">
            <h5 className="header-font">Gain valuable experience</h5>
            <div className="light-font">
              Tutoring is an excellent way to enhance your teaching skills and
              add valuable experience to your CV.
            </div>
          </div>
        </div>

        <div className="d-flex flex-row my-5">
          <div
            className="p-4 rounded-circle me-3 my-auto d-flex"
            style={{
              backgroundColor: "rgba(76, 160, 255, 0.3)",
              color: "#4CA0FF",
              width: 76,
              height: 76,
            }}>
            <FiCalendar className="fs-3 mx-auto my-auto" />
          </div>
          <div className="my-auto">
            <h5 className="header-font">Work according to your own Schedule</h5>
            <div className="light-font">
              You can choose your availability as a tutor and work around your
              existing commitments.
            </div>
          </div>
        </div>

        <div className="d-flex flex-row my-3">
          <div
            className="text-info p-4 rounded-circle me-3 my-auto d-flex"
            style={{
              backgroundColor: "rgba(91, 192, 222, 0.3)",
              width: 76,
              height: 76,
            }}>
            <FiStar className="fs-3 mx-auto my-auto" />
          </div>
          <div className="my-auto">
            <h5 className="header-font">Teach only the subjects you want</h5>
            <div className="light-font">
              As a tutor, you can select the subjects you are passionate about
              teaching, allowing you to focus on what you do best.
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex col-12 col-md-6 sm-down-max-200"
        style={{
          backgroundImage:
            "url(https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Ftutor_bg_blackboard.jpg?alt=media&token=748b5d11-05b0-43c0-b6f3-a98337b36520)",
          backgroundPosition: "left center",
          backgroundSize: "240%",
          backgroundRepeat: "no-repeat",
        }}>
        <img
          className="my-auto mx-auto"
          style={{ maxWidth: "100%", maxHeight: "100%", opacity: "0.1" }}
          alt="MH1 Tutors - How it works"
          src="/assets/mhi-icon.png"
        />
      </div>
    </div>
  );
};

export default BecomeTutor;
