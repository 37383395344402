import {
  Alert,
  Button,
  CloseButton,
  Collapse,
  Container,
  Spinner,
  Toast,
} from "react-bootstrap";
import FilterSection from "./FilterSection";
import { FaCalendar, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { useEffect, useState } from "react";
import StudentAdsSection from "./StudentAdsSection";
import studentAdsInterface from "../../interfaces/studentAdsInterface";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db, functions } from "../../firebase";
import { AiOutlineLogin, AiOutlineWarning } from "react-icons/ai";
import { httpsCallable } from "firebase/functions";
import { useAuth } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { ImageProvider } from "../../contexts/ImagesContext";
import { BasketProvider } from "../../contexts/BasketContext";
import StudentAdsBasket from "./StudentAdsBasket";

function arrayEqual(arr1: Array<String>, arr2: Array<String>) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedA = arr1.slice().sort();
  const sortedB = arr2.slice().sort();

  for (let i = 0; i < arr1.length; i++) {
    if (sortedA[i] !== sortedB[i]) {
      return false;
    }
  }

  return true;
}

const StudentAdsComp = () => {
  const { currentUser } = useAuth();

  const [authError, setAuthError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // to prevent unnecesary calls to db
  const [oldSubjectFilters, setOldSubjectFilters] = useState<Array<String>>([]);
  const [oldLevelFilters, setOldLevelFilters] = useState<Array<String>>([]);
  const [oldLocationFilters, setOldLocationFilters] = useState<Array<String>>(
    []
  );
  const [oldOnlineOnly, setOldOnlineOnly] = useState(false);

  const [openSortCollapse, setOpenSortCollapse] = useState(false);
  const [sortDirectionAscending, setSortDirectionAscending] = useState(false);

  const [ads, setAds] = useState<Array<studentAdsInterface> | null>(null);
  const [lastAd, setLastAd] = useState<studentAdsInterface>();

  // change this to react query - add a function for filtering & paginating ads?
  useEffect(() => {
    // get some ads on intial load -> filtering done via server
    const getAds = async () => {
      // sort ascending so tutors see the ones nearest to expiry first
      // might make thme more likely to contact as they know it'll expire soon
      const directionToOrder = "asc"; // "desc";

      const adsRef = collection(db, "studentAds");

      const publicAdsQuery = query(
        adsRef,
        orderBy("createdAt", directionToOrder),
        where("expired", "==", false),
        limit(10)
      );
      try {
        const publicAdsSnapshot = await getDocs(publicAdsQuery);
        const publicAds = publicAdsSnapshot.docs.map(
          (doc) => doc.data() as studentAdsInterface
        );
        setAds(publicAds);
        setLastAd(publicAds[publicAds.length - 1]);
      } catch (err) {
        setError("An error occured while fetching ads. Please try again.");
        console.error(err);
        // set some sort of error state
      }
    };
    getAds().then(() => setLoading(false));
  }, []);

  const getFilteredAds = async (
    subjectsToFilter: Array<String>,
    levelsToFilter: Array<String>,
    locationsToFilter: Array<String>,
    onlineOnly: boolean
  ) => {
    setLoading(true);
    setError("");

    if (!currentUser) {
      setAuthError(true);
      return setLoading(false);
    }

    // check if filters are the same as before
    if (
      arrayEqual(oldSubjectFilters, subjectsToFilter) &&
      arrayEqual(oldLevelFilters, levelsToFilter) &&
      arrayEqual(oldLocationFilters, locationsToFilter) &&
      onlineOnly === oldOnlineOnly
    ) {
      // just display a loading spinner for a bit so that users think it's doing something
      // otherwise it will be too quick and they think nothing is going on
      await new Promise((resolve) => setTimeout(resolve, 150));
      console.log("same filters");
      return setLoading(false);
    }

    const getFilteredAds = httpsCallable(functions, "getFilteredStudentAds");

    try {
      alert(
        "Nothing will happen as of now - the cloud function will be called, but it doenst return anythinhg yet"
      );
      const filteredAds = await getFilteredAds({
        subjectsToFilter,
        levelsToFilter,
        locationsToFilter,
        directionAscending: sortDirectionAscending,
        onlineOnly,
      });
    } catch (err: any) {
      setLoading(false);
      console.error(err);
      return setError(
        err.message?.toLowerCase() === "internal"
          ? "An error occured while fetching ads. Please try again."
          : err.message
      );
    }

    // set the filters as old filters
    setOldSubjectFilters(subjectsToFilter);
    setOldLevelFilters(levelsToFilter);
    setOldLocationFilters(locationsToFilter);
    setOldOnlineOnly(onlineOnly);
    setLoading(false);
  };

  const handleSortDirection = () => {
    if (!currentUser) {
      setAuthError(true);
      return;
    }
    setSortDirectionAscending((prevState) => !prevState);

    // sort current ads in direction required, and set the last ad accordingly
    alert("To be impleneted");
  };

  const handleLoadMore = async () => {
    if (!currentUser) {
      setAuthError(true);
      return;
    }
    alert("Use react-query for displaying more ads!!!!");
  };

  return (
    <>
      <Container className="d-flex my-3" fluid>
        <div
          className="light-font d-none d-md-block w-100"
          style={{ maxWidth: 250 }}
        >
          <FilterSection getFilteredAds={getFilteredAds} loading={loading} />
        </div>
        <div className="d-flex flex-column w-100 ms-md-4">
          <div className="light-font d-flex">
            <div className="light-font d-block d-md-none">
              <FilterSection
                getFilteredAds={getFilteredAds}
                loading={loading}
              />
            </div>

            <Button
              variant={openSortCollapse ? "primary" : "outline-primary"}
              className="d-flex ms-auto"
              onClick={() => setOpenSortCollapse((prevState) => !prevState)}
            >
              <FaCalendar className="my-auto mx-2" /> Sort By Date
            </Button>
          </div>
          <Collapse in={openSortCollapse}>
            <div>
              <div className="d-flex justify-content-end my-2 light-font">
                <Button
                  variant={
                    sortDirectionAscending ? "primary" : "outline-primary"
                  }
                  size="sm"
                  className="me-2"
                  onClick={handleSortDirection}
                >
                  <FaSortAmountUp className="my-auto mx-2" /> Oldest First
                </Button>
                <Button
                  variant={
                    !sortDirectionAscending ? "primary" : "outline-primary"
                  }
                  size="sm"
                  className="ms-2"
                  onClick={handleSortDirection}
                >
                  <FaSortAmountDown className="my-auto mx-2" /> Newest First
                </Button>
              </div>
            </div>
          </Collapse>

          {error && (
            <Alert
              variant="danger"
              className="w-100 mt-3 d-flex justify-content-center flex-md-row flex-column align-items-center"
            >
              <AiOutlineWarning className="my-auto me-md-2 fs-5" />
              {error}
            </Alert>
          )}

          {/* This image provider ensures the images aren't 
            re-fetched when loading state changes
            - probs better way to do it tbh

            Basket provider also ensures basket not cleared when loading state changes
            */}
          <BasketProvider>
            <ImageProvider>
              {loading || !ads ? (
                <div className="d-flex justify-content-center my-5">
                  <Spinner style={{ width: 75, height: 75 }} />
                </div>
              ) : (
                <StudentAdsSection ads={ads} />
              )}
            </ImageProvider>
            <StudentAdsBasket />
          </BasketProvider>
        </div>
      </Container>
      <div className="mb-3 d-flex">
        {/* EMPTY DIV SO BUTTON IS CENTERED */}
        <div
          className="d-none d-md-block w-100"
          style={{ maxWidth: 250 }}
        ></div>
        <div className="d-flex w-100">
          <Button
            className="mx-auto"
            variant="outline-dark"
            onClick={handleLoadMore}
          >
            Load More
            <FiPlus className="ms-2 my-auto" />
          </Button>
        </div>
      </div>
      <Toast
        onClose={() => setAuthError(false)}
        show={authError}
        delay={10000}
        autohide
        className="shadow-lg mx-auto"
        style={{
          position: "fixed",
          bottom: 75,
          left: 0,
          right: 0,
          zIndex: 999,
        }}
      >
        <Toast.Header
          closeButton={false}
          className="w-100 bg-danger text-light light-font d-flex justify-content-center"
        >
          <h6 className="my-auto">Sign in to filter or view more ads!</h6>
        </Toast.Header>
        <Toast.Body className="text-dark bg-white rounded py-4">
          <Link
            to="/login"
            className="btn btn-outline-danger w-100 d-flex justify-content-center"
          >
            Login <AiOutlineLogin className="ms-2 my-auto fs-5" />
          </Link>
        </Toast.Body>
      </Toast>
    </>
  );
};

export default StudentAdsComp;
