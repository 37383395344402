import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

type Props = {
  image: string;
  title: string;
  paragraph: string;
  badgeColor: string;
  step: number;
  accountType: string | null;
};

const HowItWorksCard = ({
  image,
  title,
  paragraph,
  badgeColor,
  step,
  accountType,
}: Props) => {
  const domRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    let currentVal: any = null;
    if (!domRef.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    currentVal = domRef.current;
    return () => {
      if (currentVal) return observer.unobserve(currentVal);
    };
  }, []);

  return (
    <div
      className={`col col-12 col-sm-6 col-md-4 py-3 fade-in-section ${
        isVisible ? "is-visible" : ""
      }`}>
      <div
        ref={domRef}
        className="w-100 h-100 bg-white rounded shadow mx-auto pb-4"
        style={{ maxWidth: 450 }}>
        <div className="w-100 p-4">
          <img
            className="w-100 rounded"
            src={image}
            alt={"MH1 Tutors - How it works step " + step}
          />
        </div>
        <div className="w-100 mx-auto my-2 py-2 d-flex">
          <Link
            to={accountType ? "/sign-up?account=" + accountType : "/sign-up"}
            className="w-50 mx-auto text-light text-decoration-none rounded shadow-lg my-2 py-2"
            style={{ backgroundColor: badgeColor }}>
            Step {step + 1}
          </Link>
        </div>
        <div className="header-font fs-5">{title}</div>
        <div className="light-font px-2">{paragraph}</div>
      </div>
    </div>
  );
};

export default HowItWorksCard;
