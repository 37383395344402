import { useRef, useState, useEffect } from "react";
import { FaRegSmile } from "react-icons/fa";
import { FiShield, FiUmbrella } from "react-icons/fi";

const HowItWorksMiddle = () => {
  const domRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    let currentVal: any = null;
    if (!domRef.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    currentVal = domRef.current;
    return () => {
      if (currentVal) return observer.unobserve(currentVal);
    };
  }, []);
  // this is here to update branches
  return (
    <div
      ref={domRef}
      className={`row mb-4 mt-5 fade-in-section ${
        isVisible ? "is-visible" : ""
      }`}
      style={{
        backgroundImage:
          "url(https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2Ftutor_bg2.jpg?alt=media&token=7c270c68-f064-470b-8af6-ecd5d0845e88)",
        backgroundPosition: "left top",
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
      }}>
      <div className="col-0 col-md-6 d-md-flex d-none">
        <img
          className="my-auto mx-auto"
          style={{ maxWidth: "100%", opacity: "0.1" }}
          alt="MH1 Tutors - How it works"
          src="/assets/mhi-icon.png"
        />
      </div>
      <div className="bg-white col-12 col-md-6 text-start py-4 ps-2 ps-md-3 ps-lg-5">
        <h5>Empowering Students and Tutors</h5>
        <h3 className="header-font">Discover the benefits of our platform</h3>
        <div className="d-flex flex-row my-3">
          <div
            className="text-success p-4 rounded-circle me-3 my-auto"
            style={{
              backgroundColor: "rgba(92, 184, 92, 0.3)",
              width: 76,
              height: 76,
            }}>
            <FiUmbrella className="fs-3" />
          </div>
          <div className="my-auto">
            <h5 className="header-font">Streamlined Sign-Up Process</h5>
            <div className="light-font">
              Get started in seconds with our straightforward sign-up process.
            </div>
          </div>
        </div>
        <div className="d-flex flex-row my-5">
          <div
            className="text-danger p-4 rounded-circle me-3 my-auto"
            style={{
              backgroundColor: "rgba(217, 83, 79, 0.3)",
              width: 76,
              height: 76,
            }}>
            <FiShield className="fs-3" />
          </div>
          <div className="my-auto">
            <h5 className="header-font">User Friendly</h5>
            <div className="light-font">
              The platform's interface is designed to make it easy for students
              and tutors to connect.
            </div>
          </div>
        </div>

        <div className="d-flex flex-row my-3">
          <div
            className="p-4 rounded-circle me-3 my-auto"
            style={{
              backgroundColor: "#f1eeff",
              color: "#7357fb",
              width: 76,
              height: 76,
            }}>
            <FaRegSmile className="fs-3" />
          </div>
          <div className="my-auto">
            <h5 className="header-font">Reduced barriers to success</h5>
            <div className="light-font">
              Dedicated tutors for students, and increased flexibilty for all.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorksMiddle;
