import { RefObject, useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { useDebouncedCallback } from "use-debounce";
import { BiChevronUp } from "react-icons/bi";

interface Props {
  firstNameRef: RefObject<HTMLInputElement>;
  text: String;
}

const SignUpButton = ({ firstNameRef, text }: Props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // Wait 200ms before setting size of window
  const handleResize = useDebouncedCallback(() => {
    setWindowWidth(window.innerWidth);
  }, 100);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const buttonDom = useRef<HTMLDivElement | null>(null);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    let currentVal: any = null;
    if (!buttonDom.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(buttonDom.current);
    currentVal = buttonDom.current;
    return () => {
      if (currentVal) return observer.unobserve(currentVal);
    };
  }, []);

  return (
    <div
      ref={buttonDom}
      className={`d-flex mb-3 fade-in-section ${
        isVisible ? "is-visible" : ""
      }`}>
      <Button
        size="lg"
        className="mx-auto fs-6 px-4 py-2 hover-up"
        onClick={() => {
          windowWidth >= 992 && firstNameRef.current?.focus();
          return firstNameRef.current?.scrollIntoView({
            block: "center",
            inline: "center",
          });
        }}>
        {text}
        <BiChevronUp className="fs-3 ms-2 my-auto" />
      </Button>
    </div>
  );
};

export default SignUpButton;
