import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Alert, Spinner, Modal, Form, Button } from "react-bootstrap";
import { AiOutlineDoubleRight } from "react-icons/ai";
import GoogleSignInDivider from "../signInSignUp/GoogleSignInDivider";
import { FcGoogle } from "react-icons/fc";
import { browserName } from "react-device-detect";
import ChromeOrSafariAlert from "../utils/ChromeOrSafariAlert";
import {
  AuthError,
  GoogleAuthProvider,
  UserCredential,
  getAdditionalUserInfo,
  sendEmailVerification,
  signInWithPopup,
} from "firebase/auth";
import { auth, functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { Link, useSearchParams } from "react-router-dom";
import { getLetterImageFromStorage } from "../../requests/getStorageProfileImage";

type Props = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  tutorSlug: string;
  handleModalToDisplay: () => void;
};

const SignUpForm = ({
  setLoading,
  loading,
  tutorSlug,
  handleModalToDisplay,
}: Props) => {
  const { currentUser, accountType, signup } = useAuth();
  const [searchParams] = useSearchParams();
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const acceptsMarketingRef = useRef<HTMLInputElement>(null);
  const [acceptsTerms, setAcceptsTerms] = useState(false);

  const [isBadBrowser, setIsBadBrowser] = useState(false);

  const [googleError, setGoogleError] = useState("");
  const [error, setError] = useState("");

  // when loggin in, but also want the account type
  useEffect(() => {
    if (currentUser && accountType) {
      console.log("run");
      handleModalToDisplay();
    }
  }, [currentUser, accountType]);

  useEffect(() => {
    if (currentUser && !currentUser.emailVerified) {
      // listen for cahnges in emailVerified
      const interval = setInterval(() => {
        console.log("listen for changes in emailVerified");
        if (currentUser.emailVerified) {
          clearInterval(interval);
          return handleModalToDisplay();
        }
        return currentUser.reload();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [currentUser, accountType, handleModalToDisplay]);

  const handleSignUp = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    const acceptsMarketing = acceptsMarketingRef.current?.checked;

    let userCredential: UserCredential;
    try {
      userCredential = await signup(
        emailRef.current!.value,
        passwordRef.current!.value
      );
    } catch (error: any) {
      setLoading(false);

      if ((error as AuthError).code === "auth/email-already-in-use") {
        return setError(
          "The email address is already in use by another account. Please use the Login form to sign in."
        );
      } else {
        return setError("Error creating your account. Please try again.");
      }
    }

    ///////////////////////////////////////////////////////////////////
    // setting up database documents here
    try {
      const [firstName, lastName] = nameRef.current!.value.split(" ");
      const fullNameProper =
        firstName.charAt(0).toUpperCase() +
        firstName.slice(1) +
        " " +
        lastName.charAt(0).toUpperCase() +
        lastName.slice(1);
      const user = userCredential.user;

      // get image from storage to set as profile image
      const imageUrl = await getLetterImageFromStorage(firstName);

      // Call firebase function to set user document
      const createUserDocs = httpsCallable(functions, "createUserDocs");
      await createUserDocs({
        firstName: firstName.charAt(0).toUpperCase() + firstName.slice(1),
        lastName: lastName.charAt(0).toUpperCase() + lastName.slice(1),
        accountType: "student",
        uid: user.uid,
        email: user.email,
        displayName: fullNameProper,
        imageUrl: imageUrl,
        ref: searchParams.get("ref"),
        acceptsMarketing: acceptsMarketing,
      });
      // returned from server -> firestore docs should be created
      // console.log("db data set");
    } catch (err: any) {
      // an error occurred somehwere - deleting user for now
      await userCredential.user.delete();
      setLoading(false);
      return setError(
        err.message.replace("Firebase:", "") +
          " - an error occured setting up your account. Please contact us if this error persists"
      );
    }

    ///////////////////////////////////////////////////////////////////
    // user has been signed up, and docs have been created successfully
    // send them an email to verify their email address
    try {
      const currentDomain =
        process.env.NODE_ENV === "development"
          ? "localhost:3000"
          : window.location.hostname;
      await sendEmailVerification(userCredential.user, {
        url: `https://${currentDomain}/tutors?tu=${tutorSlug}`,
      });

      // After succesful sign-up
      setLoading(false);
      setError("");
    } catch (err) {
      alert(
        "Your account has been created, but an error occured sending you a verification email. Please use the 'Resend Verification Email' button from the login page."
      );
    }

    setLoading(false);
  };

  const googleSignUp = async () => {
    if (
      browserName === "Instagram" ||
      browserName === "Facebook" ||
      browserName === "TikTok"
    ) {
      return setIsBadBrowser(true);
    }

    if (!acceptsTerms)
      return setGoogleError(
        "Please accept the terms of service and privacy policy."
      );
    const acceptsMarketing = acceptsMarketingRef.current?.checked;

    // GOOGLE SIGN
    const provider = new GoogleAuthProvider();
    setLoading(true);

    let googleResult = null;

    // try to sign in
    try {
      googleResult = await signInWithPopup(auth, provider);
    } catch (e: any) {
      setError(
        "An error occured setting up your account: " +
          e.message.replace("Firebase: Error", "")
      );
      return setLoading(false);
    }

    // if new user, set up account
    // if existing user, the log in is complete

    const additionalUserInfo = getAdditionalUserInfo(googleResult);
    if (additionalUserInfo?.isNewUser) {
      // set up account
      console.log("set up account");
      // set up everything here
      let firstName: string;
      let lastName: string;
      const user = googleResult.user;

      // get user display name
      if (user.displayName) {
        firstName = user.displayName.split(" ")[0];
        lastName = user.displayName.split(" ")[1];
      } else {
        firstName = "firstName";
        lastName = "lastName";
      }

      // Call firebase function to set user document
      const createUserDocs = httpsCallable(functions, "createUserDocs");

      try {
        await createUserDocs({
          firstName: firstName.charAt(0).toUpperCase() + firstName.slice(1),
          lastName: lastName.charAt(0).toUpperCase() + lastName.slice(1),
          accountType: "student",
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          imageUrl: user.photoURL,
          ref: searchParams.get("ref"),
          acceptsMarketing: acceptsMarketing,
        });
      } catch (err: any) {
        await user.delete();
        setLoading(false);
        return setError(
          err.message.replace("Firebase:", "") +
            " - Something went wrong. Please try again, and if an error persists, please contact us."
        );
      }
    }
    setLoading(false);
  };

  if (currentUser && loading)
    return (
      <div className="d-flex flex-column w-100">
        <Alert className="shadow border-primary border-opacity-50 m-2">
          Setting up your account, please wait...
        </Alert>
        <Spinner className="mx-auto my-4" />
      </div>
    );
  else if (currentUser) return null;
  return (
    <>
      {error && (
        <Alert
          variant="danger"
          className="mt-2 mx-2 mb-0 light-font border-danger border-opacity-50 shadow"
        >
          {error}
        </Alert>
      )}
      <Form onSubmit={handleSignUp}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label className="paragraph-font mb-0">
              Full Name <span className="text-danger fw-bold">*</span>
            </Form.Label>
            <Form.Control required type="name" ref={nameRef} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="paragraph-font mb-0">
              Email <span className="text-danger fw-bold">*</span>
            </Form.Label>
            <Form.Control required type="email" ref={emailRef} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="paragraph-font mb-0">
              Password <span className="text-danger fw-bold">*</span>
            </Form.Label>
            <Form.Control required type="password" ref={passwordRef} />
          </Form.Group>
          <Form.Check
            required
            className="mt-3"
            type="checkbox"
            defaultChecked={false}
            onChange={(e) => setAcceptsTerms(e.target.checked)}
            label={
              <p style={{ fontSize: 14 }}>
                <span className="text-danger fw-bold">*</span> By Signing up,
                you agree to our{" "}
                <Link to="/terms-of-service" target="_blank">
                  Terms of Service
                </Link>
                , and our{" "}
                <Link to="/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>
              </p>
            }
          />
          <Form.Check
            className="mt-1"
            type="checkbox"
            defaultChecked={false}
            ref={acceptsMarketingRef}
            label={
              <p style={{ fontSize: 14 }}>
                Check this box to receive updates about our latest features,
                updates, and promotions.
              </p>
            }
          />
        </Modal.Body>
        <Modal.Footer className="light-font">
          <Button
            variant="primary"
            type="submit"
            className="w-100"
            disabled={loading}
          >
            {loading ? (
              <Spinner size="sm" />
            ) : (
              <>
                Sign Up <AiOutlineDoubleRight className="my-auto ms-2" />
              </>
            )}
          </Button>
          <GoogleSignInDivider />
          {isBadBrowser && (
            <ChromeOrSafariAlert
              showProp={isBadBrowser}
              setShowProp={setIsBadBrowser}
              chromeUrl={`googlechrome://${window.location.hostname}/tutors?tu=${tutorSlug}`}
            />
          )}
          {googleError && (
            <Alert
              variant="danger"
              className="w-100 mt-2 mb-0 light-font border-danger border-opacity-50 shadow"
            >
              {googleError}
            </Alert>
          )}
          <Button
            disabled={loading || (currentUser ? true : false)}
            variant="dark"
            className="w-100"
            onClick={googleSignUp}
          >
            {loading ? (
              <Spinner size="sm" className="my-auto" />
            ) : (
              <>
                <FcGoogle className="me-2 fs-5" />
                Sign up with Google
              </>
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </>
  );
};

export default SignUpForm;
