import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import tutorUserData from "../interfaces/tutorUserData";
import studentUserData from "../interfaces/studentUserData";

export default async function getUserDoc(userUid: string | undefined, accountType: string | null): Promise<studentUserData | tutorUserData | null | undefined>{
  // console.log(userUid, accountType)
  console.log("getting user doc - new")
  if(!userUid) {
    throw new Error("An error occured!")
  }
  if(accountType !== "tutor" && accountType !== "student"){
    throw new Error("An error occured!")
  }
  const userPublicDocRef = doc(db, accountType + "s/", userUid);

  const userPublicDoc = await getDoc(userPublicDocRef);

  if (!userPublicDoc.exists()) {
    return null;
  } else {
    if(accountType === "tutor"){
      return userPublicDoc.data() as tutorUserData;
    }
    else if(accountType === "student"){
      return userPublicDoc.data() as studentUserData;
    }
  }
}