import { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";

type pointsForGrades = {
  H1: number;
  H2: number;
  H3: number;
  H4: number;
  H5: number;
  H6: number;
  H7: number;
  H8: number;
  O1: number;
  O2: number;
  O3: number;
  O4: number;
  O5: number;
  O6: number;
  O7: number;
  O8: number;
  Distinction: number;
  Merit: number;
  Pass: number;
};

const POINTS_FOR_GRADE = {
  H1: 100,
  H2: 88,
  H3: 77,
  H4: 66,
  H5: 56,
  H6: 46,
  H7: 37,
  H8: 0,
  O1: 56,
  O2: 46,
  O3: 37,
  O4: 28,
  O5: 20,
  O6: 12,
  O7: 0,
  O8: 0,
  Distinction: 66,
  Merit: 46,
  Pass: 28,
};

const HL_COLOR = "#333333";
const LCVP_COLOR = "#b38302";
const OL_COLOR = "#3e79b5";
const OFF_FOCUS_COLOR = "#cccccc";

const BONUS_25 = ["H1", "H2", "H3", "H4", "H5", "H6"];
const BUTTON_LABELS = [
  ["O1", "O2", "O3", "O4", "O5", "O6", "O7", "O8"],
  ["H1", "H2", "H3", "H4", "H5", "H6", "H7", "H8"],
  ["Distinction", "Merit", "Pass"],
];
const POINTS_LABELS = [
  [56, 46, 37, 28, 20, 12, 0, 0],
  [100, 88, 77, 66, 56, 46, 37, 0],
  [66, 46, 28],
];
const GRADE_LABELS = [
  "90-100%",
  "80-89%",
  "70-79%",
  "60-69%",
  "50-59%",
  "40-49%",
  "30-39%",
  "0-29%",
];
const ROWS_ARR = [
  [0, 1, 2],
  [3, 4, 5],
  [6, 7, 8],
];

function CAOCalculatorComponent() {
  // In this state => 0: Ordinary Level, 1: Higher Level, 2: LCVP
  const [displayingLevel, setDisplayingLevel] = useState(1);

  const [mathsGradeFound, setMathsGradeFound] = useState(false);
  const [lcvpFound, setLcvpFound] = useState(false);
  const [grades, setGrades] = useState<string[]>([]);
  const [points, setPoints] = useState<number[]>([]); // Array of top 6 points
  const [totalPoints, setTotalPoints] = useState(0); // Int of total points of top 6
  const [subjectCount, setSubjectCount] = useState(0);

  const [buttonColor, setButtonColor] = useState(HL_COLOR);

  const styles = {
    btn: {
      height: "100px",
      backgroundColor: buttonColor,
      // borderRadius: "5px",
    },
    restartBtn: {
      color: "#333333",
      backgroundColor: "#f0f0f0",
      height: "100px",
    },
    selectBtn: {
      border: "0px",
      // color: "#ffffff",
      padding: "20px",
      fontWeight: "bold",
      fontSize: "18px",
      width: "100%",
    },
  };

  function calculatePoints(grade: keyof pointsForGrades) {
    return POINTS_FOR_GRADE[grade] || 0;
  }

  function handleClick(event: any) {
    if (subjectCount === 0) {
      setMathsGradeFound(true);
    }
    const value = event.currentTarget.getAttribute("value");
    // If a value for LCVP was already set when trying to set another one, dont do it
    if (value === "Distinction" || value === "Merit" || value === "Pass") {
      if (lcvpFound) return;
      setLcvpFound(true);
    }

    if (subjectCount > 5) {
      if (calculatePoints(value) > Math.min(...points)) {
        setPoints((prevPoints) => {
          prevPoints.splice(prevPoints.indexOf(Math.min(...prevPoints)), 1); //RUNNING TWICE
          prevPoints.push(calculatePoints(value)); // RUNNING TWICE
          return prevPoints;
        });
      }
      setSubjectCount(subjectCount + 1);
      setGrades((prevGrades) => [...prevGrades, value]);
      return;
    } else {
      subjectCount === 0
        ? setPoints([
            calculatePoints(value) + (BONUS_25.includes(value) ? 25 : 0),
          ])
        : setPoints((prevPoints) => [...prevPoints, calculatePoints(value)]);
      setSubjectCount(subjectCount + 1);
      setGrades((prevGrades) => [...prevGrades, value]);
    }
  }

  function handleRestart() {
    setDisplayingLevel(1);

    setMathsGradeFound(false);
    setLcvpFound(false);
    setGrades([]);
    setPoints([]);
    setTotalPoints(0);
    setSubjectCount(0);
  }

  useEffect(() => {
    switch (displayingLevel) {
      case 1:
        setButtonColor(HL_COLOR); // Higher Level Color
        break;
      case 2:
        setButtonColor(LCVP_COLOR); // LCVP Color
        break;
      case 0:
        setButtonColor(OL_COLOR); // Ordinary Color
        break;
      default:
        break;
    }
  }, [displayingLevel]);

  // Set total points when grades change
  useEffect(() => {
    console.log("useEffect running");

    setTotalPoints(
      Object.values(points).reduce(
        (acc, point) => {
          return acc + point;
        },
        0
        // The bonus 25 is included if the points for maths+bonus
        // is greater than the lowest score
      )
    );
  }, [grades, points]);

  return (
    <>
      <Container className="d-flex flex-column text-start mt-2 mb-4">
        {/* Make this section aesthetic */}
        <div className="text-center">{subjectCount} Subjects</div>
        <div>
          <span className="fs-1">{totalPoints} </span>
          <span style={{ fontSize: "14px" }}>points</span>
        </div>
      </Container>

      {
        <div
          className="mb-1 fs-3 fw-bold"
          style={{ visibility: mathsGradeFound ? "hidden" : "visible" }}
        >
          Select Your Grade in Maths
        </div>
      }
      <div>
        <Container className="mb-3">
          <Row>
            <Col
              style={{
                backgroundColor:
                  displayingLevel === 1 ? HL_COLOR : OFF_FOCUS_COLOR,
                opacity: displayingLevel === 1 ? "100%" : "30%",
                color: displayingLevel === 1 ? "#eeeeee" : "#000000",
                ...styles.selectBtn,
              }}
              className="mx-1 select-btn"
              onClick={() => setDisplayingLevel(1)}
            >
              Higher
            </Col>
            <Col
              style={{
                backgroundColor:
                  displayingLevel === 0 ? OL_COLOR : OFF_FOCUS_COLOR,
                opacity: displayingLevel === 0 ? "100%" : "30%",
                color: displayingLevel === 0 ? "#eeeeee" : "#000000",

                ...styles.selectBtn,
              }}
              className="mx-1 select-btn"
              onClick={() => setDisplayingLevel(0)}
            >
              Ordindary
            </Col>
            {mathsGradeFound && (
              <Col
                style={{
                  backgroundColor:
                    displayingLevel === 2 ? LCVP_COLOR : OFF_FOCUS_COLOR,
                  opacity: displayingLevel === 2 ? "100%" : "30%",
                  color: displayingLevel === 2 ? "#eeeeee" : "#000000",

                  ...styles.selectBtn,
                }}
                className="mx-1 select-btn"
                onClick={() => setDisplayingLevel(2)}
              >
                LCVP
              </Col>
            )}
          </Row>
        </Container>
        <Container className="text-light">
          {displayingLevel === 2
            ? mathsGradeFound &&
              BUTTON_LABELS[2].map((label, index) => {
                return (
                  <Row key={label + "-lcvp"}>
                    <Col
                      xs={8}
                      className="m-1 mx-0 calc-button fs-5 fw-bold d-flex align-items-center flex-column"
                      style={styles.btn}
                      value={label}
                      onClick={handleClick}
                    >
                      <div className="mx-auto mt-4">{label}</div>
                      <div
                        className="fw-normal fs-6 fst-italic"
                        style={{ opacity: "80%" }}
                      >
                        +{POINTS_LABELS[displayingLevel][index]} points
                      </div>
                    </Col>
                    {!(label === "Pass") ? (
                      <Col></Col>
                    ) : (
                      <Col
                        onClick={handleRestart}
                        style={styles.restartBtn}
                        className="m-1 calc-button fs-5 fw-bold d-flex align-items-center"
                      >
                        <div className="mx-auto">RESTART</div>
                      </Col>
                    )}
                  </Row>
                );
              })
            : [0, 1, 2].map((j) => {
                return (
                  <Row key={j + "-row"}>
                    {ROWS_ARR[j].map((i) => {
                      if (i === 8) {
                        return (
                          <Col
                            key={i + "-btn"}
                            onClick={handleRestart}
                            style={styles.restartBtn}
                            className="m-1 calc-button fs-5 fw-bold d-flex align-items-center"
                          >
                            <div className="mx-auto">RESTART</div>
                          </Col>
                        );
                      }
                      return (
                        <Col
                          key={i + "-btn"}
                          className="m-1 calc-button fs-5 fw-bold d-flex align-items-center flex-column"
                          style={styles.btn}
                          value={BUTTON_LABELS[displayingLevel][i]}
                          onClick={handleClick}
                        >
                          <div className="mx-auto mt-2">
                            {BUTTON_LABELS[displayingLevel][i]}
                          </div>
                          <div
                            className="fw-normal fs-6 fst-italic"
                            style={{ opacity: "80%" }}
                          >
                            {GRADE_LABELS[i]}
                          </div>
                          <div
                            className="fw-normal fst-italic"
                            style={{ opacity: "90%", fontSize: "14px" }}
                          >
                            {/* Display the bonus 25 points for higher level maths only & not if grade is H7 or H8 */}
                            {mathsGradeFound
                              ? `
                                +${POINTS_LABELS[displayingLevel][i]} points
                                `
                              : displayingLevel === 1 &&
                                BUTTON_LABELS[displayingLevel][i] !== "H8" &&
                                BUTTON_LABELS[displayingLevel][i] !== "H7"
                              ? `+${
                                  POINTS_LABELS[displayingLevel][i] + 25
                                } points`
                              : `
                                  +${POINTS_LABELS[displayingLevel][i]} points
                                  `}
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                );
              })}
        </Container>
      </div>
    </>
  );
}

export default CAOCalculatorComponent;
