import { httpsCallable } from "firebase/functions"
import { functions } from "../firebase"
import { userDataFromContact } from "../interfaces/allContactData"


// redo this function so it gets tutor infor or student info
const getUserEmailAndPhone = async (userToGetUid: string, contactUid: string, requesterAccountType: "student" | "tutor") => {
  
  if(requesterAccountType === "student"){
    // get tutor info
    const getTutorDataFromContact = httpsCallable(functions, 'getTutorDataFromContact')
    try{
      const result = await getTutorDataFromContact({ tutorUid: userToGetUid, contactUid })
      // includes email, and optional phone number
      return result.data as userDataFromContact
    }
    catch(err: any){
      // console.log('Error getting tutor data: ', err)
      throw new Error(err.message?.replace('FirebaseError: ', '') || "An error occured while getting tutor data. Please try again later.")
    }
  }
  else if(requesterAccountType === "tutor"){
    // get student info
    const getStudentDataFromContact = httpsCallable(functions, 'getStudentDataFromContact')
    try{
      const result = await getStudentDataFromContact({ studentUid: userToGetUid, contactUid })
      // includes email, and optional phone number
      return result.data as userDataFromContact
    }
    catch(err: any){
      // console.log('Error getting student data: ', err)
      throw new Error(err.message?.replace('FirebaseError: ', '') || "An error occured while getting student data. Please try again later.")
    }
  }
  console.log("Trying to get user email")
}

export default getUserEmailAndPhone