import { OverlayTrigger, Popover } from "react-bootstrap";
import { AiFillQuestionCircle } from "react-icons/ai";
import { FiCheckCircle } from "react-icons/fi";
import { Link } from "react-router-dom";

type Props = {
  planName: string;
  planPrice: string;
  features: Array<string>;
  featuresDesc: Array<string>;
  accountType: string;
  buttonText: string;
};

const PricingCard = ({
  planName,
  planPrice,
  features,
  featuresDesc,
  accountType,
  buttonText,
}: Props) => {
  const styles = {
    pop: {
      borderRadius: "10px",
      backgroundColor: "#f9f9f9",
      // : "justify",
    },
  };

  return (
    <div
      className="p-3 bg-white shadow mx-auto my-3 rounded w-100 d-flex flex-column justify-content-between"
      style={{ maxWidth: 350 }}>
      <div>
        <h4 className="header-font text-center d-flex justify-content-between">
          {planName}
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover>
                <Popover.Body
                  className="paragraph-font text-light bg-dark"
                  style={styles.pop}>
                  Tap on the green check mark to read more about each feature.
                </Popover.Body>
              </Popover>
            }>
            <div>
              <AiFillQuestionCircle className="my-auto" />
            </div>
          </OverlayTrigger>
        </h4>
        <hr />
      </div>
      <ul
        style={{
          listStyleType: "none",
          margin: 0,
          padding: 0,
        }}
        className="mb-3 mt-1">
        {features.map((feature, idx) => (
          <li className="my-3 d-flex" style={{ fontSize: "18px" }} key={idx}>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Popover>
                  <Popover.Body
                    className="light-font text-light bg-dark"
                    style={styles.pop}>
                    {featuresDesc[idx]}
                  </Popover.Body>
                </Popover>
              }>
              <div>
                <FiCheckCircle className="text-success me-2 my-auto" />
              </div>
            </OverlayTrigger>
            {feature}
          </li>
        ))}
      </ul>

      <div className="mb-2 d-flex">
        <Link
          className="w-100 bg-primary py-2 rounded text-center fs-5 text-light hover-btn-stdnt"
          to={"/sign-up?account=" + accountType}
          style={{
            textDecoration: "none",
            backgroundImage: "linear-gradient(to right,#8ac1f1,#0275d8)",
          }}>
          {buttonText}
        </Link>
      </div>
    </div>
  );
};

export default PricingCard;
