import { useEffect, useState } from "react";
import {
  Alert,
  Container,
  Dropdown,
  Image,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { useSearchParams } from "react-router-dom";

import { BsBookFill, BsFillPersonFill } from "react-icons/bs";
import { FaGraduationCap } from "react-icons/fa";
import { AiFillMessage, AiFillUnlock } from "react-icons/ai";
import ChangeProfileImageModal from "../../components/dashboard/ChangeProfileImageModal";
import PersonalDetailsForm from "../../components/dashboard/PersonalDetailsForm";
import QualificationsForm from "../../components/dashboard/QualificationsForm";
import TutorSubjectsForm from "../../components/dashboard/TutorSubjectsForm";
import AccountDetailsForm from "../../components/dashboard/AccountDetailsForm";
// import MyStudentAdsForm from "../../components/dashboard/MyStudentAdsForm";
import ContactsSection from "../../components/dashboard/ContactsSection";
import OtherInfoSection from "../../components/dashboard/OtherInfoSection";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { updateProfile } from "firebase/auth";
import LoadingSpinner from "../../components/reuseables/LoadingSpinner";

// Interfaces
import studentUserData from "../../interfaces/studentUserData";
import tutorUserData from "../../interfaces/tutorUserData";
import tutorAboutInterface from "../../interfaces/tutorAboutInterface";
import tutorQualsInterface from "../../interfaces/tutorQualsInterface";
import MyNavbar from "../../components/reuseables/MyNavBar";
import SEO from "../../components/utils/SEO";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import getUserDoc from "../../requests/getUserDoc";

export const sortObjectArray = (
  a: tutorQualsInterface,
  b: tutorQualsInterface
) => {
  if (a.endDate === "present") {
    return b.endDate === "present" ? -1 : 1;
  } else if (b.endDate === "present") {
    return -1;
  } else {
    const [aStartMonth, aStartYear] = a.startDate.split("-");
    const [bStartMonth, bStartYear] = b.startDate.split("-");
    if (aStartYear === bStartYear) {
      if (aStartMonth === bStartMonth) {
        const [aEndMonth, aEndYear] = a.endDate.split("-");
        const [bEndMonth, bEndYear] = b.endDate.split("-");
        return (
          Number(aEndYear) - Number(bEndYear) ||
          Number(aEndMonth) - Number(bEndMonth)
        );
      }
      return Number(aStartMonth) - Number(bStartMonth);
    }
    return Number(aStartYear) - Number(bStartYear);
  }
};

const EditDetailsPage = () => {
  const queryClient = useQueryClient();
  const { currentUser, accountType, profileImageSrc, userPrivateData } =
    useAuth();

  const [formToEdit, setFormToEdit] = useState("personal-details");
  // const [userData, setUserData] = useState<
  //   studentUserData | tutorUserData | null
  // >(null);

  const [tutorAbout, setTutorAbout] = useState<tutorAboutInterface | null>(
    null
  );
  const [tutorQuals, setTutorQuals] =
    useState<Array<tutorQualsInterface> | null>(null);

  const [loadingData, setLoadingData] = useState(true);
  const [searchParams] = useSearchParams();

  const {
    data: userDataDoc,
    isLoading: loadingUserDataDoc,
    isError: errorUserDataDoc,
  } = useQuery(
    ["userDataDoc", currentUser?.uid],
    () => getUserDoc(currentUser?.uid, accountType),
    {
      staleTime: 1000 * 60 * 60,
      // retry: false,
      enabled: !!currentUser && !!accountType,
    }
  );

  const { data: pendingContactsCount, isLoading: loadingPendingContactsCount } =
    useQuery(
      ["pendingContactsCount", currentUser?.uid],
      async () => {
        const contactsRef = query(
          collection(db, "contacts"),
          where("responded", "==", false),
          where("tutorUid", "==", currentUser?.uid)
        );

        const pendingContactsSnapshot = await getDocs(contactsRef);
        console.log(pendingContactsSnapshot.size);
        return pendingContactsSnapshot.size;
      },
      {
        staleTime: 1000 * 60 * 60,
        enabled: !!currentUser,
      }
    );

  useEffect(() => {
    const getData = async () => {
      // get account details from firestore
      if (currentUser && accountType) {
        if (accountType === "tutor") {
          const tutorAboutsRef = doc(db, "tutorAbouts", currentUser.uid);

          const tutorQualsRef = collection(
            db,
            "tutors/",
            currentUser.uid,
            "qualifications"
          );

          // const tutorSubjectsRef = doc(db, "tutorSubjects", currentUser.uid);

          // Tutor Qualifications
          const tutorQualsUnsub = onSnapshot(tutorQualsRef, (snapshot) => {
            const quals: Array<tutorQualsInterface> = [];
            if (!snapshot.empty) {
              snapshot.forEach((doc) => {
                quals.push(doc.data() as tutorQualsInterface);
              });
              quals.sort(sortObjectArray);
              setTutorQuals(quals);
            } else {
              setTutorQuals(null);
            }
          });

          // Tutor About section
          const tutorAboutDoc = await getDoc(tutorAboutsRef);
          if (tutorAboutDoc.exists()) {
            setTutorAbout(tutorAboutDoc.data() as tutorAboutInterface);
          }

          return () => {
            // unsubscribe from firestore
            // userPublicDocUnsub();
            tutorQualsUnsub();
          };
        }
      }
    };
    getData().then(() => {
      // console.log("data read");
      setLoadingData(false);
    });
  }, [accountType, currentUser]);

  useEffect(() => {
    // get parameters of which button clicked to get to dashboard page
    switch (searchParams.get("location")) {
      case "my-student-ads":
        if (accountType === "student") {
          setFormToEdit("my-student-ads");
        }
        break;
      case "contacts":
        setFormToEdit("contacts");
        break;
      default:
        setFormToEdit("personal-details");
        break;
    }
  }, [searchParams, accountType]);

  // this function is the callback from the personal-details
  const setUserDataCallback = async (
    data: studentUserData | tutorUserData,
    tutorAbout: tutorAboutInterface
  ) => {
    if (currentUser && accountType === "student") {
      const userRef = doc(db, accountType + "s/", currentUser.uid);
      await setDoc(
        userRef,
        {
          firstName: data.firstName || userDataDoc?.firstName,
          lastName: data.lastName || userDataDoc?.lastName,
          displayName: data.displayName || userDataDoc?.displayName,
        },
        { merge: true }
      );
      // update cache here
      queryClient.setQueryData(["userDataDoc", currentUser?.uid], {
        ...userDataDoc,
        firstName: data.firstName || userDataDoc?.firstName,
        lastName: data.lastName || userDataDoc?.lastName,
        displayName: data.displayName || userDataDoc?.displayName,
      });

      // setUserData({
      //   ...userData,
      //   firstName: data.firstName,
      //   lastName: data.lastName,
      //   displayName: data.displayName,
      // });
      updateProfile(currentUser, { displayName: data.displayName });
    } else if (currentUser && accountType === "tutor") {
      const userRef = doc(db, accountType + "s/", currentUser.uid);
      await setDoc(
        userRef,
        {
          firstName: data.firstName,
          lastName: data.lastName,
          displayName: data.displayName,
          subheading: data.subheading,
          currentlyBookedUp: data.currentlyBookedUp,
          offersFreeConsultation: data.offersFreeConsultation,
          locationOnline: data.locationOnline,
          locationStudentsPlace: data.locationStudentsPlace,
          locationTutorsPlace: data.locationTutorsPlace,
          displayProfile: data.displayProfile,
          inPersonArea: data.inPersonArea,
          inPersonCounty: data.inPersonCounty,
          travelRadius: data.travelRadius,
          availability: data.availability,
        },
        { merge: true }
      );

      const tutorAboutRef = doc(db, "tutorAbouts/", currentUser.uid);
      await setDoc(
        tutorAboutRef,
        {
          aboutSection: tutorAbout.aboutSection,
        },
        { merge: true }
      );

      // update cache here
      queryClient.setQueryData(["userDataDoc", currentUser?.uid], {
        ...userDataDoc,
        firstName: data.firstName,
        lastName: data.lastName,
        displayName: data.displayName,
        subheading: data.subheading,
        currentlyBookedUp: data.currentlyBookedUp,
        offersFreeConsultation: data.offersFreeConsultation,
        locationOnline: data.locationOnline,
        locationStudentsPlace: data.locationStudentsPlace,
        locationTutorsPlace: data.locationTutorsPlace,
        displayProfile: data.displayProfile,
        inPersonArea: data.inPersonArea,
        inPersonCounty: data.inPersonCounty,
        travelRadius: data.travelRadius,
        availability: data.availability,
      });

      // setUserData({
      //   ...userData,
      //   firstName: data.firstName,
      //   lastName: data.lastName,
      //   displayName: data.displayName,
      //   subheading: data.subheading,
      //   currentlyBookedUp: data.currentlyBookedUp,
      //   offersFreeConsultation: data.offersFreeConsultation,
      //   locationOnline: data.locationOnline,
      //   locationStudentsPlace: data.locationStudentsPlace,
      //   locationTutorsPlace: data.locationTutorsPlace,
      //   displayProfile: data.displayProfile,
      //   inPersonArea: data.inPersonArea,
      //   inPersonCounty: data.inPersonCounty,
      //   travelRadius: data.travelRadius,
      //   availability: data.availability,
      // });
      setTutorAbout({ aboutSection: tutorAbout.aboutSection });
      updateProfile(currentUser, { displayName: data.displayName });
    }
  };

  // This is the tab on the side of the "dashboard" page - EditDetailsPage.tsx
  const SelectDetailsTab = ({
    className,
    style,
  }: {
    className: string;
    style: object;
  }) => {
    return (
      <div className={className} style={style}>
        <div
          className="mt-2 mb-4 bg-white py-3 shadow rounded mx-auto"
          style={{ position: "sticky", top: 110 }}
        >
          <div className="text-center text-dark mb-3 header-font">
            {accountType &&
              accountType?.charAt(0).toUpperCase() + accountType?.slice(1)}{" "}
            Account
            <br />
            <span className="paragraph-font">{userDataDoc?.displayName}</span>
          </div>
          {/* Image section of select edit details */}
          <div className="text-center">
            <Image
              roundedCircle
              src={profileImageSrc}
              // referrerPolicy="no-referrer"
              // className="rounded-circle"
              alt="Profile Failed to load"
              style={{
                width: "150px",
                height: "150px",
                objectFit: "cover",
                maxWidth: "150px",
              }}
            />
            <div
              style={{ color: "#777777", fontSize: "14px" }}
              className="mx-5 my-2"
            >
              Your profile image must be in PNG, JPEG or JPG format, and should
              not be larger than 2 MB.
            </div>
            <ChangeProfileImageModal />
          </div>
          {accountTypeList()}
        </div>
      </div>
    );
  };

  const accountTypeList = () => {
    switch (accountType) {
      case "tutor":
        return (
          <>
            <ListGroup variant="flush">
              <ListGroup.Item></ListGroup.Item>
              <ListGroup.Item
                variant="light"
                action
                active={formToEdit === "personal-details"}
                onClick={() => setFormToEdit("personal-details")}
              >
                <BsFillPersonFill className="me-2" />
                My Details
              </ListGroup.Item>
              <ListGroup.Item
                variant="light"
                action
                className="d-flex"
                active={formToEdit === "contacts"}
                onClick={() => setFormToEdit("contacts")}
              >
                <AiFillMessage className="me-2 my-auto" />
                My Contacts
                {pendingContactsCount && pendingContactsCount > 0 ? (
                  <span className="ms-auto rounded bg-danger text-white px-2">
                    {pendingContactsCount}
                  </span>
                ) : //For some reason, the null with ternary operator must be used
                // instead of being able to do: pendingContactsCount > 0 && <Component/>
                null}
              </ListGroup.Item>
              <ListGroup.Item
                variant="light"
                action
                active={formToEdit === "qualifications"}
                onClick={() => setFormToEdit("qualifications")}
              >
                <FaGraduationCap className="me-2" />
                My Qualifications
              </ListGroup.Item>
              <ListGroup.Item
                variant="light"
                action
                active={formToEdit === "subjects"}
                onClick={() => setFormToEdit("subjects")}
              >
                <BsBookFill className="me-2" />
                My Subjects
              </ListGroup.Item>
              <Dropdown.Divider />

              <ListGroup.Item
                variant="light"
                action
                active={formToEdit === "account-details"}
                onClick={() => setFormToEdit("account-details")}
              >
                <AiFillUnlock className="me-2" />
                Account Details
              </ListGroup.Item>
              <Dropdown.Divider />
            </ListGroup>
          </>
        );
      case "student":
        return (
          <>
            <ListGroup variant="flush">
              <ListGroup.Item></ListGroup.Item>
              <ListGroup.Item
                variant="light"
                action
                active={formToEdit === "personal-details"}
                onClick={() => setFormToEdit("personal-details")}
              >
                <BsFillPersonFill className="me-2" />
                My Details
              </ListGroup.Item>
              <Dropdown.Divider />

              {/* <ListGroup.Item
                variant="light"
                action
                active={formToEdit === "my-student-ads"}
                onClick={() => setFormToEdit("my-student-ads")}
              >
                <BsFillMegaphoneFill className="me-2" />
                My Student Ads
              </ListGroup.Item> */}
              <ListGroup.Item
                variant="light"
                action
                active={formToEdit === "contacts"}
                onClick={() => setFormToEdit("contacts")}
              >
                <AiFillMessage className="me-2" />
                Tutors Contacted
              </ListGroup.Item>
              <ListGroup.Item
                variant="light"
                action
                active={formToEdit === "account-details"}
                onClick={() => setFormToEdit("account-details")}
              >
                <AiFillUnlock className="me-2" />
                Account Details
              </ListGroup.Item>
              <Dropdown.Divider />
            </ListGroup>
          </>
        );
      default:
        return;
    }
  };

  const formToDisplay = () => {
    switch (formToEdit) {
      case "personal-details":
        if (loadingUserDataDoc) return <Spinner className="mx-auto my-3" />;
        if (errorUserDataDoc)
          return <Alert variant="danger">Error loading data</Alert>;
        else if (userDataDoc) {
          return (
            <PersonalDetailsForm
              userData={userDataDoc}
              setUserDataCallback={setUserDataCallback}
              tutorAbout={tutorAbout}
            />
          );
        } else return <Alert variant="danger">Error loading data</Alert>;

      case "qualifications":
        return <QualificationsForm tutorQuals={tutorQuals} />;
      case "subjects":
        return <TutorSubjectsForm tutorData={userDataDoc as tutorUserData} />;
      // case "my-student-ads":
      //   return <MyStudentAdsForm />;
      case "contacts":
        // error with react-query getting contacts if there is not accountType
        // usually if going straight to "my contacts" section, it'll throw error
        return accountType ? <ContactsSection /> : null;
      case "account-details":
        return (
          userPrivateData && (
            <AccountDetailsForm privateData={userPrivateData} />
          )
        );
      default:
        return (
          <Alert variant="warning" className="border-warning shadow">
            No section selected. Please select one from the side menu.
          </Alert>
        );
    }
  };

  return (
    <>
      <SEO
        title={currentUser?.displayName || "MH1 Tutors"}
        description="Sign up as a student on MH1 Tutors and find the perfect tutor in any subject. Join as a tutor to advertise your services to students completely free."
        keywords="Grinds Ireland,Maths Grinds,Find Grinds Tutors,Become a Grinds Tutor,Leaving Cert, Junior Cert, College Grinds, Primary School Grinds"
        robots="noindex"
        ogTitle="MH1 Tutors"
        ogUrl="https://www.mh1-tutors.com/dashboard"
        ogSiteName="MH1 Tutors"
        ogImage="https://firebasestorage.googleapis.com/v0/b/mh1-tutors-prod.appspot.com/o/websiteImages%2FLogo.png?alt=media&token=6e6b79d8-87db-45b1-840d-919623cbc159"
        ogImageType="image/png"
      />
      <MyNavbar />
      <Container className="edit-details-body row mx-auto my-2" fluid>
        <SelectDetailsTab
          className="col-sm-12 col-md-4 side-tab-sticky-md"
          style={{}}
        />
        <div className="col-md-8 col-sm-12 mb-4">
          <div className="mt-md-2">
            <div className="bg-white mb-4 shadow p-3 rounded">
              {userPrivateData && (
                <OtherInfoSection privateData={userPrivateData} />
              )}
            </div>
            <div className="bg-white shadow p-3 rounded">
              {loadingData ? <LoadingSpinner /> : formToDisplay()}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default EditDetailsPage;
