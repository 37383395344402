import { getDocs, collection, where, query } from "firebase/firestore";
import { db } from "../firebase";
import tutorQualsInterface from "../interfaces/tutorQualsInterface";
import { sortObjectArray } from "../pages/protected_pages/EditDetailsPage";


export default async function getTutorLatestQual (tutorUid: string) {
  
  // try to find a qualification that has "endDate" == "present"
  // if none found, then read all and return most recent

  const presentQualRef = query(collection(db, "tutors", tutorUid, "qualifications"), where("endDate", "==", "present"))

  const presentQualSnap = await getDocs(presentQualRef)

  // if end date is present, then return the most recet with that
  if(!presentQualSnap.empty){
    const sortedQuals = presentQualSnap.docs
    .map(doc => {
        const data = doc.data();
        const [month, year] = data.startDate.split('-').map(Number);
        return {
            ...data,
            startDateTime: new Date(year, month - 1)  // JS month is 0-indexed
        };
    })
    .sort((a, b) => b.startDateTime.getTime() - a.startDateTime.getTime());  // Sort ascending for start date
    // console.log(sortedQuals[0])
    return sortedQuals[0] as tutorQualsInterface
  }

  // other wise, get all and return most recent

  const tutorQualsPromise = getDocs(
    collection(db, "tutors", tutorUid, "qualifications")
  );
    try{
      const tutorQualsSnap = await tutorQualsPromise
      let month: number;
      let year: number;
      if (tutorQualsSnap.empty) {
        return null;
      }else {
        const tutorQuals = tutorQualsSnap.docs.map(doc => {
          const data = doc.data();
          [month, year] = data.startDate.split('-').map(Number);
          return {
              ...data,
              startDateTime: new Date(year, month - 1)  // JS month is 0-indexed
          }as tutorQualsInterface;
      }).sort((a, b) => b.startDateTime!.getTime() - a.startDateTime!.getTime());  // Sort ascending for start date
        return tutorQuals[0] as tutorQualsInterface
      }
    } catch (error) {
      return null
    }
}
