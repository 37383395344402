// import { RefObject, useEffect, useState } from "react";
// import { useDebouncedCallback } from "use-debounce";
import FadeInHeader from "../reuseables/FadeInHeader";
import SignUpBenefits from "./SignUpBenefits";
import {
  FcCalendar,
  FcGraduationCap,
  FcIdea,
  FcMoneyTransfer,
  FcReading,
  FcWorkflow,
} from "react-icons/fc";

interface Props {
  accountType: string;
}

const TabComponentBenefits = ({ accountType }: Props) => {
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // // Wait 200ms before setting size of window
  // const handleResize = useDebouncedCallback(() => {
  //   setWindowWidth(window.innerWidth);
  // }, 100);

  // useEffect(() => {
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, [handleResize]);

  // return component here
  const accountTypeToReturn = () => {
    switch (accountType) {
      case "tutor":
        return (
          // If tutor card selected
          <div className="fadeInOut">
            <FadeInHeader text="Make a Difference in the Lives of Students" />
            <SignUpBenefits
              texts={[
                "Share your passion for teaching and positively impact students' education.",
                "Enjoy the freedom to work on your own schedule and never feel constrained by rigid work hours again.",
                "Whether you're seeking to replace a part-time job or simply looking to earn extra income, tutoring provides a rewarding opportunity to achieve your financial goals.",
              ]}
              titles={[
                "Impact Students Positively",
                "Work Flexibly",
                "Earn Extra Income",
              ]}
              icons={[<FcIdea />, <FcWorkflow />, <FcMoneyTransfer />]}
            />
          </div>
        );
      default:
        return (
          // If student card selected
          <div className="fadeInOut">
            <FadeInHeader
              text="You're one step away from unlocking your learning potential
                  with the help of a dedicated tutor"
            />
            <SignUpBenefits
              texts={[
                "1-on-1 tutoring provides the most personalised learning, allowing the tutor to focus solely on the student's needs and adjust their teaching style accordingly.",
                "Over 80% of the tutors are college students and fresh graduates, so they are experts in their fields and charge lower prices than private grinds agencies.",
                "Decide on a time that suits you and your tutor. You can work around your schedule and never worry about missing a class again.",
              ]}
              titles={[
                "Personalised Learning",
                "Affordable Tutors",
                "Flexibility",
              ]}
              icons={[<FcReading />, <FcGraduationCap />, <FcCalendar />]}
            />
          </div>
        );
    }
  };
  return <div className="mt-5">{accountTypeToReturn()}</div>;
};

export default TabComponentBenefits;
