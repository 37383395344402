import { Alert, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import privateUserDataInterface from "../../interfaces/privateUserDataInterface";
import { formatFirebaseTimestamp } from "../utils/ToolingFunctions";

const OtherInfoSection = ({
  privateData,
}: {
  privateData: privateUserDataInterface;
}) => {
  const { accountType } = useAuth();
  if (accountType === "tutor") {
    return (
      <div className="row">
        <div className="col-5">
          Current Plan:{" "}
          <OverlayTrigger
            placement="top"
            delay={{ show: 0, hide: 200 }}
            overlay={
              <Tooltip>
                The option to upgrade your plan will be coming soon!
              </Tooltip>
            }
          >
            <span
              className="header-font fs-5 ms-md-1"
              style={{ color: "#e56220" }}
            >
              {privateData.subscriptionPlan.charAt(0).toUpperCase() +
                privateData.subscriptionPlan.slice(1)}
            </span>
          </OverlayTrigger>
        </div>
        <div className="col-7 d-flex flex-column align-items-end justify-content-end">
          {privateData.freeContactsAsTutorRemaining ? (
            <div className="d-flex">
              <div className="header-font fs-5 me-1">
                {privateData.freeContactsAsTutorRemaining}
              </div>
              <div className="text-start ms-2 my-auto">Contacts Available</div>
            </div>
          ) : null}
          {privateData.nextRenewalDate &&
            privateData.freeContactsAsTutorRemaining < 2 && (
              <div className="light-font" style={{ fontSize: 14 }}>
                Renews on {formatFirebaseTimestamp(privateData.nextRenewalDate)}
              </div>
            )}
        </div>
      </div>
    );
  } else if (accountType === "student") {
    return (
      <div className="row">
        <div className="col-5 d-flex">
          {/* ADDING SOON */}
          {/* <Button className="my-auto">Get More Contacts</Button> */}
        </div>

        <div className="col-7 d-flex flex-column align-items-end justify-content-center">
          <div className="d-flex">
            <div className="header-font fs-5 me-1">
              {privateData.freeMonthlyContactsAsStudentRemaining || 0}
            </div>
            <div className="text-start ms-2 my-auto">Contacts Available</div>
          </div>
          {privateData.nextRenewalDate &&
            privateData.freeMonthlyContactsAsStudentRemaining < 3 && (
              <div className="light-font" style={{ fontSize: 14 }}>
                Renews on {formatFirebaseTimestamp(privateData.nextRenewalDate)}
              </div>
            )}
        </div>
      </div>
    );
  } else {
    return (
      <Alert variant="info">
        Some error has occured. Please refresh, and if this message persists,
        contact us via the contact button.
      </Alert>
    );
  }
};

export default OtherInfoSection;
