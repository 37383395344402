import { Image } from "react-bootstrap";

type Props = {
  src: string;
  alt: string;
  width: number;
  height: number;
  className?: string;
  style?: React.CSSProperties;
};

const RoundedImageFitted = ({
  src,
  alt,
  width,
  height,
  className,
  style,
}: Props) => (
  <Image
    roundedCircle
    className={className}
    alt={alt}
    style={{
      width: width,
      maxWidth: width,
      height: height,
      objectFit: "cover",
      filter: "drop-shadow(0px 3px 5px #bbbbbb)",
      ...style,
    }}
    src={src}
  />
);

export default RoundedImageFitted;
