import { useSearchParams } from "react-router-dom";
import { Alert } from "react-bootstrap";

type bannerRefType = "dcu_open_day" | "dcu_tutor" | string | null;
type Props = {
  bannerRef: bannerRefType;
};

const RefBannerSignUp = ({ bannerRef }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  switch (bannerRef) {
    case "dcu_open_day":
      // if date is after 19 november 2023, return null & remove the ref
      const today = new Date();
      const openDayDate = new Date("November 19, 2023 00:00:00");
      if (today > openDayDate) {
        const newParams = new URLSearchParams(searchParams);
        newParams.delete("ref");
        setSearchParams(newParams);
        return null; // NEED TO TEST THIS
      }
      return (
        <Alert
          variant="warning"
          className="border-warning shadow mx-1 mx-md-5 mt-4 pulse light-font d-flex flex-column flex-md-row justify-content-between"
        >
          <span>
            Welcome to DCU Open Day! By signing up via this link & verifying
            your account, you are automatically entered into our raffle!
            <br />
            <br />
            Make sure you follow our instagram <strong>(@mh1_tutors)</strong> &
            share us on your story for two additional entries to our raffle!
          </span>
          <img
            style={{ maxWidth: 60, maxHeight: 60 }}
            className="shadow mx-auto"
            src="/assets/DCU_Digital_Logo.png"
            alt="DCU Logo"
          />
        </Alert>
      );
    case "dcu_tutor":
      // if dcu tutor, free contact will be given
      return (
        <Alert
          variant="warning"
          className="border-warning shadow mx-1 mx-md-5 mt-4 pulse light-font d-flex flex-column flex-md-row justify-content-between"
        >
          <span>
            Thank you for signing up using the DCU Tutor link! Complete your
            registration now, and your first contact will be completely free!
          </span>
          <img
            style={{ maxWidth: 60, maxHeight: 60 }}
            className="shadow mx-auto"
            src="/assets/DCU_Digital_Logo.png"
          />
        </Alert>
      );

    default:
      return null;
  }
};

export default RefBannerSignUp;
