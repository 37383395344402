import { useRef, useEffect, useState } from "react";

const TruncateTextComponent = ({ text }: { text: string | null }) => {
  const [atBottom, setAtBottom] = useState(false);
  const scrollDiv = useRef<HTMLDivElement>(null);

  const checkScroll = () => {
    if (scrollDiv.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollDiv.current;
      // console.log(scrollTop, scrollHeight, clientHeight);
      const atBottom = scrollTop + clientHeight >= scrollHeight;
      setAtBottom(atBottom);
    }
  };

  useEffect(() => {
    checkScroll();
    if (scrollDiv.current) {
      scrollDiv.current.addEventListener("scroll", checkScroll);
    }
    return () => {
      if (scrollDiv.current) {
        scrollDiv.current.removeEventListener("scroll", checkScroll);
      }
    };
  }, []);

  if (!text) return <></>;

  return (
    <>
      <div
        ref={scrollDiv}
        className="light-font bg-secondary bg-opacity-10 px-2 py-2 rounded-3 shadow-sm thin-scrollbar"
        style={{
          textAlign: "justify",
          maxHeight: 200,
          overflowY: "scroll",
        }}
      >
        {text}
      </div>
      <div
        className={`position-absolute bottom-0 w-100 rounded-bottom ${
          atBottom ? "d-none" : "d-block"
        }`}
        style={{
          height: "2em",
          backgroundImage:
            "linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(235, 235, 235, 0.9) 100%)",
        }}
      ></div>
    </>
  );
};

export default TruncateTextComponent;
