import { Timestamp } from "firebase/firestore";
import { TimestampObject } from "../../interfaces/studentAdsInterface";

/**
 *
 * @param {Timestamp | TimestampObject} timestamp a timestamp objetc, either read directly from Firestore, or returned from a backend function
 *
 * @returns the timestamp as a date string with the month as a word
 */

export const formatFirebaseTimestamp = (
  timestamp: Timestamp | TimestampObject
) => {
  // When reading a timestamp from firestore directly, it comes in as a timestamp

  if (timestamp instanceof Timestamp) {
    return new Date(timestamp.toMillis()).toLocaleDateString("en-IE", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  }
  // When Timestamp object is returned from a backend function
  // it comes in as an objetc
  else if (typeof timestamp === "object") {
    return new Date(
      timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
    ).toLocaleDateString("en-IE", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  }
  // if we messed up somewhere, we'll just print nothing
  else {
    return "";
  }
};

export const getColorOfStudentAdExpireDate = (
  timestamp: Timestamp | TimestampObject
) => {

    let localDate: Date | null = null;

    if (timestamp instanceof Timestamp) {
      localDate = new Date(timestamp.toMillis());
    } else if (typeof timestamp === "object") {
      localDate = new Date(
        timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
      );
    }
  
    return localDate ? getStatus(localDate) : null;
}

function getStatus(localDate: Date) {
  const timeDifference = localDate.getTime() - new Date().getTime();

  // if timeDifference is less than 10 days, return danger
  if (timeDifference < 864000000) {
    return "danger text-opacity-75";
  }
  // if timeDifference is less than 20 days, return warning
  if (timeDifference < 1728000000) {
    return "warning";
  }
  // else return success
  return "success";
}

/**
 * @param e the event that triggered the function - will usually be error event from an image
 * EG. when an image fails to load
 * 
 * @description this function is used to handle errors when loading images
 * It sets the src of the image to a default image
 */
export const handleImageLoadError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
  const target = e.target as HTMLImageElement;
  target.onerror = null;
  target.src = "/assets/no-profile-img1.png";
};

export const formatCountyString = (county: string) => {
  return county.charAt(0).toUpperCase() + county.slice(1);
}

/**
 * 
 * @param array 
 * @returns 
 */
// export const sortTimestampOrTimestampObjectArray = (array: ) => {
//   if(array.length === 0) return array;

//   if (array[0].timestamp instanceof Timestamp) {
//     return array.sort((a, b) => {
//       const timeA = a.timestamp as Timestamp;
//       const timeB = b.timestamp as Timestamp;
//       return timeB.toMillis() - timeA.toMillis();
//     });
//   }else if (typeof array[0] === 'object') {
//     return array.sort((a, b) => {

//       const timeA = a.timestamp as TimestampObject;
//       const timeB = b.timestamp as TimestampObject;      

//       const aMillis = timeA._seconds * 1000 + timeA._nanoseconds / 1000000;
//       const bMillis = timeB._seconds * 1000 + timeB._nanoseconds / 1000000;

//       return bMillis - aMillis;
//     });
//   }else{

//     return array;
//   }
  
// }