import CAOCalcBanner from "../components/cao-calculator/CAOCalcBanner";
import CAOCalcBody from "../components/cao-calculator/CAOCalcBody";
import MyNavbar from "../components/reuseables/MyNavBar";

const CAOCalculator = () => {
  return (
    <>
      <MyNavbar />
      <div
        className="App paragraph-font"
        style={{
          backgroundSize: "cover",
        }}
      >
        <CAOCalcBanner />
        <CAOCalcBody />
      </div>
    </>
  );
};

export default CAOCalculator;
