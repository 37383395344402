import { useState, useEffect } from "react";

const LoadingDots = () => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev.length < 3 ? prev + "." : ""));
    }, 200); // change the speed of the animation by adjusting this value

    return () => clearInterval(interval); // cleanup on unmount
  }, []);

  return <>{dots}</>;
};

export default LoadingDots;
