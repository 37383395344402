type Props = {
  subject: string;
};

const SubjectBubble = ({ subject }: Props) => {
  const formattedSubject = subject.replaceAll("-", " ");
  return (
    <div
      className=" d-flex px-3 py-1 rounded mx-1 my-1 paragraph-font"
      style={{ backgroundColor: "#e9e9e9" }}>
      {formattedSubject}
    </div>
  );
};

export default SubjectBubble;
