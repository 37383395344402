import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import MyNavbar from "../components/reuseables/MyNavBar";
import { Alert, Button, Spinner } from "react-bootstrap";
import { useMutation } from "@tanstack/react-query";
import { EMAIL_REGEX } from "../constants/regex";

const Unsuscribe = () => {
  const [loading, setLoading] = useState(false);
  const [unsubMessage, setUnsubMessage] = useState("");
  const [error, setError] = useState<null | string>(null);
  const [hasRun, setHasRun] = useState(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const mutation = useMutation(
    async (subDocID: string) => {
      setLoading(true);
      const REACT_APP_LOCAL_IP = "localhost";
      const apiUrl =
        process.env.NODE_ENV === "development"
          ? `http://${REACT_APP_LOCAL_IP}:5001/mh1-tutors-dev/europe-west2`
          : process.env.REACT_APP_FIREBASE_API_URL;

      const unsubRes = await fetch(
        `${apiUrl}/unsubscribeMarketing?docID=${subDocID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const msg = await unsubRes.text();
      if (unsubRes.ok) {
        return msg;
      } else {
        throw new Error(msg);
      }
    },
    {
      onSuccess: (data) => {
        // Handle success, e.g., set a success message
        setLoading(false);
        setUnsubMessage(data);
        setHasRun(true);
      },
      onError: (error: unknown) => {
        if (error instanceof Error) {
          setLoading(false);
          setError(error.message);
          setHasRun(true);
        }
      },
    }
  );

  // useEffect(() => {
  //   const email = searchParams.get("email");
  //   if (!email || email === "" || !EMAIL_REGEX.test(email)) {
  //     navigate("/tutors");
  //   }
  // }, [searchParams, navigate]);

  return (
    <>
      <MyNavbar />
      <div className="px-3 py-5 my-5">
        {!hasRun && (
          <div className="w-100 d-flex light-font flex-column">
            <Alert
              variant="warning"
              className="border-warning shadow mx-auto"
              style={{ maxWidth: 600 }}
            >
              To unsubscribe from Marketing Emails, press the button below :
            </Alert>
            <Button
              disabled={loading}
              className="mx-auto"
              style={{ maxWidth: 300 }}
              variant="outline-danger"
              onClick={() => mutation.mutate(searchParams.get("uid") || "")}
            >
              {loading ? (
                <Spinner className="mx-auto my-auto" size="sm" />
              ) : (
                <>Unsubscribe</>
              )}
            </Button>
          </div>
        )}

        {error && (
          <Alert
            style={{ maxWidth: 600 }}
            variant="danger"
            className="shadow border-danger my-4 light-font mx-auto"
          >
            {error}
          </Alert>
        )}
        {unsubMessage && (
          <Alert
            style={{ maxWidth: 600 }}
            variant="success"
            className="shadow border-success my-4 light-font mx-auto"
          >
            {unsubMessage}
          </Alert>
        )}
      </div>
    </>
  );
};

export default Unsuscribe;
