import {
  arrayRemove,
  arrayUnion,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useState, useRef } from "react";
import {
  Alert,
  Button,
  Form,
  InputGroup,
  Modal,
  Spinner,
  Toast,
} from "react-bootstrap";
import { AiOutlineCheckCircle, AiOutlineWarning } from "react-icons/ai";
import { FiX, FiPlus, FiEdit3 } from "react-icons/fi";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase";
// import tutorSubjectsInterface from "../../interfaces/tutorSubjectsInterface";
import tutorUserData from "../../interfaces/tutorUserData";
import { useQueryClient } from "@tanstack/react-query";
import studentUserData from "../../interfaces/studentUserData";

const TutorSubjectsLevel = ({
  level,
  levelDbLabel,
  tutorUserData,
}: {
  level: string;
  levelDbLabel: string;
  tutorUserData: tutorUserData | null;
}) => {
  const { currentUser, websiteReadableInfo } = useAuth();
  const [canEditPrice, setCanEditPrice] = useState<boolean>(false);
  const [priceError, setPriceError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [showAddSubjectModal, setShowAddSubjectModal] = useState(false);
  const newSubjectRef = useRef<HTMLSelectElement | null>(null);
  const [newSubjectError, setNewSubjectError] = useState("");
  const [showSubjectAddedToast, setShowSubjectAddedToast] = useState(false);

  const priceRef = useRef<HTMLInputElement | null>(null);
  const queryClient = useQueryClient();

  const handleShowAddSubjectModal = () => {
    setShowAddSubjectModal(true);
    setNewSubjectError("");
  };

  const allowEditPrice = () => {
    setCanEditPrice(true);
  };

  const subjectChoices = () => {
    if (websiteReadableInfo) {
      const topSubjects = websiteReadableInfo.topSubjects.map((subject) => {
        const formattedSubject = subject.replaceAll("-", " ");
        return (
          <option key={"top-" + subject} value={subject}>
            {formattedSubject}
          </option>
        );
      });
      const restOfSubejcts = websiteReadableInfo.subjectsAvailable.map(
        (subject) => {
          const formattedSubject = subject.replaceAll("-", " ");
          return (
            <option key={"rest-" + subject} value={subject}>
              {formattedSubject}
            </option>
          );
        }
      );
      return [
        <option value="" key="default" className="text-muted">
          Select Subject
        </option>,
        ...topSubjects,
        <option key="divider" disabled>
          {" "}
        </option>,
        ...restOfSubejcts,
      ];
    } else {
      return [];
    }
  };

  const savePrice = async () => {
    setPriceError("");
    setLoading(true);
    if (priceRef.current) {
      const priceToSet = parseInt(priceRef.current.value);
      if (isNaN(priceToSet)) {
        priceRef.current.value =
          (tutorUserData && String(tutorUserData[levelDbLabel + "Price"])) ||
          "N/A";
        setLoading(false);
        setCanEditPrice(false);
        return setPriceError("Please input a number");
      } else {
        // console.log(levelDbLabel + " Price = " + priceToSet);

        // Save to Db here
        const tutorSubjectsRef = doc(db, "tutors", currentUser!.uid);

        try {
          await setDoc(
            tutorSubjectsRef,
            { [levelDbLabel + "Price"]: priceToSet },
            { merge: true }
          );
        } catch (err: any) {
          setLoading(false);
          return setPriceError(err.message?.replace("Firebase:", ""));
        }
        priceRef.current.value = String(priceToSet);
        setPriceError("");
        setCanEditPrice(false);
        // update cache
        queryClient.setQueryData(
          ["userDataDoc", currentUser!.uid],
          (oldData: tutorUserData | studentUserData | undefined | null) => {
            if (oldData) {
              return {
                ...oldData,
                [levelDbLabel + "Price"]: priceToSet,
              };
            } else {
              return oldData;
            }
          }
        );

        return setLoading(false);
      }
    } else {
      setCanEditPrice(false);
      return setLoading(false);
    }
  };

  const addSubject = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    // CHECK IF SUBJECT ALREADY EXISTS
    if (
      tutorUserData &&
      tutorUserData[levelDbLabel + "Subjects"] &&
      tutorUserData[levelDbLabel + "Subjects"].includes(
        newSubjectRef.current!.value
      )
    ) {
      const form = e.target as HTMLFormElement;
      form.reset();
      setLoading(false);
      return setNewSubjectError("You already have this subject set.");
    }
    // Don't Allow more than 6 subjects -> this is also checked for on server
    // but adding this just so it let's user know why it fails
    if (
      tutorUserData &&
      tutorUserData[levelDbLabel + "Subjects"] &&
      tutorUserData[levelDbLabel + "Subjects"].length >= 6
    ) {
      const form = e.target as HTMLFormElement;
      form.reset();
      setLoading(false);
      return setNewSubjectError(
        "You can't have more than 6 subjects per level."
      );
    }
    try {
      await setDoc(
        doc(db, "tutors", currentUser!.uid),
        {
          [levelDbLabel + "Subjects"]: arrayUnion(newSubjectRef.current!.value),
        },
        { merge: true }
      );
      // update cache
      queryClient.setQueryData(
        ["userDataDoc", currentUser!.uid],
        (oldData: tutorUserData | studentUserData | undefined | null) => {
          if (oldData) {
            console.log(oldData);

            // if oldData didnt exist
            if (!oldData[levelDbLabel + "Subjects"]) {
              return {
                ...oldData,
                [levelDbLabel + "Subjects"]: [newSubjectRef.current!.value],
              };
            }

            return {
              ...oldData,
              [levelDbLabel + "Subjects"]: [
                ...oldData[levelDbLabel + "Subjects"],
                newSubjectRef.current!.value,
              ],
            };
          } else {
            return oldData;
          }
        }
      );
    } catch (err: any) {
      const form = e.target as HTMLFormElement;
      form.reset();
      setLoading(false);
      // console.log(err.message);
      return setNewSubjectError(err.message?.replace("Firebase:", " "));
    }
    setShowSubjectAddedToast(true);
    setNewSubjectError("");
    const form = e.target as HTMLFormElement;
    form.reset();
    setLoading(false);
  };

  const deleteSubject = async (subject: string) => {
    setPriceError("");
    try {
      await updateDoc(doc(db, "tutors", currentUser!.uid), {
        [levelDbLabel + "Subjects"]: arrayRemove(subject),
      });

      // update cache
      queryClient.setQueryData(
        ["userDataDoc", currentUser!.uid],
        (oldData: tutorUserData | studentUserData | undefined | null) => {
          if (oldData) {
            return {
              ...oldData,
              [levelDbLabel + "Subjects"]: oldData[
                levelDbLabel + "Subjects"
              ].filter((sub: string) => sub !== subject),
            };
          }
        }
      );
    } catch (err: any) {
      setLoading(false);
      // console.log(err);
      // Didnt set a new state for "delete subject" because "priceError"
      // will pop up above the subjects so should have same informative effect
      return setPriceError(err.message?.replace("Firebase:", " "));
    }
  };

  const subjectBubbles = () => {
    if (
      tutorUserData &&
      tutorUserData[levelDbLabel + "Subjects"] &&
      tutorUserData[levelDbLabel + "Subjects"].length > 0
    ) {
      return (
        <>
          {tutorUserData[levelDbLabel + "Subjects"].map((subject: string) => {
            const formattedSubject = subject.replaceAll("-", " ");
            return (
              <div
                className="subject-bubble d-flex me-3 ps-3 py-1 rounded my-2"
                key={tutorUserData[levelDbLabel + "Subjects"] + subject}
                style={{ backgroundColor: "#f1f1f1" }}
              >
                {formattedSubject}
                <FiX
                  className="subject-bubble-icon my-auto mx-2 cursor-pointer"
                  onClick={() => deleteSubject(subject)}
                />
              </div>
            );
          })}
        </>
      );
    } else {
      return (
        <Alert variant="secondary" className="w-100">
          <AiOutlineWarning className="text-dark fs-4 me-2 my-auto" />
          You don't have any {level} subjects set.
        </Alert>
      );
    }
  };

  return (
    <>
      <div>
        <div className="my-2 d-flex justify-content-between">
          <div className="header-font fs-5">{level}</div>
          <div
            style={{
              fontSize: "14px",
              color: "#1DA1F2",
              letterSpacing: "0.04rem",
            }}
            onClick={handleShowAddSubjectModal}
            className="header-font d-flex float-right cursor-pointer my-auto"
          >
            <FiPlus className="my-auto fs-6" />
            Add New Subject
          </div>
        </div>
        <div className="d-flex mb-3">
          <InputGroup size="sm" style={{ width: "75%", maxWidth: "200px" }}>
            <InputGroup.Text>€</InputGroup.Text>
            <Form.Control
              ref={priceRef}
              type="text"
              inputMode="numeric"
              defaultValue={
                (tutorUserData && tutorUserData[levelDbLabel + "Price"]) ||
                "N/A"
              }
              disabled={!canEditPrice}
            />
            <InputGroup.Text> per class</InputGroup.Text>
          </InputGroup>
          {canEditPrice ? (
            <Button
              size="sm"
              variant="success"
              onClick={savePrice}
              className="mx-1 d-flex"
            >
              <AiOutlineCheckCircle className="me-2 my-auto" />
              {loading ? <Spinner size="sm" /> : "Save"}
            </Button>
          ) : (
            <FiEdit3
              onClick={allowEditPrice}
              className="ms-2 my-auto cursor-pointer text-success"
              style={{ fontSize: "18px" }}
            />
          )}
        </div>
        {priceError && (
          <Alert variant="danger" className="py-1">
            {priceError}
          </Alert>
        )}
        {(!tutorUserData || !tutorUserData[levelDbLabel + "Price"]) && (
          <Alert variant="warning">
            <AiOutlineWarning className="text-danger fs-4 me-2 my-auto" />
            You don't have a price set for {level} subjects. You can set your
            price by pressing on the green edit button.
          </Alert>
        )}
        <div>
          <div
            className="d-flex justify-content-start mt-2"
            style={{ flexFlow: "row wrap" }}
          >
            {subjectBubbles()}
          </div>
        </div>
      </div>
      <hr className="dashed" />
      {/* Add new subject modal */}
      <Modal
        show={showAddSubjectModal}
        backdrop="static"
        onHide={() => {
          setShowAddSubjectModal(false);
          setShowSubjectAddedToast(false);
        }}
        centered
      >
        <Modal.Header closeButton className="header-font fs-4">
          Add {level} Subject
        </Modal.Header>
        <Form onSubmit={addSubject}>
          <Toast
            bg="success"
            className="w-100 my-2 mx-auto paragraph-font"
            show={showSubjectAddedToast}
            onClose={() => setShowSubjectAddedToast(false)}
            delay={2000}
            autohide
          >
            <Toast.Body className="d-flex text-light">
              <AiOutlineCheckCircle className="fs-5 me-2 my-auto" />
              Subject Added succesfully
            </Toast.Body>
          </Toast>
          <Modal.Body className="paragraph-font">
            {newSubjectError && (
              <Alert variant="danger">{newSubjectError}</Alert>
            )}
            <Form.Group>
              <Form.Label className="header-font">
                Select Subject to Add
              </Form.Label>
              <Form.Select required defaultValue="" ref={newSubjectRef}>
                {subjectChoices()}
              </Form.Select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="paragraph-font">
            <Button disabled={loading} type="submit">
              {loading ? <Spinner size="sm" /> : "Add Subject"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default TutorSubjectsLevel;
