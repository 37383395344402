import { Alert, Button, Modal, Spinner } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";

const NewTermsBanner = () => {
  // userPRivateDAta autoamticall gets set when updated (realtime databse)
  const { userPrivateData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const location = useLocation();

  const acceptNewTerms = async () => {
    setLoading(true);
    const acceptTerms26Nov2023 = httpsCallable(
      functions,
      "acceptTerms26Nov2023"
    );
    try {
      await acceptTerms26Nov2023();
    } catch (err: any) {
      alert(
        "An error occured. Please contact mh1tutors.contact@gmail.com if this persists."
      );
    }
    setLoading(false);
  };

  // have modal appear when user signs in => must accept terms
  if (userPrivateData && !userPrivateData?.acceptsTerms26Nov2023) {
    return (
      <Modal
        centered
        show={
          !userPrivateData?.acceptsTerms26Nov2023 &&
          location.pathname !== "/terms-of-service"
        }
        backdrop="static"
      >
        <Modal.Body className="light-font">
          <p>
            We've recently updated our terms of service. You can view the
            updates from the "Terms of Services" page linked below. By
            continuing to use our platform, you agree to the new terms.
          </p>
          <Button
            onClick={() => setSeeMore((prevVal) => !prevVal)}
            variant="link"
            style={{ textDecoration: "underline", color: "#808080" }}
            size="sm"
          >
            {seeMore ? "See Less" : "See More"}
          </Button>
          {seeMore && (
            <p style={{ fontSize: 14 }}>
              We've recently updated our terms of service. The full terms can be
              seen at the link below. This change allows us to reserve the right
              to distribute your personal information to tutors/students when a
              connection is made. We will never share your information to
              tutors/students without your consent. If you do not agree to these
              terms, please contact us via the contact button and we will delete
              your account and all associated data.
            </p>
          )}
        </Modal.Body>
        <Modal.Footer className="p-1 d-flex justify-content-between">
          <Link
            target="_blank"
            to="/terms-of-service"
            className="me-2 my-auto"
            style={{ fontSize: 12 }}
          >
            See Terms
          </Link>
          <Button onClick={acceptNewTerms} disabled={loading}>
            {loading ? <Spinner size="sm" /> : "Agree"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return null;
};

export default NewTermsBanner;
