import { useEffect, useState } from "react";
import { Form, InputGroup, Button, Collapse } from "react-bootstrap";
import { dbSubjectStrings, subjectLevels } from "../../constants/strings";
import { useSearchParams } from "react-router-dom";
import { FiCheckCircle, FiChevronUp, FiX } from "react-icons/fi";

type Props = {};

const LevelsFilterSelect = ({}: Props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsInSearch, setItemsInSearch] = useState(subjectLevels);

  const [expanded, setExapanded] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  // update search parameter
  const handleItemClick = (item: string) => {
    if (searchParams.get("level") === item) {
      setSearchParams((prevParams) => {
        prevParams.delete("level");
        prevParams.delete("sort");
        return prevParams;
      });
    } else {
      const dbLabel = dbSubjectStrings[subjectLevels.indexOf(item)];
      const newParams = new URLSearchParams(searchParams);
      newParams.set("level", dbLabel);
      setSearchParams(newParams);
    }
    setExapanded(false);
  };

  useEffect(() => {
    if (searchTerm) {
      // search for item in formatted array, but set the itemsInSearch
      // array using non formatted values (full array)
      const temp =
        subjectLevels &&
        subjectLevels.filter((str) => {
          return str.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
        });
      setItemsInSearch(temp || []);
    } else {
      setItemsInSearch(subjectLevels);
    }
  }, [searchTerm]);

  return (
    <>
      <div>
        {/* REMOVE HOVER EFFECT */}
        <InputGroup className="shadow-sm rounded">
          <Form.Control
            onChange={(e) => {
              if (e.target.value) setExapanded(true);
              return setSearchTerm(e.target.value);
            }}
            placeholder="Select Level"
            aria-label="Select Level"
          />
          <Button
            className="d-flex border"
            variant="light"
            onClick={() => {
              setExapanded((prevState) => !prevState);
            }}
          >
            <FiChevronUp
              className="mx-auto my-auto"
              style={{
                transform: !expanded ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease",
              }}
            />
          </Button>
        </InputGroup>

        <Collapse in={expanded}>
          <div>
            <div
              className="d-flex flex-column font-light border rounded mt-2 shadow-sm"
              style={{ maxHeight: 250, overflowY: "scroll" }}
            >
              {itemsInSearch.map((item) => (
                <div
                  onClick={() => handleItemClick(item)}
                  key={item}
                  className={
                    "py-1 ps-3 rounded hover-opacity d-flex flex-row justify-content-between cursor-pointer " +
                    (searchParams.get("level") ===
                    dbSubjectStrings[subjectLevels.indexOf(item)]
                      ? "paragraph-font"
                      : "light-font")
                  }
                >
                  {item}
                  {searchParams.get("level") ===
                  dbSubjectStrings[subjectLevels.indexOf(item)] ? (
                    <FiCheckCircle className="text-success my-auto me-3" />
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </Collapse>

        {searchParams.get("level") && (
          <div className="d-flex mt-2" style={{ flexFlow: "row wrap" }}>
            <div className="header-font w-100">Selected Level:</div>
            <div
              className="blue-bubble-hover d-flex me-2 ps-1 rounded my-1"
              style={{
                backgroundColor: "#a9e0fb",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.15)",
              }}
            >
              {
                subjectLevels[
                  dbSubjectStrings.indexOf(searchParams.get("level") || "")
                ]
              }
              <FiX
                className="subject-bubble-icon my-auto mx-1 cursor-pointer"
                onClick={() => handleItemClick(searchParams.get("level") || "")}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LevelsFilterSelect;
