import { ReactNode, useEffect, useRef, useState } from "react";

interface Props {
  text: String;
  title: String;
  icon: ReactNode;
  reverse?: boolean;
}

const HomePageBenefit = ({ text, title, icon, reverse }: Props) => {
  const domRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    let currentVal: any = null;
    if (!domRef.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    currentVal = domRef.current;
    return () => {
      if (currentVal) return observer.unobserve(currentVal);
    };
  }, []);

  return (
    <div
      className={`my-5 py-3 text-center mx-auto text-center text-md-justify d-flex flex-column ${
        reverse ? "flex-md-row-reverse" : "flex-md-row"
      } fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
      style={{
        maxWidth: 900,
        minHeight: 150,
      }}>
      <div className="d-flex mx-auto" style={{ minWidth: "50%" }}>
        <div
          style={{
            fontSize: "80px",
            borderRadius: 20,
            backgroundColor: "#232323",
            minWidth: 150,
            minHeight: 100,
          }}
          className="shadow-lg mx-auto my-auto d-flex h-100">
          {icon}
        </div>
      </div>
      <div className="mx-auto" style={{ minWidth: "50%" }}>
        <h3
          className="header-font mt-3 fs-2"
          style={{ letterSpacing: "0.05rem" }}>
          {title}
        </h3>
        <p
          className="mt-2 mx-3 light-font"
          style={{ wordWrap: "break-word", textAlign: "justify" }}>
          {text}
        </p>
      </div>
    </div>
  );
};

export default HomePageBenefit;
